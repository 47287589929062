import {fork, put, take, select} from 'redux-saga/effects';
import moment from 'moment';
import * as messagesActionTypes from '../messagesActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import * as userProfileSelectors from '../../user-management/userManagementSelectors';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';
import {eafMessageOriginator} from '../../ella-dispo-entity-types/message';

/**
 * Get VP messages
 */
const getPartnersMessagesFlow = function* getPartnersMessagesFlow() {
    const userAccount = yield select(userProfileSelectors.getUserAccount);

    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    yield put({
        type: messagesActionTypes.STORE_PARTNER_MESSAGES,
        payload: {messages: []},
    });

    const defaultParams = {
        size: 100000,
        lastCreatedAfter: moment().subtract(13, 'days')
            .set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString(),
        originator: [eafMessageOriginator.NAV, eafMessageOriginator.ALF],
    };

    yield fork(fetchRequest, messagesActionTypes.GET_PARTNER_MESSAGES_REQUEST,
        eafService.getPartnerMessages, {contractPartnerIds: userAccount.partnerIds, ...defaultParams});

    const resultAction = yield take([
        messagesActionTypes.GET_PARTNER_MESSAGES_REQUEST_SUCCEEDED,
        messagesActionTypes.GET_PARTNER_MESSAGES_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {response} = resultAction.payload;
        yield put({
            type: messagesActionTypes.STORE_PARTNER_MESSAGES,
            payload: response,
        });

        yield put({
            type: messagesActionTypes.REFRESH_MESSAGES,
        });
    }
    if (resultAction.error) {
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export default getPartnersMessagesFlow;
