import {immerable} from 'immer';
import moment from 'moment';
import Entity from '../entity/Entity';
import Person from '../person/Person';


/**
 * Vehicle entity type definition
 *
 * @typedef {Object} Vehicle
 * @property {?string} licensePlate
 * @property {?string} manufacturer
 * @property {?string} model
 * @property {?number} odometer
 * @property {?string} registrationDateTime
 * @property {?string} weight
 * @property {?string} hasTrailer
 * @property {?Person} driver
 * @property {?Person} owner
 * @property {?number} length
 * @property {?number} width
 * @property {?number} height
 * @property {?string} color
 * @property {?string} specialFeatures
 */

/**
 * Vehicle entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const vehicleEntityDTOMapping = {
    licensePlate: {
        dtoProperty: 'licensePlate',
        fromDTO: data => (data ? data.toUpperCase() : ''),
    },
    manufacturer: {
        dtoProperty: 'manufacturer',
        defaultValue: '',
    },
    model: {
        dtoProperty: 'model',
        defaultValue: '',
    },
    weight: {
        dtoProperty: 'weight',
        defaultValue: '',
    },
    odometer: {
        dtoProperty: 'odometer',
        defaultValue: 0,
    },
    length: {
        dtoProperty: 'length',
        defaultValue: 0,
    },
    hasTrailer: {
        dtoProperty: 'hasTrailer',
        defaultValue: false,
    },
    width: {
        dtoProperty: 'width',
        defaultValue: 0,
    },
    driver: {
        dtoProperty: 'driver',
        defaultValue: new Person(),
        entity: Person,
    },
    owner: {
        dtoProperty: 'owner',
        defaultValue: new Person(),
        entity: Person,
    },
    height: {
        dtoProperty: 'height',
        defaultValue: 0,
    },
    color: {
        dtoProperty: 'color',
        defaultValue: '',
    },
    specialFeatures: {
        dtoProperty: 'specialFeatures',
        defaultValue: '',
    },
    registrationDateTime: {
        dtoProperty: 'registrationDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
};

/**
 * Vehicle entity
 *
 * @class
 * @extends Entity
 * @type {Vehicle}
 */
export default class Vehicle extends Entity {
    [immerable] = true;

    static entityDTOMapping = vehicleEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
