import {immerable} from 'immer';
import Entity from '../entity/Entity';

/**
 * Attachment entity type definition
 *
 * @typedef {Object} Attachment
 * @property {?string} id
 * @property {?string} url
 * @property {?string} fileName
 * @property {?boolean} isSignature
 * @property {?boolean} isReceipt
 */

/**
 * Attachment entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const attachmentEntityDTOMapping = {
    id: {
        dtoProperty: 'id',
        defaultValue: '',
    },
    url: {
        dtoProperty: 'url',
        defaultValue: '',
    },
    fileName: {
        dtoProperty: 'fileName',
        defaultValue: '',
    },
    isSignature: {
        dtoProperty: 'isSignature',
        defaultValue: false,
    },
    isReceipt: 'isReceipt',
};

/**
 * Attachment entity
 *
 * @class
 * @extends Entity
 * @type {Attachment}
 */
export default class Attachment extends Entity {
    [immerable] = true;

    static entityDTOMapping = attachmentEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
