import React from 'react';
import {useTranslate} from '@computerrock/formation-i18n';
import './AccessNotAllowedView.scss';
import AppLayout from '../AppLayoutView';

const AccessNotAllowedView = () => {
    const {translate} = useTranslate();
    return (
        <AppLayout>
            <div className="ace-access-not-allowed-view__header-container">
                <h3 className="ace-h3">
                    {translate('access_not_allowed_view.section_title.access_not_allowed')}
                </h3>
            </div>
        </AppLayout>
    );
};

export default AccessNotAllowedView;
