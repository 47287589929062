
export const processTime = (time, isNullDate = false) => {
    return {
        ...(isNullDate ? {
            hours: '',
            minutes: '',
        } : {
            hours: `${time.hours()}`,
            minutes: `${time.minutes()}`,
        }),
    };
};
