import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as messagesActionTypes from '../messagesActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import markMessagesAsRead from './markMessagesAsRead';
import {parseErrorMessages} from '../../utils/validationMessages';

const getInvoiceSubmissionMessages = function* getInvoiceSubmissionMessages(payload) {
    const {invoiceSubmissionId} = payload;
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    yield put({
        type: messagesActionTypes.STORE_INVOICE_SUBMISSION_MESSAGES,
        payload: {messagesDTO: []},
    });

    yield fork(fetchRequest, messagesActionTypes.GET_INVOICE_SUBMISSION_MESSAGES_REQUEST,
        eafService.getInvoiceSubmissionMessages, {invoiceSubmissionId});

    const actionResult = yield take([
        messagesActionTypes.GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_SUCCEEDED,
        messagesActionTypes.GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        const messagesDTO = actionResult.payload.response.data;
        yield put({
            type: messagesActionTypes.STORE_INVOICE_SUBMISSION_MESSAGES,
            payload: {messagesDTO},
        });
        const unreadMessageIds = messagesDTO.filter(message => {
            return message.isRead === false;
        }).map(message => {
            return message.messageId;
        });

        if (unreadMessageIds.length) {
            yield fork(markMessagesAsRead, {unreadMessageIds});
        }

        return;
    }
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(actionResult.payload),
            },
        },
    });
};

export default getInvoiceSubmissionMessages;
