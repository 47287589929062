import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslate} from '@computerrock/formation-i18n';
import {Icon} from '../ui-components';
import AceIcon from '../assets/images/ACE_Logo.svg';
import MessageInput from './view-elements/MessageInput';
import {eafMessageOriginator} from '../ella-dispo-entity-types/message';
import './CaseMessageView.scss';


const DATE_TIME_FORMAT = 'DD.MM.YYYY, HH:mm';

const CaseMessagesView = props => {
    const {messages, partnerName, invoiceSubmissionId, isMessageInputFieldShown, isAccordionOpen} = props;
    const [isMessagesAccordionOpen, setIsMessagesAccordionOpen] = useState(isAccordionOpen);
    const {createTranslateShorthand} = useTranslate();
    const translateView = createTranslateShorthand('case_message_view');

    useEffect(() => {
        setIsMessagesAccordionOpen(isAccordionOpen);
    }, [isAccordionOpen]);

    const iconClassName = classNames('ace-c-case-messages-view__toggle-icon', {
        'ace-c-case-messages-view__toggle-icon--up': isMessagesAccordionOpen,
    });
    return (
        <div className="ace-c-case-messages-view">
            <div
                className="ace-c-case-messages-view__header"
                onClick={() => setIsMessagesAccordionOpen(!isMessagesAccordionOpen)}
            >
                <h4 className="ace-h4">
                    {translateView('section_title.message')}
                </h4>
                <div className={iconClassName}>
                    <Icon dimension="lg" iconName="expand-arrow" />
                </div>
            </div>
            {
                isMessagesAccordionOpen && (
                    <div key={0} className="ace-c-case-messages-view__message-wrapper">
                        {
                            messages && messages.length > 0 ? messages.map(message => {
                                const {text, createdAt, messageId, originator, author} = message;
                                const isMessageFromACE = originator === eafMessageOriginator.NAV;
                                const isMessageFromPartner = originator === eafMessageOriginator.ELLA;
                                const isMessageFromALF = originator === eafMessageOriginator.ALF;
                                const messageSender = isMessageFromACE ? '' : isMessageFromALF ? author : partnerName;
                                const date = createdAt ? `${createdAt.format(DATE_TIME_FORMAT)} Uhr` : '-';
                                const messagePanelClassName = classNames('ace-c-case-messages-view__message', {
                                    'ace-c-case-messages-view__message--left': isMessageFromACE,
                                    'ace-c-case-messages-view__message--right': isMessageFromPartner,
                                });
                                return (
                                    <div key={messageId} className={messagePanelClassName}>
                                        <div className="ace-c-case-messages-view__message-header">
                                            <div className="ace-c-case-messages-view__message-icon-wrapper">
                                                {isMessageFromACE && (
                                                    <img src={AceIcon} className="ace-c-case-messages-view__message-icon" alt="" />
                                                )}
                                                <span className="ace-c-case-messages-view__message-header-label">{messageSender}</span>
                                            </div>
                                            <span className="ace-c-case-messages-view__message-header-label">{date}</span>
                                        </div>
                                        <p className="ace-c-case-messages-view__message-text">{text.replaceAll(/<[^>]*>/g, ' ').trim()}</p>
                                    </div>
                                );
                            }) : (
                                <div className="ace-c-case-messages-view__no-message-wrapper">
                                    <h4 className="ace-h4">
                                        {translateView('section_title.no_message')}
                                    </h4>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {isMessagesAccordionOpen && isMessageInputFieldShown && (
                <MessageInput
                    key={1}
                    label={translateView('message_input_label.answer')}
                    author={partnerName}
                    invoiceSubmissionId={invoiceSubmissionId}
                />
            )}
        </div>
    );
};

CaseMessagesView.propTypes = {
    messages: PropTypes.array,
    isAccordionOpen: PropTypes.bool,
    partnerName: PropTypes.string,
    invoiceSubmissionId: PropTypes.string,
    isMessageInputFieldShown: PropTypes.bool,
};

CaseMessagesView.defaultProps = {
    messages: [],
    partnerName: '',
    invoiceSubmissionId: '',
    isMessageInputFieldShown: false,
    isAccordionOpen: false,
};

export default CaseMessagesView;
