import {fork, put, take} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import invoiceSubmissionFiles from '../../invoiceSubmissionFiles';

export const downloadBCReceiptPDF = function* downloadBCReceiptPDF() {
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.DOWNLOAD_BC_RECEIPT_PDF);
        const {fileName, url} = payload;

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.DOWNLOADING},
        });

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.DOWNLOAD_BC_RECEIPT_PDF_REQUEST,
            invoiceSubmissionFiles.downloadAttachment,
            {fileName, url},
        );

        const actionResult = yield take([
            invoiceSubmissionActionTypes.DOWNLOAD_BC_RECEIPT_PDF_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.DOWNLOAD_BC_RECEIPT_PDF_REQUEST_FAILED,
        ]);

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });

        if (actionResult.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(actionResult.payload),
                    },
                },
            });
        }
    }
};

export default downloadBCReceiptPDF;
