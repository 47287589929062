import * as actionTypes from './errorActionTypes';
import {parseErrorMessages} from '../utils/validationMessages';

const initialState = {
    error: null,
};

const errorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_ERRORS: {
            const {error} = action.payload;
            state = {
                ...state,
                error,
            };
            break;
        }
        case actionTypes.DELETE_ERRORS: {
            state = {
                ...initialState,
            };
            break;
        }
        // TO-DO refactor this to be just one STORE_ERRORS
        case actionTypes.STORE_FILES_ERROR: {
            const error = {
                ...parseErrorMessages(action.payload),
            };
            state = {
                ...state,
                error,
            };
            break;
        }

        default:
        // no-op
    }
    return state;
};

export default errorsReducer;
