import React from 'react';
import PropTypes from 'prop-types';

import './TableHeader.scss';

const TableHeader = props => {
    return (
        <div className={`ace-grid ${props.className}`}>
            <div className={`ace-grid__row ${props.gridClassName}`}>
                {props.children}
            </div>
        </div>
    );
};

TableHeader.propTypes = {
    className: PropTypes.string,
    gridClassName: PropTypes.string,
};

TableHeader.defaultProps = {
    className: '',
    gridClassName: '',
};

export default TableHeader;
