import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './Icon.scss';

export default class Icon extends PureComponent {
    static propTypes = {
        iconName: PropTypes.string,
        dimension: PropTypes.string.isRequired,
        color: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        iconName: '',
        color: '',
        className: '',
    };

    render() {
        const {iconName, dimension, color, className, ...restProps} = this.props;
        const iconClassName = color ? `ace-c-icon__${iconName}--${color}` : `ace-c-icon__${iconName}`;
        return (
            <div {...restProps} className={`ace-c-icon ace-c-icon--${dimension} ${iconClassName} ${className}`} />
        );
    }
}
