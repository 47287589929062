import {createSelector} from 'reselect';

export const getDriverAssignments = state => state.driverAssignments.driverAssignments;
export const getOpenDriverAssignmentsIds = state => state.driverAssignments.openDriverAssignmentsIds;
export const getAssignedDriverAssignmentsIds = state => state.driverAssignments.assignedDriverAssignmentsIds;
export const getSearchedDriverAssignmentsIds = state => state.driverAssignments.searchedDriverAssignmentsIds;
export const getArchivedDriverAssignmentsIds = state => state.driverAssignments.archivedDriverAssignmentsIds;

export const createOpenDriverAssignmentsSelector = () => createSelector(
    [
        getDriverAssignments,
        getOpenDriverAssignmentsIds,
    ],
    (driverAssignments, openDriverAssignmentsIds) => {
        if (!openDriverAssignmentsIds.length) return [];
        const openDriverAssignments = [];
        openDriverAssignmentsIds.forEach(assignmentId => {
            const driverAssignment = driverAssignments[assignmentId];
            if (driverAssignment) openDriverAssignments.push(driverAssignment);
        });
        return openDriverAssignments;
    },
);

export const createAssignedDriverAssignmentsSelector = () => createSelector(
    [
        getDriverAssignments,
        getAssignedDriverAssignmentsIds,
    ],
    (driverAssignments, assignedDriverAssignmentsIds) => {
        if (!assignedDriverAssignmentsIds.length) return [];
        const openDriverAssignments = [];
        assignedDriverAssignmentsIds.forEach(assignmentId => {
            const driverAssignment = driverAssignments[assignmentId];
            if (driverAssignment) openDriverAssignments.push(driverAssignment);
        });
        return openDriverAssignments;
    },
);

export const createDriverAssignmentSelector = () => createSelector(
    [
        getDriverAssignments,
        (state, props) => {
            const {match} = props;
            return match?.params['driverAssignmentId'];
        },
    ],
    (serviceAssignments, driverAssignmentId) => {
        if (!driverAssignmentId) return null;

        return serviceAssignments[driverAssignmentId];
    },
);

export const createSearchedDriverAssignmentsSelector = () => createSelector(
    [
        getDriverAssignments,
        getSearchedDriverAssignmentsIds,
    ],
    (driverAssignments, searchedDriverAssignmentsIds) => {
        if (!searchedDriverAssignmentsIds.length) return [];
        const searchedDriverAssignments = [];
        searchedDriverAssignmentsIds.forEach(assignmentId => {
            const driverAssignment = driverAssignments[assignmentId];
            if (driverAssignment) searchedDriverAssignments.push(driverAssignment);
        });
        return searchedDriverAssignments;
    },
);

export const createArchivedDriverAssignmentsSelector = () => createSelector(
    [
        getDriverAssignments,
        getArchivedDriverAssignmentsIds,
    ],
    (driverAssignments, archivedDriverAssignmentsIds) => {
        if (!archivedDriverAssignmentsIds.length) return [];
        const archivedDriverAssignments = [];
        archivedDriverAssignmentsIds.forEach(assignmentId => {
            const driverAssignment = driverAssignments[assignmentId];
            if (driverAssignment) archivedDriverAssignments.push(driverAssignment);
        });
        return archivedDriverAssignments;
    },
);


export const getDriverAssignmentSearchResultIds = state => {
    return state.driverAssignments.searchedDriverAssignmentsIds;
};

export const getIsSearchViewActive = state => state.driverAssignments.isSearchViewActive;

