import {select, put, race, fork, take} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import * as userProfileSelectors from '../../user-management/userManagementSelectors';
import {invoiceSubmissionParams} from '../constants/queryParams';
import fetchRequest from '../../application/sagas/fetchRequest';


const searchInvoiceSubmissionsFlow = function* () {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.SEARCH_INVOICE_SUBMISSION);
        const userAccount = yield select(userProfileSelectors.getUserAccount);
        const {searchTerm} = payload;
        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_SEARCH_STATE,
            payload: {
                isSearchStarted: true,
                searchTerm,
            },
        });
        const query = invoiceSubmissionParams.SEARCHED;
        yield fork(fetchRequest, invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST,
            eafService.getInvoiceSubmissions, {
                ...query,
                contractPartnerIds: userAccount.partnerIds,
                phrase: searchTerm,
            });
        const resultAction = yield race({
            success: take(invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED),
            error: take(invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_FAILED),
            clear: take(invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS),
        });

        if (resultAction.success) {
            const invoiceSubmissionsDTO = resultAction.success.payload.response.data;
            yield put({
                type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_SEARCH_STATE,
                payload: {isSearchingEnded: true},
            });
            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_SEARCH_RESULTS,
                payload: {invoiceSubmissionsDTO},
            });
            continue;
        }

        if (resultAction.clear) {
            yield put({
                type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_SEARCH_STATE,
                payload: {isSearchResultsCleared: true, isSearchingEnded: true},
            });
            continue;
        }

        if (resultAction.error) {
            yield put({
                type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_SEARCH_STATE,
                payload: {isSearchingEnded: true},
            });

            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        errorDetail: resultAction.payload,
                    },
                },
            });
        }
    }
};

export default searchInvoiceSubmissionsFlow;
