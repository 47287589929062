import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {withTranslations} from '@computerrock/formation-i18n';
import './MessageInput.scss';
import {Icon} from '../../ui-components';
import * as messagesActionTypes from '../messagesActionTypes';
import {getValidationErrors} from '../../application/errorsSelector';

const KEY_ENTER = 'Enter';

class MessageInput extends React.Component {
    static propTypes = {
        placeholder: PropTypes.any,
        isDisabled: PropTypes.bool,
        isLocked: PropTypes.bool,
        // isFocused: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string,
        error: PropTypes.string,
        errors: PropTypes.object,
        maxLength: PropTypes.number,
        sendMessage: PropTypes.func.isRequired,
        author: PropTypes.string,
        invoiceSubmissionId: PropTypes.string,
        translate: PropTypes.func,
    };

    static defaultProps = {
        placeholder: '',
        isDisabled: false,
        isLocked: false,
        // isFocused: false,
        label: '',
        name: '',
        error: '',
        errors: {},
        maxLength: 1500,
        author: '',
        invoiceSubmissionId: '',
        translate: null,
    };


    constructor(props) {
        super(props);
        this.state = {
            messageText: '',
            isFocused: false,
            rows: 1,
            minRows: 1,
            maxRows: 50,
        };
        this.keyReleased = true;
        this.input = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.stretchTextareaToFitContent);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.stretchTextareaToFitContent);
    }

    onInputFocus = () => {
        const {isLocked, isDisabled} = this.props;
        this.setState({
            isFocused: !isDisabled && !isLocked,
        });
        this.input.focus();
    };

    onInputBlur = () => {
        const {messageText} = this.state;

        this.setState({
            isFocused: !!messageText,
        });
    };

    onSendMessageClick = () => {
        const {messageText} = this.state;
        const {sendMessage, author, invoiceSubmissionId} = this.props;
        if (!messageText) {
            return;
        }
        sendMessage({
            text: messageText,
            author,
            invoiceSubmissionId,
        });
        this.setState({
            messageText: '',
            rows: 1,
            minRows: 1,
            maxRows: 50,
        });
    };

    onInputKeyUp = () => {
        this.keyReleased = true;
    };

    onInputKeyDown = event => {
        const {key} = event;
        if (key !== KEY_ENTER || !this.keyReleased) {
            return;
        }
        event.preventDefault();
        // ensure we send only one search request per keyDown
        this.keyReleased = false;
        this.onSendMessageClick();
    };

    onInputChange = event => {
        this.stretchTextareaToFitContent();
        const {
            target: {value},
        } = event;
        this.setState({
            messageText: value,
        });
    };

    getElementPropertyValue = (element, property) => {
        return window.getComputedStyle(element)
            .getPropertyValue(property)
            .replace('px', '');
    };

    stretchTextareaToFitContent = () => {
        const textareaLineHeight = this.getElementPropertyValue(this.input, 'line-height');
        const textareaTopPadding = this.getElementPropertyValue(this.input, 'padding-top');
        const textareaBottomPadding = this.getElementPropertyValue(this.input, 'padding-bottom');
        const {minRows, maxRows} = this.state;

        const previousRows = this.input.rows;
        this.input.rows = minRows;

        const currentRows = Math.floor(
            (this.input.scrollHeight - textareaTopPadding - textareaBottomPadding) / textareaLineHeight,
        );

        if (currentRows === previousRows) {
            this.input.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            this.input.rows = maxRows;
            this.input.scrollTop = this.input.scrollHeight;
        }
        this.setState({
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
    };

    render() {
        const {isDisabled, isLocked, placeholder, error, name, label, maxLength, errors, translate} = this.props;
        const {searchActive, messageText, isFocused, rows} = this.state;
        const iconWrapperClassName = classNames('ace-c-message-input__icon-wrapper', {
            'ace-c-message-input__icon-wrapper--disabled': isDisabled,
            'ace-c-message-input__icon-wrapper--locked': isLocked,
        });
        const searchInputClassName = classNames('ace-c-message-input', {
            'ace-c-message-input--disabled': isDisabled,
            'ace-c-message-input--error': error || errors['text'],
            'ace-c-message-input--focused': isFocused,
            'ace-c-message-input--locked': isLocked,
        });
        const labelClassName = classNames('ace-c-message-input__label', {
            'ace-c-message-input__label--focused': isFocused,
        });
        const errorLabelClassName = classNames('ace-c-message-input__error-label', {
            'ace-c-message-input__error-label--disabled': isDisabled,
            'ace-c-message-input__error-label--focused': messageText || isFocused,
        });

        return (
            <div className="ace-c-message-input__wrapper">
                <div className={iconWrapperClassName} onClick={this.onSendMessageClick}>
                    <Icon dimension="xl" iconName="arrow-right" />
                </div>
                <textarea
                    className={searchInputClassName}
                    ref={input => {
                        this.input = input;
                    }}
                    name={name}
                    value={messageText === '' && searchActive ? this.onExitSearch : messageText}
                    placeholder={placeholder}
                    onChange={this.onInputChange}
                    disabled={isDisabled}
                    onKeyDown={this.onInputKeyDown}
                    onKeyUp={this.onInputKeyUp}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    rows={rows}
                    maxLength={maxLength}
                />
                <div className={labelClassName}>
                    {label}
                </div>
                {Object.keys(errors).length !== 0 && (
                    <div
                        className={`${errorLabelClassName}`}
                    >{translate(`global.validation_messages.${errors['text']}`)}
                    </div>
                )}
                {error && <div className={`${errorLabelClassName}`}>{error}</div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        errors: getValidationErrors(state),
    };
};
const mapDispatchToProps = dispatch => ({
    sendMessage: payload => dispatch({type: messagesActionTypes.SEND_MESSAGE, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslations(MessageInput));
