import {fork, put, select, take} from 'redux-saga/effects';
import * as driverAssignmentActions from '../driverAssignmentActions';
import * as errorActionTypes from '../../application/errorActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';
import {driverAssignmentViews} from '../constants/driverAssignmentViews';
import {dafStatuses} from '../../ella-dispo-entity-types';
import * as userManagementActions from '../../user-management/userManagementActionTypes';

const updateDriverAssignmentDataFlow = function* updateDriverAssignmentDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');

    while (true) {
        const {payload} = yield take(driverAssignmentActions.SUBMIT_DRIVER_ASSIGNMENT_DATA_CHANGES);
        const {assignmentId, data} = payload;

        yield put({type: errorActionTypes.DELETE_ERRORS});
        yield put({
            type: driverAssignmentActions.STORE_UPDATE_STATUS,
            payload: {isUpdating: true},
        });

        yield fork(
            fetchRequest,
            driverAssignmentActions.UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST,
            dafService.updateAssignment,
            {
                assignmentId,
                data,
            },
        );
        const updateAssignmentResponse = yield take([
            driverAssignmentActions.UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_SUCCEEDED,
            driverAssignmentActions.UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_FAILED,
        ]);

        if (!updateAssignmentResponse.error) {
            const driverAssignmentDTO = updateAssignmentResponse.payload.response;
            const driverAssignmentsViewState = driverAssignmentDTO.status === dafStatuses.BILLABLE
            || driverAssignmentDTO.status === dafStatuses.DECLINED
                ? driverAssignmentViews.DISPATCH_INACTIVE : driverAssignmentViews.DISPATCH_ACTIVE;


            yield put({
                type: driverAssignmentActions.STORE_UPDATE_STATUS,
                payload: {isUpdating: false},
            });

            yield put({
                type: driverAssignmentActions.STORE_DRIVER_ASSIGNMENT_VIEW_STATE,
                payload: {
                    driverAssignmentsViewState,
                },
            });

            const apmService = serviceManager.loadService('partnerManagementService');

            yield fork(
                fetchRequest,
                driverAssignmentActions.GET_CONTRACT_PARTNER_INFO_REQUEST,
                apmService.getContractPartner,
                {vpId: driverAssignmentDTO.contractPartner?.id},
            );

            const resultContractPartnerAction = yield take([
                driverAssignmentActions.GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED,
                driverAssignmentActions.GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED,
            ]);

            if (resultContractPartnerAction.error) {
                yield put({
                    type: errorActionTypes.STORE_ERRORS,
                    payload: {
                        error: {
                            ...parseErrorMessages(resultContractPartnerAction.payload),
                        },
                    },
                });
                return;
            }

            const {vpContactsDTO} = resultContractPartnerAction.payload.response;

            let vpDriver = null;
            if (driverAssignmentDTO.driverId) {
                const {serviceManager} = yield select(state => state.application);
                const deumService = serviceManager.loadService('userManagementService');

                yield fork(
                    fetchRequest,
                    userManagementActions.FETCH_USER_REQUEST,
                    deumService.fetchUser,
                    {userId: driverAssignmentDTO.driverId},
                );

                const vpDriverResult = yield take([
                    userManagementActions.FETCH_USER_REQUEST_SUCCEEDED,
                    userManagementActions.FETCH_USER_REQUEST_FAILED,
                ]);
                if (!vpDriverResult.error) {
                    vpDriver = vpDriverResult.payload.response;
                }
            }

            yield put({
                type: driverAssignmentActions.STORE_DRIVER_ASSIGNMENT,
                payload: {
                    ...driverAssignmentDTO,
                    vpDriver,
                    contractPartner: vpContactsDTO,
                },
            });

            yield put({
                type: driverAssignmentActions.TOGGLE_DRIVER_ASSIGNMENT_EDIT_MODE,
                payload: {isEditMode: false},
            });

            continue;
        }

        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(updateAssignmentResponse.payload),
                },
            },
        });
    }
};

export default updateDriverAssignmentDataFlow;
