import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {invoiceSubmissionViews} from '../constants/invoiceSubmissionViews';
import './ViewIndicator.scss';


const View = {
    EDIT_FORM: 'EDIT_FORM',
    OVERVIEW: 'OVERVIEW',
};

const Indicator = ({text, indicator, view, selectedView}) => {
    const indicatorClassName = classNames('view-indicator__indicator', {
        'view-indicator__indicator--active': view === selectedView,
    });
    return (
        <div className="view-indicator__wrapper">
            <div className={indicatorClassName}>
                {indicator}
            </div>
            <div className="view-indicator__text">
                {text}
            </div>
        </div>
    );
};

Indicator.propTypes = {
    text: PropTypes.string.isRequired,
    indicator: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    selectedView: PropTypes.string.isRequired,
};


class ViewIndicator extends React.Component {
    static propTypes = {
        viewState: PropTypes.string,
    };

    static defaultProps = {
        viewState: false,
    };

    getView = viewState => {
        if (viewState === invoiceSubmissionViews.FORM) {
            return View.EDIT_FORM;
        }
        return View.OVERVIEW;
    };

    render() {
        const selectedView = this.getView(this.props.viewState);
        return (
            <div className="view-indicator__container">
                <Indicator
                    key={1}
                    text="Leistungserfassung"
                    indicator="1"
                    view={View.EDIT_FORM}
                    selectedView={selectedView}
                />
                <div key={2} className="view-indicator__separator" />
                <Indicator
                    key={3}
                    text="Zusammenfassung"
                    indicator="2"
                    view={View.OVERVIEW}
                    selectedView={selectedView}
                />
            </div>
        );
    }
}
export default ViewIndicator;
