import {immerable} from 'immer';
import Entity from '../entity/Entity';
import Location from '../location/Location';
import Address from '../address/Address';

/**
 * Damage entity type definition
 *
 * @typedef {Object} Damage
 * @property {?Location} location
 * @property {?Address} address
 * @property {?number} adultsOnSite
 * @property {?number} childrenOnSite
 * @property {?number} description
 * @property {?Array} childrenAge
 */

/**
 * Damage entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const damageEntityDTOMapping = {
    location: {
        dtoProperty: 'location',
        defaultValue: new Location(),
        entity: Location,
    },
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    adultsOnSite: {
        dtoProperty: 'adultsOnSite',
        defaultValue: 0,
    },
    childrenOnSite: {
        dtoProperty: 'childrenOnSite',
        defaultValue: 0,
    },
    description: {
        dtoProperty: 'description',
        defaultValue: '',
    },
    childrenAge: {
        dtoProperty: 'childrenAge',
        defaultValue: [],
    },
};

/**
 * Damage entity
 *
 * @class
 * @extends Entity
 * @type {Damage}
 */
export default class Damage extends Entity {
    [immerable] = true;

    static entityDTOMapping = damageEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
