import moment from 'moment';

export const convertNumberToCurrency = amount => {
    const newAmount = amount || 0;
    const price = newAmount.toLocaleString('de', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return price.substring(0, price.length - 2);
};

export const convertNumberToKilometers = amount => {
    return amount.toLocaleString('de', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
};

export const encodeFloatStandard = amount => {
    const amountString = amount.toString();
    return amountString.replace(',', '.');
};

export const decodeFloatStandard = amount => {
    const amountString = amount.toString();
    return amountString.replace('.', ',');
};
export const convertTime = time => {
    const {
        hours = 0,
        minutes = 0,
        seconds = 0,
        nanos = 0,
    } = time || {};
    return [
        +hours,
        +minutes,
        +seconds,
        +nanos,
    ];
};

const TIME_FORMAT = 'HH:mm:ss';

export const convertStringTimeToArray = time => {
    return [
        moment(time, TIME_FORMAT).hours(),
        moment(time, TIME_FORMAT).minutes(),
        0,
        0,
    ];
};

export const trimBase64DataUrl = dataUrl => {
    const regex = /^data:.*?\/.*?;base64,(.*)$/;
    return `${dataUrl}`.replace(regex, '$1');
};
