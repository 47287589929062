import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {withRouter} from '@computerrock/formation-router';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import routePaths from '../routePaths';
import {UserRoles} from '../ella-dispo-entity-types';
import AppLayout from './AppLayoutView';
import {ButtonSecondary} from '../ui-components';
import AceLogo from '../assets/images/ACE_Logo.svg';
import './SelectAppView.scss';


const SelectAppView = props => {
    const {history, userAccount} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateView = createTranslateShorthand('select_app_view');
    const isAccountUser = userAccount && typeof userAccount === 'object' && Object.keys(userAccount).length
    && (userAccount.userRoles.includes(UserRoles.ACCOUNTANT));
    const isDispatchUser = userAccount && typeof userAccount === 'object' && Object.keys(userAccount).length
    && (userAccount.userRoles.includes(UserRoles.DISPATCHER));
    const appBoxClassString = classnames('select-app-view__app col-xs--5', {
        'select-app-view__app--right': isAccountUser,
        'select-app-view__app--left col-start-xs--2': !isAccountUser,
    });
    return (
        <AppLayout>
            <div className="select-app-view">
                <div className="ace-grid__row">
                    <div className="select-app-view__header col-xs--6 col-start-xs--4 ace-bottom-margin--md">
                        <h3 className="ace-h3 ace-h3--center ace-bottom-margin--md">
                            {translateView('section_title.ace_access')}
                        </h3>
                        <p className="ace-copy-m--center">{translateView('section_subtitle.where_to_forward')}</p>
                    </div>
                </div>
                <div className="ace-grid__row ace-bottom-margin--xl">
                    {isAccountUser && (
                        <div className="select-app-view__app select-app-view__app--left col-xs--5 col-start-xs--2">
                            <div className="select-app-view__app-content">
                                <div className="select-app-view__logo-shape">
                                    <div className="select-app-view__logo-text">{translateView('section_title.ella')}</div>
                                    <img className="ace-lower-header__logo" src={AceLogo} alt="" />
                                </div>
                                <p className="ace-copy-m ace-bottom-margin--smd">
                                    <b>{translateView('section_title.ella')}</b>
                                    - {translateView('section_description.ella')}
                                </p>
                                <ButtonSecondary name="Ella" label={translateView('button_label.choose')} onClick={() => history.push(routePaths.OPEN_INVOICE_SUBMISSIONS)} />
                            </div>
                        </div>
                    )}
                    {isDispatchUser && (
                        <div className={appBoxClassString}>
                            <div className="select-app-view__app-content">
                                <div className="select-app-view__logo-shape">
                                    <div className="select-app-view__logo-text">{translateView('section_title.dispo')}</div>
                                    <img className="ace-lower-header__logo" src={AceLogo} alt="" />
                                </div>
                                <p className="ace-copy-m ace-bottom-margin--smd">
                                    <b>{translateView('section_title.dispo')}</b>
                                    - {translateView('section_description.dispo')}
                                </p>
                                <ButtonSecondary name="Dispatch" label={translateView('button_label.choose')} onClick={() => history.push(routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS)} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </AppLayout>
    );
};

SelectAppView.propTypes = {
    history: PropTypes.object.isRequired,
    userAccount: PropTypes.object,
};

SelectAppView.defaultProps = {
    userAccount: null,
};

const mapStateToProps = state => ({
    userAccount: state.userManagement.user,
});

export default connect(mapStateToProps)(withRouter(SelectAppView));
