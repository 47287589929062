import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import routePaths from '../../../routePaths';
import {TableBody, TableCell, TableRow} from '../../../ui-components/table';
import {HoverIcon} from '../../../ui-components';
import './DriverAssignmentsArchiveRowDetail.scss';
import * as driverAssignmentActionTypes from '../../driverAssignmentActions';
import {dafStatuses} from '../../../ella-dispo-entity-types';


const DriverAssignmentsArchiveRowDetail = ({driverAssignment, history, downloadProofOfService, isDownloadActive}) => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateRow = createTranslateShorthand('driver_assignment_archive_row');
    const startTime = driverAssignment.startDate
        ? `${driverAssignment.startDate.format('DD.MM.YYYY - HH:mm')} ${translate('global.time.hours')}`
        : '-';
    const endTime = driverAssignment.endDate
        ? `${driverAssignment.endDate.format('DD.MM.YYYY - HH:mm')} ${translate('global.time.hours')}`
        : '-';
    const viewDriverAssignment = e => {
        e.preventDefault();
        history.push(resolveRoute(routePaths.DISPATCH_DRIVER_ASSIGNMENT, {
            driverAssignmentId: driverAssignment.assignmentId,
        }));
    };
    return (
        <TableBody className="ace-driver-assignment-archive-row-detail__table-body">
            <TableRow className="ace-driver-assignment-archive-row-detail__table-row ace-driver-assignment-archive-row-detail__table-row--border">
                <TableCell className="col-sm--2 ace-driver-assignment-archive-row-detail__cell ace-driver-assignment-archive-row-detail__flex-start">
                    <p className="ace-copy-s">
                        <b>{translateRow('table_cell.period')}:</b>
                    </p>
                </TableCell>
                <TableCell className="col-sm--4 ace-driver-assignment-archive-row-detail__cell ace-driver-assignment-archive-row-detail__time-cell">
                    <p className="ace-driver-assignment-archive-row-detail__time-text">
                        <span className="ace-driver-assignment-archive-row-detail__time-text-title">
                            {translateRow('table_cell.start')}:
                        </span>
                        <span>{startTime}</span>
                    </p>
                    <p className="ace-driver-assignment-archive-row-detail__time-text">
                        <span className="ace-driver-assignment-archive-row-detail__time-text-title">
                            {translateRow('table_cell.end')}:
                        </span>
                        <span>{endTime}</span>
                    </p>
                </TableCell>
                <TableCell className="col-sm--2 ace-driver-assignment-archive-row-detail__cell ace-driver-assignment-archive-row-detail__flex-start">
                    <p className="ace-copy-s">
                        <b>{translateRow('table_cell.delivered_services')}:</b>
                    </p>
                </TableCell>
                <TableCell className="col-sm--3 ace-driver-assignment-archive-row-detail__cell ace-driver-assignment-archive-row-detail__flex-start">
                    <p className="ace-copy-s">
                        {driverAssignment.mainServices.length ? driverAssignment.mainServices
                            .map(service => translate(`global.daf_main_services.${service.toLowerCase()}`)).join(', ') : '---'}
                    </p>
                </TableCell>
                <TableCell className="col-sm--1 ace-driver-assignment-archive-row-detail__cell ace-c-table-cell--right">
                    <HoverIcon
                        icon="eye"
                        hoverText={translateRow('hover_icon_text.opinion')}
                        onClick={viewDriverAssignment}
                        isLink
                        href={`${routePaths.DISPATCH_DAMAGE_EVENTS}/${driverAssignment.assignmentId}`}
                    />
                </TableCell>
            </TableRow>
            <TableRow className="ace-driver-assignment-archive-row-detail__table-row">
                <TableCell className="col-sm--2 ace-driver-assignment-archive-row-detail__cell ace-archive-row-detail__flex-start">
                    <p className="ace-copy-s">
                        <b>{translateRow('table_cell.remarks')}:</b>
                    </p>
                </TableCell>
                <TableCell className="col-sm--3 ace-driver-assignment-archive-row-detail__cell ace-archive-row-detail__flex-start">
                    <p className="ace-copy-s">{driverAssignment.mainServicesComment || '---'}</p>
                </TableCell>
                <TableCell className="col-sm--6 ace-driver-assignment-archive-row-detail__cell ace-driver-assignment-archive-row-detail__flex-start" />
                {driverAssignment.status === dafStatuses.BILLABLE && (
                    <TableCell className="col-sm--1 ace-driver-assignment-archive-row-detail__cell ace-c-table-cell--right">
                        <HoverIcon
                            icon="download"
                            isDisabled={isDownloadActive}
                            hoverText={translateRow('hover_icon_text.achievement_certificate')}
                            onClick={() => {
                                downloadProofOfService({driverAssignmentId: driverAssignment.assignmentId});
                            }}
                        />
                    </TableCell>
                )}
            </TableRow>
        </TableBody>
    );
};

DriverAssignmentsArchiveRowDetail.propTypes = {
    driverAssignment: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isDownloadActive: PropTypes.bool,
    downloadProofOfService: PropTypes.func.isRequired,
};

DriverAssignmentsArchiveRowDetail.defaultProps = {
    isDownloadActive: false,
};

const mapStateToProps = state => {
    return {
        isDownloadActive: state.driverAssignments.isDownloadActive,
    };
};

const mapDispatchToProps = dispatch => ({
    downloadProofOfService: payload => dispatch({
        type: driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DriverAssignmentsArchiveRowDetail));
