import {invoiceSubmissionViews} from './invoiceSubmissionViews';
import {eafStatuses} from '../../ella-dispo-entity-types';

export const eafArchiveStatuses = [
    eafStatuses.BOOKED,
    eafStatuses.PAID,
    eafStatuses.PAYMENT_DECLINED,
    eafStatuses.EXTERNAL_INVOICING,
];

export const eafStatusPillTypes = {
    [eafStatuses.BILLABLE]: 'red',
    [eafStatuses.IN_EDITING]: 'grey',
    [eafStatuses.CREDITING_REQUESTED]: 'trans-red',
    [eafStatuses.REQUEST]: 'trans-red',
    [eafStatuses.BOOKED]: 'grey',
    [eafStatuses.PAID]: 'trans-red',
    [eafStatuses.PAYMENT_DECLINED]: 'trans-grey',
    [eafStatuses.EXTERNAL_INVOICING]: 'trans-grey',
};

export const eafViewFromStatus = {
    [eafStatuses.BILLABLE]: invoiceSubmissionViews.FORM,
    [eafStatuses.IN_EDITING]: invoiceSubmissionViews.FORM,
    [eafStatuses.CREDITING_REQUESTED]: invoiceSubmissionViews.SUMMARY,
    [eafStatuses.REQUEST]: invoiceSubmissionViews.SUMMARY,
    [eafStatuses.BOOKED]: invoiceSubmissionViews.SUMMARY,
    [eafStatuses.PAID]: invoiceSubmissionViews.SUMMARY,
    [eafStatuses.PAYMENT_DECLINED]: invoiceSubmissionViews.SUMMARY,
    [eafStatuses.EXTERNAL_INVOICING]: invoiceSubmissionViews.EXTERNAL,
};

export const submitPopUpStatuses = {
    VALIDATION_FAILED: 'VALIDATION_FAILED',
    SUCCESS: 'SUCCESS',
    CLOSED: 'CLOSED',
};
