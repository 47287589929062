import {fork, put, take, select} from 'redux-saga/effects';
import {resolveRoute, END_SIDE_EFFECTS_RUNNING, push, replace} from '@computerrock/formation-router';
import * as actionTypes from './userManagementActionTypes';
import * as errorActionTypes from '../application/errorActionTypes';
import fetchRequest from '../application/sagas/fetchRequest';
import * as selectors from './userManagementSelectors';
import routePaths from '../routePaths';
import {UserRoles} from '../ella-dispo-entity-types';

/**
 * Load users
 */
export const loadUsers = function* loadUsers() {
    const userAccount = yield select(selectors.getUserAccount);
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userManagementService');

    yield put({
        type: actionTypes.CLEAR_USERS_DATA,
    });

    yield fork(fetchRequest, actionTypes.FETCH_USERS_REQUEST, userProfileService.fetchUsers, {
        partnerIds: userAccount.partnerIds,
    });
    const resultAction = yield take([
        actionTypes.FETCH_USERS_REQUEST_SUCCEEDED,
        actionTypes.FETCH_USERS_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const userDTOs = resultAction.payload.response.data;
        yield put({
            type: actionTypes.STORE_USERS,
            payload: {userDTOs},
        });
        return;
    }
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                errorDetail: resultAction.payload.error_description,
            },
        },
    });
};

export const loadUserProfile = function* loadUserProfile() {
    const userProfile = yield select(selectors.getUserAccount);
    if (userProfile && typeof userProfile === 'object' && Object.keys(userProfile).length) return;

    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userManagementService');

    yield fork(fetchRequest, actionTypes.SIGN_IN_USER_REQUEST, userProfileService.getUserAccount);
    const fetchUserResultAction = yield take([
        actionTypes.SIGN_IN_USER_REQUEST_SUCCEEDED,
        actionTypes.SIGN_IN_USER_REQUEST_FAILED,
    ]);

    if (fetchUserResultAction.type === actionTypes.SIGN_IN_USER_REQUEST_FAILED) {
        yield put(replace(routePaths.SYSTEM_ACCESS_DENIED));
        return END_SIDE_EFFECTS_RUNNING;
    }

    const userAccountDTO = fetchUserResultAction.payload.response;
    yield put({
        type: actionTypes.STORE_USER_ACCOUNT,
        payload: {userAccountDTO},
    });
};

export const selectAppAccessControl = function* selectAppAccessControl() {
    const userProfile = yield select(selectors.getUserAccount);

    const shouldRedirectToSelectApp = userProfile.userRoles.includes(UserRoles.ADMINISTRATOR)
    || (userProfile.userRoles.includes(UserRoles.ACCOUNTANT)
    && userProfile.userRoles.includes(UserRoles.DISPATCHER));


    const shouldRedirectToEllaApp = userProfile.userRoles.includes(UserRoles.ACCOUNTANT);

    const shouldRedirectToDispoApp = userProfile.userRoles.includes(UserRoles.DRIVER)
    || userProfile.userRoles.includes(UserRoles.DISPATCHER);

    if (shouldRedirectToSelectApp) {
        yield put(push(resolveRoute(routePaths.SELECT_APP)));
        return;
    }
    if (shouldRedirectToEllaApp) {
        yield put(push(resolveRoute(routePaths.OPEN_INVOICE_SUBMISSIONS)));
        return;
    }
    if (shouldRedirectToDispoApp) {
        yield put(push(resolveRoute(routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS)));
        return;
    }
};

export const userRolesAccessControl = function* ({payload}) {
    const userProfile = yield select(selectors.getUserAccount);
    const userRoles = !userProfile || !userProfile.userRoles.length
        ? [UserRoles.ANONYMOUS] : userProfile.userRoles;

    const {allowedRoles, redirectTo} = payload;
    if (!redirectTo) return;

    yield put({
        type: actionTypes.USER_PROFILE_ACCESS_CONTROL_INITIATED,
        payload: {userRoles, allowedRoles, redirectTo},
    });

    if (!userRoles.find(userRole => allowedRoles.includes(userRole))) {
        yield put(push(redirectTo));
        return END_SIDE_EFFECTS_RUNNING;
    }
};

export const registerPushNotifications = function* registerPushNotifications() {
    const {serviceManager} = yield select(state => state.application);
    const deumService = serviceManager.loadService('userManagementService');
    yield fork(fetchRequest, actionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST,
        deumService.unregisterPushNotifications);

    const unsubscribeActionResult = yield take([
        actionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_SUCCEEDED,
        actionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_FAILED,
    ]);

    if (unsubscribeActionResult.error) {
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    errorDetail: unsubscribeActionResult.payload,
                },
            },
        });
    }

    yield fork(fetchRequest, actionTypes.REGISTER_PUSH_NOTIFICATIONS_REQUEST,
        deumService.registerPushNotifications);

    const subscribeActionResult = yield take([
        actionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_SUCCEEDED,
        actionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_FAILED,
    ]);

    if (subscribeActionResult.error) {
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    errorDetail: subscribeActionResult.payload,
                },
            },
        });
    }
};

