import {put, fork, take, call, select} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';
import fetchRequest from '../../application/sagas/fetchRequest';
import getInvoiceSubmissionFlow from './getInvoiceSubmissionFlow';
import {parseErrorMessages} from '../../utils/validationMessages';

const updateVehicleDataFlow = function* updateVehicleDataFlow() {
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.UPDATE_VEHICLE_DATA);
        const {invoiceSubmissionId, data, isNoValidation} = payload;
        yield put({type: errorActionTypes.DELETE_ERRORS});
        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.UPDATING},
        });
        const {serviceManager} = yield select(state => state.application);
        const eafService = serviceManager.loadService('ellaAssignmentFlowService');

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.UPDATE_VEHICLE_DATA_REQUEST,
            eafService.updateVehicleData,
            {invoiceSubmissionId, data, isNoValidation},
        );

        const resultAction = yield take([
            invoiceSubmissionActionTypes.UPDATE_VEHICLE_DATA_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.UPDATE_VEHICLE_DATA_REQUEST_FAILED,
        ]);

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });

        if (!resultAction.error) {
            yield call(getInvoiceSubmissionFlow, {invoiceSubmissionId});
            continue;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export default updateVehicleDataFlow;
