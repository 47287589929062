import {put, fork, take, select} from 'redux-saga/effects';
import {resolveRoute, push} from '@computerrock/formation-router';
import * as driverAssignmentActions from '../driverAssignmentActions';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';
import {driverAssignmentTriggerTypes} from '../constants/driverAssignmentMappings';


const changeDriverAssignmentStatusFlow = function* () {
    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');

    while (true) {
        const {payload} = yield take(driverAssignmentActions.START_CHANGING_DRIVER_ASSIGNMENT_STATUS_FLOW);
        const {driverAssignmentId, data, shouldUpdateTwice} = payload;

        yield put({type: errorActionTypes.DELETE_ERRORS});
        yield put({
            type: driverAssignmentActions.STORE_UPDATE_STATUS,
            payload: {isUpdating: true},
        });
        let updateData = data;
        let updateFunction = dafService.triggerFlowEvent;
        if (shouldUpdateTwice) {
            const secondFlowData = {
                ...data,
                type: driverAssignmentTriggerTypes.ASSIGN_DRIVER,
            };
            updateData = [data, secondFlowData];
            updateFunction = dafService.triggerMultipleFlowEvents;
        }
        yield fork(fetchRequest, driverAssignmentActions.CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST,
            updateFunction, {driverAssignmentId, data: updateData});
        const actionResult = yield take([
            driverAssignmentActions.CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_SUCCEEDED,
            driverAssignmentActions.CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_FAILED,
        ]);

        if (!actionResult.error) {
            yield put({
                type: driverAssignmentActions.STORE_UPDATE_STATUS,
                payload: {isUpdating: false},
            });
            yield put({
                type: driverAssignmentActions.TRIGGER_CHANGE_DECLINE_CASE_POP_UP_STATE,
                payload: {isOpen: false},
            });
            yield put(push(resolveRoute(routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS)));
            continue;
        }

        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(actionResult.payload),
                },
            },
        });
        yield put({
            type: driverAssignmentActions.TRIGGER_CHANGE_DECLINE_CASE_POP_UP_STATE,
            payload: {isOpen: false},
        });
        continue;
    }
};
export default changeDriverAssignmentStatusFlow;
