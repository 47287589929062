import React from 'react';
import PropTypes from 'prop-types';
import './SearchDataCell.scss';

const SearchDataCell = ({label, className, children}) => {
    return (
        <div className={`ace-bottom-margin--sm ${className}`}>
            <p className="search-data-cell__text search-data-cell_text--grey">{label}</p>
            <p className="search-data-cell__text search-data-cell__text--margin-top">
                {children}
            </p>
        </div>
    );
};
SearchDataCell.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
};

SearchDataCell.defaultProps = {
    className: '',
};

export default SearchDataCell;
