import {dafStatuses} from '../../ella-dispo-entity-types';

export const dafArchiveStatuses = [
    dafStatuses.BILLABLE,
    dafStatuses.DECLINED,
];

export const dafStatusPillTypes = {
    [dafStatuses.VP_ASSIGNED]: 'red',
    [dafStatuses.VP_ACCEPTED]: 'red',
    [dafStatuses.DRIVER_ASSIGNED]: 'trans-red',
    [dafStatuses.DRIVER_ACCEPTED]: 'grey',
    [dafStatuses.APPROACHING]: 'grey',
    [dafStatuses.IN_SERVICE]: 'grey',
    [dafStatuses.SERVICE_FINISHED]: 'trans-grey',
    [dafStatuses.DECLINED]: 'trans-grey',
    [dafStatuses.BILLABLE]: 'grey',
};

export const dafStatusIcons = {
    [dafStatuses.VP_ASSIGNED]: 'new-status',
    [dafStatuses.VP_ACCEPTED]: 'assign-status',
    [dafStatuses.DRIVER_ASSIGNED]: 'time-status',
    [dafStatuses.DRIVER_ACCEPTED]: 'driver-accepted-status',
    [dafStatuses.APPROACHING]: 'approaching-status',
    [dafStatuses.IN_SERVICE]: 'in-service-status',
    [dafStatuses.SERVICE_FINISHED]: 'service-finished-status',
    [dafStatuses.DECLINED]: 'close-status',
    [dafStatuses.BILLABLE]: 'history',
};

export const dafStatusIconColors = {
    [dafStatuses.VP_ASSIGNED]: 'fill-white',
    [dafStatuses.VP_ACCEPTED]: 'fill-red',
    [dafStatuses.DRIVER_ASSIGNED]: 'fill-red',
    [dafStatuses.DRIVER_ACCEPTED]: '',
    [dafStatuses.APPROACHING]: '',
    [dafStatuses.IN_SERVICE]: 'fill-red',
    [dafStatuses.SERVICE_FINISHED]: '',
    [dafStatuses.DECLINED]: '',
    [dafStatuses.BILLABLE]: '',
};

export const movableToEllaStatuses = [
    dafStatuses.VP_ACCEPTED,
    dafStatuses.DRIVER_ASSIGNED,
    dafStatuses.DRIVER_ACCEPTED,
    dafStatuses.APPROACHING,
    dafStatuses.IN_SERVICE,
    dafStatuses.SERVICE_FINISHED,
];
