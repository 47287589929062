import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Ribbon.scss';

const Ribbon = ({
    color,
    width,
}) => {
    const className = classNames('ace-c-ribbon', {
        'ace-c-ribbon--red': color === 'red',
        'ace-c-ribbon--grey': color === 'grey',
        'ace-c-ribbon--light-grey': color === 'light-grey',
    });

    return (
        <div className={className} style={{width: width, marginLeft: '-' + width}} />
    );
};

Ribbon.propTypes = {
    color: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
};

export default Ribbon;
