// init window.DYNAMIC_ENV
window.DYNAMIC_ENV = new Proxy({
    handlers: [],
    onEnvChange: function (handler) {
        this.handlers.push(handler);
        return () => {
            this.handlers.delete(this.handlers.length);
        };
    },
}, {
    set(target, property, value) {
        const prevValue = target[property];
        const success = Reflect.set(...arguments); // eslint-disable-line prefer-rest-params
        if (success && prevValue !== value) {
            target.handlers.forEach(handler => handler(property, value));
        }
        return success;
    },
});
