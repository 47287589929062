import {fork, put, select, take} from 'redux-saga/effects';
import * as driverAssignmentActionTypes from '../driverAssignmentActions';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';

const proofOfServiceDownload = function* proofOfServiceDownload() {
    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');
    while (true) {
        const {payload} = yield take(driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE);
        const {driverAssignmentId} = payload;

        yield put({
            type: driverAssignmentActionTypes.STORE_DOWNLOAD_STATUS,
            payload: {isDownloadActive: true},
        });

        yield fork(fetchRequest, driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE_REQUEST,
            dafService.getProofOfServicePdf, {driverAssignmentId});
        const actionResult = yield take([
            driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE_REQUEST_SUCCEEDED,
            driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE_REQUEST_FAILED,
        ]);

        yield put({
            type: driverAssignmentActionTypes.STORE_DOWNLOAD_STATUS,
            payload: {isDownloadActive: false},
        });

        if (actionResult.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(actionResult.payload),
                    },
                },
            });
        }
    }
};

export default proofOfServiceDownload;
