export const NEW_GOOGLE_MAP_API_KEY = 'AIzaSyBdQqldMqkmicjEyRjZEXpwWDxQza8Lj_Q';
export const GOOGLE_MAP_API_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${NEW_GOOGLE_MAP_API_KEY}&region=DE`;

export const DEFAULT_MAP_CENTER = {
    lat: 51.165691,
    lng: 10.451526,
};

export const DEFAULT_ZOOM_WITHOUT_ADDRESS = 5;
export const DEFAULT_ZOOM = 16;
export const ALLOWED_GEOLOCATION_DIFFERENCE = 0.0004; // 0.0001 is equivalent to 11.132 m at equator or 4.3496 m poles

export const ADDRESS_VALIDATION_ERROR = 'address_validation_error';

export const polygonOptions = {
    fillColor: '#e2001a',
    fillOpacity: 0.3,
    strokeColor: '#e2001a',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
};

export const MAP_STYLES = [
    {
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#f5f5f5',
            },
        ],
    },
    {
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#616161',
            },
        ],
    },
    {
        'elementType': 'labels.text.stroke',
        'stylers': [
            {
                'color': '#f5f5f5',
            },
        ],
    },
    {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'administrative.land_parcel',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#bdbdbd',
            },
        ],
    },
    {
        'featureType': 'poi',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'poi',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#eeeeee',
            },
        ],
    },
    {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#757575',
            },
        ],
    },
    {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#e5e5e5',
            },
        ],
    },
    {
        'featureType': 'poi.park',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#9e9e9e',
            },
        ],
    },
    {
        'featureType': 'road',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#ffffff',
            },
        ],
    },
    {
        'featureType': 'road',
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'road.arterial',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#757575',
            },
        ],
    },
    {
        'featureType': 'road.highway',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#dadada',
            },
        ],
    },
    {
        'featureType': 'road.highway',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#616161',
            },
        ],
    },
    {
        'featureType': 'road.local',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#9e9e9e',
            },
        ],
    },
    {
        'featureType': 'transit',
        'stylers': [
            {
                'visibility': 'off',
            },
        ],
    },
    {
        'featureType': 'transit.line',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#e5e5e5',
            },
        ],
    },
    {
        'featureType': 'transit.station',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#eeeeee',
            },
        ],
    },
    {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#c9c9c9',
            },
        ],
    },
    {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#9e9e9e',
            },
        ],
    },
];
