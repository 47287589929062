/**
 * Allowed attachment MIME types for DAF
 */
export default [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/octet-stream',
    'application/pdf',
    'application/zip',
];
