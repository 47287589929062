import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Input.scss';

class Input extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        name: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        service: PropTypes.string,
        placeholder: PropTypes.string,
        error: PropTypes.any,
        type: PropTypes.string,
        errors: PropTypes.object,
        isDisabled: PropTypes.bool,
        isLocked: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        additionalAttributes: PropTypes.object,
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        charCount: PropTypes.any,
        isCountdownHidden: PropTypes.bool,
        enterPressHandler: PropTypes.func,
        isLabelIntegrated: PropTypes.bool,
    };

    static defaultProps = {
        label: '',
        value: '',
        service: '',
        name: '',
        placeholder: '',
        className: '',
        error: false,
        errors: {},
        isDisabled: false,
        isLocked: false,
        additionalAttributes: {},
        onChange: () => {},
        onFocus: () => {},
        onBlur: () => {},
        enterPressHandler: () => {},
        type: 'text',
        id: '',
        charCount: null,
        isCountdownHidden: false,
        isLabelIntegrated: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLabelOnTop: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.isLabelOnTop !== nextProps.value) {
            return {
                isLabelOnTop: !!nextProps.value || nextProps.value === 0,
            };
        }
        return null;
    }


    onInputFocus = () => {
        const {isLocked, isDisabled} = this.props;
        this.setState({
            isLabelOnTop: !isDisabled && !isLocked,
            isFocused: true,
        });
        this.props.onFocus();
        this.input.focus();
    };

    onInputBlur = () => {
        const {isLocked, value} = this.props;
        this.props.onBlur(value);
        this.setState({
            isLabelOnTop: !!value && !isLocked,
            isFocused: false,
        });
    };

    onInputChange = event => {
        const {service} = this.props;
        const isDebounce = true;
        const {
            target: {value},
        } = event;
        this.props.onChange({
            name: this.props.name,
            value: value,
            service: service,
            isDebounce,
        });
    };

    enterPressHandler = event => {
        if (event.key === 'Enter') {
            this.input.blur();
        }
    };

    getPlaceholderValue = () => {
        const {isLabelOnTop} = this.state;
        const {label, placeholder} = this.props;
        if (!label || isLabelOnTop) {
            return placeholder;
        }
        return '';
    };

    getError = () => {
        const {name, error, errors} = this.props;
        if (error) {
            return error;
        }
        if (errors[name]) {
            const [errorMessage] = errors[name];
            return errorMessage;
        }
        return '';
    };

    render() {
        const {isLabelOnTop, isFocused} = this.state;
        const {
            label,
            isDisabled,
            isLocked,
            value,
            name,
            type,
            id,
            isCountdownHidden,
            additionalAttributes = {},
            className,
            isLabelIntegrated,
        } = this.props;
        const inputClassName = classNames('ace-c-input', className, {
            'ace-c-input--disabled': isDisabled,
            'ace-c-input--error': this.getError(),
            'ace-c-input--focused': isLabelOnTop,
            'ace-c-input--locked': isLocked,
        });
        const labelClassName = classNames('ace-c-input__label', {
            'ace-c-input__label--focused': (value || isLabelOnTop) && !isLabelIntegrated,
            'ace-c-input__label--locked': isLocked,
            'ace-c-input__label--integrated': isLabelIntegrated,
        });
        const errorClassName = classNames('ace-c-input__error', {
            'ace-c-input__error--sm': isLabelIntegrated,
        });
        const validatedID = id || Math.floor(Math.random() * 1000);

        const inputStringLength = additionalAttributes.maxLength - value.toString().length;
        const characterCounter = inputStringLength < 0
            ? '0'
            : inputStringLength;
        const characterCountString = `${label} (${characterCounter}/${additionalAttributes.maxLength})`;


        return (
            <div className="ace-c-input__wrapper">
                <input
                    className={inputClassName}
                    ref={input => {
                        this.input = input;
                    }}
                    id={validatedID}
                    name={name}
                    type={type}
                    value={value}
                    placeholder={this.getPlaceholderValue()}
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    disabled={isDisabled}
                    onKeyPress={e => this.props.enterPressHandler(e, this.input)}
                    {...additionalAttributes}
                />
                <div className={labelClassName} onClick={this.onInputFocus}>
                    {additionalAttributes.maxLength && (isFocused || value) && !isCountdownHidden
                        ? characterCountString : label}
                </div>
                {this.getError() && (
                    <div className={errorClassName}>{this.getError()}</div>
                )}
            </div>
        );
    }
}

export default Input;
