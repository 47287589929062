import React from 'react';
import PropTypes from 'prop-types';
import {Marker, InfoWindow} from '@react-google-maps/api';
import {getAddressFromLocation} from '../../google-map/googleMapFunctions';
import './DriverMarker.scss';


class DriverMarker extends React.Component {
    static propTypes = {
        driver: PropTypes.object.isRequired,
        clickedDriverId: PropTypes.string,
        setClickedDriverId: PropTypes.func,
        isAddressShown: PropTypes.bool,
    };

    static defaultProps = {
        clickedDriverId: '',
        setClickedDriverId: () => {},
        isAddressShown: false,
    };

    state = {
        address: '',
    };

    componentDidMount() {
        if (this.props.isAddressShown) {
            getAddressFromLocation(this.props.driver.location)
                .then(address => { this.setState({address}); });
        }
    }

    componentWillUnmount = () => {
        if (this.props.clickedDriverId) {
            this.props.setClickedDriverId('', false);
        }
    };

    onMarkerClick = driverId => {
        this.props.setClickedDriverId(driverId, true);
    };

    onInfoWindowClose = () => {
        this.props.setClickedDriverId('', false);
    };

    render() {
        const {driver, clickedDriverId, isAddressShown} = this.props;
        const pinUrl = clickedDriverId ? '/assets/icons/towing-active.svg' : '/assets/icons/towing-marker.svg';
        const {address} = this.state;
        return driver.location && driver.location.lat && driver.location.lng ? (
            <Marker
                key={driver.id}
                position={driver.location}
                icon={{
                    url: pinUrl,
                    scaledSize: new window.google.maps.Size(72, 72),
                }}
                onClick={() => this.onMarkerClick(driver.id)}
                zIndex={2}
            >
                {clickedDriverId === driver.id && (
                    <InfoWindow key={driver.id} onCloseClick={this.onInfoWindowClose}>
                        <div className="ace__info-window">
                            <p className="ace__info-window-title">Fahrer</p>
                            <p className="ace__info-window-text">
                                {`${driver.firstName} ${driver.lastName}`}
                            </p>
                            <p className="ace__info-window-text">
                                {isAddressShown ? address : driver.phoneNumber}
                            </p>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        ) : null;
    }
}


export default DriverMarker;
