import {put, select, fork, take} from 'redux-saga/effects'; // take fork
import * as actionTypes from '../userManagementActionTypes';
import * as userSelectors from '../userManagementSelectors';
import * as driverAssignmentsActions from '../../driver-assignments/driverAssignmentActions';
import * as errorActionTypes from '../../application/errorActionTypes';
import {UsersPurpose} from '../constants/usersPurpose';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';

/**
 * Load users
 */
export const getUsersFlow = function* (params) {
    const userAccount = yield select(userSelectors.getUserAccount);
    const {roleFilter, usersPurpose} = params;

    yield put({type: actionTypes.CLEAR_USERS_DATA});

    const {serviceManager} = yield select(state => state.application);
    const deumService = serviceManager.loadService('userManagementService');
    yield fork(fetchRequest, actionTypes.FETCH_USERS_REQUEST, deumService.fetchUsers,
        {roleFilter, partnerIds: userAccount.partnerIds});
    const resultAction = yield take([
        actionTypes.FETCH_USERS_REQUEST_SUCCEEDED,
        actionTypes.FETCH_USERS_REQUEST_FAILED,
    ]);

    if (resultAction.error) {
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    errorDetail: resultAction.payload.error_description,
                },
            },
        });
        return;
    }
    let users = resultAction.payload.response.data;

    if (roleFilter === 'DRIVER' && usersPurpose === UsersPurpose.FOR_DRIVER_ASSIGNMENT) {
        const {serviceManager} = yield select(state => state.application);
        const DLService = serviceManager.loadService('driverLocatorService');
        yield fork(fetchRequest, actionTypes.FETCH_USERS_LOCATION_REQUEST, DLService.getDriversLocations,
            {roleFilter, partnerIds: userAccount.partnerIds});
        const result = yield take([
            actionTypes.FETCH_USERS_LOCATION_REQUEST_SUCCEEDED,
            actionTypes.FETCH_USERS_LOCATION_REQUEST_FAILED,
        ]);

        if (result.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(result.payload),
                    },
                },
            });
            return;
        }
        // MAPING DRIVERS WITH LOCATIONS
        const lc = result.payload.response.data;
        const locations = lc.reduce((locationMapping, lc) => {
            locationMapping[lc.driverId] = lc;
            return locationMapping;
        }, {});
        users = users.map(user => {
            return locations[user.userId]
                ? {...user,
                    location: {
                        latitude: locations[user.userId].latitude,
                        longitude: locations[user.userId].longitude,
                    }}
                : user;
        });
    }


    yield put({
        type: actionTypes.STORE_USERS,
        payload: {userDTOs: users},
    });

    if (usersPurpose === UsersPurpose.FOR_DRIVER_ASSIGNMENT) {
        yield put({
            type: driverAssignmentsActions.SET_DRIVER_ASSIGNMENTS_STATE,
            payload: {isLoading: false},
        });
    }
};
export default getUsersFlow;
