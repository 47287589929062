export const assembleAddressString = ({
    street,
    number,
    city,
    postalCode,
    country,
}) => {
    if (!street && !number && !city && !postalCode && !country) {
        return '';
    }

    number = number ? ` ${number}` : '';
    street = street || '';
    postalCode = postalCode || '';
    city = /,/.test(city) || !city ? '' : city;
    country = country ? `, ${country}` : '';
    const punctuation = (street || number) && (postalCode || city) ? ',' : '';
    const locationString = `${street}${number}${punctuation} ${postalCode} ${city}${country}`;

    return locationString.trim();
};

