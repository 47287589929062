import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ServiceOption.scss';
import {ToastMessage, Icon} from '../../ui-components';

class ServiceOption extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        errorName: PropTypes.string.isRequired,
        serviceImages: PropTypes.object.isRequired,
        isSelected: PropTypes.bool,
        onChange: PropTypes.func,
        isDisabled: PropTypes.bool,
        isStrikeIcons: PropTypes.bool,
        errors: PropTypes.any,
        toastMessage: PropTypes.string,
        toastMessageClassName: PropTypes.string,
        handleToastMessage: PropTypes.func,
        dimension: PropTypes.string,
    };

    static defaultProps = {
        isSelected: false,
        onChange: () => {},
        handleToastMessage: () => {},
        isDisabled: false,
        isStrikeIcons: false,
        errors: {},
        toastMessage: '',
        toastMessageClassName: '',
        dimension: '',
    };

    onSelectService = () => {
        return this.props.onChange();
    };

    render() {
        const {
            name,
            serviceImages,
            isSelected,
            isDisabled,
            isStrikeIcons,
            errors,
            errorName,
            toastMessage,
            toastMessageClassName,
            handleToastMessage,
            dimension,
        } = this.props;

        const isClickableIcon = isSelected ? 'white' : 'red';
        const fill = isDisabled && !isSelected ? '' : isClickableIcon;
        const classNameImage = isStrikeIcons
            ? classNames('ace-c-service-option__icon-strike', {
                'ace-c-service-option__icon-strike--selected': isSelected,
                'ace-c-service-option__icon-strike--disabled': isDisabled,
                'ace-c-service-option__icon-strike--selected-disabled': isSelected && isDisabled,
                'ace-c-service-option__icon-strike--errors': errors[errorName],
            })
            : classNames('ace-c-service-option__icon', {
                'ace-c-service-option__icon--selected': isSelected,
                'ace-c-service-option__icon--disabled': isDisabled,
                'ace-c-service-option__icon--selected-disabled': isSelected && isDisabled,
                'ace-c-service-option__icon--errors': errors[errorName],
            });
        return (
            <div className="ace-c-service-option">
                <div className={classNameImage} onClick={this.onSelectService}>
                    {isStrikeIcons || dimension === 'xxl'
                        ? <Icon dimension="xxl" iconName={serviceImages.icon} color={fill} />
                        : <Icon dimension="lg" iconName={serviceImages.icon} color={fill} />
                    }
                </div>
                <div className="ace-c-service-option__text-wrapper ace-copy-m">
                    {name}
                    <ToastMessage
                        className={toastMessageClassName}
                        isEnabled={toastMessage}
                        handleToastMessage={handleToastMessage}
                    >
                        { toastMessage }
                    </ToastMessage>
                </div>
            </div>
        );
    }
}
export default ServiceOption;
