import React, {useState, useRef, Children, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useClickOutside} from '../general/useClickOutside';
import './Dropdown.scss';

const DropdownWithTrigger = ({children, className, handleStateChange, isClosableOnSelect}) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownClassName = classnames('ace-c-dropdown-trigger', className);

    useEffect(() => {
        if (handleStateChange) {
            handleStateChange(isOpen);
        }
        if (isClosableOnSelect) {
            setIsOpen(false);
        }
    }, [isOpen, handleStateChange, isClosableOnSelect]);

    const toggleDropdown = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const dropdownRef = useRef(null);

    const enhancedChildren = [];

    useClickOutside(dropdownRef, () => {
        setIsOpen(false);
    });

    Children.forEach(children, (child, index) => {
        if (!child || typeof child === 'string') return child;

        if (index === 0) {
            enhancedChildren.push(React.cloneElement(child, {
                onClick: toggleDropdown,
                key: index,
            }));
        }

        if (index === 1) {
            enhancedChildren.push(React.cloneElement(child, {
                isVisible: isOpen,
                key: index,
            }));
        }
    });

    return (
        <>
            <div className={dropdownClassName} ref={dropdownRef}>
                {enhancedChildren}
            </div>
            {isOpen && <div className="ace-message-box__overlay" />}
        </>
    );
};

DropdownWithTrigger.propTypes = {
    className: PropTypes.string,
    handleStateChange: PropTypes.func,
    isClosableOnSelect: PropTypes.bool,
};

DropdownWithTrigger.defaultProps = {
    className: '',
    handleStateChange: null,
    isClosableOnSelect: false,
};

export default DropdownWithTrigger;
