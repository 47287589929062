import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon/Icon';

import './HoverIcon.scss';

const HoverIcon = ({
    icon,
    hoverText,
    onClick,
    isDisabled,
    isInvisible,
    isLink,
    href,
    color,
    textColor,
    position,
}) => {
    const fill = isDisabled ? '' : color;
    const iconClass = classNames('ace-c-hover-icon', {
        'ace-c-hover-icon--disabled': isDisabled,
        'ace-c-hover-icon--is-not-clickable': isDisabled,
        'ace-c-hover-icon--is-not-visible': isInvisible,
    });
    const textClass = classNames('ace-c-hover-icon__text', {
        'ace-c-hover-icon__text--red': textColor === 'red',
        'ace-c-hover-icon__text--grey': textColor === 'grey',
        'ace-c-hover-icon__text--left': position === 'left',
        'ace-c-hover-icon__text--center': position === 'center',
    });
    if (isLink) {
        return (
            <a href={href} className={iconClass} onClick={onClick}>
                <Icon dimension="lg" iconName={icon} color={fill} />
                <div className={textClass}>{hoverText}</div>
            </a>
        );
    }
    return (
        <div className={iconClass} onClick={onClick}>
            <Icon dimension="lg" iconName={icon} color={fill} />
            <div className={textClass}>{hoverText}</div>
        </div>
    );
};

HoverIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    hoverText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    isInvisible: PropTypes.bool,
    href: PropTypes.string,
    isLink: PropTypes.bool,
    color: PropTypes.string,
    textColor: PropTypes.string,
    position: PropTypes.string,
};

HoverIcon.defaultProps = {
    onClick: () => {},
    isDisabled: false,
    isInvisible: false,
    isLink: false,
    href: '',
    color: 'red',
    textColor: 'red',
    position: 'center',
};

export default HoverIcon;
