import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './TextArea.scss';

class TextArea extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        error: PropTypes.string,
        errors: PropTypes.object,
        isDisabled: PropTypes.bool,
        isLocked: PropTypes.bool,
        onChange: PropTypes.func,
        additionalAttributes: PropTypes.object,
        className: PropTypes.string,
        isAdditionalServices: PropTypes.bool,
        heightParameters: PropTypes.object,
        isOptional: PropTypes.bool,
    };

    static defaultProps = {
        label: '',
        value: '',
        name: '',
        placeholder: '',
        error: '',
        errors: {},
        isDisabled: false,
        isLocked: false,
        additionalAttributes: {},
        onChange: () => {},
        className: '',
        isAdditionalServices: false,
        heightParameters: {},
        isOptional: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            isLabelOnTop: !!props.value || false,
            isFocused: !!props.value || false,
        };
    }

    onInputFocus = () => {
        const {isLocked, isDisabled} = this.props;
        this.setState({
            isFocused: !isDisabled && !isLocked,
        });
        this.input.focus();
    };

    onInputBlur = () => {
        const {isLocked} = this.props;

        this.setState(prevState => {
            return {
                isFocused: !!prevState.value,
                isLabelOnTop: !!prevState.value && !isLocked,
            };
        });
    };

    onInputChange = event => {
        const {isLocked} = this.props;
        const {
            target: {value},
        } = event;
        this.setState({
            value: value,
            isLabelOnTop: !!value && !isLocked,
        }, () => {
            this.props.onChange(value);
        });
    };

    getPlaceholderValue = () => {
        const {isLabelOnTop} = this.state;
        const {label, placeholder} = this.props;
        if (!label || isLabelOnTop) {
            return placeholder;
        }
        return '';
    };

    getError = () => {
        const {name, error, errors} = this.props;
        if (error) {
            return error;
        }
        if (errors[name]) {
            const [errorMessage] = errors[name];
            return errorMessage;
        }
        return '';
    };

    calculateHeight = () => {
        const {heightParameters} = this.props;
        const n = heightParameters.numberOfOptions;
        const m = heightParameters.marginSize;
        const s = heightParameters.inputSize;

        if (n === 1) {
            return (n * s);
        }
        return ((n * s) + (n - 1) * m + (m / 2));
    };

    render() {
        const {isLabelOnTop, isFocused, value} = this.state;
        const {
            className,
            label,
            isDisabled,
            isLocked,
            isAdditionalServices,
            name,
            additionalAttributes = {},
            error,
            isOptional,
        } = this.props;
        const wrapperClassName = classnames('ace-c-text-area__wrapper', className);
        const inputClassName = classnames('ace-c-text-area', {
            'ace-c-text-area--disabled': isDisabled,
            'ace-c-text-area--error': this.getError(),
            'ace-c-text-area--focused': isFocused,
            'ace-c-text-area--locked': isLocked,
            'ace-c-text-area--additional-services': isAdditionalServices,
        });
        const labelClassName = classnames('ace-c-text-area__label', {
            'ace-c-text-area__label--focused': isLabelOnTop,
            'ace-c-text-area__label--locked': isLocked,
        });

        const height = this.calculateHeight();
        const optionalString = isOptional ? '(optional)' : '';
        return (
            <div className={wrapperClassName}>
                <textarea
                    className={inputClassName}
                    ref={input => {
                        this.input = input;
                    }}
                    name={name}
                    value={value}
                    placeholder={this.getPlaceholderValue()}
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    disabled={isDisabled}
                    style={{height: `${height}px`}}
                    {...additionalAttributes}
                />
                <div className={labelClassName} onClick={this.onInputFocus}>
                    {`${label} ${optionalString}`}
                </div>
                {error && (<div className="ace-c-time-input__error-message">{error}</div>)}
            </div>
        );
    }
}

export default TextArea;
