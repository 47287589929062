export const highlightResults = (searchTerm, searchResult, isIdSearchResult) => {
    if (!searchResult) {
        return '---';
    }
    if (!searchTerm) {
        return searchResult;
    }
    if (isIdSearchResult) {
        let regexString = '';
        for (let i = 0; i < searchTerm.length; i += 1) {
            regexString = regexString.concat(searchTerm[i] + '-?');
        }
        const regex = new RegExp(regexString);
        const match = String(searchResult.match(regex));
        return searchResult.replace(match, `<strong>${match.toUpperCase()}</strong>`);
    }

    return searchResult.toUpperCase().replace(searchTerm.toUpperCase(), `<strong>${searchTerm.toUpperCase()}</strong>`);
};
