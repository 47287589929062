import React, {useState} from 'react';
import AppLayoutView from './application/AppLayoutView';
import FilterOption from './ui-components/filters/filter-select/FilterOption';
import FilterSelect from './ui-components/filters/filter-select/FilterSelect';


const colors = ['red', 'green', 'blue'];


const TestView = () => {
    const [filters, setFilters] = useState({
        colors: [],
    });

    const onChange = (filterName, value) => {
        let shouldAddValue = true;
        let values = [];
        values = filters[filterName].filter(val => {
            if (value === val) {
                shouldAddValue = false;
            }
            return value !== val;
        });
        setFilters({
            ...filters,
            [filterName]: shouldAddValue ? [...values, value] : values,
        });
    };
    return (
        <AppLayoutView>
            <h1>TEST</h1>
            <FilterSelect onChange={onChange} filterName="colors">
                {colors.map(color => {
                    const isChecked = filters.colors.find(col => col === color);
                    return (
                        <FilterOption key={color} value={color} isChecked={isChecked}>
                            {color}
                        </FilterOption>
                    );
                })}
            </FilterSelect>
        </AppLayoutView>
    );
};
export default TestView;
