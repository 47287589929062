import React, {Fragment} from 'react';
import qs from 'qs';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {withTranslations} from '@computerrock/formation-i18n';
import AppLayout from '../application/AppLayoutView';
import * as invoiceSubmissionSelectors from './invoiceSubmissionSelectors';
import InvoiceSubmissionArchiveRow from './view-elements/rows/InvoiceSubmissionArchiveRow';
import {TableBody, TableHeader, TablePanel, TableHeaderCell} from '../ui-components/table';
import {SearchInput, ButtonSecondary, AceDatePicker, ButtonPrimary, VersionPopUp, Select} from '../ui-components';
import {FilterDropdown, FilterOrder, FilterSelect, FilterOption} from '../ui-components/filters';
import {eafArchiveStatuses} from './constants/invoiceSubmissionStatuses';
import Pagination from '../application/view-elements/Pagination';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import config from '../config';
import routePaths from '../routePaths';
import {ExportFileTypes} from './constants/exportFileTypes';
import './InvoiceSubmissionArchiveView.scss';
import {isStartDateBeforeEndDate} from '../utils/times';
import SelectOption from '../ui-components/select/SelectOption';
import withInvoiceSubmissionSearch from './view-elements/withInvoiceSubmissionSearch';
import SearchedInvoiceSubmissionsView from './SearchedInvoiceSubmissionsView';
import {InvoiceSubmissionDataStates as InvoiceSubmissionDataState} from './constants/invoiceSubmissionDataStates';
import {eafArchiveMainServices} from './constants/invoiceSubmissionMainServices';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';

const sortingNames = {
    INVOICE_SUBMISSION_ID: 'INVOICE_SUBMISSION_ID',
    DATE: 'SERVICE_START_DATE',
    TOTAL: 'TOTAL',
};

const fileTypeArray = Object.keys(ExportFileTypes).map(fileTypeKey => {
    return ExportFileTypes[fileTypeKey].type;
});

const InitialState = {
    page: 1,
    pageSize: config.DEFAULT_RECORDS_PER_PAGE,
    areFiltersCleared: false,
    mainServices: [],
    statuses: [],
    filterFromDate: '',
    filterToDate: '',
    sortBy: '',
    direction: '',
    fileType: ExportFileTypes.CSV.type,
};

class InvoiceSubmissionArchiveView extends React.Component {
    static propTypes = {
        total: PropTypes.number.isRequired,
        totalInvoicesCount: PropTypes.number,
        invoiceSubmissions: PropTypes.array.isRequired,
        exportInvoiceSubmissions: PropTypes.func.isRequired,
        invoiceSubmissionSearchResults: PropTypes.array.isRequired,
        isSearchPending: PropTypes.bool.isRequired,
        isSearchTermCleared: PropTypes.bool.isRequired,
        searchInvoiceSubmissions: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchTerm: PropTypes.string.isRequired,
        invoiceSubmissionDataState: PropTypes.string.isRequired,
        isSearchViewActive: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        downloadMultipleInvoices: PropTypes.func,
        isDownloadNotificationModalOpen: PropTypes.bool,
        closeDownloadNotificationModal: PropTypes.func,
        translate: PropTypes.func,
    };

    static defaultProps = {
        totalInvoicesCount: 0,
        isDownloadNotificationModalOpen: false,
        downloadMultipleInvoices: () => {
        },
        closeDownloadNotificationModal: () => {
        },
        translate: null,
    };

    constructor(props) {
        super(props);
        const queryObject = qs.parse(props.location.search, {ignoreQueryPrefix: true, comma: true});
        const filterFromDate = queryObject.fromServiceStartDateTime ? moment(queryObject.fromServiceStartDateTime) : '';
        const filterToDate = queryObject.toServiceEndDateTime ? moment(queryObject.toServiceEndDateTime) : '';
        this.state = {
            ...InitialState,
            ...queryObject,
            statuses: queryObject.statuses
                ? (Array.isArray(queryObject.statuses) ? queryObject.statuses : [queryObject.statuses]) : [],
            mainServices: queryObject.mainServices
                ? (Array.isArray(queryObject.mainServices)
                    ? queryObject.mainServices : [queryObject.mainServices]) : [],
            page: queryObject.page ? +queryObject.page : 1,
            filterFromDate,
            filterToDate: isStartDateBeforeEndDate(filterFromDate, filterToDate) ? filterToDate : filterFromDate,
        };
    }

    makeQueryObject = () => {
        const {page, mainServices, statuses, sortBy, direction} = this.state;
        const {filterFromDate, filterToDate} = this.state;
        const fromServiceStartDateTime = filterFromDate ? filterFromDate.startOf('day').toISOString() : null;
        const toServiceEndDateTime = filterToDate ? filterToDate.endOf('day').toISOString() : null;
        return {
            ...(page && {page: page}),
            ...(mainServices.length && {mainServices}),
            ...(statuses.length && {statuses}),
            ...(fromServiceStartDateTime && {fromServiceStartDateTime}),
            ...(toServiceEndDateTime && {toServiceEndDateTime}),
            ...(sortBy && {sortBy}),
            ...(direction && {direction}),
        };
    };

    fetchArchiveData = () => {
        const {history, location} = this.props;
        const filterObject = this.makeQueryObject();
        const query = qs.stringify(filterObject, {arrayFormat: 'comma'});

        history.push({
            pathname: location.pathname,
            search: query,
            query: filterObject,
        });
    };

    onFiltersChange = (filterName, filterValues) => {
        this.setState({
            [filterName]: filterValues,
            page: 1,
        }, debounce(this.fetchArchiveData, 1000));
    };

    onFiltersChangeNew = (filterName, value) => {
        let shouldAddValue = true;
        let values = [];
        values = this.state[filterName].filter(val => {
            if (value === val) {
                shouldAddValue = false;
            }
            return value !== val;
        });
        this.setState({
            [filterName]: shouldAddValue ? [...values, value] : values,
            page: 1,
        }, debounce(this.fetchArchiveData, 1000));
    };

    onStartDateFilterChange = date => {
        this.setState({
            filterFromDate: date ? moment(date) : null,
            page: 1,
        }, debounce(this.fetchArchiveData, 1000));
    };

    onEndDateFilterChange = date => {
        this.setState({
            filterToDate: date ? moment(date) : null,
            page: 1,
        }, debounce(this.fetchArchiveData, 1000));
    };

    onFilterDateDelete = dateRange => {
        this.setState({
            [dateRange]: null,
            page: 1,
        }, debounce(this.fetchArchiveData, 1000));
    };

    onSortChange = (sortName, sortValue) => {
        this.setState({
            sortBy: sortName,
            direction: sortValue,
        }, () => {
            this.fetchArchiveData();
        });
    };

    onPreviousClick = e => {
        e.preventDefault();
        if (!this.hasPrev()) return;

        this.setState(prevState => {
            const prevPage = prevState.page - 1;
            return {
                page: prevPage,
            };
        }, () => {
            this.fetchArchiveData();
        });
    };

    goToPage = (e, pageNumber) => {
        e.preventDefault();
        this.setState({
            page: pageNumber,
        }, () => {
            this.fetchArchiveData();
        });
    };

    onNextClick = e => {
        e.preventDefault();
        if (!this.hasNext()) return;

        this.setState(prevState => {
            const prevPage = prevState.page + 1;
            return {
                page: prevPage,
            };
        }, () => {
            this.fetchArchiveData();
        });
    };

    hasPrev = () => {
        return this.state.page > 1;
    };

    hasNext = () => {
        const {total} = this.props;
        const {page, pageSize} = this.state;

        return page * pageSize < total;
    };

    calculateTotalPagesNumber = () => {
        const {total} = this.props;
        const {pageSize} = this.state;
        const totalPages = total / pageSize;
        if (totalPages === 0) {
            return 1;
        }
        if (total % pageSize) {
            return Math.ceil(totalPages);
        }
        return totalPages;
    };

    changeFilterType = value => {
        this.setState({
            fileType: value,
        });
    };

    exportInvoiceSubmissions = () => {
        const {mainServices, statuses, sortBy, direction, fileType} = this.state;
        const {filterFromDate, filterToDate} = this.state;
        const fromServiceStartDateTime = filterFromDate ? filterFromDate.startOf('day').toISOString() : null;
        const toServiceEndDateTime = filterToDate ? filterToDate.endOf('day').toISOString() : null;
        const fileExtension = ExportFileTypes[fileType].ext;
        this.props.exportInvoiceSubmissions({
            fileExtension,
            fileType,
            filters: {
                filters: {
                    mainServices,
                    statuses,
                    date: {
                        fromServiceStartDateTime,
                        toServiceEndDateTime,
                    },
                },
                sort: {
                    sortBy: sortBy,
                    direction: direction,
                },
            },
        });
    };

    downloadMultipleInvoices = () => {
        const filterObject = this.makeQueryObject();
        filterObject.page = 1;
        filterObject.statuses
            ? filterObject.statuses = filterObject.statuses.filter(status => status !== 'EXTERNAL_INVOICING' || status !== 'PAYMENT_DECLINED')
            : filterObject.statuses = ['PAID, BOOKED'];
        const query = qs.stringify(filterObject, {arrayFormat: 'comma'});
        const payload = {
            search: query,
            size: this.props.totalInvoicesCount,
        };
        this.props.downloadMultipleInvoices(payload);
    };

    render() {
        const {invoiceSubmissionSearchResults, isSearchPending, invoiceSubmissionDataState, searchTerm} = this.props;
        const {searchInvoiceSubmissions, clearSearchResults, isSearchViewActive, isSearchTermCleared} = this.props;
        const {invoiceSubmissions, totalInvoicesCount, translate} = this.props;
        const {page, sortBy, direction, fileType} = this.state;
        const {filterFromDate, filterToDate, mainServices, statuses} = this.state;
        const rootPath = routePaths.ARCHIVE;
        const totalPages = this.calculateTotalPagesNumber();
        const queryObject = this.makeQueryObject();
        const links = {
            firstPage: `${rootPath}?${qs.stringify({...queryObject, page: 1}, {arrayFormat: 'comma'})}`,
            lastPage: `${rootPath}?${qs.stringify({...queryObject, page: totalPages}, {arrayFormat: 'comma'})}`,
            prevPage: `${rootPath}?${qs.stringify({
                ...queryObject,
                page: page === 1 ? 1 : page - 1,
            }, {arrayFormat: 'comma'})}`,
            nextPage: `${rootPath}?${qs.stringify({
                ...queryObject,
                page: page === totalPages ? totalPages : page + 1,
            }, {arrayFormat: 'comma'})}`,
        };


        return (
            <AppLayout>
                <div className="ace-c-invoice-submission-archive-view">
                    <div className="ace-c-invoice-submission-archive-view__header ace-grid__row">
                        <div className="col-start-xs--3 col-xs--8 col-start-md--4 col-md--6">
                            <SearchInput
                                hasSearchResults={isSearchViewActive && invoiceSubmissionSearchResults.length === 0}
                                placeholder={translate('invoice_submission_archive_view.select_placeholder.search')}
                                label={translate('invoice_submission_archive_view.select_label.search')}
                                isSearchTermCleared={isSearchTermCleared}
                                doSearching={searchInvoiceSubmissions}
                                clearSearchResults={clearSearchResults}
                                additionalAttributes={{maxLength: '50'}}
                            />
                        </div>
                    </div>
                    {!isSearchViewActive ? (
                        <Fragment>
                            <TablePanel>
                                <TableHeader
                                    className="ace-c-invoice-submission-archive-view__table-header ace-grid__row"
                                >
                                    <TableHeaderCell className="col-xs--2">
                                        <FilterDropdown
                                            label={translate('invoice_submission_archive_view.filter_dropdown_label.status')}
                                            isActive={!!statuses.length}
                                        >
                                            <FilterSelect
                                                onChange={this.onFiltersChangeNew}
                                                filterName="statuses"
                                            >
                                                {
                                                    Object.values(eafArchiveStatuses)
                                                        .map(status => {
                                                            const isChecked = !!this.state.statuses
                                                                .find(element => element === status);
                                                            return (
                                                                <FilterOption
                                                                    key={status}
                                                                    value={status}
                                                                    isChecked={isChecked}
                                                                >
                                                                    {
                                                                        translate(`global.eaf_status.${status.toLowerCase()}`)
                                                                    }
                                                                </FilterOption>
                                                            );
                                                        })
                                                }
                                            </FilterSelect>
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-xs--3">
                                        <FilterDropdown
                                            label={translate('invoice_submission_archive_view.filter_dropdown_label.service')}
                                            isActive={!!mainServices.length}
                                        >
                                            <FilterSelect
                                                onChange={this.onFiltersChangeNew}
                                                filterName="mainServices"
                                            >
                                                {
                                                    Object.values(eafArchiveMainServices)
                                                        .map(mainService => {
                                                            const isChecked = !!this.state.mainServices
                                                                .find(element => element === mainService);
                                                            return (
                                                                <FilterOption
                                                                    key={mainService}
                                                                    value={mainService}
                                                                    isChecked={isChecked}
                                                                >
                                                                    {translate(`global.eaf_main_service.${mainService.toLowerCase()}`)}
                                                                </FilterOption>
                                                            );
                                                        })
                                                }
                                            </FilterSelect>
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-sm--3">
                                        <FilterDropdown
                                            label={translate('invoice_submission_archive_view.filter_dropdown_label.case_number')}
                                            isActive={sortBy === sortingNames.INVOICE_SUBMISSION_ID}
                                        >
                                            <FilterOrder
                                                onSortChange={this.onSortChange}
                                                name={sortingNames.INVOICE_SUBMISSION_ID}
                                                sortDirection={sortBy === sortingNames.INVOICE_SUBMISSION_ID ? direction : ''}
                                            />
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-sm--2">
                                        <FilterDropdown
                                            label={translate('invoice_submission_archive_view.filter_dropdown_label.date')}
                                            isActive={sortBy === sortingNames.DATE
                                                || !!filterFromDate || !!filterToDate}
                                        >
                                            <FilterOrder
                                                onSortChange={this.onSortChange}
                                                name={sortingNames.DATE}
                                                sortDirection={sortBy === sortingNames.DATE ? direction : ''}
                                            />
                                            <AceDatePicker
                                                selected={filterFromDate
                                                    ? filterFromDate.toDate() : null}
                                                onChange={this.onStartDateFilterChange}
                                                name="date-from"
                                                isButtonInput={true}
                                                buttonInputLabel={translate('invoice_submission_archive_view.date_picker_label.from')}
                                                dateFormat="dd.M.yyyy"
                                                placeholderText={translate('invoice_submission_archive_view.date_picker_placeholder.from')}
                                                filterName="filterFromDate"
                                                dateFilterDelete={this.onFilterDateDelete}
                                            />
                                            <AceDatePicker
                                                selected={filterToDate
                                                    ? filterToDate.toDate() : null}
                                                onChange={this.onEndDateFilterChange}
                                                name="date-to"
                                                isButtonInput={true}
                                                buttonInputLabel={translate('invoice_submission_archive_view.date_picker_label.to')}
                                                dateFormat="dd.M.yyyy"
                                                placeholderText={translate('invoice_submission_archive_view.date_picker_placeholder.to')}
                                                filterName="filterToDate"
                                                dateFilterDelete={this.onFilterDateDelete}
                                            />
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                    <TableHeaderCell className="col-sm--2">
                                        <FilterDropdown
                                            label={translate('invoice_submission_archive_view.filter_dropdown_label.total')}
                                            isActive={sortBy === sortingNames.TOTAL}
                                        >
                                            <FilterOrder
                                                onSortChange={this.onSortChange}
                                                name={sortingNames.TOTAL}
                                                sortDirection={sortBy === sortingNames.TOTAL ? direction : ''}
                                            />
                                        </FilterDropdown>
                                    </TableHeaderCell>
                                </TableHeader>
                                {invoiceSubmissionDataState !== InvoiceSubmissionDataState.LOADING
                                    ? (invoiceSubmissions.length ? (
                                        <Fragment>
                                            <TableBody
                                                className="ace-c-invoice-submission-archive-view__table-body"
                                                hasRows={!!invoiceSubmissions.length}
                                            >
                                                {invoiceSubmissions.map(invoiceSubmission => {
                                                    return (
                                                        <InvoiceSubmissionArchiveRow
                                                            key={`${invoiceSubmission.invoiceSubmissionId}-${invoiceSubmission.assignment.assignmentName}`}
                                                            invoiceSubmission={invoiceSubmission}
                                                        />
                                                    );
                                                })}
                                            </TableBody>
                                            <Pagination
                                                onPreviousClick={this.onPreviousClick}
                                                onNextClick={this.onNextClick}
                                                goToPage={this.goToPage}
                                                totalPages={totalPages}
                                                currentPage={page}
                                                links={links}
                                            />
                                            <Fragment>
                                                <div className="ace-grid__row ace-bottom-margin--md">
                                                    <div className="col-xs--4 col-start-xs--5">
                                                        <Select
                                                            onChange={this.changeFilterType}
                                                            name="fileType"
                                                            value={fileType}
                                                        >
                                                            {fileTypeArray.map(fileType => {
                                                                return (
                                                                    <SelectOption
                                                                        optionText={fileType}
                                                                        optionValue={fileType}
                                                                        key={fileType}
                                                                    />
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div
                                                    className="ace-c-invoice-submission-archive-view__export-button-wrapper ace-grid__row"
                                                >
                                                    <div className="col-start-sm--3 col-sm--8">
                                                        <p className="ace-c-invoice-submission-archive-view__button-description ace-copy-m--center">
                                                            {translate('invoice_submission_archive_view.text.export_all_cases')}
                                                        </p>
                                                    </div>
                                                    <div className="col-start-sm--3 col-sm--4">
                                                        <div
                                                            className="ace-c-invoice-submission-archive-view__button-container"
                                                        >
                                                            <ButtonSecondary
                                                                name="btnExport"
                                                                label={translate('invoice_submission_archive_view.button_label.export_cases')}
                                                                onClick={this.exportInvoiceSubmissions}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-start-sm--7 col-sm--4">
                                                        <div
                                                            className="ace-c-invoice-submission-archive-view__button-container"
                                                        >
                                                            <div
                                                                className="ace-c-invoice-submission-archive-view__tooltip-wrapper"
                                                            >
                                                                <ButtonPrimary
                                                                    className="ace-c-invoice-submission-archive-view__tooltip-button"
                                                                    name="btnMultipleInvoices"
                                                                    label={translate('invoice_submission_archive_view.button_label.download_multiple_invoices')}
                                                                    onClick={this.downloadMultipleInvoices}
                                                                    isDisabled={totalInvoicesCount > 50
                                                                        || totalInvoicesCount === 0}
                                                                />
                                                                <p className="ace-c-invoice-submission-archive-view__tooltip-message">{
                                                                    totalInvoicesCount > 50
                                                                        ? translate('invoice_submission_archive_view.tooltip_message.maximum_50_invoices')
                                                                        : translate('invoice_submission_archive_view.tooltip_message.no_credits_for_selected')
                                                                }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Fragment>
                                        </Fragment>
                                    ) : (
                                        <ScreenMessage
                                            messageParagraphs={[
                                                translate('global.screen_message.no_order'),
                                                translate('global.screen_message.change_filter_criteria'),
                                            ]}
                                        />
                                    )) : (
                                        <ScreenMessage
                                            messageParagraphs={[translate('global.screen_message.loading')]}
                                            isLoading
                                        />
                                    )}
                            </TablePanel>
                        </Fragment>
                    ) : (
                        <SearchedInvoiceSubmissionsView
                            isSearchPending={isSearchPending}
                            searchResults={invoiceSubmissionSearchResults}
                            searchTerm={searchTerm}
                        />
                    )}

                    {this.props.isDownloadNotificationModalOpen && (
                        <VersionPopUp
                            isClosableOnClickOutside
                            closeFunction={this.props.closeDownloadNotificationModal}
                            className="ace-c-invoice-submission-archive-view__download-modal"
                        >
                            <p>{translate('invoice_submission_archive_view.pop_up_title.will_be_download')}</p>
                            <ButtonPrimary
                                className="ace-c-invoice-submission-archive-view__download-modal__button"
                                onClick={this.props.closeDownloadNotificationModal}
                                name="closePopUp"
                                label={translate('invoice_submission_archive_view.button_label.ok')}
                            />
                        </VersionPopUp>
                    )}
                </div>
            </AppLayout>
        );
    }
}

const mapStateToProps = state => ({
    total: state.invoiceSubmission.totalCount,
    totalInvoicesCount: state.invoiceSubmission.totalInvoicesCount,
    invoiceSubmissions: invoiceSubmissionSelectors.createArchiveInvoiceSubmissions()(state),
    isDownloadNotificationModalOpen: state.invoiceSubmission.isDownloadNotificationModalOpen,
    invoiceSubmissionDataState: state.invoiceSubmission.invoiceSubmissionDataState,
});

const mapDispatchToProps = dispatch => ({
    exportInvoiceSubmissions: payload => dispatch({
        type: invoiceSubmissionActionTypes.EXPORT_INVOICE_SUBMISSIONS,
        payload,
    }),
    downloadMultipleInvoices: payload => dispatch({
        type: invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_INVOICES,
        payload,
    }),
    closeDownloadNotificationModal: () => dispatch({
        type: invoiceSubmissionActionTypes.SET_DOWNLOAD_NOTIFICATION_MODAL_STATE,
        payload: {isDownloadNotificationModalOpen: false},
    }),
});

// eslint-disable-next-line
export default withRouter(withInvoiceSubmissionSearch(connect(mapStateToProps, mapDispatchToProps)(withTranslations(InvoiceSubmissionArchiveView))));
