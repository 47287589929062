import {fork, put, select, take} from 'redux-saga/effects';
import {push} from '@computerrock/formation-router';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorsActionTypes from '../../application/errorActionTypes';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';


const updateExternalStatusFlow = function* () {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.START_UPDATE_EXTERNAL_STATUS_FLOW);

        yield fork(fetchRequest, invoiceSubmissionActionTypes.UPDATE_EXTERNAL_STATUS_REQUEST,
            eafService.updateExternalStatus, payload);

        const resultAction = yield take([
            invoiceSubmissionActionTypes.UPDATE_EXTERNAL_STATUS_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.UPDATE_EXTERNAL_STATUS_REQUEST_FAILED,
        ]);

        if (!resultAction.error) {
            yield put(push(routePaths.OPEN_INVOICE_SUBMISSIONS));
            continue;
        }

        yield put({
            type: errorsActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export default updateExternalStatusFlow;
