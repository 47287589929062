import {immerable} from 'immer';
import Entity from '../entity/Entity';
import Assignment from './Assignment';
import InvoiceSubmissionAdditionalService from './InvoiceSubmissionAdditionalService';
import InvoiceSubmissionMainService from './InvoiceSubmissionMainService';
import eafMainServices from './eafMainServices';
import {JourneyContinuation} from '../journey-continuation';
import {Attachment} from '../attachment';

/**
 * InvoiceSubmission entity type definition
 *
 * @typedef {Object} InvoiceSubmission
 * @property {?string} invoiceSubmissionId
 * @property {?string} status
 * @property {?Assignment} assignment
 * @property {?Array} mainServices
 * @property {?string} mainServiceComment
 * @property {?Array} additionalServices
 * @property {?string} additionalServicesComment
 * @property {?string} paymentProposalNumber
 * @property {?number} totalAmount
 * @property {?string} serviceType
 * @property {?string} serviceTypeText
 * @property {?string} invoiceSubmissionName
 * @property {?string} externalDocumentNo
 * @property {?string} serviceIcon
 * @property {?string} serviceName
 * @property {?boolean} isManuallyCreatedCase
 * @property {?boolean} isForeignInsurance
 * @property {?boolean} isFirstOpening
 * @property {?string} invoiceLocation
 * @property {?number} total
 * @property {?Array} attachments
 * @property {?boolean} inflationSubsidyAllowed
 */

/**
 * InvoiceSubmission entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const invoiceSubmissionEntityDTOMapping = {
    invoiceSubmissionId: 'invoiceSubmissionId',
    mainServices: {
        dtoProperty: 'mainServices',
        fromDTO: services => (services ? services.map(service => {
            return new InvoiceSubmissionMainService().fromDTO(service);
        }) : []),
    },
    assignment: {
        dtoProperty: 'assignment',
        defaultValue: new Assignment(),
        entity: Assignment,
    },
    status: {
        dtoProperty: 'status',
        defaultValue: '',
    },
    mainServicesComment: {
        dtoProperty: 'mainServicesComment',
        defaultValue: '',
    },
    additionalServices: {
        dtoProperty: 'additionalServices',
        fromDTO: services => (services ? services.map(service => {
            return new InvoiceSubmissionAdditionalService().fromDTO(service);
        }) : []),
    },
    paymentProposalNumber: {
        dtoProperty: 'paymentProposalNumber',
        fromDTO: data => (data ? data.toUpperCase() : ''),
    },
    externalDocumentNo: {
        dtoProperty: 'externalDocumentNumber',
        defaultValue: '',
    },
    additionalServicesComment: {
        dtoProperty: 'additionalServicesComment',
        defaultValue: '',
    },
    isFirstOpening: {
        dtoProperty: 'isFirstOpening',
        defaultValue: false,
    },
    total: 'total',
    invoiceLocation: {
        dtoProperty: 'invoiceLocation',
        defaultValue: '',
    },
    attachments: {
        dtoProperty: 'attachments',
        fromDTO: attachments => (attachments ? attachments.map(attachment => {
            return new Attachment().fromDTO(attachment);
        }) : []),
    },
    inflationSubsidyAllowed: {
        dtoProperty: 'inflationSubsidyAllowed',
        defaultValue: true,
    },
};

/**
 * InvoiceSubmission entity
 *
 * @class
 * @extends Entity
 * @type {InvoiceSubmission}
 */
export default class InvoiceSubmission extends Entity {
    [immerable] = true;

    static entityDTOMapping = invoiceSubmissionEntityDTOMapping;

    static extendEntityDTOMapping = entityDTOMapping => {
        return {
            ...invoiceSubmissionEntityDTOMapping,
            ...entityDTOMapping,
        };
    };

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }

    get invoiceSubmissionName() {
        return `${this.assignment.prefix}-${this.assignment.id.caseId}-${this.assignment.id.lineNumber}-${this.invoiceSubmissionId.substring(this.invoiceSubmissionId.length - 2)}`;
    }

    get searchedSubmissionName() {
        return `${this.assignment.prefix}-${this.assignment?.id?.caseId}-${this.assignment?.id?.lineNumber}-${this.invoiceSubmissionId.substring(this.invoiceSubmissionId.length - 2)}`;
    }

    get isEmptyTrip() {
        return this.assignment?.journeyContinuation === JourneyContinuation.EMPTY_TRIP;
    }

    get serviceType() {
        return getServiceType(this.mainServices, this.isEmptyTrip);
    }

    get isForeignInsurance() {
        return this.assignment?.prefix !== '491';
    }
}

const getServiceType = (mainServices, isEmptyTrip) => {
    let mainServiceType = null;
    if (!isEmptyTrip) {
        if (mainServices.length === 1
            || (mainServices.length === 2
                && mainServices[0] === mainServices[1])) mainServiceType = mainServices[0].type;
        if ((mainServices.length === 2 && mainServices[0] !== mainServices[1])
            || mainServices.length > 2) {
            mainServiceType = eafMainServices.MULTIPLE_SERVICES;
        }
    }

    return mainServiceType;
};
