
import handleResponse from './handleResponse';
import resourceEndpoints from './resourceEndpoints';

/**
 * ECS DriverApp Ella User Management (DEUM) microservice client
 *
 * @constructor
 */
export default class MSClientACEPartnerManagementAPM {
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            // ensure SERVICE_URL has trailing slash
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/') : '',
        };

        // add SERVICE_URL to each resource endpoint
        this.resourceEndpoints = Object.keys(resourceEndpoints)
            .reduce((parsedResourceEndpoints, endpointKey) => {
                parsedResourceEndpoints[endpointKey] = this.serviceParameters.SERVICE_URL
                    + resourceEndpoints[endpointKey];
                return parsedResourceEndpoints;
            }, {});

        return {
            getContractPartner: this.getContractPartner,
            setAccessToken: this.setAccessToken,
        };
    }

    accessToken = null;

    getContractPartner = params => {
        const {vpId} = params;
        const urlPath = `${this.resourceEndpoints['ACE_PARTNERS']}/${vpId}`;
        return fetch(
            urlPath + '',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`,
                },
                method: 'GET',
            },
        )
            .then(handleResponse)
            .then(data => ({contractPartnerDTO: data}));
    };


    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };
}
