import qs from 'qs';
import handleResponse from './handleResponse';
import resourcePaths from './resourcePaths';
import resolveResourcePath from './resolveResourcePath';
import allowedAttachmentMIMETypes from './allowedAttachmentMIMETypes';

/**
 * ACE Member Profile (AMP) microservice client
 * @constructor
 */
export default class MSClientACEAssignmentFlowDAF {
    /**
    * @param {Object} serviceParameters
    * @returns {Object}
    */
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            // ensure SERVICE_URL has trailing slash
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/') : '',
        };

        return {
            setAccessToken: this.setAccessToken,
            getAssignments: this.getAssignments,
            getAssignment: this.getAssignment,
            triggerFlowEvent: this.triggerFlowEvent,
            triggerMultipleFlowEvents: this.triggerMultipleFlowEvents,
            getProofOfServicePdf: this.getProofOfServicePdf,
            updateAssignment: this.updateAssignment,
        };
    }

    accessToken = null;

    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };

    getAssignments = params => {
        // const {
        //     mainService, vpIds, size, status, partnerWorkerId, startTime,
        //     endTime, sortBy, direction, page, searchTerm,
        // } = params;
        // const query = {
        //     status,
        //     partnerIds: vpIds,
        //     ...(mainService && {mainService}),
        //     ...(partnerWorkerId && {partnerWorkerId}),
        //     ...(startTime && {fromServiceStartDateTime: startTime}),
        //     ...(endTime && {fromServiceEndDateTime: endTime}),
        //     ...(sortBy && {sortBy}),
        //     ...(direction && {direction}),
        //     ...(page && {page}),
        //     ...(size && {size}),
        //     ...(searchTerm && {searchPhrase: searchTerm}),
        // };
        const queryString = qs.stringify(params, {
            arrayFormat: 'comma',
        });
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourcePaths['ASSIGNMENTS'])
        + `?${queryString}`;
        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(data => ({results: data}));
    };

    getAssignment = params => {
        const {assignmentId} = params;

        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourcePaths['ASSIGNMENTS'])
        + `/${assignmentId}`;

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(data => ({results: data}));
    };

    updateAssignment = params => {
        const {assignmentId, data} = params;
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['ASSIGNMENTS'])
            + `/${assignmentId}`;

        return fetch(resourceEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    triggerFlowEvent = params => {
        const {driverAssignmentId, data} = params;
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourcePaths['FLOW_EVENTS'])
        + `/${driverAssignmentId}`;

        return fetch(resourceEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then(handleResponse);
    };

    triggerMultipleFlowEvents = params => {
        const {driverAssignmentId, data} = params;
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
        + resolveResourcePath(resourcePaths['FLOW_EVENTS'])
        + `/${driverAssignmentId}/bulk`;

        return fetch(resourceEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            body: JSON.stringify({'events': data}),
        })
            .then(handleResponse);
    };

    getProofOfServicePdf = params => {
        const {driverAssignmentId} = params;
        const resourceEndpoint = this.serviceParameters.SERVICE_URL
            + resolveResourcePath(resourcePaths['ASSIGNMENTS'])
            + `/${driverAssignmentId}/proof-of-services`;

        return fetch(resourceEndpoint, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${driverAssignmentId}`);
                link.target = '_blank';
                link.type = allowedAttachmentMIMETypes;
                document.body.appendChild(link);
                link.click();
                link.remove();

                return blob;
            });
    };
}
