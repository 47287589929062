/**
 * @typedef {string} journeyContinuation
 */

/**
 * Journey continuation
 *
 * @enum {journeyContinuation}
 */

export default {
    YES: 'YES',
    NO: 'NO',
    EMPTY_TRIP: 'EMPTY_TRIP',
};
