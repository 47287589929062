import {take, put, fork, select} from 'redux-saga/effects';
import * as actionTypes from '../userManagementActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import recordPersistenceStates from '../../utils/recordPersistenceStates';
import recordPersistenceOperations from '../../utils/recordPersistenceOperations';
import fetchRequest from '../../application/sagas/fetchRequest';

/**
 * Delete user flow
 */
const deleteUserFlow = function* deleteUserFlow() {
    const defaultPersistenceState = {
        persistenceOperation: recordPersistenceOperations.NO_OPERATION,
        persistenceState: recordPersistenceStates.IDLE,
    };

    const {serviceManager} = yield select(state => state.application);
    const userManagementService = serviceManager.loadService('userManagementService');

    mainFlow: while (true) {
        const {payload} = yield take(actionTypes.START_DELETE_USER_FLOW);
        const {userId} = payload;
        yield put({
            type: actionTypes.SET_USER_PERSISTENCE_STATE,
            payload: {
                persistenceOperation: recordPersistenceOperations.DELETE,
                userId,
            },
        });

        let submitChoiceResult = yield take([
            actionTypes.END_DELETE_USER_FLOW,
            actionTypes.SUBMIT_DELETE_USER_FORM,
        ]);

        while (true) {
            if (submitChoiceResult.type === actionTypes.END_DELETE_USER_FLOW) {
                yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
                continue mainFlow;
            }

            yield put({
                type: actionTypes.SET_USER_PERSISTENCE_STATE,
                payload: {
                    persistenceState: recordPersistenceStates.PENDING,
                },
            });

            yield fork(fetchRequest, actionTypes.DELETE_USER_REQUEST, userManagementService.deleteUser, {userId});
            const submitRequestResult = yield take([
                actionTypes.END_DELETE_USER_FLOW,
                actionTypes.DELETE_USER_REQUEST_SUCCEEDED,
                actionTypes.DELETE_USER_REQUEST_FAILED,
            ]);

            if (submitRequestResult.type === actionTypes.END_DELETE_USER_FLOW) {
                yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
                continue mainFlow;
            }

            if (submitRequestResult.type === actionTypes.DELETE_USER_REQUEST_SUCCEEDED) {
                yield put({
                    type: actionTypes.DELETE_USER,
                    payload: {userId},
                });
                yield put({
                    type: actionTypes.SET_USER_PERSISTENCE_STATE,
                    payload: {
                        persistenceState: recordPersistenceStates.SUCCEEDED,
                    },
                });
                break;
            }

            const errorObject = submitRequestResult.payload;
            yield put({
                type: actionTypes.SET_USER_PERSISTENCE_STATE,
                payload: {
                    persistenceState: recordPersistenceStates.FAILED,
                },
            });
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(errorObject),
                    },
                },
            });

            submitChoiceResult = yield take([
                actionTypes.END_DELETE_USER_FLOW,
                actionTypes.SUBMIT_DELETE_USER_FORM,
            ]);
        }

        yield take(actionTypes.END_DELETE_USER_FLOW);
        yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
    }
};

export default deleteUserFlow;
