import {all, fork, takeLatest} from 'redux-saga/effects';
import * as driverAssignmentActions from './driverAssignmentActions';
import changeDriverAssignmentStatusFlow from './sagas/changeDriverAssignmentStatusFlow';
import clearDriverAssignmentSearchResults from './sagas/clearDriverAssignmentSearchResults';
import searchDriverAssignmentsFlow from './sagas/searchDriverAssignmentsFlow';
import proofOfServiceDownload from './sagas/proofOfServiceDownload';
import updateDriverAssignmentDataFlow from './sagas/updateDriverAssignmentDataFlow';
/**
 *  Damage events watcher saga
 */
const driverAssignmentWatcher = function* driverAssignmentsWatcher() {
    yield all([
        fork(searchDriverAssignmentsFlow),
        fork(changeDriverAssignmentStatusFlow),
        fork(proofOfServiceDownload),
        fork(updateDriverAssignmentDataFlow),
        takeLatest(driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS, clearDriverAssignmentSearchResults),
    ]);
};

export default driverAssignmentWatcher;
