import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {withTranslations} from '@computerrock/formation-i18n';
import {createCurrentUsersSelector} from '../user-management/userManagementSelectors';
import {driverAssignmentViews} from './constants/driverAssignmentViews';
import AppLayoutView from '../application/AppLayoutView';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import DriverAssignmentActiveView from './DriverAssignmentActiveView';
import DriverAssignmentInactiveView from './DriverAssignmentInactiveView';
import {Icon} from '../ui-components';
import './DriverAssignmentView.scss';
import {createDriverAssignmentSelector} from './driverAssignmentSelectors';
import {dafStatusIcons} from './constants/driverAssignmentStatuses';
import {dafStatuses} from '../ella-dispo-entity-types';
import * as driverAssignmentActions from './driverAssignmentActions';

class DriverAssignmentView extends React.Component {
    static propTypes = {
        driverAssignment: PropTypes.object,
        driverAssignmentsViewState: PropTypes.string,
        drivers: PropTypes.array,
        history: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isEditMode: PropTypes.bool.isRequired,
        toggleEditMode: PropTypes.func.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        driverAssignment: null,
        driverAssignmentsViewState: '',
        drivers: null,
        translate: null,
    };

    onToggleEditMode = () => {
        const {toggleEditMode, isEditMode} = this.props;
        toggleEditMode({
            isEditMode: !isEditMode,
        });
    };

    render() {
        const {driverAssignment, drivers, driverAssignmentsViewState, history, isLoading,
            isEditMode, toggleEditMode, translate} = this.props;

        return (
            <AppLayoutView>
                {!isLoading ? (
                    <div className="driver-assignment-wrapper-view">
                        <div className="driver-assignment-wrapper-view__title">
                            <div className="ace-grid__row">
                                <div className="col-xs--2" />
                                <h3 className="ace-h3 col-xs--8">Fall {driverAssignment.assignmentId}</h3>
                                {(driverAssignment.status === dafStatuses.VP_ASSIGNED
                                  || driverAssignment.status === dafStatuses.VP_ACCEPTED)
                                && (
                                <div className="col-xs--2 driver-assignment-edit__icon" onClick={this.onToggleEditMode}>
                                    <Icon iconName={!isEditMode ? 'pen' : 'close'} dimension="xl" />
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="ace-grid__row ace-bottom-margin--md">
                            <div
                                className="col-xs--2 ace-driver-assignment-view__back-link"
                                onClick={() => {
                                    toggleEditMode({
                                        isEditMode: false,
                                    });
                                    history.goBack();
                                }}
                            >
                                <Icon iconName="arrow-back" dimension="md" />
                                <span className="ace-driver-assignment-view__back-link-text">
                                    {translate('driver_assignments_view.link_text.back')}
                                </span>
                            </div>
                            <div className="col-xs--8 ace-driver-assignment-view__heading">
                                <Icon
                                    iconName={dafStatusIcons[driverAssignment.status]}
                                    dimension="xl"
                                    color="fill-grey"
                                />
                                <span className="ace-driver-assignment-view__heading-text">
                                    {translate(`global.daf_status_text.${driverAssignment.status.toLowerCase()}`)}
                                </span>
                            </div>
                        </div>
                        {driverAssignmentsViewState === driverAssignmentViews.DISPATCH_ACTIVE && (
                            <DriverAssignmentActiveView
                                driverAssignment={driverAssignment}
                                drivers={drivers}
                                onDriverSelect={this.onDriverSelect}
                                isEditMode={isEditMode}
                            />
                        )}
                        {driverAssignmentsViewState === driverAssignmentViews.DISPATCH_INACTIVE && (
                            <DriverAssignmentInactiveView driverAssignment={driverAssignment} />
                        )}
                    </div>
                ) : (<ScreenMessage messageParagraphs={[translate('global.screen_message.loading')]} isLoading />)}
            </AppLayoutView>
        );
    }
}

const mapStateToProps = (state, props) => {
    const getDriverAssignment = createDriverAssignmentSelector();
    const getUsers = createCurrentUsersSelector();
    return {
        driverAssignment: getDriverAssignment(state, props),
        driverAssignmentsViewState: state.driverAssignments.driverAssignmentsViewState,
        drivers: getUsers(state),
        isLoading: state.driverAssignments.isLoading,
        isEditMode: state.driverAssignments.isEditMode,
    };
};

const mapDispatchToProps = dispatch => ({
    toggleEditMode: payload => dispatch({
        type: driverAssignmentActions.TOGGLE_DRIVER_ASSIGNMENT_EDIT_MODE,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslations(DriverAssignmentView)));
