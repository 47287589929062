import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Link} from '@computerrock/formation-router';
import {withTranslations} from '@computerrock/formation-i18n';
import classNames from 'classnames';
import {ButtonPrimary, ButtonSecondary, Icon, Input, PopUp, Select, TextArea} from '../ui-components';
import AppLayout from '../application/AppLayoutView';
import * as errorActionTypes from '../application/errorActionTypes';
import * as supportFormActionsTypes from './supportFormActionsTypes';
import './SupportView.scss';
import routePaths from '../routePaths';
import FileUpload from '../file-upload/FileUpload';
import {SupportEmailReasons} from './constants/supportEmailReasons';
import {scrollToAnchorWithOffset} from '../utils/validationMessages';
import SelectOption from '../ui-components/select/SelectOption';
import supportFiles from '../supportFiles';

const INITIAL_STATE = {
    emailReason: '',
    assignmentNumber: '',
    message: '',
    phone: '',
};

class SupportView extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        uploadFile: PropTypes.func.isRequired,
        deleteFile: PropTypes.func.isRequired,
        files: PropTypes.object.isRequired,
        error: PropTypes.any,
        isSupportModalOpen: PropTypes.bool.isRequired,
        closeSupportModal: PropTypes.func.isRequired,
        handleFormSubmit: PropTypes.func.isRequired,
        deleteErrors: PropTypes.func.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        error: null,
        translate: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            emailReason: '',
            assignmentNumber: '',
            message: '',
            phone: '',
        };
    }

    componentDidMount() {
        this.props.deleteErrors();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.error !== prevProps.error) {
            if (this.props.error?.errors?.message) {
                scrollToAnchorWithOffset(this.validationRef, 150);
            }
        }
    }

    componentWillUnmount() {
        this.props.deleteErrors();
    }

    validationRef = React.createRef();

    onSelectChange = value => {
        this.setState({emailReason: value});
    };

    onMessageChange = value => {
        this.setState({message: value});
    };

    onInputChange = event => {
        const {value, name} = event;
        this.setState({[name]: value});
    };

    onFormSubmit = () => {
        const {emailReason, assignmentNumber, message, phone} = this.state;
        this.props.handleFormSubmit({emailReason, assignmentNumber, message, phone});
    };


    render() {
        const {uploadFile, deleteFile, files, history, error, translate} = this.props;
        const supportEmailReasons = Object.keys(SupportEmailReasons).map(key => ({
            value: SupportEmailReasons[key],
            text: translate(`support_view.support_email_reasons.${key.toLowerCase()}`),
        }));

        return (
            <Fragment>
                <AppLayout>
                    <div className="ace-support-view">
                        <div className="ace-grid__row">
                            <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                                <h2 className="ace-h2">{translate('support_view.section_title.support')}</h2>
                            </div>

                            <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--lg">
                                <p className="ace-copy-s">
                                    {translate('support_view.support_message.contact_support')}
                                    <Link to={routePaths.HELP} className="ace-link-s ace-support-link">
                                        {` ${translate('support_view.support_message.support_link')} `}
                                    </Link>
                                    {translate('support_view.support_message.compiled')}.
                                </p>
                            </div>

                            <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--lg">
                                <Select
                                    onChange={this.onSelectChange}
                                    name="emailReason"
                                    value={this.state.emailReason}
                                    optionsArray={supportEmailReasons.map(e => e.text)}
                                    placeholder={translate('support_view.select_placeholder.reference')}
                                >
                                    {supportEmailReasons.map(reason => {
                                        return (
                                            <SelectOption
                                                optionText={reason.text}
                                                optionValue={reason.value}
                                                key={reason.value}
                                            />
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                        {!!this.state.emailReason
                            && (
                                <Fragment>
                                    <div className="ace-grid__row">
                                        <div className="col-xs--5 col-start-xs--3 ace-bottom-margin--lg">
                                            <p className="ace-copy-m">
                                                {translate('support_view.email_reason_question.benefit_case')}
                                            </p>
                                        </div>
                                        <div className="col-xs--3 col-start-xs--3 ace-bottom-margin--lg">
                                            <Input
                                                name="assignmentNumber"
                                                label={translate('support_view.input_label.service_case_number')}
                                                value={this.state.assignmentNumber}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                        <div
                                            className="col-xs--8 col-start-xs--3 ace-bottom-margin--smd ace-c-support-view__validation"
                                            ref={this.validationRef}
                                        >
                                            {!!error?.errors?.message && <Icon dimension="md" iconName="info-red" />}
                                            <p className={classNames('ace-c-support-view__validation__text', {
                                                'ace-c-support-view__validation__text--error': !!error?.errors?.message,
                                            })}
                                            >
                                                {translate('support_view.error_message.add_description')}
                                            </p>
                                        </div>


                                        <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--lg">
                                            <div className="ace-support__text-area-wrapper">
                                                <TextArea
                                                    className="ace-support__text-area-container"
                                                    value={this.state.message}
                                                    onChange={this.onMessageChange}
                                                    name="message"
                                                    label={translate('support_view.text_area_label.request_description')}
                                                    isOptional={false}
                                                    heightParameters={{
                                                        numberOfOptions: 1.5,
                                                        marginSize: 42,
                                                        inputSize: 60,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xs--12 ace-bottom-margin--lg">
                                            <FileUpload
                                                uploadFile={uploadFile}
                                                deleteFile={deleteFile}
                                                files={files}
                                            />
                                        </div>

                                        <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                                            <p className="ace-copy-m">
                                                {translate('support_view.email_reason_question.contact_details')}
                                            </p>
                                        </div>

                                        <div className="col-xs--3 col-start-xs--3 ace-bottom-margin--lg">
                                            <Input
                                                label={translate('support_view.input_label.phone')}
                                                name="phone"
                                                onChange={this.onInputChange}
                                                value={this.state.phone}
                                            />
                                        </div>

                                        <div className="col-xs--4 col-start-xs--5 ace-bottom-margin--md">
                                            <ButtonPrimary
                                                label={translate('support_view.button_label.submit_request')}
                                                name="btnSubmit"
                                                onClick={this.onFormSubmit}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }

                    </div>

                </AppLayout>
                {this.props.isSupportModalOpen
                    && (
                        <PopUp>
                            <div className="col-xs--8 col-start-xs--3">
                                <h3 className="ace-h3 ace-h3--center ace-support-popup__content__header">
                                    {translate('support_view.success_popup.title')}
                                </h3>
                            </div>
                            <div className="col-xs--8 col-start-xs--3">
                                <p className="ace-copy-m ace-copy-m--center ace-bottom-margin--md">
                                    {translate('support_view.success_popup.message')}
                                </p>
                            </div>
                            <div className="col-xs--4 col-start-xs--3">
                                <ButtonSecondary
                                    label={translate('support_view.button_label.start_page')}
                                    name="btnLinkHome"
                                    onClick={() => {
                                        this.props.closeSupportModal();
                                        history.push(routePaths.ELLA);
                                    }
                            }
                                />
                            </div>
                            <div className="col-xs--4 col-start-xs--7">
                                <ButtonPrimary
                                    label={translate('support_view.button_label.close')}
                                    name="btnLinkSupport"
                                    onClick={() => {
                                        this.setState(INITIAL_STATE, () => {
                                            this.props.closeSupportModal();
                                        });
                                    }
                                            }
                                />
                            </div>
                        </PopUp>
                    )
            }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        files: state.supportFilesReducer.files,
        error: state.errorsReducer.error,
        isSupportModalOpen: state.supportFormReducer.isSupportModalOpen,
    };
};

const mapDispatchToProps = dispatch => ({
    uploadFile: payload => dispatch(supportFiles.uploadFile(payload)),
    deleteFile: payload => dispatch(supportFiles.deleteFile(payload)),
    handleFormSubmit: payload => dispatch({
        type: supportFormActionsTypes.START_SUBMIT_SUPPORT_FORM_FLOW,
        payload,
    }),
    closeSupportModal: () => dispatch({
        type: supportFormActionsTypes.SET_SUPPORT_MODAL_OPEN_STATUS,
        payload: {isSupportModalOpen: false},
    }),
    deleteErrors: () => dispatch({
        type: errorActionTypes.DELETE_ERRORS,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslations(SupportView)));
