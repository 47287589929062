import React from 'react';
import AppLayout from './AppLayoutView';
import './HelpView.scss';

const HelpView = () => {
    return (
        <AppLayout>
            <div className="ace-grid__row">
                <div className="col-xs--12">
                    <iframe
                        title="help-page"
                        className="ace-help-view"
                        src="https://help.prod.ace.computerrock.com/"
                    />
                </div>
            </div>
        </AppLayout>
    );
};

export default HelpView;
