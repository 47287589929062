import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './ListOption.scss';


const ListOption = ({optionValue, optionText, onClick, className}) => {
    const listOptionClassName = classNames('ace-c-list-option', className);

    return (
        <div onClick={() => onClick(optionValue)} className={listOptionClassName}>
            {optionText}
        </div>
    );
};

ListOption.propTypes = {
    className: PropTypes.string,
    optionValue: PropTypes.string,
    optionText: PropTypes.string,
    onClick: PropTypes.func,
};

ListOption.defaultProps = {
    className: '',
    optionText: '',
    optionValue: '',
    onClick: () => {},
};

export default ListOption;
