import {call, fork, put, select, take} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';
import fetchRequest from '../../application/sagas/fetchRequest';
import getInvoiceSubmissionFlow from './getInvoiceSubmissionFlow';
import {parseErrorMessages} from '../../utils/validationMessages';

const prepareDataForUpdate = (data, isDriverMember, isOwnerMember) => {
    const {memberData, ownerData, driverData} = data;
    const memberType = memberData.type;
    const memberName = memberData.name;
    const memberAddress = {
        street: memberData.address,
        postCode: memberData.postCode,
        city: memberData.city,
        country: memberData.country,
    };
    const driverAddress = {
        street: driverData.address,
        postCode: driverData.postCode,
        city: driverData.city,
        country: driverData.country,
    };

    const ownerAddress = {
        street: ownerData.address,
        postCode: ownerData.postCode,
        city: ownerData.city,
        country: ownerData.country,
    };
    return {
        member: {
            name: memberName,
            type: memberType,
            membershipNumber: memberData.membershipNumber,
            phoneNumber: memberData.phoneNumber,
            address: {...memberAddress},
        },
        driver: {
            name: isDriverMember ? memberName : driverData.name,
            type: isDriverMember ? 'MEMBER' : driverData.type,
            address: isDriverMember ? {...memberAddress} : {...driverAddress},
        },
        owner: {
            name: isOwnerMember ? memberName : ownerData.name,
            type: isOwnerMember ? 'MEMBER' : ownerData.type,
            address: isOwnerMember ? {...memberAddress} : {...ownerAddress},
        },
    };
};

const updateMemberDataFlow = function* updateMemberDataFlow() {
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.UPDATE_MEMBER_DATA);
        const {invoiceSubmissionId, data, isOwnerMember, isDriverMember, isNoValidation} = payload;
        yield put({type: errorActionTypes.DELETE_ERRORS});
        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.UPDATING},
        });
        const {serviceManager} = yield select(state => state.application);
        const eafService = serviceManager.loadService('ellaAssignmentFlowService');

        const updateData = prepareDataForUpdate(data, isDriverMember, isOwnerMember);

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.UPDATE_MEMBER_DATA_REQUEST,
            eafService.updateMemberData,
            {invoiceSubmissionId, data: updateData, isNoValidation},
        );

        const resultAction = yield take([
            invoiceSubmissionActionTypes.UPDATE_MEMBER_DATA_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.UPDATE_MEMBER_DATA_REQUEST_FAILED,
        ]);

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });

        if (!resultAction.error) {
            yield call(getInvoiceSubmissionFlow, {invoiceSubmissionId});
            continue;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export default updateMemberDataFlow;
