import React from 'react';
import PropTypes from 'prop-types';
import './ScreenMessage.scss';

const ScreenMessage = ({
    messageParagraphs,
    isLoading,
}) => {
    return (
        <div className="ace-c-screen-message__wrapper ace-grid__row">
            <div className="col-start-xs--3 col-xs--8">
                {messageParagraphs && (
                    <div className="ace-c-screen-message__paragraph-wrapper">
                        {messageParagraphs.map((e, index) => {
                            if (index === 0) {
                                return (
                                    <div key={index} className="ace-c-screen-message__heading-wrapper">
                                        <h4 className={isLoading ? 'ace-h4 loading-dots' : 'ace-h4'}>
                                            {e}
                                        </h4>
                                    </div>
                                );
                            }
                            return (<p key={index} className="ace-copy-m">{e}</p>);
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

ScreenMessage.propTypes = {
    messageParagraphs: PropTypes.array,
    isLoading: PropTypes.bool,
};

ScreenMessage.defaultProps = {
    messageParagraphs: [],
    isLoading: false,
};

export default ScreenMessage;
