import React, {useState, Children, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../dropdown/Dropdown';
import './Autocomplete.scss';
import {useClickOutside} from '../general/useClickOutside';
import Input from '../input/Input';
import List from '../list/List';

const Autocomplete = props => {
    const {
        label, isDisabled, value, name, additionalAttributes,
        errors, error, children, onOptionClick,
        setAutocompleteFields, className, isLabelIntegrated,
    } = props;
    const enhancedChildren = [];

    const [isFocused, setIsFocused] = useState(false);


    const autocompleteRef = useRef(null);

    useEffect(() => {
        const onKeyUp = event => {
            const {key} = event;
            if (key !== 'Enter' || !isFocused) {
                return;
            }
            event.preventDefault();
            setAutocompleteFields(value);
            setIsFocused(false);
        };

        document.addEventListener('keyup', onKeyUp);
        if (!isFocused) {
            setAutocompleteFields(value);
        }

        return () => {
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [isFocused, value, setAutocompleteFields]);

    useClickOutside(autocompleteRef, () => {
        setIsFocused(false);
    });


    const onFocus = () => {
        props.onFocus();
        setIsFocused(true);
    };

    const onBlur = value => {
        props.onBlur(value);
    };

    const onChange = value => {
        if (!isFocused) setIsFocused(true);
        props.onChange(value);
    };
    const isDropdownShown = isFocused && !!value && !!value.trim() && !!children.length;

    Children.forEach(children, child => {
        if (!child || typeof child === 'string') return child;

        enhancedChildren.push(React.cloneElement(child, {
            onClick: value => {
                onOptionClick(value);
                setIsFocused(false);
            },
        }));
    });

    const enterPressHandler = (event, ref) => {
        if (event.key === 'Enter') {
            ref.blur();
        }
    };

    return (
        <div ref={autocompleteRef} className="ace-c-autocomplete">
            <Input
                className={`${className} ace-c-autocomplete__input`}
                value={value}
                label={label}
                name={name}
                enterPressHandler={enterPressHandler}
                onChange={onChange}
                onFocus={onFocus}
                isDisabled={isDisabled}
                errors={errors}
                error={error}
                additionalAttributes={additionalAttributes}
                isLabelIntegrated={isLabelIntegrated}
                onBlur={onBlur}
            />
            <Dropdown isVisible={isDropdownShown} position="bottom-center" className="ace-c-autocomplete__dropdown">
                <List>
                    {enhancedChildren}
                </List>
            </Dropdown>
        </div>
    );
};

Autocomplete.propTypes = {
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    additionalAttributes: PropTypes.object,
    errors: PropTypes.object,
    error: PropTypes.any,
    setAutocompleteFields: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onOptionClick: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    isLabelIntegrated: PropTypes.bool,
};

Autocomplete.defaultProps = {
    label: '',
    isDisabled: false,
    value: '',
    name: '',
    additionalAttributes: {},
    setAutocompleteFields: () => {
    },
    errors: {},
    error: false,
    onChange: () => {},
    onFocus: () => {},
    onOptionClick: () => {},
    onBlur: () => {},
    className: '',
    isLabelIntegrated: false,
};

export default Autocomplete;
