import {FilesPlugin} from './services/files-plugin';
import config from './config';
import * as errorActionTypes from './application/errorActionTypes';

const supportFiles = new FilesPlugin({
    name: 'supportFiles',
    fileParamsUrl: `${config.EAF_SERVICE_URL}/v1/attachments/upload-parameters`,
    errorHandlingActionType: errorActionTypes.STORE_FILES_ERROR,
});

export default supportFiles;
