import React, {Fragment} from 'react';
import qs from 'qs';
import {withRouter} from '@computerrock/formation-router';
import {withTranslations} from '@computerrock/formation-i18n';
import PropTypes from 'prop-types';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import DriverAssignmentRow from './view-elements/rows/DriverAssignmentRow';
import {FilterDropdown, FilterOrder} from '../ui-components/filters';
import {TableHeader, TablePanel, TableBody, TableHeaderCell} from '../ui-components/table';
import {SortDirection, goToNewSortingDashboard, getSortedTableData} from '../utils/table';
import {TabLinks, TabLink} from '../ui-components';
import routePaths from '../routePaths';


class DriverAssignmentsPanel extends React.Component {
    static propTypes = {
        numberOfNewCases: PropTypes.number,
        driverAssignments: PropTypes.array,
        rowDefinition: PropTypes.array.isRequired,
        areOpenCases: PropTypes.bool,
        emptyResultMessage: PropTypes.array.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        numberOfNewCases: 0,
        driverAssignments: null,
        areOpenCases: false,
        translate: null,
    };

    constructor(props) {
        super(props);
        const queryObject = qs.parse(props.location.search, {ignoreQueryPrefix: true});
        this.state = {
            sortColumnId: +queryObject.sortColumnId || 0,
            sortDirection: queryObject.sortDirection || SortDirection.ASC,
        };
    }

    onSortChange = (columnId, sortDirection) => {
        this.setState({
            sortColumnId: columnId,
            sortDirection: sortDirection,
        }, () => {
            const {history, location} = this.props;
            const {sortColumnId, sortDirection} = this.state;
            goToNewSortingDashboard({history, location, sortDirection, sortColumnId});
        });
    };

    render() {
        const {driverAssignments, rowDefinition, areOpenCases, emptyResultMessage, isLoading} = this.props;
        const {numberOfNewCases, translate} = this.props;
        const {sortColumnId, sortDirection} = this.state;
        return (
            <Fragment>
                <TabLinks>
                    <TabLink linkTo={routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS} tabCount={numberOfNewCases}>
                        {translate('driver_assignment_panel.tab_link.content')}
                    </TabLink>
                </TabLinks>
                <TablePanel>
                    <TableHeader className="ace-c-driver-assignments-panel__table-header">
                        <TableHeaderCell className="col-xs--3">
                            <FilterDropdown
                                label={translate('driver_assignment_panel.filter_drop_down_label.status')}
                                isActive={sortColumnId === 0}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={0}
                                    sortDirection={sortColumnId === 0 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-xs--3">
                            <FilterDropdown
                                label={translate('driver_assignment_panel.filter_drop_down_label.service')}
                                isActive={sortColumnId === 1}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={1}
                                    sortDirection={sortColumnId === 1 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-xs--2">
                            <FilterDropdown
                                label={translate('driver_assignment_panel.filter_drop_down_label.case_number')}
                                isActive={sortColumnId === 2}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={2}
                                    sortDirection={sortColumnId === 2 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-xs--2">
                            <FilterDropdown
                                label={translate('driver_assignment_panel.filter_drop_down_label.post_code')}
                                isActive={sortColumnId === 3}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={3}
                                    sortDirection={sortColumnId === 3 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-xs--2">
                            <FilterDropdown
                                label={areOpenCases
                                    ? translate('driver_assignment_panel.filter_drop_down_label.assigned')
                                    : translate('driver_assignment_panel.filter_drop_down_label.driver')
                            }
                                isActive={sortColumnId === 4}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={4}
                                    sortDirection={sortColumnId === 4 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                    </TableHeader>
                    {!isLoading ? (
                        <TableBody className="ace-driver-assignments-view__table-body" hasRows={!!driverAssignments.length}>
                            {driverAssignments && driverAssignments.length ? getSortedTableData({
                                tableData: driverAssignments,
                                rowDefinition,
                                sortColumnId,
                                sortDirection,
                                secondSortingKey: 'emergencyCallDateTime',
                            }).map(driverAssignment => {
                                return (
                                    <DriverAssignmentRow
                                        areOpenCases={areOpenCases}
                                        key={driverAssignment.assignmentId}
                                        driverAssignment={driverAssignment}
                                    />
                                );
                            }) : (
                                <ScreenMessage messageParagraphs={emptyResultMessage} />
                            )}
                        </TableBody>
                    ) : (
                        <ScreenMessage messageParagraphs={[translate('global.screen_message.loading')]} isLoading />
                    )}
                </TablePanel>
            </Fragment>
        );
    }
}

export default withRouter(withTranslations(DriverAssignmentsPanel));
