import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorActions from '../../application/errorActionTypes';
import {decodeMainServices} from '../utils/mainServices';

const getMainServicesForSubCaseFlow = function* getMainServicesForSubCaseFlow({payload}) {
    const {invoiceSubmissionId} = payload;
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST,
        eafService.getInvoiceSubmission,
        {invoiceSubmissionId, shouldSetInEditing: false},
    );

    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {mainServices} = resultAction.payload.response;
        const selectedMainServices = decodeMainServices(mainServices);
        yield put({
            type: invoiceSubmissionActionTypes.STORE_FOLLOW_UP_INVOICE_SUBMISSION_MAIN_SERVICES,
            payload: {mainServices: selectedMainServices},
        });
        return;
    }
    yield put({
        type: errorActions.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export default getMainServicesForSubCaseFlow;
