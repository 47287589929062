/**
 * Allowed attachment MIME types for EAF
 */
export default [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/octet-stream',
    'application/pdf',
    'application/zip',
    'text/csv',
    'application/vnd.ms-excel',
];
