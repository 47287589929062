import React from 'react';
import './SummaryDataCell.scss';

const SummaryDataCell = ({children}) => {
    return (
        <div className="ace-c-summary-data-cell">
            {children}
        </div>
    );
};

export default SummaryDataCell;
