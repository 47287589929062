import {immerable} from 'immer';
import Entity from '../entity/Entity';

/**
 * AssignmentId entity type definition
 *
 * @typedef {Object} AssignmentId
 * @property {?string} caseId
 * @property {?string} lineNumber
 */

/**
 * AssignmentId entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const assignmentIdEntityDTOMapping = {
    caseId: 'caseId',
    lineNumber: 'lineNumber',
};

/**
 * AssignmentId entity
 *
 * @class
 * @extends Entity
 * @type {PickUp}
 */
export default class AssignmentId extends Entity {
    [immerable] = true;

    static entityDTOMapping = assignmentIdEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
