import React from 'react';
import PropTypes from 'prop-types';
import {journeyContinuationMainServices} from '../constants/invoiceSubmissionMainServices';


const MainServiceProviderHOC = Component => {
    const MainServiceProvider = props => {
        const {journeyContinuation} = props.invoiceSubmission;
        const availableMainServices = journeyContinuationMainServices[journeyContinuation];

        return (
            <Component {...props} availableMainServices={availableMainServices} />
        );
    };

    MainServiceProvider.propTypes = {
        invoiceSubmission: PropTypes.object.isRequired,
    };

    return MainServiceProvider;
};

export default MainServiceProviderHOC;
