const ALLOWED_MIME_TYPES = {
    IMAGE_JPG: 'image/jpeg',
    IMAGE_PNG: 'image/png',
    APPLICATION_PDF: 'application/pdf',
};

export const getContentTypeFromUrl = url => {
    if (!url) return '';
    return new URLSearchParams(url).get('rsct');
};

export const isAllowedMIMEType = type => {
    return Object.values(ALLOWED_MIME_TYPES).indexOf(type) !== -1;
};


export const isPdfFileExtension = url => {
    return isPdfMIMEType(getContentTypeFromUrl(url));
};

export const isPdfMIMEType = type => {
    return type === ALLOWED_MIME_TYPES.APPLICATION_PDF;
};

export const lowercaseExtension = name => {
    if (!name) return '';

    const regex = /(jpg|jpeg|png|pdf)/i;
    return name.replace(regex, match => {
        return match.toLowerCase();
    });
};
