import {all, fork, takeEvery} from 'redux-saga/effects';
import * as actionTypes from './documentDownloadActionTypes';
import downloadMultipleDocuments from './sagas/downloadMultipleDocuments';
import downloadDocument from './sagas/downloadDocument';

/**
 *  Document download watcher saga
 */
const documentDownloadWatcher = function* documentDownloadWatcher() {
    yield all([
        fork(downloadMultipleDocuments),
        takeEvery(actionTypes.SUBMIT_DOCUMENT_DOWNLOAD_FORM, downloadDocument),
    ]);
};

export default documentDownloadWatcher;
