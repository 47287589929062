import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {TimeInput, Icon} from '../../ui-components';

const RecoveryDurationSection = ({invoiceSubmission, errors, onRecoveryTimeChange}) => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateSection = createTranslateShorthand('recovery_duration_section');
    return (
        <Fragment>
            <div className="col-xs--12 ace-bottom-margin--md">
                <span className="ace-copy-m">{translateSection('section_title.recovery_time')}</span>
            </div>
            <div className="col-xs--2">
                <TimeInput
                    name="recoveryStart"
                    label={translateSection('input_label.start_time')}
                    labelFor="startTime"
                    divClasses="form__input--xsmall"
                    time={invoiceSubmission.recoveryStart}
                    onChange={onRecoveryTimeChange}
                    error={errors['assignment.startOfRecoveryDateTime']
                && translate(`global.validation_messages.${errors['assignment.startOfRecoveryDateTime']}`)}
                />
            </div>
            <div className="col-xs--2">
                <TimeInput
                    name="recoveryEnd"
                    label={translateSection('input_label.end_time')}
                    labelFor="endTime"
                    divClasses="form__input--xsmall"
                    time={invoiceSubmission.recoveryEnd}
                    onChange={onRecoveryTimeChange}
                    error={errors['assignment.endOfRecoveryDateTime']
                        && translate(`global.validation_messages.${errors['assignment.endOfRecoveryDateTime']}`)}
                />
            </div>
            <div className="col-xs--8">
                <div className="ace-main-service__text-wrapper">
                    <span className="ace-main-service__icon-wrapper">
                        <Icon dimension="xl" iconName="clock" />
                    </span>
                    <div className="ace-main-service__tariff-wrapper">
                        <span className="ace-copy-m">{translateSection('field_title.on_site_salvage')}:&nbsp;</span>
                        <span className="ace-copy-m">{invoiceSubmission.recoveryDuration}</span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

RecoveryDurationSection.propTypes = {
    invoiceSubmission: PropTypes.object.isRequired,
    onRecoveryTimeChange: PropTypes.func.isRequired,
    errors: PropTypes.any,
};

RecoveryDurationSection.defaultProps = {
    errors: {},
};

export default RecoveryDurationSection;
