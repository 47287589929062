import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ButtonHOC from '../ui-button-hoc';
import Icon from '../icon/Icon';
import './Button.scss';

const ButtonComponent = ({
    icon,
    iconDimension,
    label,
    value,
    className,
    isDisabled,
    styleType,
}) => {
    const componentClassName = classnames('ace-c-button-primary', {
        'ace-c-button-primary--is-disabled': isDisabled,
        'ace-c-button-primary--has-icon': icon,
    },
    `ace-c-button-primary--${styleType}`,
    className);

    return (
        <div className={componentClassName}>
            <div className="ace-c-button-primary__content">{value || label}</div>
            {icon && (
                <div className="ace-c-button-primary__icon-wrapper">
                    <Icon dimension={iconDimension} iconName={icon} />
                </div>
            )}
        </div>
    );
};

ButtonComponent.propTypes = {
    icon: PropTypes.string,
    iconDimension: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    styleType: PropTypes.string,
};

ButtonComponent.defaultProps = {
    icon: '',
    iconDimension: 'lg',
    className: '',
    value: '',
    isDisabled: false,
    styleType: 'primary',
};

const Button = ButtonHOC(ButtonComponent);

Button.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    type: PropTypes.oneOf(['submit', 'button']),
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    styleType: PropTypes.string,
};

Button.defaultProps = {
    className: '',
    type: 'button',
    isDisabled: false,
    value: null,
    styleType: 'primary',
};

export {Button as default, ButtonComponent};
