import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ValidationMessage.scss';
import {Icon} from '../../ui-components';


const ValidationMessage = ({
    type,
    children,
}) => {
    const notificationClassName = classNames('ace-validation-message__notification', {
        'ace-validation-message__notification--red': type !== 'OK',
    });
    return (
        <Fragment>
            <div className="ace-validation-message__icon-wrapper">
                {type === 'OK'
                    ? <Icon dimension="md" iconName="checkmark" />
                    : <Icon dimension="xl" iconName="danger" />
                }
            </div>
            <p className={notificationClassName}>
                {children}
            </p>
        </Fragment>
    );
};

ValidationMessage.propTypes = {
    type: PropTypes.string,
};

ValidationMessage.defaultProps = {
    type: '',
};

export default ValidationMessage;
