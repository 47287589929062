import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslations} from '@computerrock/formation-i18n';
import './AppLayoutView.scss';
import * as errorActionTypes from './errorActionTypes';
import {validationMessages, validationMessagesTranslation} from '../utils/validationMessages';
import {getServerErrors} from './errorsSelector';
import Header from './view-elements/Header';
import {PopUp, VersionPopUp, ButtonPrimary, ScrollToTop} from '../ui-components';
import {getFromLocalStorage, saveToLocalStorage} from '../invoice-submission/utils/localStorage';

const currentVersion = process.env.APP_VERSION;
const VERSION_STORAGE_KEY = 'version_number';

class AppLayoutView extends React.Component {
    static propTypes = {
        error: PropTypes.object,
        deleteErrors: PropTypes.func.isRequired,
        isSessionValid: PropTypes.bool,
        translate: PropTypes.func,
    };

    static defaultProps = {
        error: null,
        isSessionValid: false,
        translate: null,
    };

    state = {
        isVersionPopUpActive: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            isVersionPopUpActive: this.props.isSessionValid
                && currentVersion !== getFromLocalStorage(VERSION_STORAGE_KEY),
        });
    }

    closeVersionPopUp = () => {
        this.setState({
            isVersionPopUpActive: false,
        });
        saveToLocalStorage(VERSION_STORAGE_KEY, currentVersion);
    };

    deleteConflictError = () => {
        window.location.reload();
        this.props.deleteErrors();
    };

    render() {
        const {deleteErrors, error, translate} = this.props;
        const {isVersionPopUpActive} = this.state;
        const isConflictErrorActive = error
            && error.errorType === validationMessagesTranslation[validationMessages.CONFLICT];
        const isServerErrorActive = error
            && error.errorType !== validationMessagesTranslation[validationMessages.CONFLICT];
        return (
            <div className="ace-app-layout-view">
                {isVersionPopUpActive && (
                    <VersionPopUp>
                        <h3 className="ace-h3 ace-h3--center ace-bottom-margin--md">
                            {translate('app_layout_view.version_popup_title.new_ella_features')}
                            ({translate('app_layout_view.version_popup_title.version')}  {process.env.APP_VERSION})
                        </h3>
                        <p className="ace-copy-s ace-bottom-margin--sm">
                            {translate('app_layout_view.version_popup_message.dear_contract_partners')}
                        </p>
                        <p className="ace-copy-s ace-bottom-margin--sm">
                            {translate('app_layout_view.version_popup_message.description')}
                        </p>
                        <p className="ace-copy-s ace-copy-s ace-bottom-margin--sm">
                            {translate('app_layout_view.version_popup_message.signature')}
                        </p>
                        <p className="ace-copy-s ace-copy-s--bold">
                            {translate('app_layout_view.version_popup_message.case_processing')}
                        </p>
                        <ul className="ace-c-pop-up__list ace-bottom-margin--md">
                            <li className="ace-c-pop-up__list-item">
                                <p className="ace-copy-s">
                                    {translate('app_layout_view.version_popup_message.list_item_cp_address')}
                                </p>
                            </li>
                            <li className="ace-c-pop-up__list-item">
                                <p className="ace-copy-s">
                                    {translate('app_layout_view.version_popup_message.list_item_pick_up')}
                                </p>
                            </li>
                            <li className="ace-c-pop-up__list-item">
                                <p className="ace-copy-s">
                                    {translate('app_layout_view.version_popup_message.list_item_notification')}
                                </p>
                            </li>
                            <li className="ace-c-pop-up__list-item">
                                <p className="ace-copy-s">
                                    {translate('app_layout_view.version_popup_message.list_item_optimisation')}
                                </p>
                            </li>
                        </ul>
                        <p className="ace-copy-s ace-bottom-margin--md">
                            {translate('app_layout_view.version_popup_message.new_version_problems')}
                            <a href="mailto:ella@ace.de" className="ace-link-s ace-link-s--small" target="_blank" rel="noopener noreferrer">
                                {translate('app_layout_view.version_popup_message.ella_mail')}
                            </a>.
                        </p>
                        <div className="ace-c-pop-up__button-wrapper">
                            <div className="ace-c-pop-up__button-container">
                                <ButtonPrimary
                                    name="cancelPopUp"
                                    label={translate('app_layout_view.button_label.close')}
                                    onClick={this.closeVersionPopUp}
                                    className="ace-bottom-margin--md"
                                />
                            </div>
                        </div>
                    </VersionPopUp>
                )}
                {isServerErrorActive && (
                    <PopUp>
                        <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                            <h3 className="ace-h3 ace-h3--center">
                                {translate('app_layout_view.popup_title.server_issues')}
                            </h3>
                        </div>
                        <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                            <p className="ace-copy-m ace-copy-m--center">
                                {translate('app_layout_view.popup_message.server_issues')}
                            </p>
                        </div>
                        <div className="col-xs--4 col-start-xs--5">
                            <ButtonPrimary
                                name="cancelPopUp"
                                label={translate('app_layout_view.button_label.close')}
                                onClick={deleteErrors}
                            />
                        </div>
                    </PopUp>
                )}
                {isConflictErrorActive && (
                    <PopUp>
                        <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                            <h3 className="ace-h3 ace-h3--center">
                                {translate('app_layout_view.popup_title.failure')}
                            </h3>
                        </div>
                        <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                            <p className="ace-copy-m ace-copy-m--center">
                                {translate('app_layout_view.popup_message.failure')}
                                <a href="mailto:ella@ace.de" className="ace-link-m ace-link-m--small" target="_blank" rel="noopener noreferrer">
                                    {translate('app_layout_view.version_popup_message.ella_mail')}
                                </a>.
                            </p>
                        </div>
                        <div className="col-xs--4 col-start-xs--5">
                            <ButtonPrimary
                                name="cancelPopUp"
                                label={translate('app_layout_view.button_label.reload')}
                                onClick={this.deleteConflictError}
                            />
                        </div>
                    </PopUp>
                )}
                <header className="ace-header__wrapper">
                    <Header />
                </header>
                <main className="ace-app-layout-view__content">
                    {this.props.children}
                </main>
                <footer className="ace-app-layout-view__footer">
                    {`${translate('app_layout_view.version_popup_title.version')}: ${process.env.APP_VERSION} ${translate('app_layout_view.version_popup_title.build')}: ${process.env.APP_BUILD}`}
                </footer>
                <ScrollToTop />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: getServerErrors(state),
        isSessionValid: state.keycloakAuth.isSessionValid,
    };
};

const mapDispatchToProps = dispatch => ({
    deleteErrors: () => dispatch({type: errorActionTypes.DELETE_ERRORS}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslations(AppLayoutView));
