import {take, put, fork, select} from 'redux-saga/effects';
import * as actionTypes from '../userManagementActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import * as userProfileSelectors from '../userManagementSelectors';
import recordPersistenceStates from '../../utils/recordPersistenceStates';
import recordPersistenceOperations from '../../utils/recordPersistenceOperations';
import fetchRequest from '../../application/sagas/fetchRequest';

/**
 * Update user flow
 */
const updateUserFlow = function* updateUserFlow() {
    const defaultPersistenceState = {
        persistenceOperation: recordPersistenceOperations.NO_OPERATION,
        persistenceState: recordPersistenceStates.IDLE,
    };

    const {serviceManager} = yield select(state => state.application);
    const userManagementService = serviceManager.loadService('userManagementService');

    mainFlow: while (true) {
        const {payload} = yield take(actionTypes.START_UPDATE_USER_FLOW);
        const {userId} = payload;
        yield put({
            type: actionTypes.SET_USER_PERSISTENCE_STATE,
            payload: {
                persistenceOperation: recordPersistenceOperations.UPDATE,
                userId,
            },
        });
        yield put({type: errorActionTypes.DELETE_ERRORS});

        let submitChoiceResult = yield take([
            actionTypes.END_UPDATE_USER_FLOW,
            actionTypes.SUBMIT_UPDATE_USER_FORM,
        ]);

        while (true) {
            if (submitChoiceResult.type === actionTypes.END_UPDATE_USER_FLOW) {
                yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
                continue mainFlow;
            }

            yield put({type: errorActionTypes.DELETE_ERRORS});

            yield put({
                type: actionTypes.SET_USER_PERSISTENCE_STATE,
                payload: {
                    persistenceState: recordPersistenceStates.PENDING,
                },
            });

            const userAccount = yield select(userProfileSelectors.getUserAccount);
            const {payload} = submitChoiceResult;
            const {email, password, firstName, userRoles, phoneNumber} = payload;

            yield fork(fetchRequest, actionTypes.UPDATE_USER_REQUEST, userManagementService.updateUser, {
                userId,
                email,
                password,
                firstName,
                lastName: '',
                userRoles,
                phoneNumber,
            });
            const submitRequestResult = yield take([
                actionTypes.END_UPDATE_USER_FLOW,
                actionTypes.UPDATE_USER_REQUEST_SUCCEEDED,
                actionTypes.UPDATE_USER_REQUEST_FAILED,
            ]);

            if (submitRequestResult.type === actionTypes.END_UPDATE_USER_FLOW) {
                yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
                continue mainFlow;
            }

            if (submitRequestResult.type === actionTypes.UPDATE_USER_REQUEST_SUCCEEDED) {
                yield fork(fetchRequest, actionTypes.FETCH_USERS_REQUEST, userManagementService.fetchUsers, {
                    partnerIds: userAccount.partnerIds,
                });
                const resultAction = yield take([
                    actionTypes.FETCH_USERS_REQUEST_SUCCEEDED,
                    actionTypes.FETCH_USERS_REQUEST_FAILED,
                ]);

                const {data: userDTOs = []} = resultAction.payload.response;
                yield put({
                    type: actionTypes.UPDATE_USER,
                    payload: {
                        userDTO: userDTOs.find(userDTO => userDTO.userId === userId),
                    },
                });
                yield put({
                    type: actionTypes.SET_USER_PERSISTENCE_STATE,
                    payload: {
                        persistenceState: recordPersistenceStates.SUCCEEDED,
                        userId,
                    },
                });
                break;
            }

            const errorObject = submitRequestResult.payload;
            yield put({
                type: actionTypes.SET_USER_PERSISTENCE_STATE,
                payload: {
                    persistenceState: recordPersistenceStates.FAILED,
                },
            });
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(errorObject),
                    },
                },
            });

            submitChoiceResult = yield take([
                actionTypes.END_UPDATE_USER_FLOW,
                actionTypes.SUBMIT_UPDATE_USER_FORM,
            ]);
        }

        yield take(actionTypes.END_UPDATE_USER_FLOW);
        yield put({type: actionTypes.SET_USER_PERSISTENCE_STATE, payload: defaultPersistenceState});
    }
};

export default updateUserFlow;
