/**
 * Resource endpoints
 */
export default {
    INVOICE_SUBMISSIONS: 'v1/invoice-submissions',
    SUPPORT: 'v1/support',
    PRICING: 'v1/pricing',
    ATTACHMENTS_UPLOAD: 'v1/attachments/upload-parameters',
    ASSIGNMENTS: 'v1/assignments',
    MESSAGES: 'v1/messages',
    FILES: 'v1/files',
};
