import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../icon/Icon';
import './RadioButton.scss';

const RadioButton = ({
    id,
    className,
    selectedId,
    onClick,
    isDisabled,
    children,
}) => {
    const isSelected = id === selectedId;
    const componentClassName = classnames(
        'ace-radio-button', {
            'ace-radio-button--is-disabled': isDisabled,
            'ace-radio-button--selected': isSelected,
        },
        className,
    );

    return (
        <div className={componentClassName} onClick={() => onClick(id)}>
            {isSelected && (
                <div className="ace-radio-button--icon-container">
                    <Icon dimension="sm" iconName="checkmark" color="white" />
                </div>
            )}
            {children}
        </div>
    );
};

RadioButton.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    selectedId: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

RadioButton.defaultProps = {
    className: '',
    selectedId: '',
    isDisabled: false,
    onClick: null,
};

export default RadioButton;
