import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import './TimeInput.scss';

const KEY_ENTER = 'Enter';

const validateInput = (value, max) => {
    // max two digits check
    if (!/^\d{0,2}$/.test(value)) {
        return false;
    }
    if (value === '') {
        return true;
    }
    const num = parseInt(value, 10);
    return num <= max;
};

class TimeInput extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        // labelFor: PropTypes.string.isRequired,
        time: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        isDisabled: PropTypes.bool,
        error: PropTypes.string,
    };

    static defaultProps = {
        onChange: () => {},
        error: null,
        isDisabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            hoursFocus: false,
            minutesFocus: false,
        };
        this.secondInputRef = React.createRef();
        this.firstInputRef = React.createRef();
    }

    focusInput = e => {
        const {className} = e.target;
        if (className === 'ace-c-time-input__input') {
            return;
        }
        if (!this.firstInputRef.current.value && !this.secondInputRef.current.value) {
            this.firstInputRef.current.focus();
            this.setState({hoursFocus: true, minutesFocus: false});
            return;
        }
        if (this.firstInputRef.current.value) {
            this.secondInputRef.current.focus();
            this.setState({minutesFocus: true, hoursFocus: false});
            return;
        }
        if (this.secondInputRef.current.value) {
            this.firstInputRef.current.focus();
            this.setState({hoursFocus: true, minutesFocus: false});
        }
    };

    onHoursChange = e => {
        const {value} = e.target;
        if (!validateInput(value, 23)) {
            e.preventDefault();
            return;
        }
        const {time, name} = this.props;
        this.props.onChange(name, {
            ...time,
            hours: value,
        });
        if (/\d{2}/.test(value)) {
            this.secondInputRef.current.focus();
        }
    };

    onMinutesChange = e => {
        const {value} = e.target;
        if (!validateInput(value, 59)) {
            e.preventDefault();
            return;
        }
        const {time, name} = this.props;
        this.props.onChange(name, {
            ...time,
            minutes: value,
        });
    };

    onHoursFocus = e => {
        e.stopPropagation();
        this.setState({hoursFocus: true});
    };

    onHoursBlur = () => {
        this.setState({hoursFocus: false});
    };

    onMinutesFocus = e => {
        e.stopPropagation();
        this.setState({minutesFocus: true});
    };

    onMinutesBlur = () => {
        this.setState({minutesFocus: false});
    };

    onKeyDownStartTimeInput = event => {
        const {
            key,
        } = event;
        if (key !== KEY_ENTER) {
            return;
        }
        event.preventDefault();
        const {
            time: {
                hours = '',
            } = {},
        } = this.props;
        if (hours.length > 0) {
            this.secondInputRef.current.focus();
        }
    };

    onKeyDownEndTimeInput = event => {
        const {
            key,
            target: {value},
        } = event;
        if (key === 'Backspace' || key === 'Delete') {
            if (value === '') {
                this.firstInputRef.current.focus();
                this.setState({hoursFocus: true});
                return;
            }
        }
        if (key !== KEY_ENTER) {
            return;
        }
        event.preventDefault();
        this.secondInputRef.current.blur();
    };

    render() {
        const {
            time: {
                hours,
                minutes,
            },
            label,
            name,
            // labelFor,
            error,
            isDisabled,
        } = this.props;
        const {
            hoursFocus,
            minutesFocus,
        } = this.state;
        let hoursShow = hours;
        let minutesShow = minutes;
        if (!hoursFocus) {
            hoursShow = hours === '' ? '' : moment(hours, 'HH').format('HH');
        }
        if (!minutesFocus) {
            minutesShow = minutes === '' ? '' : moment(minutes, 'mm').format('mm');
        }
        const className = classNames('ace-c-time-input', {
            'ace-c-time-input--error': error,
            'ace-c-time-input--disabled': isDisabled,
            'ace-c-time-input--focused': hoursFocus || minutesFocus,
        });
        const labelClassName = classNames('ace-c-time-input__label', {
            'ace-c-time-input__label--focused': minutes !== '' || hours !== '' || hoursFocus || minutesFocus,
        });
        const separatorClassName = classNames('ace-c-time-input__separator', {
            'ace-c-time-input__separator--hidden': minutes === '' && hours === '',
        });
        return (
            <div>
                <div className={className} onClick={this.focusInput}>
                    <div className={labelClassName}>
                        {label}
                    </div>
                    <div>
                        <input
                            ref={this.firstInputRef}
                            name={name}
                            type="text"
                            className="ace-c-time-input__input"
                            value={hoursShow}
                            onChange={this.onHoursChange}
                            onFocus={this.onHoursFocus}
                            onBlur={this.onHoursBlur}
                            onKeyDown={this.onKeyDownStartTimeInput}
                            size="2"
                        />
                        <span className={separatorClassName}>:</span>
                        <input
                            type="text"
                            className="ace-c-time-input__input"
                            ref={this.secondInputRef}
                            value={minutesShow}
                            onChange={this.onMinutesChange}
                            onFocus={this.onMinutesFocus}
                            onBlur={this.onMinutesBlur}
                            onKeyDown={this.onKeyDownEndTimeInput}
                            size="2"
                        />
                    </div>
                </div>
                {error && (<div className="ace-c-time-input__error-message">{error}</div>)}
            </div>
        );
    }
}
export default TimeInput;
