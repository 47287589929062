import {produce} from 'immer';
import * as driverAssignmentActions from './driverAssignmentActions';
import {DriverAssignment} from '../ella-dispo-entity-types';

const addDriversDataToAssignmentDTO = driverAssignmentDTO => {
    if (!driverAssignmentDTO) return;
    const driverFirstName = driverAssignmentDTO.driverName ? driverAssignmentDTO.driverName.split(' ')[0] : null;
    const driverLastName = driverAssignmentDTO.driverName ? driverAssignmentDTO.driverName.split(' ').map((string, index) => {
        if (index > 0) {
            return string;
        }
        return '';
    })?.join(' ') : null;

    return {
        ...driverAssignmentDTO,
        driverFirstName,
        driverLastName,
    };
};

const initialState = {
    driverAssignments: {},
    openDriverAssignmentsIds: [],
    assignedDriverAssignmentsIds: [],
    totalCount: 0,
    isLoading: true,
    driverAssignmentsViewState: null,
    searchedDriverAssignmentsIds: [],
    searchTerm: '',
    isSearchPending: false,
    isSearchResultsCleared: false,
    isSearchViewActive: false,
    isSearchTermCleared: false,
    isDeclinePopUpOpen: false,
    archivedDriverAssignmentsIds: [],
    isDownloadActive: false,
    isEditMode: false,
    isSchedulePopUpOpen: false,
};

const driverAssignmentsReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case driverAssignmentActions.STORE_OPEN_DRIVER_ASSIGNMENTS: {
            const {driverAssignmentsDTO, totalCount} = payload;
            driverAssignmentsDTO.forEach(driverAssignmentDTO => {
                draft.openDriverAssignmentsIds.push(driverAssignmentDTO.assignmentId);
                const newDriverAssignment = new DriverAssignment().fromDTO(driverAssignmentDTO);
                draft.driverAssignments[newDriverAssignment.assignmentId] = newDriverAssignment;
            });
            draft.totalCount = totalCount;
            break;
        }
        case driverAssignmentActions.STORE_ASSIGNED_DRIVER_ASSIGNMENTS: {
            const driverAssignmentsDTO = payload.driverAssignments.data;
            const {totalCount} = payload.driverAssignments;
            driverAssignmentsDTO.forEach(driverAssignmentDTO => {
                draft.assignedDriverAssignmentsIds.push(driverAssignmentDTO.assignmentId);
                const newDriverAssignment = new DriverAssignment().fromDTO(driverAssignmentDTO);
                draft.driverAssignments[newDriverAssignment.assignmentId] = newDriverAssignment;
            });
            draft.totalCount = totalCount;
            break;
        }
        case driverAssignmentActions.SET_DRIVER_ASSIGNMENTS_STATE: {
            const {isLoading} = payload;
            draft.isLoading = isLoading;
            break;
        }
        case driverAssignmentActions.CLEAR_DRIVER_ASSIGNMENT_DATA: {
            draft.totalCount = 0;
            draft.openDriverAssignmentsIds = [];
            draft.assignedDriverAssignmentsIds = [];
            draft.archivedDriverAssignmentsIds = [];
            break;
        }
        case driverAssignmentActions.STORE_DRIVER_ASSIGNMENT: {
            const driverAssignmentDTO = payload;
            const newDriverAssignment = new DriverAssignment().fromDTO(driverAssignmentDTO);
            draft.driverAssignments[newDriverAssignment.assignmentId] = newDriverAssignment;
            break;
        }
        case driverAssignmentActions.STORE_ARCHIVED_DRIVER_ASSIGNMENTS: {
            const driverAssignmentsDTO = payload.driverAssignments.results.data;
            const {totalCount} = payload.driverAssignments.results;
            driverAssignmentsDTO.forEach(driverAssignmentDTO => {
                draft.archivedDriverAssignmentsIds.push(driverAssignmentDTO.assignmentId);
                const newDriverAssignment = new DriverAssignment().fromDTO(driverAssignmentDTO);
                draft.driverAssignments[newDriverAssignment.assignmentId] = newDriverAssignment;
                draft.totalCount = totalCount;
            });
            break;
        }
        case driverAssignmentActions.STORE_DRIVER_ASSIGNMENT_VIEW_STATE: {
            const {driverAssignmentsViewState} = payload;
            draft.driverAssignmentsViewState = driverAssignmentsViewState;
            break;
        }
        case driverAssignmentActions.STORE_DRIVER_ASSIGNMENT_SEARCH_RESULTS: {
            const driverAssignmentsDTO = payload.driverAssignments;
            driverAssignmentsDTO.forEach(driverAssignmentDTO => {
                driverAssignmentDTO = addDriversDataToAssignmentDTO(driverAssignmentDTO);
                draft.searchedDriverAssignmentsIds.push(driverAssignmentDTO.assignmentId);
                const newDriverAssignment = new DriverAssignment().fromDTO(driverAssignmentDTO);
                draft.driverAssignments[newDriverAssignment.assignmentId] = newDriverAssignment;
            });
            break;
        }
        case driverAssignmentActions.STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS: {
            const {searchTerm, isSearchStarted, isSearchingEnded, isSearchResultsCleared} = payload;
            if (isSearchStarted) {
                draft.isSearchPending = true;
                draft.searchTerm = searchTerm;
                draft.isSearchTermCleared = false;
                draft.searchedDriverAssignmentsIds = [];
                draft.isSearchViewActive = true;
                break;
            }
            if (isSearchingEnded) {
                draft.isSearchPending = false;
                break;
            }
            if (isSearchResultsCleared) {
                draft.isSearchPending = false;
                draft.searchTerm = '';
                draft.isSearchTermCleared = true;
                draft.searchedDriverAssignmentsIds = [];
                draft.isSearchViewActive = false;
                break;
            }
            break;
        }
        case driverAssignmentActions.TRIGGER_CHANGE_DECLINE_CASE_POP_UP_STATE: {
            const {isOpen} = payload;
            draft.isDeclinePopUpOpen = isOpen;
            break;
        }
        case driverAssignmentActions.TRIGGER_CHANGE_SCHEDULE_CASE_POP_UP_STATE: {
            const {isOpen} = payload;
            draft.isSchedulePopUpOpen = isOpen;
            break;
        }
        case driverAssignmentActions.STORE_DOWNLOAD_STATUS: {
            const {isDownloadActive} = payload;
            draft.isDownloadActive = isDownloadActive;
            break;
        }
        case driverAssignmentActions.TOGGLE_DRIVER_ASSIGNMENT_EDIT_MODE: {
            const {isEditMode} = payload;
            draft.isEditMode = isEditMode;
            break;
        }
        default:
        // no-op
    }
}, initialState);

export default driverAssignmentsReducer;

