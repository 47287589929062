import React from 'react';
import PropTypes from 'prop-types';
import './TableHeaderCell.scss';

const TableHeaderCell = props => {
    return (
        <div className={`ace-c-table-header-cell ${props.className}`}>
            {props.children && props.children.length !== 0
                ? props.children
                : (<span className="ace-c-table-header-cell__text">{props.title}</span>)
            }
        </div>
    );
};

TableHeaderCell.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

TableHeaderCell.defaultProps = {
    className: '',
    title: '',
    children: [],
};

export default TableHeaderCell;
