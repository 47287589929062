export const EmptyTripReasons = {
    ORDER_CANCELED: {
        id: '1',
        type: 'ORDER_CANCELED',
        icon: {icon: 'leerfahrt-strike'},
    },
    NO_LOAD_CUSTOMER_NO_LONGER_ON_SITE: {
        id: '2',
        type: 'NO_LOAD_CUSTOMER_NO_LONGER_ON_SITE',
        icon: {icon: 'user-strike'},
    },
    INCORRECT_LOCATION: {
        id: '3',
        type: 'INCORRECT_LOCATION',
        icon: {icon: 'pickup-strike'},
    },
    NO_DAMAGE_DETECTED_VEHICLE_RAN_AGAIN: {
        id: '4',
        type: 'NO_DAMAGE_DETECTED_VEHICLE_RAN_AGAIN',
        icon: {icon: 'car-strike'},
    },
    FURTHER_ASSISTANCE_REFUSED: {
        id: '5',
        type: 'FURTHER_ASSISTANCE_REFUSED',
        icon: {icon: 'pannenhilfe-strike'},
    },
};
