import {put} from 'redux-saga/effects';
import * as driverAssignmentActionTypes from '../driverAssignmentActions';


const clearDriverAssignmentSearchResults = function* () {
    yield put({
        type: driverAssignmentActionTypes.STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS,
        payload: {isSearchResultsCleared: true},
    });
};
export default clearDriverAssignmentSearchResults;
