import {immerable} from 'immer';
import Entity from '../entity/Entity';
import Location from '../location/Location';
import Address from '../address/Address';

/**
 * Damage entity type definition
 *
 * @typedef {Object} Damage
 * @property {?Location} location
 * @property {?Address} address
 * @property {?number} state
 * @property {?number} description
 * @property {?boolean} isInsideVpPolygon
 */

/**
 * Damage entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const damageEntityDTOMapping = {
    location: {
        dtoProperty: 'location',
        defaultValue: new Location(),
        entity: Location,
    },
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    state: {
        dtoProperty: 'state',
        defaultValue: '',
    },
    description: {
        dtoProperty: 'description',
        defaultValue: '',
    },
    isInsideVpPolygon: {
        dtoProperty: 'isInsideVpPolygon',
        defaultValue: false,
    },
};

/**
 * Damage entity
 *
 * @class
 * @extends Entity
 * @type {Damage}
 */
export default class Damage extends Entity {
    [immerable] = true;

    static entityDTOMapping = damageEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
