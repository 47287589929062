import qs from 'qs';
import {all, fork, takeEvery} from 'redux-saga/effects';
import createActionTypes from './createActionTypes';
import handleResponse from './handleResponse';
import uploadFileFlow from './sagas/uploadFileFlow';
import createFilesReducer from './createFilesReducer';
import deleteFileFlow from './sagas/deleteFileFlow';
import allowedAttachmentMIMETypes from './allowedAttachmentMIMETypes';

class FilesPlugin {
    constructor(params) {
        const {name, errorHandlingActionType} = params;

        this.actionTypes = createActionTypes(name);
        this.reducerName = `${name}Reducer`;
        this.fileParamsUrl = params.fileParamsUrl || '';
        this.accessToken = null;
        this.errorHandlingActionType = errorHandlingActionType;
        return {
            actionTypes: this.actionTypes,
            setAccessToken: this.setAccessToken,
            uploadFile: this.startUploadFlow,
            deleteFile: this.startDeleteFlow,
            reducer: this.getReducer(),
            watcher: this.getWatcher(),
            downloadAttachment: this.downloadAttachment,
        };
    }

    getWatcher() {
        const context = this;
        return function* filesWatcher() {
            yield all([
                takeEvery(context.actionTypes.START_UPLOAD_FLOW, [context, uploadFileFlow]),
                fork([context, deleteFileFlow]),
            ]);
        };
    }

    getReducer = () => {
        return {
            [this.reducerName]: createFilesReducer(this),
        };
    };

    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };

    startUploadFlow = payload => {
        return {
            type: this.actionTypes.START_UPLOAD_FLOW,
            payload,
        };
    };

    startDeleteFlow = payload => {
        return {
            type: this.actionTypes.START_DELETE_FLOW,
            payload,
        };
    };

    getFileParameters = ({fileName, apiUrl}) => {
        const queryString = qs.stringify({fileName}, {
            arrayFormat: 'brackets',
        });

        const url = `${apiUrl}?${queryString}`;

        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse);
    };

    uploadFile = params => {
        const {file, url, contentType} = params;

        return fetch(
            url,
            {
                headers: {
                    'Content-Type': contentType,
                    'Accept': 'application/json',
                    'x-ms-blob-type': 'BlockBlob',
                },
                method: 'PUT',
                body: file,
            },
        )
            .then(handleResponse);
    };

    downloadAttachment = ({fileName, url}) => {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
            },
        })
            .then(handleResponse)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}`);
                link.target = '_blank';
                link.type = allowedAttachmentMIMETypes;

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    };
}

export default FilesPlugin;
