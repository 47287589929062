import React from 'react';
import PropTypes from 'prop-types';
import './TabLinks.scss';


const TabLinks = ({className, children}) => {
    return (
        <div className={`ace-tab-links__wrapper ${className}`}>
            <div className="ace-tab-links ace-grid__row">
                <div className="col-xs--12">
                    {children}
                    <div className="ace-tab-links__bottom-shadow">
                        <div className="ace-tab-links__bottom-shadow--before" />
                    </div>
                </div>
            </div>
        </div>
    );
};

TabLinks.propTypes = {
    className: PropTypes.string,
};
TabLinks.defaultProps = {
    className: '',
};
export default TabLinks;
