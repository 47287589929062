import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {
    ButtonPrimary,
    ButtonSecondary,
    Input,
    RadioButton,
    RadioButtons,
    ToastMessage,
} from '../../ui-components';
import {NumberValidator} from '../../utils/validation';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {invoiceSubmissionViews} from '../constants/invoiceSubmissionViews';
import {BillingTypes} from '../constants/billingTypes';
import './VehicleForm.scss';
import {VehicleTonnages} from '../../ella-dispo-entity-types';

const VehicleForm = ({invoiceSubmission, saveVehicleData, closeVehicleForm}) => {
    const {translate, createTranslateShorthand} = useTranslate();
    const translateForm = createTranslateShorthand('vehicle_form');
    const vehicle = {
        manufacturer: invoiceSubmission.vehicleManufacturer || '',
        model: invoiceSubmission.vehicleModel || '',
        registrationDateTime: invoiceSubmission.registrationDateTime ? invoiceSubmission.registrationDateTime.format('YYYY') : '',
        licensePlate: invoiceSubmission.licensePlate || '',
        weight: invoiceSubmission.vehicleWeight,
        odometer: invoiceSubmission.odometer || 0,
    };
    const [vehicleData, setVehicleData] = useState({...vehicle});
    const [isToastMessageShown, setIsToastMessageShown] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        const showToastMessage = invoiceSubmission.billingType === BillingTypes.FIXED_PRICE
            && vehicleData.weight !== VehicleTonnages.WEIGHT_VALUE_1;
        setIsToastMessageShown(showToastMessage);
    }, [invoiceSubmission, vehicleData.weight]);

    const onInputChange = ({name, value}) => {
        if ((name === 'registrationDateTime' || name === 'odometer')
            && !NumberValidator.validate(value)) return;

        setVehicleData({
            ...vehicleData,
            [name]: name === 'licensePlate' ? value.toUpperCase() : value,
        });
    };

    const onSubmitForm = () => {
        if (vehicleData.registrationDateTime && parseInt(vehicleData.registrationDateTime, 10) < 1886) {
            setValidationErrors({
                invalidVehicleRegDateTime: translate('global.validation_messages.vehicle_reg_date_time'),
            });
            return;
        }
        saveVehicleData({
            assignmentId: invoiceSubmission.assignmentId,
            invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
            data: {
                ...vehicleData,
                registrationDateTime: vehicleData.registrationDateTime
                    ? moment(vehicleData.registrationDateTime).utc().format() : null,
            },
            isNoValidation: invoiceSubmission.isForeignInsurance,
        });
    };

    const isSubmitButtonDisabled = !vehicleData.weight || !vehicleData.model
        || !vehicleData.licensePlate || !vehicleData.manufacturer;

    return (
        <div className="ace-forms-container">
            <div className="edit-page__form-container">
                <div className="edit-page__shadow-wrapper edit-page__shadow-wrapper--top">
                    <div className="edit-page__shadow" />
                </div>
                <div className="ace-grid__row edit-page-form-container__title">
                    <div className="col-xs--12">
                        <h4 className="ace-h4">{translateForm('section_title.vehicle_data')}</h4>
                    </div>
                </div>
                <div className="ace-grid__row member-form__row--first">
                    <div className="col-xs--4 col-xl--3 vehicle-form__form-column">
                        <Input
                            label={translateForm('input_label.manufacturer')}
                            name="manufacturer"
                            value={vehicleData.manufacturer}
                            onChange={onInputChange}
                            additionalAttributes={{maxLength: '30'}}
                        />
                    </div>
                    <div className="col-xs--4 col-xl--3 vehicle-form__form-column">
                        <Input
                            label={translateForm('input_label.model')}
                            name="model"
                            value={vehicleData.model}
                            onChange={onInputChange}
                            additionalAttributes={{maxLength: '70'}}
                        />
                    </div>
                </div>
                <div className="ace-grid__row member-form__row">
                    <div className="col-xs--3 col-xl--2 vehicle-form__form-column">
                        <Input
                            label={translateForm('input_label.first_registration_year')}
                            name="registrationDateTime"
                            value={vehicleData.registrationDateTime}
                            onChange={onInputChange}
                            additionalAttributes={{maxLength: '4'}}
                            error={validationErrors.invalidVehicleRegDateTime}
                        />
                    </div>
                    <div className="col-xs--2 vehicle-form__form-column">
                        <Input
                            label={translateForm('input_label.license_plate')}
                            name="licensePlate"
                            value={vehicleData.licensePlate}
                            onChange={onInputChange}
                            additionalAttributes={{maxLength: '20'}}
                        />
                    </div>
                    <div className="col-xs--3 col-xl--2 vehicle-form__form-column">
                        <Input
                            label={translateForm('input_label.odometer')}
                            name="odometer"
                            value={vehicleData.odometer}
                            onChange={onInputChange}
                            additionalAttributes={{maxLength: '9'}}
                        />
                    </div>
                </div>
                <div className="ace-grid__row">
                    <div className="col-xs--12 vehicle-form__radio-button-wrapper">
                        <span className="ace-copy-m">{translateForm('field_title.vehicle_weight')}</span>
                    </div>
                </div>
                <RadioButtons onChange={weight => setVehicleData({...vehicleData, weight})}>
                    {Object.keys(VehicleTonnages).map(option => {
                        return (
                            <RadioButton
                                key={option}
                                id={option}
                                selectedId={vehicleData.weight}
                                className="col-xs--3 col-xl--2 vehicle-form__radio-button-container"
                            >
                                {translate(`global.vehicle_tonnages.${option.toLowerCase()}`)}
                                {vehicleData.weight === option && (
                                    <ToastMessage
                                        className="vehicle-form__toast-message"
                                        isEnabled={isToastMessageShown}
                                        handleToastMessage={() => setIsToastMessageShown(false)}
                                    >
                                        {translateForm('toast_message.higher_tonnage')}
                                    </ToastMessage>
                                )}
                            </RadioButton>
                        );
                    })}
                </RadioButtons>
                <div className="ace-grid__row vehicle-form__buttons-container">
                    <div className="col-xs--3 col-start-xs--4 col-xl--2 col-start-xl--5">
                        <ButtonSecondary
                            type="button"
                            name="backButton"
                            label={translateForm('button_label.back')}
                            onClick={() => closeVehicleForm({
                                invoiceSubmissionViewState: invoiceSubmissionViews.SUMMARY,
                            })}
                        />
                    </div>
                    <div className="col-xs--3 col-xl--2">
                        <ButtonPrimary
                            type="button"
                            name="saveButton"
                            label={translateForm('button_label.save')}
                            onClick={onSubmitForm}
                            isDisabled={isSubmitButtonDisabled}
                        />
                    </div>
                </div>
                <div className="edit-page__shadow-wrapper edit-page__shadow-wrapper--bottom">
                    <div className="edit-page__shadow" />
                </div>
            </div>
        </div>
    );
};

VehicleForm.propTypes = {
    invoiceSubmission: PropTypes.object.isRequired,
    saveVehicleData: PropTypes.func.isRequired,
    closeVehicleForm: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    saveVehicleData: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_VEHICLE_DATA,
        payload,
    }),
    closeVehicleForm: payload => dispatch({
        type: invoiceSubmissionActionTypes.CHANGE_INVOICE_SUBMISSION_VIEW_STATE,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(VehicleForm);
