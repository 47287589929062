import {createSelector} from 'reselect';

export const getUsers = state => state.userManagement.users;
export const getCurrentUsersIds = state => state.userManagement.currentUsersIds;

export const getUsersOrderedMap = state => {
    return state.userManagement.users.reduce((mapObject, user) => {
        mapObject[user.id] = user;
        return mapObject;
    }, {});
};

export const createCurrentUsersSelector = () => createSelector(
    [
        getUsers,
        getCurrentUsersIds,
    ],
    (users, currentUsersIds) => {
        if (!currentUsersIds.length) return [];
        const currentUsers = [];
        currentUsersIds.forEach(userId => {
            const user = users[userId];
            if (user) currentUsers.push(user);
        });
        return currentUsers;
    },
);

export const getUserAccount = state => state.userManagement.user;
