import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';

const WarningMessage = ({classNames, toastRef, children}) => {
    return (
        <div className={classNames} ref={toastRef}>
            <Icon dimension="xl" iconName="danger" />
            <span className="ace-copy-m">{children}</span>
        </div>
    );
};

WarningMessage.propTypes = {
    classNames: PropTypes.string.isRequired,
    toastRef: PropTypes.object,
};

WarningMessage.defaultProps = {
    toastRef: {},
};

export default WarningMessage;
