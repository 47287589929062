import React from 'react';
import Icon from '../icon/Icon';

import './ScrollToTop.scss';

class ScrollToTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasScroll: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.hasScrollHandler);
        window.addEventListener('scroll', this.hasScrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hasScrollHandler);
        window.removeEventListener('scroll', this.hasScrollHandler);
    }

    hasScrollHandler = () => {
        const hasScroll = document.body.scrollHeight > document.body.clientHeight && window.scrollY > 0;
        this.setState({
            hasScroll: hasScroll,
        });
    };

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    render() {
        return (
            this.state.hasScroll && (
            <div className="ace-grid__row ace-c-scroll-to-top__wrapper">
                <div className="col-xs--12 ace-c-scroll-to-top__container">
                    <div className="ace-c-scroll-to-top__button" onClick={this.scrollToTop}>
                        <Icon dimension="lg" iconName="pointer" />
                    </div>
                </div>
            </div>
            )
        );
    }
}

export default ScrollToTop;
