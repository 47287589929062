import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './FilterDropdown.scss';
import Icon from '../../icon/Icon';
import DropdownWithTrigger from '../../dropdown/DropdownWithTrigger';
import Dropdown from '../../dropdown/Dropdown';

class FilterDropdown extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        label: PropTypes.string.isRequired,
        isDropdownRight: PropTypes.bool,
        isActive: PropTypes.bool,
    };

    static defaultProps = {
        isVisible: false,
        isDropdownRight: false,
        isActive: false,
    };

    constructor(props) {
        super(props);
        const {isVisible} = props;

        this.state = {
            isVisible: isVisible || false,
        };
    }

    saveOpenState = isVisible => {
        this.setState({isVisible});
    };

    render() {
        const {label, isDropdownRight, isActive} = this.props;
        const {isVisible} = this.state;

        const iconClassName = classnames('ace-c-filter-dropdown__label-icon', {
            'expanded': isVisible,
        });
        const labelClassName = classnames('ace-c-table-header-cell__text', {
            'select-active': isVisible || isActive,
        });

        return (
            <DropdownWithTrigger className="ace-c-filter-dropdown__container" handleStateChange={this.saveOpenState}>
                <div className="ace-c-filter-dropdown__label">
                    <span className={labelClassName}>{label}</span>
                    <span className={iconClassName}>
                        <Icon dimension="xs" iconName="expand-arrow" />
                    </span>
                </div>
                <Dropdown position="bottom-center" className={isDropdownRight ? 'is-last-cell' : ''}>
                    <div>
                        {this.props.children}
                    </div>
                </Dropdown>
            </DropdownWithTrigger>
        );
    }
}

export default FilterDropdown;
