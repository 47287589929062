import {put, fork, take, select} from 'redux-saga/effects';
import {push, resolveRoute} from '@computerrock/formation-router';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import routePaths from '../../routePaths';
import {parseErrorMessages} from '../../utils/validationMessages';
import fetchRequest from '../../application/sagas/fetchRequest';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';
import {eafStatuses} from '../../ella-dispo-entity-types';


const createFollowInvoiceSubmissionFlow = function* () {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.CREATE_FOLLOW_UP_INVOICE_SUBMISSION);
        yield put({type: errorActionTypes.DELETE_ERRORS});
        const {invoiceSubmissionId} = payload;

        yield fork(fetchRequest, invoiceSubmissionActionTypes.CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST,
            eafService.createFollowUpInvoiceSubmission, {invoiceSubmissionId});

        const actionResult = yield take([
            invoiceSubmissionActionTypes.CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_FAILED,
        ]);

        if (!actionResult.error) {
            const {response: invoiceSubmission} = actionResult.payload;
            const invoiceSubmissionId = invoiceSubmission.invoiceSubmissionId;

            yield put({
                type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
                payload: {state: InvoiceSubmissionDataStates.LOADING},
            });

            yield put(push(resolveRoute(routePaths.INVOICE_SUBMISSION,
                {invoiceSubmissionId},
                {shouldOpenForm: true, invoiceSubmissionStatus: eafStatuses.BILLABLE})));
            continue;
        }

        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(actionResult.payload),
                },
            },
        });
    }
};

export default createFollowInvoiceSubmissionFlow;
