import React, {Fragment} from 'react';
import {v1 as uuidv1} from 'uuid';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withTranslations} from '@computerrock/formation-i18n';
import './FileUpload.scss';
import {isAllowedMIMEType, isPdfMIMEType, lowercaseExtension} from '../utils/files';
import pdfIcon from '../assets/images/pdf-icon.png';
import {ButtonPrimary, ErrorMessage} from '../ui-components';

const MAX_ALLOWED_FILES = 10;

class FileUpload extends React.Component {
    static propTypes = {
        isInvoiceSubmissionClosed: PropTypes.bool,
        error: PropTypes.string,
        uploadFile: PropTypes.func.isRequired,
        files: PropTypes.object,
        deleteFile: PropTypes.func.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        error: '',
        files: [],
        isInvoiceSubmissionClosed: false,
        translate: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            dragCounter: 0,
        };
    }

    componentDidMount() {
        this.fileReader = new FileReader();
        this.fileReader.onloadend = () => {
            const {
                filesToRead,
                currentFileIndex,
            } = this;
            const {
                name: fileName,
                type,
            } = filesToRead[currentFileIndex];
            const file = {
                recognitionId: uuidv1(),
                fileName: lowercaseExtension(fileName),
                file: filesToRead[currentFileIndex],
                fileUrl: isPdfMIMEType(type) ? pdfIcon : URL.createObjectURL(filesToRead[currentFileIndex]),
                contentType: type,
            };
            this.props.uploadFile(file);
            this.currentFileIndex += 1;
            this.readFiles();
        };
    }

    readFiles = () => {
        const {
            filesToRead,
            currentFileIndex,
        } = this;
        const {files, translate} = this.props;
        if (!filesToRead || !filesToRead.length) {
            return;
        }
        if (currentFileIndex === filesToRead.length) {
            this.fileInput.value = '';
            return;
        }
        if (Object.keys(files).length + filesToRead.length > MAX_ALLOWED_FILES) {
            alert(translate('global.validation_messages.update_file_num_limit')); // eslint-disable-line
            return;
        }
        const currentFile = filesToRead[currentFileIndex];
        const {type} = currentFile;
        if (isAllowedMIMEType(type)) {
            this.fileReader.readAsDataURL(currentFile);
        } else {
            alert(translate('global.validation_messages.invalid_file_extension')); // eslint-disable-line
            this.currentFileIndex += 1;
            this.readFiles();
        }
    };

    handleFileInputChange = e => {
        e.preventDefault();
        this.currentFileIndex = 0;
        this.filesToRead = e.target.files;
        this.readFiles();
    };

    onDragEnter = () => {
        // triggered for children as well, so countrer needs to be kept
        this.setState(prevState => {
            return {
                dragCounter: prevState.dragCounter + 1,
            };
        });
    };

    onDragLeave = () => {
        this.setState(prevState => {
            return {
                dragCounter: prevState.dragCounter - 1,
            };
        });
    };

    render() {
        const {
            dragCounter,
        } = this.state;
        const {
            isInvoiceSubmissionClosed,
            error,
            files,
            deleteFile,
            translate,
        } = this.props;
        const isDraggingOver = dragCounter > 0;

        const fileUploadWrapperClass = classnames('ace-file-upload', {
            'ace-file-upload--is-dragging-over': isDraggingOver,
            'ace-file-upload--has-files': files.size > 0,
        });
        const fileInputClass = classnames('ace-file-upload__input', {
            'ace-file-upload__input--is-dragging-over': isDraggingOver,
        });
        const dropOverlayClass = classnames('ace-file-upload__drop-overlay', {
            'ace-file-upload__drop-overlay--is-dragging-over': isDraggingOver,
        });
        const fileUploadPreviewClass = classnames('ace-file-upload__preview', {
            'ace-file-upload__preview--is-dragging-over': isDraggingOver,
        });
        const inputLabelClass = classnames('ace-file-upload__label', {
            'ace-file-upload__label--is-dragging-over': isDraggingOver,
        });
        const fileUploadContainerClass = classnames('ace-file-upload__container', {
            'ace-file-upload__container--error': error,
        });
        return (
            <Fragment>
                <div className={classnames('ace-grid__row', fileUploadContainerClass)}>
                    <div className="col-xs--6">
                        <div className="ace-file-upload__wrapper">
                            <div className={dropOverlayClass}>
                                <h5 className="ace-h5">{translate('file_upload.section_title.drop_files')}</h5>
                            </div>
                            <div
                                className={fileUploadWrapperClass}
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDrop={this.onDragLeave}
                            >
                                <div className="ace-bottom-margin--sm">
                                    <h6 className="ace-h6">{translate('file_upload.section_title.drag_drop')}</h6>
                                </div>
                                <div className="ace-bottom-margin--sm">
                                    <span className="ace-copy-m">{translate('file_upload.section_subtitle.file_formats')}</span>
                                </div>
                                <div className="ace-file-upload__button-wrapper">
                                    <ButtonPrimary
                                        icon="cloud"
                                        label={translate('file_upload.button_label.upload_files')}
                                        name="btnFileUpload"
                                        onClick={() => {}}
                                    />
                                    <label className={inputLabelClass} htmlFor="file-upload" />
                                </div>
                                <input
                                    type="file"
                                    id="file-upload"
                                    ref={ref => { this.fileInput = ref; }}
                                    className={fileInputClass}
                                    onChange={this.handleFileInputChange}
                                    multiple
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classnames('col-xs--6', fileUploadPreviewClass)} id="file-upload__preview">
                        {files && Object.values(files).map(({id, isNewlyAdded, fileName, url}) => {
                            const fileUploadImageWrapperClass = classnames(
                                'ace-file-upload__preview-wrapper',
                            );
                            const fileUploadRemoveClass = classnames(
                                'ace-link-m',
                                {
                                    'hide-element': !isNewlyAdded && isInvoiceSubmissionClosed,
                                },
                            );
                            return (
                                <div key={id} className={fileUploadImageWrapperClass}>
                                    <div className="ace-file-upload__preview-img-wrapper">
                                        <img className="ace-file-upload__preview-img" src={fileName.endsWith('.pdf') ? pdfIcon : url} alt={fileName} />
                                        <p className="ace-file-upload__preview-name ace-copy-s">{fileName}</p>
                                    </div>
                                    <p
                                        className={fileUploadRemoveClass}
                                        onClick={() => {
                                            deleteFile({id});
                                        }}
                                    >
                                        {translate('file_upload.field_title.remove')}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    {error && (
                        <div className="ace-grid__row">
                            <div className="col-xs--12">
                                <ErrorMessage error={error} />
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default withTranslations(FileUpload);
