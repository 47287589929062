import '@computerrock/react-app-polyfill/ie11';
import '@computerrock/react-app-polyfill/stable';
import React, {useState, useEffect} from 'react';
import * as ReactDOMClient from 'react-dom/client';
import PropTypes from 'prop-types';
import {renderRoutes, createBrowserHistory} from '@computerrock/formation-router';
import {FormationApp, configureStore, serviceWorker} from '@computerrock/formation-core';
import moment from 'moment';
import 'moment/locale/de';
import './styles/index.scss';
import './application/initDynamicEnv';
import config from './config';
import getRoutes from './routes';
import sagas from './sagas';
import serviceManager from './services';
import './invoice-submission/utils/gtm';
import routePaths from './routePaths';
import reducers from './reducer';

// initialize history
const history = createBrowserHistory({basename: config.PUBLIC_URL});

// initialize store
const store = configureStore({
    routes: getRoutes(),
    history,
    reducers,
    serviceManager,
});
store.runSagas(sagas);

// set default locale
moment.updateLocale('de', {
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY - HH:mm',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
        lastDay: '[Gestern] LT',
        sameDay: '[Heute] LT',
        nextDay: 'L',
        lastWeek: 'L',
        nextWeek: 'L',
        sameElse: 'L',
    },
});

// load i18nService
const i18nService = serviceManager.loadService('i18nService');

// Application
const Application = ({store, history}) => {
    const [routes, setRoutes] = useState(getRoutes());

    useEffect(() => {
        return window.DYNAMIC_ENV.onEnvChange((property, value) => {
            if (property === 'IS_MAINTENANCE_MODE_ON') {
                setRoutes(getRoutes());
                console.log(`Dynamic ENV update: ${property}=${value}`); // eslint-disable-line no-console
            }
        });
    }, []);

    return (
        <FormationApp
            store={store}
            history={history}
            routes={routes}
            routePaths={routePaths}
            defaultRoutePath={routePaths.DASHBOARD}
            i18nService={i18nService}
            render={({children}) => (
                <>
                    {children}
                </>
            )}
        >
            {renderRoutes(routes)}
        </FormationApp>
    );
};

Application.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

// render application
const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<Application store={store} history={history} />);

// webpack hot module replacement for ./routes configuration,
// ./saga configuration and state is not hot reloaded but needs to
// be accepted so HMR can work properly
if (module.hot) {
    module.hot.accept(['./routes', './sagas'], () => {
        root.render(<Application store={store} history={history} />);
    });
}

// webpack hot module replacement for reducers
if (module.hot) {
    module.hot.accept('./reducer', () => {
        const nextReducers = require('./reducer').default;
        store.hotReloadReducers(nextReducers);
    });
}

// register service worker
serviceWorker.register({
    // reload on new content
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            });
            waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
        }
    },
});
