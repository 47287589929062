import {put, take, select} from 'redux-saga/effects';
import * as invoiceSubmissionActions from '../invoiceSubmissionActionTypes';


const createInvoiceSubmissionPreCheckFlow = function* () {
    yield put({type: invoiceSubmissionActions.OPEN_CREATE_INVOICE_SUBMISSION_PRE_CHECK_MODAL});
    while (true) {
        const {payload} = yield take(invoiceSubmissionActions.CREATE_INVOICE_SUBMISSION_PRE_CHECK_SELECT_OPTION);
        const options = [...payload];
        const startIndex = 0;
        const endIndex = options.findIndex(option => option.isBlocking);
        if (startIndex <= endIndex) {
            yield put({type: invoiceSubmissionActions.CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_OPTIONS,
                payload: options.slice(startIndex, endIndex + 1)});
            yield put({type: invoiceSubmissionActions.SET_IS_CREATE_INVOICE_SUBMISSION_PRE_CHECK_SUCCESS,
                payload: {isSuccess: false}});
        } else {
            yield put({type: invoiceSubmissionActions
                .CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_OPTIONS,
            payload: options});
            const step = yield select(state => state.invoiceSubmission.createInvoiceSubmissionPreCheckCurrentStep);
            const currentStep = step < 3 ? step + 1 : step; // if last step dont increase
            yield put({type: invoiceSubmissionActions.CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_CURRENT_STEP,
                payload: {currentStep}});
            if (step === 3 && endIndex === -1) {
                yield put({type: invoiceSubmissionActions.SET_IS_CREATE_INVOICE_SUBMISSION_PRE_CHECK_SUCCESS,
                    payload: {isSuccess: true}});
            }
        }
    }
};

export default createInvoiceSubmissionPreCheckFlow;
