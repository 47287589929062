/**
 * User management action types
 */
export const START_CREATE_USER_FLOW = 'START_CREATE_USER_FLOW';
export const SUBMIT_CREATE_USER_FORM = 'SUBMIT_CREATE_USER_FORM';
export const END_CREATE_USER_FLOW = 'END_CREATE_USER_FLOW';

export const START_UPDATE_USER_FLOW = 'START_UPDATE_USER_FLOW';
export const SUBMIT_UPDATE_USER_FORM = 'SUBMIT_UPDATE_USER_FORM';
export const END_UPDATE_USER_FLOW = 'END_UPDATE_USER_FLOW';

export const START_DELETE_USER_FLOW = 'START_DELETE_USER_FLOW';
export const SUBMIT_DELETE_USER_FORM = 'SUBMIT_DELETE_USER_FORM';
export const END_DELETE_USER_FLOW = 'END_DELETE_USER_FLOW';

export const FETCH_USERS_REQUEST_SENT = 'FETCH_USERS_REQUEST_SENT';
export const FETCH_USERS_REQUEST_SUCCEEDED = 'FETCH_USERS_REQUEST_SUCCEEDED';
export const FETCH_USERS_REQUEST_FAILED = 'FETCH_USERS_REQUEST_FAILED';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';

export const FETCH_USERS_LOCATION_REQUEST = 'FETCH_USERS_LOCATION_REQUEST';
export const FETCH_USERS_LOCATION_REQUEST_SENT = 'FETCH_USERS_LOCATION_REQUEST_SENT';
export const FETCH_USERS_LOCATION_REQUEST_SUCCEEDED = 'FETCH_USERS_LOCATION_REQUEST_SUCCEEDED';
export const FETCH_USERS_LOCATION_REQUEST_FAILED = 'FETCH_USERS_LOCATION_REQUEST_FAILED';

export const FETCH_USER_REQUEST_SENT = 'FETCH_USER_REQUEST_SENT';
export const FETCH_USER_REQUEST_SUCCEEDED = 'FETCH_USER_REQUEST_SUCCEEDED';
export const FETCH_USER_REQUEST_FAILED = 'FETCH_USER_REQUEST_FAILED';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';


export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_REQUEST_SENT = 'CREATE_USER_REQUEST_SENT';
export const CREATE_USER_REQUEST_SUCCEEDED = 'CREATE_USER_REQUEST_SUCCEEDED';
export const CREATE_USER_REQUEST_FAILED = 'CREATE_USER_REQUEST_FAILED';


export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_REQUEST_SENT = 'UPDATE_USER_REQUEST_SENT';
export const UPDATE_USER_REQUEST_SUCCEEDED = 'UPDATE_USER_REQUEST_SUCCEEDED';
export const UPDATE_USER_REQUEST_FAILED = 'UPDATE_USER_REQUEST_FAILED';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_REQUEST_SENT = 'DELETE_USER_REQUEST_SENT';
export const DELETE_USER_REQUEST_SUCCEEDED = 'DELETE_USER_REQUEST_SUCCEEDED';
export const DELETE_USER_REQUEST_FAILED = 'DELETE_USER_REQUEST_FAILED';

export const START_GET_DRIVERS_FLOW = 'START_GET_DRIVERS_FLOW';

export const STORE_USERS = 'STORE_USERS';
export const CLEAR_USERS_DATA = 'CLEAR_USERS_DATA';
export const SET_USER_PERSISTENCE_STATE = 'SET_USER_PERSISTENCE_STATE';
export const STORE_USER = 'STORE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const SIGN_IN_USER_REQUEST_SENT = 'SIGN_IN_USER_REQUEST_SENT';
export const SIGN_IN_USER_REQUEST_SUCCEEDED = 'SIGN_IN_USER_REQUEST_SUCCEEDED';
export const SIGN_IN_USER_REQUEST_FAILED = 'SIGN_IN_USER_REQUEST_FAILED';
export const SIGN_IN_USER_REQUEST = 'SIGN_IN_USER_REQUEST';

export const SIGN_OUT_USER = 'SIGN_OUT_USER';

export const UPDATE_USER_ACCOUNT_REQUEST_SENT = 'UPDATE_USER_ACCOUNT_REQUEST_SENT';
export const UPDATE_USER_ACCOUNT_REQUEST_SUCCEEDED = 'UPDATE_USER_ACCOUNT_REQUEST_SUCCEEDED';
export const UPDATE_USER_ACCOUNT_REQUEST_FAILED = 'UPDATE_USER_ACCOUNT_REQUEST_FAILED';
export const SUBMIT_UPDATE_USER_ACCOUNT_FORM = 'SUBMIT_UPDATE_USER_ACCOUNT_FORM';

export const STORE_USER_ACCOUNT = 'STORE_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';

export const SET_USER_ACCOUNT_PERSISTENCE_STATE = 'SET_USER_ACCOUNT_PERSISTENCE_STATE';
export const USER_PROFILE_ACCESS_CONTROL_INITIATED = 'USER_PROFILE_ACCESS_CONTROL_INITIATED';

export const REGISTER_PUSH_NOTIFICATIONS_REQUEST = 'REGISTER_PUSH_NOTIFICATIONS_REQUEST';
export const REGISTER_PUSH_NOTIFICATIONS_REQUEST_SENT = 'REGISTER_PUSH_NOTIFICATIONS_REQUEST_SENT';
export const REGISTER_PUSH_NOTIFICATIONS_REQUEST_SUCCEEDED = 'REGISTER_PUSH_NOTIFICATIONS_REQUEST_SUCCEEDED';
export const REGISTER_PUSH_NOTIFICATIONS_REQUEST_FAILED = 'REGISTER_PUSH_NOTIFICATIONS_REQUEST_FAILED';

export const UNREGISTER_PUSH_NOTIFICATIONS_REQUEST = 'UNREGISTER_PUSH_NOTIFICATIONS_REQUEST';
export const UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_SENT = 'UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_SENT';
export const UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_SUCCEEDED = 'UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_SUCCEEDED';
export const UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_FAILED = 'UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_FAILED';

