import {immerable} from 'immer';
import {kml} from '@tmcw/togeojson';
import Entity from '../entity/Entity';
import Location from '../location/Location';
import Address from '../address/Address';


/**
 * ContractPartner entity type definition
 *
 * @typedef {Object} ContractPartner
 * @property {?string} id
 * @property {?string} name
 * @property {?Address} address
 * @property {?Location} location
 * @property {?array} polygons
 * @property {?string} phoneNumber
 */

/**
 * ContractPartner entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const contractPartnerEntityDTOMapping = {
    id: 'id',
    name: {
        dtoProperty: 'name',
        defaultValue: '',
    },
    phoneNumber: {
        dtoProperty: 'phoneNumber',
        defaultValue: '',
    },
    location: {
        dtoProperty: 'location',
        defaultValue: new Location(),
        entity: Location,
    },
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    polygon: {
        dtoProperty: 'fixPricePolygon',
        fromDTO: polygons => (polygons
            ? !parseKml(polygons.trimStart()) ? null : parseKml(polygons.trimStart())
            : null),
    },
};

/**
 * ContractPartner entity
 *
 * @class
 * @extends Entity
 * @type {ContractPartner}
 */
export default class ContractPartner extends Entity {
    [immerable] = true;

    static entityDTOMapping = contractPartnerEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}

const parseKml = text => {
    text = text.replace(/&/g, '/&amp;/');
    const kmlData = (new DOMParser()).parseFromString(text, 'text/xml');
    const json = kml(kmlData, {styles: true});
    const coords = json.features[0]?.geometry?.coordinates[0];
    return coords?.reduce((acc, curr) => {
        return [
            ...acc,
            {
                lng: curr[0],
                lat: curr[1],
            },
        ];
    }, []);
};
