import {kml} from '@tmcw/togeojson';
import {assembleAddressString} from '../utils/address';

export const parseKml = text => {
    const kmlData = (new DOMParser()).parseFromString(text, 'text/xml');
    const json = kml(kmlData, {styles: true});
    const cords = json.features[0].geometry.coordinates[0];
    return cords.reduce((acc, curr) => {
        return [
            ...acc,
            {
                lng: curr[0],
                lat: curr[1],
            },
        ];
    }, []);
};

export const isLocationInPolygon = (location, polygon) => {
    if (!location || !polygon) {
        return false;
    }
    let isInside = false;
    const totalPoints = polygon.length;
    for (let i = -1, j = totalPoints - 1; (i += 1) < totalPoints; j = i) {
        if (
            ((polygon[i].lng <= location.lng && location.lng < polygon[j].lng)
                || (polygon[j].lng <= location.lng && location.lng < polygon[i].lng))
            && (location.lat < ((polygon[j].lat - polygon[i].lat) * (location.lng - polygon[i].lng))
                / (polygon[j].lng - polygon[i].lng) + polygon[i].lat)
        ) {
            isInside = !isInside;
        }
    }
    return isInside;
};

export const setIsLocationInsidePolygon = ({lat, lng, partnerSupportsFlatRateFlag, polygon}) => {
    return partnerSupportsFlatRateFlag ? isLocationInPolygon({lat, lng}, polygon) : false;
};

export const getGeolocationFromString = (stringAddress, geocoder = new window.google.maps.Geocoder()) => {
    if (!stringAddress) {
        return Promise.resolve({
            location: null,
            address: null,
        });
    }

    return geocoder.geocode({address: stringAddress}).then(({results}) => {
        if (results && results.length) {
            const [bestResult] = results;
            const location = bestResult.geometry.location;

            const lc = {
                lat: location.lat(),
                lng: location.lng(),
            };

            const resultingAddress = parseAddress(results);
            return {
                location: lc,
                address: resultingAddress,
            };
        }
        return null;
    })
        .catch(() => {
            return {
                location: null,
                address: null,
            };
        });
};


export const parseAddress = query => {
    const resultAddr = query.find(({types}) => {
        return types.indexOf('street_address') !== -1 // allow street addresses queries
            || types.indexOf('subpremise') !== -1 // allow singular building within a collection of buildings with a common name
            || types.indexOf('route') !== -1 // allow freeway queries
            || types.indexOf('sublocality') !== -1 // allow sublocality queries
            || (types.indexOf('locality') !== -1 && types.indexOf('political') !== -1) // allow cities queries
            || (types.indexOf('postal_code')) !== -1
            || types.indexOf('premise') !== -1; // allow building queries
    });

    if (resultAddr && resultAddr.hasOwnProperty('address_components')) {
        const res = getParsedAddress(resultAddr.address_components);
        res.formattedAddress = query[0].formatted_address;
        return res;
    }
};

export const getParsedAddress = addressComponents => {
    const res = {};
    if (addressComponents) {
        const typesExists = typeName => {
            return addressComponents.some(component => component.types.includes(typeName));
        };
        addressComponents.forEach(component => {
            const types = component.types;
            if (types.indexOf('route') !== -1) {
                res.street = component.long_name;
            } else if (types.indexOf('sublocality') !== -1 && !typesExists('route')) {
                res.street = component.long_name;
            } else if (types.indexOf('street_number') !== -1) {
                res.num = component.long_name;
            } else if (types.indexOf('locality') !== -1 && types.indexOf('political') !== -1) {
                res.city = component.long_name;
            } else if (types.indexOf('postal_code') !== -1) {
                res.postCode = component.long_name;
            } else if (types.indexOf('country') !== -1) {
                res.country = component.long_name;
            }
        });
    }
    return res;
};

export const getAddressFromLocation = (location, geocoder = new window.google.maps.Geocoder()) => {
    if (location && location.lat && location.lng) {
        return geocoder
            .geocode({location})
            .then(({results}) => {
                if (results[0]) {
                    const fullAddress = getParsedAddress(results[0].address_components);
                    return assembleAddressString({
                        street: fullAddress.street,
                        number: fullAddress.num,
                        city: fullAddress.city,
                        postalCode: fullAddress.postCode,
                        country: fullAddress.country,
                    });
                }
                return null;
            })
            .catch(() => {
                return null;
            });
    }
    return Promise.resolve(null);
};
