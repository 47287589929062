import {take, fork, put, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as errorActionTypes from '../../application/errorActionTypes';
import * as actionTypes from '../documentDownloadActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';


/**
 * Document download flow
 */
const downloadDocument = function* downloadDocument({payload}) {
    const {documentFile, downloadingDocuments, openInBrowser} = payload;
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    yield put({
        type: actionTypes.SET_DOCUMENT_DOWNLOAD_STATUS,
        payload: {
            documentFileId: documentFile.id,
            isDownloading: true,
            downloadingDocuments: downloadingDocuments,
        },
    });

    yield fork(fetchRequest, actionTypes.DOWNLOAD_DOCUMENT_REQUEST,
        eafService.downloadSingleFile,
        {
            path: documentFile.filePath,
            fileName: documentFile.name,
            openInBrowser,
        });

    while (true) {
        const submitRequestResult = yield take([
            actionTypes.DOWNLOAD_DOCUMENT_REQUEST_SUCCEEDED,
            actionTypes.DOWNLOAD_DOCUMENT_REQUEST_FAILED,
        ]);

        /* if (typeof submitRequestResult.meta.documentFileId !== 'undefined'
            && submitRequestResult.meta.documentFileId !== documentFile.id) continue; */

        yield put({
            type: actionTypes.SET_DOCUMENT_DOWNLOAD_STATUS,
            payload: {
                documentFileId: documentFile.id,
                isDownloading: false,
                downloadDocument: downloadingDocuments,
            },
        });

        if (submitRequestResult.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(submitRequestResult.payload),
                    },
                },
            });
        }

        break;
    }
};

export default downloadDocument;
