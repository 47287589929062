import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import './FilterOrder.scss';
import Icon from '../../icon/Icon';

const SortDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
};

const FilterOrder = props => {
    const {sortDirection, onSortChange, name} = props;

    return (
        <Fragment>
            <div
                className="ace-c-filter-order__option"
                onClick={() => { onSortChange(name, SortDirection.DESC); }}
                aria-selected={sortDirection === SortDirection.DESC}
            >
                Absteigend
                <div className="ace-c-filter-order__icon-wrapper">
                    {sortDirection === SortDirection.DESC && (
                        <Icon dimension="sm" iconName="checkmark" color="red" />
                    )}
                </div>
            </div>
            <div
                className="ace-c-filter-order__option"
                onClick={() => { onSortChange(name, SortDirection.ASC); }}
                aria-selected={sortDirection === SortDirection.ASC}
            >
                Aufsteigend
                <div className="ace-c-filter-order__icon-wrapper">
                    {sortDirection === SortDirection.ASC && (
                        <Icon dimension="sm" iconName="checkmark" color="red" />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

FilterOrder.propTypes = {
    onSortChange: PropTypes.func,
    sortDirection: PropTypes.string,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

FilterOrder.defaultProps = {
    onSortChange: null,
    sortDirection: '',
    name: '',
};

export default FilterOrder;
