import classNames from 'classnames';
import React from 'react';
import {Icon} from '../../ui-components';
import './MapContainer.scss';

const mapContainer = ({isMapExtended, onMapExtendClick, children}) => {
    const mapContainerClass = classNames('ace-driver-assignment-map__container', {
        'ace-driver-assignment-map__container--full-screen': isMapExtended,
    });

    const extendIcon = isMapExtended
        ? <Icon dimension="lg" iconName="close" color="red" />
        : <Icon dimension="lg" iconName="map-extended" />;

    return (
        <div className={isMapExtended ? 'ace-driver-assignment-map__wrapper col-xs--12' : 'ace-bottom-margin--md col-xs--6'}>
            <div className={mapContainerClass}>
                <div
                    className="ace-form-map__extend-btn"
                    onClick={onMapExtendClick}
                >
                    {extendIcon}
                </div>
                {children}
            </div>
        </div>
    );
};

export default mapContainer;
