/**
 * @typedef {string} UserRoles
 */

/**
 * User roles
 *
 * @enum {UserRoles}
 */

export default {
    ANONYMOUS: 'ANONYMOUS',
    ADMINISTRATOR: 'ADMINISTRATOR',
    ACCOUNTANT: 'ACCOUNTANT',
    DISPATCHER: 'DISPATCHER',
    DRIVER: 'DRIVER',
    NAVISION: 'NAVISION',
};
