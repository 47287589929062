import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './PopUp.scss';

class PopUp extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isError: PropTypes.bool,
    };

    static defaultProps = {
        isError: false,
    };


    componentDidMount() {
        document.body.classList.add('no-scroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('no-scroll');
    }

    render() {
        const {children, isError} = this.props;
        return (
            <div className={classNames('ace-c-pop-up', {'ace-is-error': isError})}>
                <div className="ace-c-pop-up__wrapper">
                    <div className="ace-grid__row ace-c-pop-up__background-wrapper">
                        <div className="col-start-xs--2 col-xs--10 ace-c-pop-up__background-container" />
                    </div>
                    <div className="ace-grid__row ace-c-pop-up__content-container">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default PopUp;
