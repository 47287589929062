import errorsReducer from './application/errorsReducer';
import pricingReducer from './pricing/pricingReducer';
import messagesReducers from './messages/messagesReducer';
import supportFormReducer from './support/supportFormReducer';
import vpContactReducer from './contract-partner/contractPartnerReducer';
import applicationReducer from './application/applicationReducer';
import userReducer from './user-management/usersReducer';
import driverAssignmentsReducer from './driver-assignments/driverAssignmentsReducer';
import invoiceSubmissionReducer from './invoice-submission/invoiceSubmissionReducer';
import keycloakAuth from './keycloakAuth';
import supportFiles from './supportFiles';
import invoiceSubmissionFiles from './invoiceSubmissionFiles';
import documentDownloadReducer from './document-download/documentDownloadReducer';

const reducers = {
    application: applicationReducer,
    driverAssignments: driverAssignmentsReducer,
    invoiceSubmission: invoiceSubmissionReducer,
    documentDownload: documentDownloadReducer,
    userManagement: userReducer,
    pricingReducer,
    messagesReducers,
    errorsReducer,
    supportFormReducer,
    vpContactReducer,
    ...keycloakAuth.reducer,
    ...supportFiles.reducer,
    ...invoiceSubmissionFiles.reducer,

};

export default reducers;
