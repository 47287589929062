import React from 'react';
import PropTypes from 'prop-types';
import {TableRow} from '../table';
import './SearchRow.scss';

const SearchRow = ({className, linkTo, children}) => {
    return (
        <TableRow
            className={`search-row ${className}`}
            linkTo={linkTo}
        >
            {children}
        </TableRow>
    );
};

SearchRow.propTypes = {
    className: PropTypes.string,
    linkTo: PropTypes.object,
};

SearchRow.defaultProps = {
    className: '',
    linkTo: null,
};

export default SearchRow;
