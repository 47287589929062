/**
 * @typedef {string} status
 */

/**
 * Status
 *
 * @enum {status}
 */

export default {
    VP_ASSIGNED: 'VP_ASSIGNED',
    VP_ACCEPTED: 'VP_ACCEPTED',
    DRIVER_ASSIGNED: 'DRIVER_ASSIGNED',
    DRIVER_ACCEPTED: 'DRIVER_ACCEPTED',
    APPROACHING: 'APPROACHING',
    IN_SERVICE: 'IN_SERVICE',
    TOWING_SUB_SERVICE: 'TOWING',
    SERVICE_FINISHED: 'SERVICE_FINISHED',
    DECLINED: 'DECLINED',
    BILLABLE: 'BILLABLE',
};
