import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslations} from '@computerrock/formation-i18n';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes'; // create actions, reducer, fields,
import {createInvoiceSubmissionPreCheckSteps, createInvoiceSubmissionStatuses, createInvoiceSubmissionPreCheckRadioOptions, createInvoiceSubmissionPreCheckRadioOptionsLast} from './constants/createInvoiceSubmission';
import {ButtonPrimary, ButtonSecondary, Input, PopUp, RadioButton, RadioButtons, VersionPopUp} from '../ui-components';
import ValidationMessage from './view-elements/ValidationMessage'; // this
import './CreateInvoiceSubmissionView.scss';

class CreateInvoiceSubmissionView extends React.Component {
    static propTypes = {
        createInvoiceSubmissionState: PropTypes.string,
        isMemberIdFound: PropTypes.bool,
        endCreateInvoiceSubmissionFlow: PropTypes.func.isRequired,
        submitCreateInvoiceSubmissionForm: PropTypes.func.isRequired,
        startCreateInvoiceSubmissionFlow: PropTypes.func.isRequired,
        submitCheckMemberStatusForm: PropTypes.func.isRequired,
        createInvoiceSubmissionPreCheckCurrentStep: PropTypes.number.isRequired,
        createInvoiceSubmissionPreCheckSelectOption: PropTypes.func.isRequired,
        createInvoiceSubmissionPreCheckOptions: PropTypes.array.isRequired,
        startCreateInvoiceSubmissionPreCheckFlow: PropTypes.func.isRequired,
        isCreateInvoiceSubmissionPreCheckModalOpen: PropTypes.bool.isRequired,
        setCreateInvoiceSubmissionPreCheckCurrentStep: PropTypes.func.isRequired,
        resetCreateInvoiceSubmissionPreCheckFields: PropTypes.func.isRequired,
        isCreateInvoiceSubmissionPreCheckSuccess: PropTypes.bool.isRequired,
        translate: PropTypes.func,
        errors: PropTypes.object,
    };

    static defaultProps = {
        createInvoiceSubmissionState: createInvoiceSubmissionStatuses.IDLE,
        isMemberIdFound: null,
        translate: null,
        errors: null,

    };

    state = {
        memberId: '',
        isMemberIdValid: true,
    };

    onMemberIdChange = ({value}) => {
        const isMemberIdValid = /^[0-9]{10}$/.test(value);

        this.setState({
            memberId: value,
            isMemberIdValid,
        });
    };

    submitCheckMemberStatusForm = () => {
        this.props.submitCheckMemberStatusForm({memberId: this.state.memberId});
    };

    submitCreateInvoiceSubmissionForm = () => {
        this.props.submitCreateInvoiceSubmissionForm({memberId: this.state.memberId});
    };

    cancelInvoiceSubmissionCreation = () => {
        this.setState({
            memberId: '',
            isMemberIdValid: true,
        }, () => {
            this.props.endCreateInvoiceSubmissionFlow();
        });
    };

    onCreateInvoiceSubmissionPreCheckOptionsChange = option => {
        const {createInvoiceSubmissionPreCheckCurrentStep, createInvoiceSubmissionPreCheckSelectOption} = this.props;
        const selectedOption = {
            value: option,
            isBlocking: !(option
                === createInvoiceSubmissionPreCheckSteps[createInvoiceSubmissionPreCheckCurrentStep].correctOption),
        };
        const options = [...this.props.createInvoiceSubmissionPreCheckOptions];
        options[createInvoiceSubmissionPreCheckCurrentStep] = selectedOption;
        createInvoiceSubmissionPreCheckSelectOption(options);
    };

    render() {
        const {
            createInvoiceSubmissionState, isMemberIdFound, startCreateInvoiceSubmissionFlow,
            startCreateInvoiceSubmissionPreCheckFlow, setCreateInvoiceSubmissionPreCheckCurrentStep,
            createInvoiceSubmissionPreCheckCurrentStep, createInvoiceSubmissionPreCheckOptions, errors,
        } = this.props;
        const {translate} = this.props;
        const {memberId, isMemberIdValid} = this.state;
        const preCheckPopUpDotsNum = this.props.createInvoiceSubmissionPreCheckOptions.length
        && createInvoiceSubmissionPreCheckOptions[createInvoiceSubmissionPreCheckOptions.length - 1].isBlocking
            ? createInvoiceSubmissionPreCheckOptions.length : createInvoiceSubmissionPreCheckOptions.length + 1;
        const shouldShowPopUpDots = !!createInvoiceSubmissionPreCheckOptions.length
            && !(createInvoiceSubmissionPreCheckOptions.length === 1
                && createInvoiceSubmissionPreCheckOptions[0].isBlocking);
        const createNewCaseButtonLabel = (isMemberIdFound === null) ? translate('create_invoice_submission_view.button_label.proceed') : translate('create_invoice_submission_view.button_label.create');
        const selectedOption = createInvoiceSubmissionPreCheckOptions[createInvoiceSubmissionPreCheckCurrentStep];
        return (
            <Fragment>
                {createInvoiceSubmissionState !== createInvoiceSubmissionStatuses.IDLE && (
                    <PopUp>
                        <div className="col-start-xs--3 col-xs--8 ace-bottom-margin--xl">
                            <h3 className="ace-h3 ace-h3--center">
                                Anlage Direktbeauftragung
                            </h3>
                        </div>
                        <div className="col-start-xs--4 col-xs--6">
                            <Input
                                name="memberId"
                                label={translate('create_invoice_submission_view.input_label.member_id')}
                                value={memberId}
                                isDisabled={isMemberIdFound !== null}
                                onChange={this.onMemberIdChange}
                                additionalAttributes={{maxLength: '20'}}
                            />
                        </div>
                        <div
                            className="col-start-xs--4 col-xs--6 ace-c-invoice-submissions-view__notification-container"
                        >
                            {isMemberIdFound === true && isMemberIdValid && (
                                <ValidationMessage type="OK">
                                    {translate('global.validation_messages.create_de_is_member')}
                                </ValidationMessage>
                            )}
                            {isMemberIdFound === false && isMemberIdValid && (
                                <ValidationMessage type="ERROR">
                                    {translate('global.validation_messages.create_de_not_member')}
                                </ValidationMessage>
                            )}
                            {!isMemberIdValid && (
                                <ValidationMessage type="ERROR">
                                    {translate('global.validation_messages.invalid_member_number_format')}
                                </ValidationMessage>
                            )}
                            {!!errors && isMemberIdValid && (
                                <ValidationMessage type="ERROR">
                                    {translate('global.validation_messages.create_de_fail')}
                                </ValidationMessage>
                            )}
                        </div>
                        <div className="col-start-xs--3 col-xs--4">
                            <ButtonSecondary
                                name="cancelNewForm"
                                label={translate('create_invoice_submission_view.button_label.cancel')}
                                onClick={this.cancelInvoiceSubmissionCreation}
                            />
                        </div>
                        <div className="col-xs--4">
                            <ButtonPrimary
                                name="confirmNewForm"
                                label={createNewCaseButtonLabel}
                                isDisabled={!isMemberIdValid
                                    || memberId === ''
                                    // eslint-disable-next-line
                                    || createInvoiceSubmissionState === createInvoiceSubmissionStatuses.MEMBERSHIP_CHECK_PENDING
                                    // eslint-disable-next-line
                                    || createInvoiceSubmissionState === createInvoiceSubmissionStatuses.INVOICE_SUBMISSION_PENDING}
                                onClick={isMemberIdFound !== null
                                    ? this.submitCreateInvoiceSubmissionForm
                                    : this.submitCheckMemberStatusForm}
                            />
                        </div>
                    </PopUp>
                )}
                {this.props.isCreateInvoiceSubmissionPreCheckModalOpen && (
                    <VersionPopUp
                        isClosableOnClickOutside
                        closeFunction={this.props.resetCreateInvoiceSubmissionPreCheckFields}
                        className="ace-c-pre-check-modal"
                    >
                        <div className="ace-bottom-margin--smd">
                            <h3 className="ace-h3 ace-h3--center">
                                {translate('create_invoice_submission_view.pop_up_title.create_invoice_pre_check')}
                            </h3>
                        </div>
                        <div
                            className="col-start-xs--3 col-xs--8 ace-bottom-margin--smd ace-c-invoice-submission-view__pre-check-modal__content"
                        >
                            <div className="ace-c-invoice-submission-view__pre-check-modal__content__main">
                                <p className="ace-copy-m ace-copy-m--center ace-bottom-margin--smd">
                                    {
                                        // eslint-disable-next-line
                                        translate(createInvoiceSubmissionPreCheckSteps[createInvoiceSubmissionPreCheckCurrentStep].questionText)
                                    }
                                </p>
                                <div>
                                    <RadioButtons
                                        className="ace-c-invoice-submissions-view__modal-buttons__container"
                                        onChange={this.onCreateInvoiceSubmissionPreCheckOptionsChange}
                                    >
                                        {Object.values(this.props.createInvoiceSubmissionPreCheckCurrentStep < 3
                                            ? createInvoiceSubmissionPreCheckRadioOptions
                                            : createInvoiceSubmissionPreCheckRadioOptionsLast).map(option => {
                                            return (
                                                <RadioButton
                                                    key={option.text}
                                                    id={option.type}
                                                    selectedId={selectedOption?.value}
                                                    className="col-xs--6"
                                                >
                                                    {translate(option.text)}
                                                </RadioButton>
                                            );
                                        })}
                                    </RadioButtons>
                                </div>
                            </div>
                            {shouldShowPopUpDots
                                && (
                                    <div
                                        className="ace-c-invoice-submission-view__pre-check-modal__content__navigation"
                                    >
                                        { // eslint-disable-next-line
                                            createInvoiceSubmissionPreCheckSteps.slice(0, preCheckPopUpDotsNum).map((element, index) => {
                                                return (
                                                    <div
                                                        key={element.step}
                                                        className={`ace-c-invoice-submission-view__pre-check-modal__content__navigation__link 
                                            ${this.props.createInvoiceSubmissionPreCheckCurrentStep === element.step
                                                            ? 'ace-c-invoice-submission-view__pre-check-modal__content__navigation__link--active' : ''}`}
                                                        onClick={
                                                            // eslint-disable-next-line
                                                            () => setCreateInvoiceSubmissionPreCheckCurrentStep({currentStep: element.step})
                                                        }
                                                    />
                                                );
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                        {this.props.createInvoiceSubmissionPreCheckOptions[createInvoiceSubmissionPreCheckCurrentStep]
                            // eslint-disable-next-line
                            && this.props.createInvoiceSubmissionPreCheckOptions[createInvoiceSubmissionPreCheckCurrentStep]
                                .isBlocking
                            && (
                                <Fragment>
                                    <div className="col-start-xs--3 col-xs--8 ace-bottom-margin--smd">
                                        <p className="ace-copy-m ace-copy-m--center">
                                            {
                                                // eslint-disable-next-line
                                                translate(createInvoiceSubmissionPreCheckSteps[createInvoiceSubmissionPreCheckCurrentStep].suggestionText)
                                            }
                                        </p>
                                    </div>
                                    <div
                                        className="ace-c-invoice-submission-view__pre-check-modal-button ace-bottom-margin--smd"
                                    >
                                        <ButtonSecondary
                                            label={translate('create_invoice_submission_view.button_label.ok')}
                                            name="btnCloseModal"
                                            onClick={() => {
                                                this.props.resetCreateInvoiceSubmissionPreCheckFields();
                                            }
                                            }
                                        />
                                    </div>
                                </Fragment>
                            )
                        }
                        {
                            this.props.isCreateInvoiceSubmissionPreCheckSuccess
                            && (
                                <div
                                    className="ace-c-invoice-submission-view__pre-check-modal-button ace-bottom-margin--smd"
                                >
                                    <ButtonPrimary
                                        label={translate('create_invoice_submission_view.button_label.start_create_invoice_pre_check')}
                                        name="btnStartCreateInvoiceSubmission"
                                        onClick={() => {
                                            this.props.resetCreateInvoiceSubmissionPreCheckFields();
                                            startCreateInvoiceSubmissionFlow();
                                        }
                                        }
                                    />
                                </div>
                            )
                        }
                    </VersionPopUp>
                )}
                <div
                    className="ace-link-s ace-c-manual-case-button"
                    onClick={startCreateInvoiceSubmissionPreCheckFlow}
                >
                    {translate('create_invoice_submission_view.pop_up_title.create_invoice_pre_check')}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        createInvoiceSubmissionState: state.invoiceSubmission.createInvoiceSubmissionState,
        isMemberIdFound: state.invoiceSubmission.isMemberIdFound,
        isCreateInvoiceSubmissionPreCheckModalOpen: state.invoiceSubmission.isCreateInvoiceSubmissionPreCheckModalOpen,
        createInvoiceSubmissionPreCheckCurrentStep: state.invoiceSubmission.createInvoiceSubmissionPreCheckCurrentStep,
        createInvoiceSubmissionPreCheckOptions: state.invoiceSubmission.createInvoiceSubmissionPreCheckOptions,
        isCreateInvoiceSubmissionPreCheckSuccess: state.invoiceSubmission.isCreateInvoiceSubmissionPreCheckSuccess,
        errors: state.invoiceSubmission.errors,
    };
};

const mapDispatchToProps = dispatch => ({
    startCreateInvoiceSubmissionFlow: () => dispatch({
        type: invoiceSubmissionActionTypes.START_CREATE_INVOICE_SUBMISSION_FLOW,
    }),
    startCreateInvoiceSubmissionPreCheckFlow: () => dispatch({
        type: invoiceSubmissionActionTypes.START_CREATE_INVOICE_SUBMISSION_PRE_CHECK_FLOW,
    }),
    createInvoiceSubmissionPreCheckSelectOption: payload => dispatch({
        type: invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_PRE_CHECK_SELECT_OPTION,
        payload,
    }),
    submitCheckMemberStatusForm: payload => dispatch({
        type: invoiceSubmissionActionTypes.SUBMIT_CHECK_MEMBER_STATUS_FORM,
        payload,
    }),
    resetCreateInvoiceSubmissionPreCheckFields: () => dispatch({
        type: invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_PRE_CHECK_RESET,
    }),
    setCreateInvoiceSubmissionPreCheckCurrentStep: payload => dispatch({
        type: invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_CURRENT_STEP,
        payload,
    }),
    endCreateInvoiceSubmissionFlow: () => dispatch({
        type: invoiceSubmissionActionTypes
            .END_CREATE_INVOICE_SUBMISSION_FLOW,
    }),
    submitCreateInvoiceSubmissionForm: payload => dispatch({
        type: invoiceSubmissionActionTypes.SUBMIT_CREATE_INVOICE_SUBMISSION_FORM,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslations(CreateInvoiceSubmissionView));
