import React from 'react';
import PropTypes from 'prop-types';
import {GoogleMap} from '@react-google-maps/api';
import * as mapSettings from '../googleMapSettings';
import './CustomGoogleMap.scss';


class CustomGoogleMap extends React.Component {
    static propTypes = {
        options: PropTypes.object,
        mapContainerStyle: PropTypes.object,
        markers: PropTypes.array.isRequired,
        isInfoWindowOpen: PropTypes.bool,
    };

    static defaultProps = {
        options: {
            styles: mapSettings.MAP_STYLES,
            maxZoom: 18,
            disableDefaultUI: true,
            zoomControl: true,
        },
        mapContainerStyle: {
            width: '100%',
            height: '100%',
        },
        isInfoWindowOpen: false,
    };

    state = {
        googleMap: null,
    };

    componentDidMount() {
        this.setBounds();
    }

    componentDidUpdate(prevProps, prevState) {
        // disable setBounds if clicked pin info window is open
        const isInfoWindowUpdate = prevProps.isInfoWindowOpen !== this.props.isInfoWindowOpen
        || this.props.isInfoWindowOpen;
        const areMarkerPositionChanged = () => {
            if (prevProps.markers.length !== this.props.markers.length) return true;
            for (let i = 0; i < this.props.markers.length; i += 1) {
                if (prevProps.markers[i].lat !== this.props.markers[i].lat
                    || this.props.markers[i].lng !== prevProps.markers[i].lng) return true;
            }
            return false;
        };
        if ((areMarkerPositionChanged()
            || (this.state.googleMap !== prevState.googleMap && prevState.googleMap === null))
            && !isInfoWindowUpdate) {
            this.setBounds();
        }
    }

    setBounds = () => {
        const bounds = new window.google.maps.LatLngBounds();

        this.props.markers.forEach(place => {
            if (place.lat && place.lng) {
                bounds.extend(new window.google.maps.LatLng(
                    place.lat,
                    place.lng,
                ));
            }
        });
        if (this.state.googleMap) this.state.googleMap.fitBounds(bounds);
    };

    render() {
        const {mapContainerStyle, options} = this.props;
        return (
            <GoogleMap
                onLoad={mapInstance => {
                    this.setState({
                        googleMap: mapInstance,
                    });
                }}
                onUnmount={
                    () => {
                        this.setState({
                            googleMap: null,
                        });
                    }
                }
                center={mapSettings.DEFAULT_MAP_CENTER}
                options={options}
                mapContainerStyle={mapContainerStyle}
            >
                {this.props.children}
            </GoogleMap>
        );
    }
}
export default CustomGoogleMap;
