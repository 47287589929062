import React from 'react';
import PropTypes from 'prop-types';
import {renderRoutes} from '@computerrock/formation-router';
import LoadScript from './LoadScript';
import * as googleMapSettings from '../google-map/googleMapSettings';

const App = ({route}) => (
    <LoadScript url={googleMapSettings.GOOGLE_MAP_API_URL}>
        {renderRoutes(route.routes)}
    </LoadScript>
);

App.propTypes = {
    route: PropTypes.object.isRequired,
};

export default App;
