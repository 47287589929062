/**
 * @typedef {string} status
 */

/**
 * Status
 *
 * @enum {status}
 */

export default {
    BILLABLE: 'BILLABLE',
    IN_EDITING: 'IN_EDITING',
    CREDITING_REQUESTED: 'CREDITING_REQUESTED',
    REQUEST: 'REQUEST',
    BOOKED: 'BOOKED',
    PAID: 'PAID',
    PAYMENT_DECLINED: 'PAYMENT_DECLINED',
    EXTERNAL_INVOICING: 'EXTERNAL_INVOICING',
};
