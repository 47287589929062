/**
 * Bem class name building utility function
 * @param {*} baseClass bem class prefix
 * @param {*} optional config object with bem class sufixes as keys
 */

export const isNotEmptyObject = obj => {
    return Object.keys(obj).length !== 0;
};

export const getFullName = (firstName, lastName) => {
    return `${firstName || ''}${lastName ? ` ${lastName}` : ''}` || '---';
};

export const isElementInArray = (element, array) => {
    if (!element && !array && !Array.isArray(array)) return false;
    return array.includes(element);
};
