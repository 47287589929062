import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import * as actionTypes from './documentDownloadActionTypes';
import './DocumentDownloadView.scss';
import AppLayout from '../application/AppLayoutView';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import {ButtonPrimary, AceDatePicker} from '../ui-components';
import {TableHeader, TablePanel, TableBody, TableHeaderCell} from '../ui-components/table';
import {FilterDropdown, FilterSelect, FilterOrder, FilterOption} from '../ui-components/filters';
import TableRowDirectory from './view-elements/TableRowDirectory';
import TableRowFile from './view-elements/TableRowFile';
import {getUserAccount} from '../user-management/userManagementSelectors';

const DocumentDownloadView = props => {
    const {translate, createTranslateShorthand} = useTranslate();
    const translateView = createTranslateShorthand('document_download_view');
    const [openDirectories, setOpenDirectories] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [sortBy, setSortBy] = useState('name');
    const [sortDirection, setSortDirection] = useState('ASC');
    const [filterFromDate, setFilterFromDate] = useState(null);
    const [filterToDate, setFilterToDate] = useState(null);
    const [filterFileTypes, setFilterFileTypes] = useState([]);

    const {documentFiles, submitDocumentDownloadForm, downloadingDocuments,
        isMultipleDocumentDownloadInProgress, userAccount} = props;

    const documentFilesCopy = documentFiles && documentFiles.map(([key, value]) => {
        return value;
    });

    const onSortChange = (sortBy, sortDirection) => {
        setSortBy(sortBy);
        setSortDirection(sortDirection);
    };

    const onFilterDateDelete = dateRange => {
        if (dateRange === 'fromServiceStartDateTime') {
            setFilterFromDate(null);
        }

        if (dateRange === 'toServiceEndDateTime') {
            setFilterToDate(null);
        }
    };

    const onStartDateFilterChange = dateValue => {
        setFilterFromDate(dateValue ? moment(dateValue) : null);
    };

    const onEndDateFilterChange = dateValue => {
        setFilterToDate(dateValue ? moment(dateValue) : null);
    };

    const onFilterFileTypeChange = (filterBy, filterValue) => {
        if (filterBy === 'fileType') {
            let newValue = [];
            newValue = filterFileTypes.includes(filterValue)
                ? filterFileTypes.filter(option => option !== filterValue)
                : [...filterFileTypes, filterValue];
            setFilterFileTypes(newValue);
        }
    };

    const filterDocumentFiles = () => {
        let filteredDocumentFiles = [];
        if (!documentFiles) return filteredDocumentFiles;
        filteredDocumentFiles = documentFilesCopy;

        // sort documents
        filteredDocumentFiles = filteredDocumentFiles.sort((documentFileA, documentFileB) => {
            return sortDirection === 'ASC'
                ? documentFileA[sortBy] > documentFileB[sortBy] ? 1 : -1
                : documentFileA[sortBy] > documentFileB[sortBy] ? -1 : 1;
        });

        // filtering by file type
        if (filterFileTypes.length > 0) {
            filteredDocumentFiles = filteredDocumentFiles.filter(documentFile => {
                return documentFile.isDir
                    ? true : filterFileTypes.includes(documentFile.fileType);
            });
        }

        // filtering by date
        if (filterFromDate) {
            filteredDocumentFiles = filteredDocumentFiles.filter(documentFile => {
                return documentFile.isDir ? true
                    : (filterFromDate.isSame(documentFile.lastModifiedDate, 'day')
                        || filterFromDate.isBefore(documentFile.lastModifiedDate));
            });
        }
        if (filterToDate) {
            filteredDocumentFiles = filteredDocumentFiles.filter(documentFile => {
                return documentFile.isDir ? true
                    : (filterToDate.isSame(documentFile.lastModifiedDate, 'day')
                        || filterToDate.isAfter(documentFile.lastModifiedDate));
            });
        }

        return filteredDocumentFiles;
    };

    const toggleDirectory = directoryId => {
        setOpenDirectories(openDirectories.includes(directoryId)
            ? openDirectories.filter(openDirectoryId => openDirectoryId !== directoryId)
            : openDirectories.concat([directoryId]));
    };

    const selectDocumentForDownload = documentFileId => {
        setSelectedDocuments(
            selectedDocuments.includes(documentFileId)
                ? selectedDocuments.filter(selectedDocumentFileId => selectedDocumentFileId !== documentFileId)
                : selectedDocuments.concat([documentFileId]),
        );
    };

    const submitDownloadSelectedDocumentsForm = () => {
        // if only one file selected, use different API
        const {submitDownloadSelectedDocumentsForm} = props;
        if (selectedDocuments.length === 1) {
            const documentFile = documentFilesCopy.filter(documentFile => documentFile.id === selectedDocuments[0])[0];
            submitDocumentDownloadForm({
                documentFile,
                openInBrowser: documentFile.fileType === 'pdf',
            });
            return;
        }

        // download multiple files
        submitDownloadSelectedDocumentsForm({
            selectedDocumentFiles: documentFilesCopy
                .filter(documentFile => selectedDocuments.includes(documentFile.id)),
        });
    };

    const filteredDocumentFiles = filterDocumentFiles();
    const fileTypeFilterOptions = documentFiles ? documentFiles.reduce((fileTypeFilterOptions, documentFile) => {
        if (!documentFile[1].fileType) return fileTypeFilterOptions;
        return {
            [documentFile[1].fileType]: {text: documentFile[1].fileType.toUpperCase(), type: documentFile[1].fileType},
            ...fileTypeFilterOptions,
        };
    }, {}) : null;

    return (
        <AppLayout>
            <div className="ace-document-download-view__header">
                <h3 className="ace-h3">
                    {translateView('section_title.documents')}
                </h3>
            </div>
            <div className="ace-grid__row ace-document-download-view__button">
                <div className="col-start-sm--5 col-sm--4">
                    <ButtonPrimary
                        name="btnDownloadSelectedDocuments"
                        label={isMultipleDocumentDownloadInProgress
                            ? translateView('button_label.please_wait')
                            : translateView('button_label.download_selection')
                    }
                        onClick={submitDownloadSelectedDocumentsForm}
                        isDisabled={selectedDocuments.length === 0 || isMultipleDocumentDownloadInProgress}
                    />
                </div>
            </div>
            <TablePanel>
                <TableHeader className="ace-bottom-margin--sm">
                    <TableHeaderCell className="col-start-sm--2 col-sm--1 ace-c-table-cell--center">
                        {fileTypeFilterOptions && (
                            <FilterDropdown
                                label={translateView('filter_dropdown_label.kind')}
                                isActive={!!filterFileTypes.length}
                            >
                                <FilterSelect
                                    onChange={onFilterFileTypeChange}
                                    filterName="fileType"
                                >  {
                                        Object.values(fileTypeFilterOptions)
                                            .map(option => {
                                                const isChecked = !!filterFileTypes
                                                    .find(element => element === option.type);
                                                return (
                                                    <FilterOption
                                                        key={option.type}
                                                        value={option.type}
                                                        isChecked={isChecked}
                                                    >
                                                        {option.text}
                                                    </FilterOption>
                                                );
                                            })
                                    }
                                </FilterSelect>
                            </FilterDropdown>
                        )}
                    </TableHeaderCell>
                    <TableHeaderCell className="col-sm--4">
                        <FilterDropdown
                            label={translateView('filter_dropdown_label.name')}
                            isActive={sortBy === 'name'}
                        >
                            <FilterOrder
                                onSortChange={onSortChange}
                                name="name"
                                sortDirection={sortBy === 'name' ? sortDirection : ''}
                            />
                        </FilterDropdown>
                    </TableHeaderCell>
                    <TableHeaderCell className="col-sm--2">
                        <FilterDropdown
                            label={translateView('filter_dropdown_label.date')}
                            isActive={sortBy === 'createdDate' || filterToDate || filterToDate}
                        >
                            <FilterOrder
                                onSortChange={onSortChange}
                                name="createdDate"
                                sortDirection={sortBy === 'createdDate' ? sortDirection : ''}
                            />
                            <AceDatePicker
                                selected={filterFromDate ? filterFromDate.toDate() : null}
                                value={filterFromDate}
                                onChange={onStartDateFilterChange}
                                name="date-from"
                                isButtonInput={true}
                                buttonInputLabel="Von"
                                dateFormat="dd.M.yyyy"
                                placeholderText={translateView('date_picker_placeholder.start_date')}
                                filterName="fromServiceStartDateTime"
                                dateFilterDelete={onFilterDateDelete}
                            />
                            <AceDatePicker
                                selected={filterToDate ? filterToDate.toDate() : null}
                                value={filterToDate}
                                onChange={onEndDateFilterChange}
                                name="date-to"
                                isButtonInput={true}
                                buttonInputLabel="Bis"
                                dateFormat="dd.M.yyyy"
                                placeholderText={translateView('date_picker_placeholder.end_date')}
                                filterName="toServiceEndDateTime"
                                dateFilterDelete={onFilterDateDelete}
                            />
                        </FilterDropdown>
                    </TableHeaderCell>
                    <TableHeaderCell className="col-sm--4">
                        <FilterDropdown
                            label={translateView('filter_dropdown_label.file_size')}
                            isActive={sortBy === 'fileSize'}
                        >
                            <FilterOrder
                                onSortChange={onSortChange}
                                name="fileSize"
                                sortDirection={sortBy === 'fileSize' ? sortDirection : ''}
                            />
                        </FilterDropdown>
                    </TableHeaderCell>
                </TableHeader>

                {!documentFiles && (
                    <ScreenMessage
                        messageParagraphs={[translate('global.screen_message.loading')]}
                        isLoading
                    />
                )}

                {documentFiles && (documentFiles.length === 0 || filteredDocumentFiles
                    .filter(filteredDocumentFile => !filteredDocumentFile.isDir).length === 0) && (
                        <ScreenMessage
                            messageParagraphs={[translateView('screen_message.no_documents')]}
                        />
                )}
                <TableBody hasRows={!!documentFiles && documentFiles.length > 0 && !!filteredDocumentFiles}>
                    {documentFiles && documentFiles.length > 0 && filteredDocumentFiles
                        .filter(documentFile => !documentFile.isDir && !documentFile.parentId)
                        .map(documentFile => (
                            <TableRowFile
                                key={documentFile.id}
                                documentFile={documentFile}
                                downloadingDocuments={downloadingDocuments}
                                submitDocumentDownloadForm={submitDocumentDownloadForm}
                                selectedDocuments={selectedDocuments}
                                selectDocumentForDownload={() => selectDocumentForDownload(documentFile.id)}
                            />
                        ))}

                    {documentFiles && documentFiles.length > 0 && filteredDocumentFiles
                        .filter(documentFile => documentFile.isDir)
                        .map(documentFile => {
                            const directoryDocumentFiles = filteredDocumentFiles.filter(directoryDocumentFile => {
                                return directoryDocumentFile.parentId === documentFile.id
                                    && !directoryDocumentFile.isDir;
                            });

                            if (directoryDocumentFiles.length === 0) return null;

                            return (
                                <TableRowDirectory
                                    key={documentFile.id}
                                    documentFile={documentFile}
                                    directoryDocumentFiles={directoryDocumentFiles}
                                    downloadingDocuments={downloadingDocuments}
                                    isOpened={openDirectories.includes(documentFile.id)}
                                    toggleDirectory={() => toggleDirectory(documentFile.id)}
                                    submitDocumentDownloadForm={submitDocumentDownloadForm}
                                    selectedDocuments={selectedDocuments}
                                    selectDocumentForDownload={selectDocumentForDownload}
                                    partnerIds={userAccount.partnerIds}
                                />
                            );
                        })}
                </TableBody>
            </TablePanel>
        </AppLayout>
    );
};

DocumentDownloadView.propTypes = {
    documentFiles: PropTypes.array,
    downloadingDocuments: PropTypes.arrayOf(PropTypes.string),
    submitDownloadSelectedDocumentsForm: PropTypes.func.isRequired,
    submitDocumentDownloadForm: PropTypes.func.isRequired,
    isMultipleDocumentDownloadInProgress: PropTypes.bool,
    userAccount: PropTypes.object,
};

DocumentDownloadView.defaultProps = {
    documentFiles: null,
    downloadingDocuments: [],
    isMultipleDocumentDownloadInProgress: false,
    userAccount: {},
};

const mapStateToProps = state => {
    return {
        documentFiles: state.documentDownload.documentFiles && Object.entries(state.documentDownload.documentFiles),
        downloadingDocuments: state.documentDownload.downloadingDocuments,
        isMultipleDocumentDownloadInProgress: state.documentDownload.isMultipleDocumentDownloadInProgress,
        userAccount: getUserAccount(state),
    };
};

const mapDispatchToProps = dispatch => ({
    submitDownloadSelectedDocumentsForm: payload => dispatch({
        type: actionTypes.SUBMIT_DOWNLOAD_SELECTED_DOCUMENTS_FORM,
        payload,
    }),
    submitDocumentDownloadForm: payload => dispatch({type: actionTypes.SUBMIT_DOCUMENT_DOWNLOAD_FORM, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDownloadView);
