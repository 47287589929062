export const DestinationOptions = {
    VP: 'VP',
    SPECIAL_WORKSHOP: 'SPECIAL_WORKSHOP',
    FREE_WORKSHOP: 'FREE_WORKSHOP',
    PLACE_OF_RESIDENCE: 'PLACE_OF_RESIDENCE',
    VEHICLE_RESCUER_PLACE: 'VEHICLE_RESCUER_PLACE',
    OTHER_LOCATION: 'OTHER_LOCATION',
};

export const towingDestinationOptions = [
    DestinationOptions.VP,
    DestinationOptions.SPECIAL_WORKSHOP,
    DestinationOptions.FREE_WORKSHOP,
    DestinationOptions.PLACE_OF_RESIDENCE,
    DestinationOptions.VEHICLE_RESCUER_PLACE,
    DestinationOptions.OTHER_LOCATION,
];

export const pickUpDestinationOptions = [
    DestinationOptions.SPECIAL_WORKSHOP,
    DestinationOptions.PLACE_OF_RESIDENCE,
];
