import {eafStatuses} from '../../ella-dispo-entity-types';

export const defaultQueryParams = {
    size: 100,
};

export const invoiceSubmissionParams = {
    OPEN: {
        ...defaultQueryParams,
        statuses: [
            eafStatuses.BILLABLE,
            eafStatuses.IN_EDITING,
            eafStatuses.REQUEST,
        ],
    },
    SUBMITTED: {
        ...defaultQueryParams,
        statuses: eafStatuses.CREDITING_REQUESTED,
    },
    SEARCHED: {
        ...defaultQueryParams,
        statuses: [
            eafStatuses.IN_EDITING,
            eafStatuses.CREDITING_REQUESTED,
            eafStatuses.EXTERNAL_INVOICING,
            eafStatuses.BOOKED,
            eafStatuses.PAID,
            eafStatuses.REQUEST,
            eafStatuses.BILLABLE,
            eafStatuses.PAYMENT_DECLINED,
        ],
    },
    ARCHIVE: {
        size: 10,
        statuses: [
            eafStatuses.BOOKED,
            eafStatuses.PAID,
            eafStatuses.EXTERNAL_INVOICING,
            eafStatuses.PAYMENT_DECLINED,
        ],
    },
};
