import moment from 'moment';

export class ShouldNotContainNumbersValidator {
    static validate(value) {
        return !(/\d/.test(value));
    }
}

export class EmailValidator {
    static errorMessage = 'inputValidation.emailFormatError';
    // eslint-disable-next-line
    static EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    static validate(email) {
        return EmailValidator.EMAIL_REGEX.test(email);
    }
}

export class BirthdayValidator {
    static validate(birthday) {
        if (!birthday) {
            return !!birthday;
        }

        const eighteenYearsAgo = moment().subtract(18, 'years');
        const birthDay = moment(birthday);

        if (!birthDay.isValid()) {
            // INVALID DATE
            return false;
        }
        if (birthDay.isSame(moment('1900-01-01'))) {
            return false;
        } else if (eighteenYearsAgo.isAfter(birthDay)) {
            // 18+
            return true;
        }
        // < 18
        return false;
    }
}

export class NumberValidator {
    static validate(value) {
        if (!value) {
            return true;
        }
        return /^\d+$/.test(value);
    }
}

export class PriceValidator {
    static validate(value) {
        if (!value) {
            return true;
        }
        return /^[0-9]*(,|\d)?([0-9]*)?$/.test(value);
    }
}

export class PostalCodeValidator {
    static errorMessage = 'inputValidation.postalCodeFormatError';

    static validate(value) {
        return /^\d{5}$/.test(value);
    }
}

export class BooleanValidator {
    static validate(value) {
        return typeof (value) === typeof (true);
    }
}

export const NumberInRangeValidator = (rangeStart, rangeEnd) => {
    if (isNaN(rangeStart) || isNaN(rangeEnd)) return;

    return class {
        static validate(value) {
            if (typeof value === 'string') {
                if (!isNaN(rangeStart)) {
                    return false;
                }
            }
            return value >= rangeStart && value <= rangeEnd;
        }
    };
};

export class NonEmptyStringValidator {
    static validate(value) {
        return typeof value === 'string' && value.length > 0;
    }
}

export class NonEmptyObjectValidator {
    static validate(value) {
        if (!value) {
            return false;
        }
        return typeof value === 'object' && Object.keys(value).length > 0;
    }
}
