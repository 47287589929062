import {fork, put, take, select} from 'redux-saga/effects';
import {push} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import {submitPopUpStatuses} from '../constants/invoiceSubmissionStatuses';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import fetchRequest from '../../application/sagas/fetchRequest';


const submitInvoiceSubmissionFlow = function* submitInvoiceSubmissionFlow() {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION);
        yield put({
            type: invoiceSubmissionActionTypes.STORE_SUBMIT_POPUP_STATUS,
            payload: {submitPopUpStatus: submitPopUpStatuses.CLOSED},
        });

        yield put({type: errorActionTypes.DELETE_ERRORS});
        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.UPDATING},
        });
        const {externalDocumentNumber, invoiceSubmissionId} = payload;
        const params = {
            invoiceSubmissionId,
            externalDocumentNumber,
        };
        yield fork(fetchRequest, invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION_REQUEST,
            eafService.submitInvoiceSubmission, params);

        const resultAction = yield take([
            invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION_REQUEST_FAILED,
        ]);

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });

        if (!resultAction.error) {
            yield put({
                type: invoiceSubmissionActionTypes.STORE_SUBMIT_POPUP_STATUS,
                payload: {submitPopUpStatus: submitPopUpStatuses.SUCCESS},
            });

            const actionResult = yield take([
                invoiceSubmissionActionTypes.CLOSE_SUBMIT_POPUP,
                invoiceSubmissionActionTypes.DOWNLOAD_PDF_AND_CLOSE_SUBMIT_POPUP,
            ]);

            if (actionResult.type === invoiceSubmissionActionTypes.DOWNLOAD_PDF_AND_CLOSE_SUBMIT_POPUP) {
                const {invoiceSubmissionId} = actionResult.payload;
                yield put({
                    type: invoiceSubmissionActionTypes.START_DOWNLOAD_PROOF_OF_SERVICE_FLOW,
                    payload: {invoiceSubmissionId},
                });
            }

            yield put({
                type: invoiceSubmissionActionTypes.STORE_SUBMIT_POPUP_STATUS,
                payload: {submitPopUpStatus: submitPopUpStatuses.CLOSED},
            });

            yield put(push(routePaths.OPEN_INVOICE_SUBMISSIONS));
            continue;
        }

        const {errors} = resultAction.payload;
        const isValidationError = errors && errors.length;
        const isExternalNumberError = errors && errors.some(error => Object.values(error).includes('externalDocumentNumber'));
        const shouldShowValidationPopUp = isValidationError && (errors.length >= 1 && !isExternalNumberError);

        if (isExternalNumberError) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(resultAction.payload),
                    },
                },
            });
            continue;
        }

        if (shouldShowValidationPopUp) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(resultAction.payload),
                    },
                },
            });

            yield put({
                type: invoiceSubmissionActionTypes.STORE_SUBMIT_POPUP_STATUS,
                payload: {submitPopUpStatus: submitPopUpStatuses.VALIDATION_FAILED},
            });
            continue;
        }

        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export default submitInvoiceSubmissionFlow;
