import {all, fork, takeLatest} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import invoiceSubmissionSearchFlow from './sagas/searchInvoiceSubmissionsFlow';
import clearInvoiceSubmissionSearchResults from './sagas/clearInvoiceSubmissionSearchResults';
import createInvoiceSubmissionPreCheckFlow from './sagas/createInvoiceSubmissionPreCheckFlow';
import createInvoiceSubmissionFlow from './sagas/createInvoiceSubmissionFlow';
import updateInvoiceSubmissionFlow from './sagas/updateInvoiceSubmissionFlow';
import changingViewState from './sagas/changingViewState';
import createFollowUpInvoiceSubmissionFlow from './sagas/createFollowUpInvoiceSubmissionFlow';
import submitInvoiceSubmissionFlow from './sagas/submitInvoiceSubmissionFlow';
import updateVehicleDataFlow from './sagas/updateVehicleDataFlow';
import updateMemberDataFlow from './sagas/updateMemberDataFlow';
import downloadProofOfServiceFlow from './sagas/downloadProofOfServiceFlow';
import updateExternalStatusFlow from './sagas/updateExternalStatusFlow';
import downloadInvoicePdf from './sagas/downloadInvoicePdf';
import downloadMultipleInvoices from './sagas/downloadMultipleInvoices';
import exportInvoiceSubmissions from './sagas/exportInvoiceSubmissions';
import downloadBCReceiptPDF from './sagas/downloadBCReceiptPDF';
import getInvoiceSubmissionDetails from './sagas/getInvoiceSubmissionDetails';

const invoiceSubmissionWatcher = function* invoiceSubmissionWatcher() {
    yield all([
        fork(invoiceSubmissionSearchFlow),
        fork(createInvoiceSubmissionFlow),
        fork(updateInvoiceSubmissionFlow),
        fork(exportInvoiceSubmissions),
        fork(changingViewState),
        fork(downloadProofOfServiceFlow),
        fork(downloadInvoicePdf),
        fork(updateVehicleDataFlow),
        fork(updateMemberDataFlow),
        fork(downloadMultipleInvoices),
        fork(submitInvoiceSubmissionFlow),
        fork(createFollowUpInvoiceSubmissionFlow),
        fork(updateExternalStatusFlow),
        fork(downloadBCReceiptPDF),
        fork(getInvoiceSubmissionDetails),
        takeLatest(invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS, clearInvoiceSubmissionSearchResults), // eslint-disable-line
        takeLatest(invoiceSubmissionActionTypes.START_CREATE_INVOICE_SUBMISSION_PRE_CHECK_FLOW,
            createInvoiceSubmissionPreCheckFlow),
    ]);
};

export default invoiceSubmissionWatcher;
