import {put, fork, take, select} from 'redux-saga/effects';
import * as supportFormActionTypes from '../supportFormActionsTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import fetchRequest from '../../application/sagas/fetchRequest';
import supportFiles from '../../supportFiles';


const submitSupportFormFlow = function* () {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(supportFormActionTypes.START_SUBMIT_SUPPORT_FORM_FLOW);
        const user = yield select(state => state.userManagement.user);
        yield put({type: errorActionTypes.DELETE_ERRORS});
        const files = yield select(state => state.supportFilesReducer.files);
        const data = {
            ...payload,
            attachments: Object.values(files).map(file => {
                return {
                    id: file.id,
                    name: file.fileName,
                    isSignature: file.isSignature,
                };
            }),
            user: {
                contractPartnerIds: user.partnerIds,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            },
        };

        yield fork(fetchRequest, supportFormActionTypes.SUPPORT_FORM_SUBMIT_REQUEST,
            eafService.sendSupportMessage, {data});

        const actionResult = yield take([
            supportFormActionTypes.SUPPORT_FORM_SUBMIT_REQUEST_SUCCEEDED,
            supportFormActionTypes.SUPPORT_FORM_SUBMIT_REQUEST_FAILED,
        ]);

        if (!actionResult.error) {
            yield put({
                type: supportFiles.actionTypes.STORE_FILES,
                payload: {files: []},
            });

            yield put({
                type: supportFormActionTypes.SET_SUPPORT_MODAL_OPEN_STATUS,
                payload: {isSupportModalOpen: true},
            });
            continue;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(actionResult.payload),
                },
                isClosedError: true,
            },
        });
    }
};

export default submitSupportFormFlow;
