import React, {createRef, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {withTranslations} from '@computerrock/formation-i18n';
import {trailer} from './constants/trailer';
import {accident} from './constants/accident';
import {BillingTypes} from './constants/billingTypes';
import {getGeolocationFromString, setIsLocationInsidePolygon} from '../google-map/googleMapFunctions';
import {assembleAddressString} from '../utils/address';
import {decodeAdditionalServices} from './utils/additionalServices';
import {calculateDuration} from './utils/times';
import {formAnchors} from './constants/formAnchors';
import {scrollToAnchorWithOffset, setAnchorRefFromError} from '../utils/validationMessages';
import * as mapSettings from '../google-map/googleMapSettings';
import MapSection from './view-elements/MapSection';
import {isMainServiceSelected} from './utils/mainServices';
import {
    ButtonPrimary,
    ButtonSecondary, ErrorMessage,
    Input,
    RadioButton,
    RadioButtons,
    TextArea,
} from '../ui-components';
import {partnerSupportsFlatRate} from './utils/pricing';
import {AdditionalServices} from './constants/additionalServices';
import MainServicesSection from './view-elements/MainServicesSection';
import InvoiceSubmissionDatesTimes from './view-elements/InvoiceSubmissionDatesTimes';
import RecoveryDurationSection from './view-elements/RecoveryDurationSection';
import AdditionalServicesSection from './view-elements/AdditionalServicesSection';
import TowingSection from './view-elements/TowingSection';
import PickUpSection from './view-elements/PickUpSection';
import Tooltip from '../ui-components/tooltip/Tooltip';
import InfoIcon from '../ui-components/assets/icons/info-red.svg';
import './FormView.scss';
import EmptyTripReasonSection from './view-elements/EmptyTripReasonSection';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import {InvoiceSubmissionDataStates as InvoiceSubmissionDataState} from './constants/invoiceSubmissionDataStates';
import FileUpload from '../file-upload/FileUpload';
import {isFollowUpInvoiceSubmission} from './utils/subCases';
import {eafMainServices, JourneyContinuation} from '../ella-dispo-entity-types';
import invoiceSubmissionFiles from '../invoiceSubmissionFiles';
import {isStartDateEqualEndDate} from '../utils/times';

class FormView extends React.Component {
    static propTypes = {
        invoiceSubmission: PropTypes.object.isRequired,
        pricingInfo: PropTypes.number,
        isInvoiceSubmissionSubmitted: PropTypes.bool,
        isInvoiceSubmissionRequestedSubService: PropTypes.bool,
        isInvoiceSubmissionStatusRequest: PropTypes.bool,
        errors: PropTypes.object,
        updateInvoiceSubmission: PropTypes.func.isRequired,
        uploadFile: PropTypes.func,
        deleteFile: PropTypes.func,
        files: PropTypes.object,
        invoiceSubmissionDataState: PropTypes.string.isRequired,
        // updateInvoiceSubmission: PropTypes.func,
        // updateClosedInvoiceSubmission: PropTypes.func,
        formAnchor: PropTypes.string,
        invoiceSubmissionMainServices: PropTypes.object,
        translate: PropTypes.func,
    };

    static defaultProps = {
        isInvoiceSubmissionSubmitted: false,
        isInvoiceSubmissionRequestedSubService: false,
        isInvoiceSubmissionStatusRequest: false,
        errors: {},
        files: null,
        formAnchor: '',
        uploadFile: () => {
        },
        deleteFile: () => {
        },
        // updateInvoiceSubmission: () => {},
        // updateClosedInvoiceSubmission: () => {},
        pricingInfo: null,
        invoiceSubmissionMainServices: {},
        translate: null,
    };

    constructor(props) {
        super(props);
        const {invoiceSubmission, pricingInfo} = this.props;
        const {startDate, startTime, endDate, endTime} = invoiceSubmission;
        const partnerSupportsFlatRateFlag = partnerSupportsFlatRate(pricingInfo);
        const isPickupBillingTypeTime = isStartDateEqualEndDate(startDate, endDate, startTime, endTime)
            && (invoiceSubmission.mainServices?.filter(service => service.type === eafMainServices.PICKUP).length !== 0
                || isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.PICKUP));

        this.state = {
            invoiceSubmission: {
                ...this.props.invoiceSubmission,
                billingType: setIsLocationInsidePolygon({
                    lat: invoiceSubmission.damageLocation.lat,
                    lng: invoiceSubmission.damageLocation.lng,
                    partnerSupportsFlatRateFlag,
                    polygon: invoiceSubmission.vpPolygon,
                }) && isPickupBillingTypeTime
                && !isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.RECOVERY)
                    ? BillingTypes.FIXED_PRICE
                    : ((!invoiceSubmission.billingType || pricingInfo === null
                        || (invoiceSubmission.isForeignInsurance && invoiceSubmission.isFirstOpening))
                    || isPickupBillingTypeTime
                    || isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.RECOVERY)
                        ? BillingTypes.TIME : invoiceSubmission.billingType),
                isInvoiceSubmissionInsideVpPolygon: setIsLocationInsidePolygon({
                    lat: invoiceSubmission.damageLocation.lat,
                    lng: invoiceSubmission.damageLocation.lng,
                    partnerSupportsFlatRateFlag,
                    polygon: invoiceSubmission.vpPolygon,
                }),
                invoiceSubmissionAddressString: assembleAddressString({
                    street: invoiceSubmission.damageAddressStreet,
                    postalCode: invoiceSubmission.damagePostCode,
                    city: invoiceSubmission.damageCity,
                    country: invoiceSubmission.damageCountry,
                }),
                towingDestinationAddress: assembleAddressString({
                    street: invoiceSubmission.towingAddressStreet,
                    postalCode: invoiceSubmission.towingAddressPostCode,
                    city: invoiceSubmission.towingAddressCity,
                    country: invoiceSubmission.towingAddressCountry,
                }),
                pickUpDestinationAddress: assembleAddressString({
                    street: invoiceSubmission.pickUpAddressStreet,
                    postalCode: invoiceSubmission.pickUpAddressPostCode,
                    city: invoiceSubmission.pickUpAddressCity,
                    country: invoiceSubmission.pickUpAddressCountry,
                }),
                towingDestinationType: invoiceSubmission.towingDestinationType,
                pickUpDestinationType: invoiceSubmission.pickUpDestinationType,
                additionalServices: decodeAdditionalServices(invoiceSubmission.additionalServices),
                invoiceSubmissionDuration: calculateDuration({startDate, startTime, endDate, endTime}),
            },
            formRefs: {
                formStart: createRef(),
                mainServicesSection: createRef(),
                recoverySection: createRef(),
                towingSection: createRef(),
                pickUpSection: createRef(),
                additionalServicesSection: createRef(),
            },
        };
    }

    componentDidMount() {
        const {invoiceSubmission, pricingInfo} = this.props;
        const partnerSupportsFlatRateFlag = partnerSupportsFlatRate(pricingInfo);
        this.calculateRecoveryDuration();
        const geocoder = new window.google.maps.Geocoder();
        if (!invoiceSubmission?.damageLocation?.lat && !invoiceSubmission?.damageLocation.lng) {
            getGeolocationFromString(invoiceSubmission.invoiceSubmissionAddressString, geocoder)
                .then(geolocation => {
                    const {location, address} = geolocation;
                    if (address) {
                        this.setState(prevState => {
                            return {
                                invoiceSubmission: {
                                    ...prevState.invoiceSubmission,
                                    damageLocation: location,
                                    isInvoiceSubmissionInsideVpPolygon: setIsLocationInsidePolygon({
                                        lat: location.lat,
                                        lng: location.lng,
                                        partnerSupportsFlatRateFlag,
                                        polygon: invoiceSubmission.vpPolygon,
                                    }),
                                },
                            };
                        });
                        return;
                    }
                    this.setState(prevState => {
                        return {
                            invoiceSubmission: {
                                ...prevState.invoiceSubmission,
                                damageLocation: {
                                    lat: mapSettings.DEFAULT_MAP_CENTER.lat,
                                    lng: mapSettings.DEFAULT_MAP_CENTER.lng,
                                },
                            },
                        };
                    });
                });
        }

        if (isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.TOWING)
            && this.state.invoiceSubmission.towingDestinationAddress) {
            getGeolocationFromString(this.state.invoiceSubmission.towingDestinationAddress, geocoder)
                .then(geolocation => {
                    if (geolocation) {
                        const {address, location} = geolocation;
                        if (address) {
                            const {lat, lng} = location;
                            const isTowingLocationInsideVpPolygon = setIsLocationInsidePolygon({
                                lat,
                                lng,
                                partnerSupportsFlatRateFlag,
                                polygon: invoiceSubmission.vpPolygon,
                            });
                            this.setState(prevState => {
                                return {
                                    invoiceSubmission: {
                                        ...prevState.invoiceSubmission,
                                        towingLocation: {
                                            lat,
                                            lng,
                                        },
                                        isTowingLocationInsideVpPolygon,
                                        billingType: isTowingLocationInsideVpPolygon
                                        && !isMainServiceSelected(invoiceSubmission.decodedMainServices,
                                            eafMainServices.RECOVERY)
                                        && !invoiceSubmission.additionalServices
                                            .find(({type}) => {
                                                return AdditionalServices[type] === AdditionalServices.ADDITIONAL_STAFF;
                                            })
                                            ? BillingTypes.FIXED_PRICE : BillingTypes.TIME,
                                    },
                                };
                            });
                        }
                    }
                });
        }
        this.scrollToAnchor();
    }

    componentDidUpdate(prevProps, prevState) {
        if (Object.keys(this.props.errors).length
            && Object.keys(prevProps.errors).length !== Object.keys(this.props.errors).length) {
            scrollToAnchorWithOffset(this.state.formRefs[setAnchorRefFromError(this.props.errors)]);
        }
    }

    scrollToAnchor = () => {
        const {formAnchor} = this.props;
        if (formAnchor) {
            formAnchor === formAnchors.FIRST_FORM
                ? scrollToAnchorWithOffset(this.state.formRefs.formStart)
                : (formAnchor === formAnchors.SECOND_FORM
                    ? scrollToAnchorWithOffset(this.state.formRefs.mainServicesSection)
                    : scrollToAnchorWithOffset(this.state.formRefs.additionalServicesSection));
        }
    };

    onDateTimeChange = (name, value) => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    [name]: value,
                },
            };
        }, () => {
            const {startDate, startTime, endDate, endTime} = this.state.invoiceSubmission;
            this.setState(prevState => {
                return {
                    invoiceSubmission: {
                        ...prevState.invoiceSubmission,
                        invoiceSubmissionDuration: calculateDuration({startDate, startTime, endDate, endTime}),
                    },
                };
            });
        });
    };

    onMapDataChange = data => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    ...data,
                },
            };
        });
    };

    onJourneyContinuationChange = journeyContinuation => {
        let decodedMainServices = {};
        if (journeyContinuation === JourneyContinuation.EMPTY_TRIP) {
            decodedMainServices = {
                [JourneyContinuation.EMPTY_TRIP]: JourneyContinuation.EMPTY_TRIP,
            };
        }
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    journeyContinuation,
                    decodedMainServices,
                    recoveryStart: {hours: '', minutes: ''},
                    recoveryEnd: {hours: '', minutes: ''},
                    recoveryDuration: '-',
                    isAccident: 'NO_ACCIDENT',
                    hasTrailer: 'NO_TRAILER',
                    towingDestinationType: '',
                    towingDestinationName: '',
                    towingDestinationAddress: '',
                    towingAddressStreet: '',
                    towingAddressCity: '',
                    towingAddressPostCode: '',
                    towingAddressCountry: '',
                    towingLocation: null,
                    isTowingLocationInsideVpPolygon: false,
                    pickUpDestinationType: '',
                    pickUpDestinationName: '',
                    pickUpDestinationAddress: '',
                    pickUpAddressStreet: '',
                    pickUpAddressCity: '',
                    pickUpAddressPostCode: '',
                    pickUpAddressCountry: '',
                    pickUpDistance: '',
                    additionalServices: [],
                    additionalServicesComment: '',
                    emptyTripReason: '',
                },
            };
        });
    };

    onBillingTypeChange = billingType => {
        let newData = {
            billingType,
        };

        this.setState(prevState => {
            if (billingType === BillingTypes.FIXED_PRICE
                && Object.keys(prevState.invoiceSubmission.additionalServices).length) {
                const newAdditionalServices = prevState.invoiceSubmission.additionalServices.filter(service => {
                    return service.additionalServiceType !== 'ADDITIONAL_STAFF';
                });
                newData = {
                    ...newData,
                    additionalServices: newAdditionalServices,
                    ...(!newAdditionalServices.length && {additionalServicesComment: ''}),
                };
            }
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    ...newData,
                },
            };
        });
    };

    onMainServiceDataChange = data => {
        this.setState(prevState => {
            let newData = {...data};
            // eslint-disable-next-line
            const wasRecoverySelected = isMainServiceSelected(prevState.invoiceSubmission.decodedMainServices, eafMainServices.RECOVERY);
            const isRecoverySelected = isMainServiceSelected(data.decodedMainServices, eafMainServices.RECOVERY);
            // eslint-disable-next-line
            const wasTowingSelected = isMainServiceSelected(prevState.invoiceSubmission.decodedMainServices, eafMainServices.TOWING);
            const isTowingSelected = isMainServiceSelected(data.decodedMainServices, eafMainServices.TOWING);
            // eslint-disable-next-line
            const wasPickUpSelected = isMainServiceSelected(prevState.invoiceSubmission.decodedMainServices, eafMainServices.PICKUP);
            const isPickUpSelected = isMainServiceSelected(data.decodedMainServices, eafMainServices.PICKUP);
            if (Object.keys(data.decodedMainServices).length === 0) {
                newData = {
                    ...newData,
                    additionalServices: [],
                    additionalServicesComment: '',
                };
            }
            if (wasRecoverySelected !== isRecoverySelected && isRecoverySelected) {
                newData = {
                    ...newData,
                    billingType: BillingTypes.TIME,
                };
            }
            if (wasRecoverySelected !== isRecoverySelected && !isRecoverySelected) {
                newData = {
                    ...newData,
                    recoveryStart: {hours: '', minutes: ''},
                    recoveryEnd: {hours: '', minutes: ''},
                    recoveryDuration: '-',
                };
            }
            if (wasTowingSelected !== isTowingSelected && !isTowingSelected) {
                newData = {
                    ...newData,
                    towingDestinationType: '',
                    towingDestinationName: '',
                    towingDestinationAddress: '',
                    towingLocation: null,
                    isTowingLocationInsideVpPolygon: false,
                    towingAddressStreet: '',
                    towingAddressCity: '',
                    towingAddressPostCode: '',
                    towingAddressCountry: '',
                };
            }
            if (wasPickUpSelected !== isPickUpSelected && isPickUpSelected) {
                newData = {
                    ...newData,
                    billingType: BillingTypes.TIME,
                };
            }
            if (wasPickUpSelected !== isPickUpSelected && !isPickUpSelected) {
                newData = {
                    ...newData,
                    pickUpDestinationType: '',
                    pickUpDestinationName: '',
                    pickUpDestinationAddress: '',
                    pickUpAddressStreet: '',
                    pickUpAddressCity: '',
                    pickUpAddressPostCode: '',
                    pickUpAddressCountry: '',
                    pickUpDistance: '',
                };
            }

            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    ...newData,
                },
            };
        });
    };

    onMainCommentChange = value => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    mainServicesComment: value,
                },
            };
        });
    };

    onRecoveryTimeChange = (name, value) => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    [name]: value,
                },
            };
        }, () => {
            this.calculateRecoveryDuration();
        });
    };

    calculateRecoveryDuration = () => {
        const {invoiceSubmission} = this.state;

        if (!invoiceSubmission.recoveryStart.hours || !invoiceSubmission.recoveryStart.minutes
            || !invoiceSubmission.recoveryEnd.hours || !invoiceSubmission.recoveryEnd.minutes) {
            this.setState(prevState => {
                return {
                    invoiceSubmission: {
                        ...prevState.invoiceSubmission,
                        recoveryDuration: '-',
                    },
                };
            });
            return;
        }

        const startDateTime = invoiceSubmission.startOfRecovery
            .hours(invoiceSubmission.recoveryStart.hours || 0)
            .minutes(invoiceSubmission.recoveryStart.minutes || 0);
        const endDateTime = invoiceSubmission.endOfRecovery
            .hours(invoiceSubmission.recoveryEnd.hours || 0)
            .minutes(invoiceSubmission.recoveryEnd.minutes || 0);
        let diff = endDateTime.diff(startDateTime, 'minutes');
        if (diff < 0) {
            endDateTime.add(1, 'days');
            diff = endDateTime.diff(startDateTime, 'minutes');
        }
        if (diff > 24 * 60) {
            endDateTime.subtract(1, 'days');
            diff = endDateTime.diff(startDateTime, 'minutes');
        }
        const hours = Math.floor(diff / 60);
        const minutes = (diff % 60);
        const duration = hours || minutes ? `${hours ? `${hours} Std. ` : ''}${minutes ? `${minutes} Min.` : ''}` : '-';
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    endOfRecovery: endDateTime,
                    recoveryDuration: duration,
                },
            };
        });
    };

    onTrailerOptionChange = hasTrailer => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    hasTrailer,
                },
            };
        });
    };

    onAccidentOptionChange = isAccident => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    isAccident,
                },
            };
        });
    };

    onAdditionalServicesChange = data => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    ...data,
                },
            };
        });
    };

    onTowingDataChange = data => {
        const {invoiceSubmission} = this.state;

        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    ...data,
                    ...(data.isTowingLocationInsideVpPolygon !== undefined
                        && data.isTowingLocationInsideVpPolygon !== prevState.isTowingLocationInsideVpPolygon && {
                        billingType: data.isTowingLocationInsideVpPolygon
                            && !isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.RECOVERY)
                            && !invoiceSubmission.additionalServices
                                .find(({type}) => {
                                    return AdditionalServices[type] === AdditionalServices.ADDITIONAL_STAFF;
                                })
                            ? BillingTypes.FIXED_PRICE : BillingTypes.TIME,
                    }),
                },
            };
        });
    };

    onPickUpDataChange = data => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    ...data,
                },
            };
        });
    };

    onExternalDocumentNoChange = data => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    externalDocumentNo: data.value,
                },
            };
        });
    };

    onEmptyTripReasonChange = emptyTripReason => {
        this.setState(prevState => {
            return {
                invoiceSubmission: {
                    ...prevState.invoiceSubmission,
                    emptyTripReason,
                },
            };
        });
    };

    onSubmitForm = isNoValidation => {
        const {invoiceSubmission} = this.state;
        const emptyEndTimeForPickUp = invoiceSubmission.billingType === BillingTypes.TIME
            && isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.PICKUP)
            && invoiceSubmission.endTime.hours === ''
            && invoiceSubmission.endTime.minutes === '';

        if (emptyEndTimeForPickUp) {
            this.setState(prevState => {
                return {
                    invoiceSubmission: {
                        ...prevState.invoiceSubmission,
                        endTime: invoiceSubmission.startTime,
                    },
                };
            });
        }

        const {startDate, endDate, startTime, endTime, mainServices, decodedMainServices} = invoiceSubmission;
        const isEqualTimeForPickUpService = isStartDateEqualEndDate(startDate, endDate, startTime, emptyEndTimeForPickUp ? startTime : endTime) // eslint-disable-line max-len
            && (mainServices?.filter(service => service.type === eafMainServices.PICKUP).length !== 0
                || isMainServiceSelected(decodedMainServices, eafMainServices.PICKUP));

        this.props.updateInvoiceSubmission({
            assignmentId: invoiceSubmission.assignmentId,
            invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
            data: {
                ...invoiceSubmission,
                endTime: emptyEndTimeForPickUp ? startTime : endTime,
                // if dates (and times) are equal then set billingType to TIME
                ...(!!isEqualTimeForPickUpService && {billingType: BillingTypes.TIME}),
            },
            isNoValidation,
        });
    };

    render() {
        // const {files, deleteFile, uploadFile} = this.props;
        const {translate} = this.props;
        const {pricingInfo, invoiceSubmissionDataState, invoiceSubmissionMainServices} = this.props;
        const {isInvoiceSubmissionSubmitted, isInvoiceSubmissionRequestedSubService} = this.props;
        const {isInvoiceSubmissionStatusRequest, uploadFile, deleteFile, files, errors} = this.props;
        const {invoiceSubmission} = this.state;
        const isFlatRateSupported = partnerSupportsFlatRate(pricingInfo);
        const isRecoverySelected = isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.RECOVERY); // eslint-disable-line
        const isAdditionalPersonalSelected = !!invoiceSubmission.additionalServices
            .find(({type}) => {
                return AdditionalServices[type] === AdditionalServices.ADDITIONAL_STAFF;
            });
        const isInvoiceSubmissionClosed = isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest;
        const isEqualTimeForPickUpService = isStartDateEqualEndDate(invoiceSubmission.startDate, invoiceSubmission.endDate, invoiceSubmission.startTime, invoiceSubmission.endTime) // eslint-disable-line
            && (invoiceSubmission.mainServices?.filter(service => service.type === eafMainServices.PICKUP).length !== 0
                || isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.PICKUP));
        const shouldDisableElement = isInvoiceSubmissionRequestedSubService || isInvoiceSubmissionClosed
            || isFollowUpInvoiceSubmission(invoiceSubmission.invoiceSubmissionId);
        const disableInputElementClass = {'disable-element': shouldDisableElement};
        const preCheck = !isFlatRateSupported || isRecoverySelected || isAdditionalPersonalSelected
            || isEqualTimeForPickUpService;
        const newSelectedAdditionalServices = invoiceSubmission.additionalServices
            ? invoiceSubmission.additionalServices.filter(service => service.newlyAdded) : [];
        const isClosedSubmitButtonDisabled = !newSelectedAdditionalServices.length;
        const isTowingSelected = isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.TOWING);
        const isMainCommentMandatory = isTowingSelected
            ? invoiceSubmission.billingType === BillingTypes.TIME
            && invoiceSubmission.isInvoiceSubmissionInsideVpPolygon
            && invoiceSubmission.isTowingLocationInsideVpPolygon
            : invoiceSubmission.isInvoiceSubmissionInsideVpPolygon
            && invoiceSubmission.billingType === BillingTypes.TIME;
        const preSelectPrice = isEqualTimeForPickUpService || preCheck
            ? BillingTypes.TIME : invoiceSubmission.billingType;
        const isEmptyTrip = invoiceSubmission.journeyContinuation === JourneyContinuation.EMPTY_TRIP;
        const journeyContinuationWrapperClass = classnames('ace-error__wrapper', {
            'ace-error__wrapper--errors': errors['assignment.journeyContinuation'],
        });

        return (
            <>
                <div className="ace-bottom-margin--xl" ref={this.state.formRefs.formStart}>
                    <div className="ace-grid__row ace-bottom-margin--lg">
                        <div className="col-xs--12">
                            <h4 className="ace-h4">{translate('form_view.header.title')}</h4>
                        </div>
                    </div>
                    <InvoiceSubmissionDatesTimes
                        invoiceSubmission={invoiceSubmission}
                        onDataChange={this.onDateTimeChange}
                        errors={errors}
                        isDisabled={shouldDisableElement}
                    />
                    <MapSection
                        invoiceSubmission={invoiceSubmission}
                        pricingInfo={pricingInfo}
                        onDataChange={this.onMapDataChange}
                        errors={errors}
                        isDisabled={shouldDisableElement}
                    />
                    <div className="ace-grid__row">
                        <div className="col-xs--12 ace-bottom-margin--sm">
                            <p className="ace-copy-m">{translate('form_view.text.journey_continuation')}</p>
                        </div>
                    </div>
                    <div className={classnames('ace-grid__row', journeyContinuationWrapperClass)}>
                        <RadioButtons
                            className={errors['assignment.journeyContinuation'] ? '' : 'ace-bottom-margin--md'}
                            onChange={this.onJourneyContinuationChange}
                        >
                            {Object.keys(JourneyContinuation).map(option => {
                                return (
                                    <RadioButton
                                        key={option}
                                        id={option}
                                        selectedId={invoiceSubmission.journeyContinuation}
                                        className="col-xs--3"
                                        isDisabled={shouldDisableElement}
                                    >
                                        {translate(`global.journey_continuation.${option.toLowerCase()}`)}
                                    </RadioButton>
                                );
                            })}
                        </RadioButtons>
                    </div>
                    {errors['assignment.journeyContinuation'] && (
                        <div className="ace-grid__row">
                            <div className="col-xs--12 ace-bottom-margin--sm">
                                <ErrorMessage error={translate(`global.validation_messages.${errors['assignment.journeyContinuation']}`)} />
                            </div>
                        </div>
                    )}
                    <div className="ace-grid__row">
                        <div className="col-xs--12 ace-bottom-margin--sm">
                            <p className="ace-copy-m">{translate('form_view.text.billing_after')}</p>
                        </div>
                    </div>
                    <RadioButtons
                        className="ace-bottom-margin--md"
                        onChange={this.onBillingTypeChange}
                        error={errors['assignment.billingType'] && translate(`global.validation_messages.${errors['assignment.billingType']}`)}
                    >
                        {Object.keys(BillingTypes).map(option => {
                            return (
                                <RadioButton
                                    key={option}
                                    id={option}
                                    selectedId={preSelectPrice}
                                    className="col-xs--3"
                                    isDisabled={shouldDisableElement
                                        || (preCheck && option === BillingTypes.FIXED_PRICE)}
                                >
                                    {translate(`global.billing_type.${option.toLowerCase()}`)}
                                </RadioButton>
                            );
                        })}
                    </RadioButtons>
                    <div className="edit-page__form-container" ref={this.state.formRefs.mainServicesSection}>
                        <div className="ace-grid__row ace-bottom-margin--lg">
                            <div className="col-xs--12">
                                <h4 className="ace-h4">{translate('form_view.header.performance_data')}</h4>
                            </div>
                        </div>
                        <div className="ace-grid__row ace-bottom-margin--lg">
                            <div className="col-xs--12 col-lg--6">
                                {invoiceSubmission.journeyContinuation !== JourneyContinuation.EMPTY_TRIP ? (
                                    <MainServicesSection
                                        invoiceSubmission={invoiceSubmission}
                                        onMainServicesChange={this.onMainServiceDataChange}
                                        errors={errors}
                                        isDisabled={shouldDisableElement}
                                    />
                                ) : (
                                    <EmptyTripReasonSection
                                        invoiceSubmission={invoiceSubmission}
                                        onEmptyTripReasonChange={this.onEmptyTripReasonChange}
                                        errors={errors}
                                        isDisabled={shouldDisableElement}
                                    />
                                )}
                            </div>
                            <div className="col-xs--10 col-lg--6">
                                <div className="service-form__text-area-wrapper">
                                    <TextArea
                                        className="service-form__text-area-container"
                                        value={invoiceSubmission.mainServicesComment}
                                        onChange={this.onMainCommentChange}
                                        name="mainServicesComment"
                                        label={translate('form_view.text_area_label.comment')}
                                        isOptional={!isMainCommentMandatory}
                                        isDisabled={shouldDisableElement}
                                        error={errors.mainServicesComment && translate(`global.validation_messages.${errors.mainServicesComment}`)}
                                    />
                                </div>
                            </div>
                        </div>
                        {isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.RECOVERY) && (
                            <div
                                ref={this.state.formRefs.recoverySection}
                                className={classnames('ace-bottom-margin--md', disableInputElementClass)}
                            >
                                <div className="ace-grid__row">
                                    <RecoveryDurationSection
                                        invoiceSubmission={invoiceSubmission}
                                        onRecoveryTimeChange={this.onRecoveryTimeChange}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        )}
                        {isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.TOWING)
                            && !isEmptyTrip && (
                                <TowingSection
                                    invoiceSubmission={invoiceSubmission}
                                    onDataChange={this.onTowingDataChange}
                                    pricingInfo={pricingInfo}
                                    errors={errors}
                                    isDisabled={shouldDisableElement}
                                    forwardRef={this.state.formRefs.towingSection}
                                />
                        )}
                        {isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.PICKUP)
                            && !isEmptyTrip && (
                                <PickUpSection
                                    invoiceSubmission={invoiceSubmission}
                                    onDataChange={this.onPickUpDataChange}
                                    forwardRef={this.state.formRefs.pickUpSection}
                                    errors={errors}
                                    isDisabled={shouldDisableElement}
                                />
                        )}
                        <div className="ace-grid__row ace-bottom-margin--md">
                            <div className="col-xs--12">
                                <span className="ace-copy-m">{translate('form_view.text.third_party_involved')}</span>
                            </div>
                        </div>
                        <RadioButtons className="ace-bottom-margin--md" onChange={this.onAccidentOptionChange}>
                            {Object.keys(accident).map(option => {
                                return (
                                    <RadioButton
                                        key={option}
                                        id={option}
                                        selectedId={invoiceSubmission.isAccident}
                                        className="col-xs--3 col-xl--2"
                                        isDisabled={shouldDisableElement}
                                    >
                                        {translate(`global.accident.${option.toLowerCase()}`)}
                                    </RadioButton>
                                );
                            })}
                        </RadioButtons>
                        {invoiceSubmission.journeyContinuation !== JourneyContinuation.EMPTY_TRIP && (
                            <Fragment>
                                <div className="ace-grid__row ace-bottom-margin--md">
                                    <div className="col-xs--12">
                                        <span
                                            className="ace-copy-m"
                                        >{translate('form_view.text.has_trailer')}
                                        </span>
                                    </div>
                                </div>
                                <RadioButtons className="ace-bottom-margin--md" onChange={this.onTrailerOptionChange}>
                                    {Object.keys(trailer).map(option => {
                                        return (
                                            <RadioButton
                                                key={option}
                                                id={option}
                                                selectedId={invoiceSubmission.hasTrailer}
                                                className="col-xs--3 col-xl--2"
                                                isDisabled={shouldDisableElement}
                                            >
                                                {translate(`global.trailer.${option.toLowerCase()}`)}
                                            </RadioButton>
                                        );
                                    })}
                                </RadioButtons>
                            </Fragment>
                        )}
                        <AdditionalServicesSection
                            invoiceSubmission={invoiceSubmission}
                            invoiceSubmissionMainServices={invoiceSubmissionMainServices}
                            onDataChange={this.onAdditionalServicesChange}
                            isInvoiceSubmissionRequestedSubService={isInvoiceSubmissionRequestedSubService}
                            isInvoiceSubmissionClosed={false}
                            forwardRef={this.state.formRefs.additionalServicesSection}
                            errors={errors}
                        />
                        {isInvoiceSubmissionClosed && (
                            <div className="ace-grid__row ace-bottom-margin--md">
                                <div className="col-xs--12">
                                    <div className="internal-invoice__wrapper">
                                        <span className="ace-copy-m">{translate('form_view.text.internal_invoice_number')}</span>
                                        <Tooltip
                                            message={translate('form_view.tooltip_message.internal_invoice_number')}
                                            position="left"
                                        >
                                            <img
                                                className="overview-price__internal-invoice-icon"
                                                src={InfoIcon}
                                                alt=""
                                            />
                                        </Tooltip>
                                    </div>

                                </div>
                                <div className="col-xs--4">
                                    <Input
                                        label={translate('form_view.input_label.external_document_number')}
                                        value={invoiceSubmission.externalDocumentNo}
                                        onChange={this.onExternalDocumentNoChange}
                                        additionalAttributes={{maxLength: '35'}}
                                        error={errors.externalDocumentNumber
                                    && translate(`global.validation_messages.${errors.externalDocumentNumber}`)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="ace-grid__row ace-bottom-margin--sm">
                        <div className="col-xs--12">
                            <span className="ace-copy-m">{translate('form_view.text.add_attachments')}</span>
                        </div>
                    </div>
                    <FileUpload
                        isInvoiceSubmissionClosed={shouldDisableElement}
                        error={errors.attachments && translate(`global.validation_messages.${errors.attachments}`)}
                        uploadFile={uploadFile}
                        deleteFile={deleteFile}
                        files={{...Object.values(files).filter(file => !file.isSignature)}}
                    />
                </div>

                {!isInvoiceSubmissionSubmitted || isInvoiceSubmissionStatusRequest ? (
                    <div className="ace-grid__row ace-bottom-margin--lg">
                        <div className="col-start-xs--3 col-xs--4 col-start-xl--4 col-xl--3">
                            <ButtonSecondary
                                label={translate('form_view.button_label.save')}
                                name="btnSave"
                                onClick={() => {
                                    this.onSubmitForm(true);
                                }}
                                isDisabled={invoiceSubmissionDataState === InvoiceSubmissionDataState.LOADING}
                            />
                        </div>
                        <div className="col-xs--4 col-xl--3">
                            <ButtonPrimary
                                label={translate('form_view.button_label.proceed')}
                                name="btnProceed"
                                onClick={() => {
                                    this.onSubmitForm(false);
                                }}
                                isDisabled={invoiceSubmissionDataState === InvoiceSubmissionDataState.LOADING}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="ace-grid__row ace-bottom-margin--lg">
                        <div className="col-start-xs--4 col-xs--5">
                            <ButtonPrimary
                                label={translate('form_view.button_label.submit')}
                                name="btnSubmit"
                                onClick={() => {
                                }}
                                isDisabled={isClosedSubmitButtonDisabled
                                    || invoiceSubmissionDataState === InvoiceSubmissionDataState.LOADING}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        files: state.invoiceSubmissionFilesReducer.files,
        invoiceSubmissionMainServices: state.invoiceSubmission.followUpInvoiceSubmissionMainServices,
    };
};

const mapDispatchToProps = dispatch => ({
    updateInvoiceSubmission: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION,
        payload,
    }),
    uploadFile: payload => dispatch(invoiceSubmissionFiles.uploadFile(payload)),
    deleteFile: payload => dispatch(invoiceSubmissionFiles.deleteFile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslations(FormView));
