/**
 *  Route paths
 */
export default {
    HELP: '/help',
    ROOT: '/',
    SELECT_APP: '/select-app',
    SUPPORT: '/ella-app/support',
    OIDC_AUTHORIZE: '/oidc/authorize',
    OIDC_AUTHENTICATE: '/oidc/authenticate',
    ACCESS_NOT_ALLOWED: '/access-not-allowed',
    INVOICE_SUBMISSION: '/ella-app/invoice-submission/:invoiceSubmissionId',
    OPEN_INVOICE_SUBMISSIONS: `/ella-app`,
    SUBMITTED_DAMAGE_EVENTS: `/ella-app/submitted-damage-events`,
    SUBMITTED_INVOICE_SUBMISSIONS: '/ella-app/submitted',
    DAMAGE_EVENTS: `/ella-app/damage-event`,
    DISPATCH_DAMAGE_EVENTS: `/dispatch-app/damage-event`,
    DISPATCH_DRIVER_ASSIGNMENTS: '/dispatch-app/driver-assignment',
    DAMAGE_EVENT: `/ella-app/damage-event/:damageEventId`,
    ACCOUNTING: `/ella-app/invoiceSubmission/:accountingId`,
    NEW_DAMAGE_EVENT: `/ella-app/new/damage-event/:damageEventId`,
    MANAGE_USERS: `/ella-app/manage-users`,
    MY_ACCOUNT: `/ella-app/my-account`,
    ARCHIVE: `/ella-app/archive`,
    DOCUMENTS: `/ella-app/documents`,
    DISPATCH_OPEN_DRIVER_ASSIGNMENTS: '/dispatch-app',
    DISPATCH_ASSIGNED_DRIVER_ASSIGNMENTS: '/dispatch-app/assigned',
    DISPATCH_DRIVER_ASSIGNMENT: '/dispatch-app/driver-assignment/:driverAssignmentId',
    DISPATCH_ARCHIVE: '/dispatch-app/archive',
    DRIVER: '/dispatch-app/driver',
    DISPATCH_MY_ACCOUNT: '/dispatch-app/my-account',
    DISPATCH_MANAGE_USERS: '/dispatch-app/manage-users',
    DISPATCH: `/dispatch-app`,
    ELLA: '/ella-app',
    TEST: '/test',
    SYSTEM_ACCESS_DENIED: '/access/403',
};
