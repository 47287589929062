import {all, fork} from 'redux-saga/effects';
import createUserFlow from './sagas/createUserFlow';
import updateUserFlow from './sagas/updateUserFlow';
import deleteUserFlow from './sagas/deleteUserFlow';
import unregisterPushNotifications from './sagas/unregisterPushNotifications';

/**
 *  User management watcher saga
 */
const userManagementWatcher = function* userManagementWatcher() {
    yield all([
        fork(createUserFlow),
        fork(updateUserFlow),
        fork(deleteUserFlow),
        fork(unregisterPushNotifications),
    ]);
};

export default userManagementWatcher;
