/**
 * Creates actionTypes for plugin instance
 *
 * @param {string} filesName
 */
const createActionTypes = filesName => {
    return {
        START_UPLOAD_FLOW: `@@formation-files/${filesName}/START_UPLOAD_FLOW`,
        START_DELETE_FLOW: `@@formation-files/${filesName}/START_DELETE_FLOW`,
        /**
         * Get upload params
         */
        GET_UPLOAD_PARAMETERS_REQUEST: `@@formation-files/${filesName}/GET_UPLOAD_PARAMETERS_REQUEST`,
        GET_UPLOAD_PARAMETERS_REQUEST_SENT: `@@formation-files/${filesName}/GET_UPLOAD_PARAMETERS_REQUEST_SENT`,
        GET_UPLOAD_PARAMETERS_REQUEST_SUCCEEDED: `@@formation-files/${filesName}/GET_UPLOAD_PARAMETERS_REQUEST_SUCCEEDED`,
        GET_UPLOAD_PARAMETERS_REQUEST_FAILED: `@@formation-files/${filesName}/GET_UPLOAD_PARAMETERS_REQUEST_FAILED`,

        /**
         * Upload files
         */
        UPLOAD_FILE_REQUEST: `@@formation-files/${filesName}/UPLOAD_FILE_REQUEST`,
        UPLOAD_FILE_REQUEST_SENT: `@@formation-files/${filesName}/UPLOAD_FILE_REQUEST_SENT`,
        UPLOAD_FILE_REQUEST_SUCCEEDED: `@@formation-files/${filesName}/UPLOAD_FILE_REQUEST_SUCCEEDED`,
        UPLOAD_FILE_REQUEST_FAILED: `@@formation-files/${filesName}/UPLOAD_FILE_REQUEST_FAILED`,

        /**
         * Store action types
         */

        STORE_FILE: `@@formation-files/${filesName}/STORE_FILE`,
        DELETE_FILE: `@@formation-files/${filesName}/DELETE_FILE`,
        DELETE_FILES: `@@formation-files/${filesName}/DELETE_FILES`,
        STORE_ERROR: `@@formation-files/${filesName}/STORE_ERROR`,
        STORE_FILES: `@@formation-files/${filesName}/STORE_FILES`,
    };
};

export default createActionTypes;
