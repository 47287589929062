import moment from 'moment';

export const formatingRecoveryDateTime = (recoveryDate, recoveryTime = {}) => {
    recoveryDate = !recoveryDate ? moment() : recoveryDate;
    if (!recoveryTime.hours && !recoveryTime.minutes) {
        return null;
    }

    return moment(recoveryDate)
        .hours(recoveryTime.hours || 0)
        .minutes(recoveryTime.minutes || 0)
        .utc()
        .format();
};

export const formatingVehicleRegistrationDateTime = vehicleRegistrationDateTime => {
    const date = moment(vehicleRegistrationDateTime).utc().format();
    return date === 'Invalid date' ? null : date;
};
