import {immerable} from 'immer';
import Entity from '../entity/Entity';


/**
 * File entity type definition
 *
 * @typedef {Object} File
 * @property {?string} id
 * @property {?string} fileName
 * @property {?string} url
 * @property {?string} contentType
 * @property {?boolean} isSignature
 */

/**
 * File entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const fileEntityDTOMapping = {
    id: {
        dtoProperty: 'id',
        defaultValue: '',
    },
    fileName: {
        dtoProperty: 'fileName',
        defaultValue: '',
    },
    url: {
        dtoProperty: 'url',
        defaultValue: '',
    },
    contentType: {
        dtoProperty: 'contentType',
        defaultValue: '',
    },
    isSignature: {
        dtoProperty: 'isSignature',
        defaultValue: false,
    },
};

/**
 * File entity
 *
 * @class
 * @extends Entity
 * @type {File}
 */
export default class File extends Entity {
    [immerable] = true;

    static entityDTOMapping = fileEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
