export const GET_INVOICE_SUBMISSION_MESSAGES_REQUEST = 'GET_INVOICE_SUBMISSION_MESSAGES_REQUEST';
export const GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_SENT = 'GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_SENT';
export const GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_SUCCEEDED = 'GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_SUCCEEDED';
export const GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_FAILED = 'GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_FAILED';

export const STORE_INVOICE_SUBMISSION_MESSAGES = 'STORE_INVOICE_SUBMISSION_MESSAGES';

export const GET_PARTNER_MESSAGES_REQUEST = 'GET_PARTNER_MESSAGES_REQUEST';
export const GET_PARTNER_MESSAGES_REQUEST_SENT = 'GET_PARTNER_MESSAGES_REQUEST_SENT';
export const GET_PARTNER_MESSAGES_REQUEST_SUCCEEDED = 'GET_PARTNER_MESSAGES_REQUEST_SUCCEEDED';
export const GET_PARTNER_MESSAGES_REQUEST_FAILED = 'GET_PARTNER_MESSAGES_REQUEST_FAILED';

export const STORE_PARTNER_MESSAGES = 'STORE_PARTNER_MESSAGES';

export const MARK_MESSAGES_AS_READ_REQUEST = 'MARK_MESSAGES_AS_READ_REQUEST';
export const MARK_MESSAGES_AS_READ_REQUEST_SENT = 'MARK_MESSAGES_AS_READ_REQUEST_SENT';
export const MARK_MESSAGES_AS_READ_REQUEST_SUCCEEDED = 'MARK_MESSAGES_AS_READ_REQUEST_SUCCEEDED';
export const MARK_MESSAGES_AS_READ_REQUEST_FAILED = 'MARK_MESSAGES_AS_READ_REQUEST_FAILED';

export const SET_MESSAGES_AS_READ = 'SET_MESSAGES_AS_READ';

export const REFRESH_MESSAGES = 'REFRESH_MESSAGES';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_REQUEST_SENT = 'SEND_MESSAGE_REQUEST_SENT';
export const SEND_MESSAGE_REQUEST_SUCCEEDED = 'SEND_MESSAGE_REQUEST_SUCCEEDED';
export const SEND_MESSAGE_REQUEST_FAILED = 'SEND_MESSAGE_REQUEST_FAILED';

