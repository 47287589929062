import {dafMainServices} from '../../ella-dispo-entity-types';

export const dafMainServiceIcons = {
    [dafMainServices.ROADSIDE_ASSISTANCE]: 'pannenhilfe',
    [dafMainServices.JUMP_START]: 'starthilfe',
    [dafMainServices.OPENING]: 'fahrzeugoffnung',
    [dafMainServices.TOWING]: 'abschleppen',
    [dafMainServices.RECOVERY]: 'bergung',
    [dafMainServices.MULTIPLE_SERVICES]: 'mehrere',
};
