import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../../ui-components';
import './DriverAssignmentDataRow.scss';

const DriverAssignmentDataRow = ({iconName, label, children, iconColor, classNames, isEditMode}) => {
    return (
        <div className={`ace-c-driver-assignment-data-row ${classNames}`}>
            <div className="ace-c-driver-assignment-data-row__label">
                {iconName
                && <Icon dimension="md" iconName={iconName} color={iconColor} className="ace-c-driver-assignment-data-row__label__icon" />}
                <p className="ace-c-driver-assignment-data-row__label__text"><b>{label}{!isEditMode && ':'}</b></p>
            </div>
            <div className="ace-c-driver-assignment-data-row__content">
                {children}
            </div>
        </div>
    );
};

DriverAssignmentDataRow.propTypes = {
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
    label: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    isEditMode: PropTypes.bool,
};

DriverAssignmentDataRow.defaultProps = {
    iconName: '',
    iconColor: '',
    classNames: '',
    isEditMode: false,
};

export default DriverAssignmentDataRow;
