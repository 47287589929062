import {FilesPlugin} from './services/files-plugin';
import config from './config';
import * as errorActionTypes from './application/errorActionTypes';

const invoiceSubmissionFiles = new FilesPlugin({
    name: 'invoiceSubmissionFiles',
    fileParamsUrl: `${config.EAF_SERVICE_URL}/v1/attachments/upload-parameters`,
    errorHandlingActionType: errorActionTypes.STORE_FILES_ERROR,
});

export default invoiceSubmissionFiles;
