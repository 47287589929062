import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Dropdown.scss';


const Dropdown = ({isVisible, position, children, className}) => {
    const dropdownClassName = classNames('ace-c-dropdown', className, {
        'ace-c-dropdown--visible': isVisible,
        'ace-c-dropdown--bottom-right': position && position === 'bottom-right',
        'ace-c-dropdown--bottom-center': position && position === 'bottom-center',
        'ace-c-dropdown--select-dropdown': position && position === 'select-dropdown',
    });

    return (
        <div className={dropdownClassName}>
            {children}
        </div>
    );
};

Dropdown.propTypes = {
    isVisible: PropTypes.bool,
    position: PropTypes.string,
    className: PropTypes.string,
};

Dropdown.defaultProps = {
    isVisible: false,
    position: '',
    className: '',
};

export default Dropdown;
