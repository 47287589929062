import {fork, put, select, take} from 'redux-saga/effects';
import moment from 'moment';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import getPricingInfoFlow from '../../pricing/sagas/getPricingInfoFlow';
import {invoiceSubmissionViews} from '../constants/invoiceSubmissionViews';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';

export const getInvoiceSubmissionFlow = function* getInvoiceSubmissionFlow(params) {
    const {invoiceSubmissionId} = params;
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    yield put({
        type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE,
        payload: {invoiceSubmissionViewState: null, formAnchor: null},
    });
    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
        payload: {state: InvoiceSubmissionDataStates.LOADING},
    });
    yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_DATA});
    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST,
        eafService.getInvoiceSubmission,
        {invoiceSubmissionId},
    );
    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {response: invoiceSubmission} = resultAction.payload;
        const apmService = serviceManager.loadService('partnerManagementService');

        yield fork(getPricingInfoFlow, {
            payload: {
                contractPartnerId: invoiceSubmission.assignment?.contractPartnerId,
                serviceEndDateTime: invoiceSubmission.assignment?.serviceEndDateTime || moment().format(),
            },
        });

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST,
            apmService.getContractPartner,
            {vpId: invoiceSubmission.assignment.contractPartnerId},
        );
        const resultContractPartnerAction = yield take([
            invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED,
        ]);

        if (!resultContractPartnerAction.error) {
            const invoiceSubmissionViewState = invoiceSubmissionViews.SUMMARY;
            const {vpContactsDTO} = resultContractPartnerAction.payload.response;
            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE,
                payload: {invoiceSubmissionViewState},
            });
            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION,
                payload: {invoiceSubmissionDTO: {
                    ...invoiceSubmission,
                    assignment: {
                        ...invoiceSubmission.assignment,
                        contractPartnerInfo: vpContactsDTO,
                    },
                }},
            });
            yield put({
                type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
                payload: {state: InvoiceSubmissionDataStates.IDLE},
            });
            return;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
        return;
    }
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export default getInvoiceSubmissionFlow;
