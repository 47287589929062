import {take, put, fork, select} from 'redux-saga/effects';
import * as actionTypes from '../documentDownloadActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

/**
 * Selected documents download flow
 */
const downloadMultipleDocuments = function* downloadMultipleDocuments() {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(actionTypes.SUBMIT_DOWNLOAD_SELECTED_DOCUMENTS_FORM);
        const {selectedDocumentFiles} = payload;
        yield put({
            type: actionTypes.SET_SELECTED_DOCUMENTS_DOWNLOAD_STATUS,
            payload: {isMultipleDocumentDownloadInProgress: true},
        });
        yield fork(fetchRequest, actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST,
            eafService.downloadMultipleFiles, {
                paths: selectedDocumentFiles.map(selectedDocumentFile => selectedDocumentFile.filePath),
            });

        const submitRequestResult = yield take([
            actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_SUCCEEDED,
            actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_FAILED,
        ]);

        yield put({
            type: actionTypes.SET_SELECTED_DOCUMENTS_DOWNLOAD_STATUS,
            payload: {isMultipleDocumentDownloadInProgress: false},
        });

        if (submitRequestResult.type === actionTypes.DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_SUCCEEDED) continue;

        const {response: errorObject} = submitRequestResult.payload;
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: errorObject,
            },
        });
    }
};

export default downloadMultipleDocuments;
