import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import * as actionTypes from '../userManagementActionTypes';
import recordPersistenceStates from '../../utils/recordPersistenceStates';
import recordPersistenceOperations from '../../utils/recordPersistenceOperations';
import {ButtonPrimary, ButtonSecondary, PopUp} from '../../ui-components';
import './ManageUsersPopUps.scss';
import UserForm from './UserForm';
import {getValidationErrors} from '../../application/errorsSelector';

const ManageUsersPopUps = props => {
    const {persistenceOperation, persistenceState, errors, temporaryUser} = props;
    const {submitCreateUserForm, endCreateUserFlow} = props;
    const {submitUpdateUserForm, endUpdateUserFlow} = props;
    const {submitDeleteUserForm, endDeleteUserFlow} = props;
    const isFormDisabled = persistenceState === recordPersistenceStates.PENDING;
    const {createTranslateShorthand} = useTranslate();
    const translatePopup = createTranslateShorthand('manage_users_popups');

    return (
        <Fragment>
            {persistenceOperation === recordPersistenceOperations.CREATE
            && persistenceState !== recordPersistenceStates.SUCCEEDED
            && (
                <PopUp>
                    <div className="col-xs--8 col-start-xs--3 ace-manage-users-popups__margin-bottom">
                        <h3 className="ace-h3 ace-h3--center">
                            {translatePopup('popup_title.add_user')}
                        </h3>
                    </div>
                    <UserForm
                        submitCreateUserForm={submitCreateUserForm}
                        endCreateUserFlow={endCreateUserFlow}
                        submitUpdateUserForm={submitUpdateUserForm}
                        endUpdateUserFlow={endUpdateUserFlow}
                        persistenceOperation={persistenceOperation}
                        persistenceState={persistenceState}
                        errors={errors}
                        temporaryUser={temporaryUser}
                    />
                </PopUp>
            )}

            {persistenceOperation === recordPersistenceOperations.CREATE
            && persistenceState === recordPersistenceStates.SUCCEEDED
            && (
                <PopUp>
                    <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                        <h3 className="ace-h3 ace-h3--center ace-bottom-margin--md">
                            {translatePopup('popup_title.add_user')}
                        </h3>
                        <p className="ace-copy-m ace-copy-m--grey text-center">
                            {`${translatePopup('popup_success_message.user')} 
                            ${temporaryUser.firstName} (${temporaryUser.email}) 
                            ${translatePopup('popup_success_message.successfully_added')}`}
                        </p>
                    </div>
                    <div className="col-xs--4 col-start-xs--5">
                        <ButtonPrimary
                            name="endCreate"
                            label={translatePopup('button_label.close')}
                            onClick={endCreateUserFlow}
                        />
                    </div>
                </PopUp>
            )}

            {persistenceOperation === recordPersistenceOperations.UPDATE
            && persistenceState !== recordPersistenceStates.SUCCEEDED
            && (
                <PopUp>
                    <div className="col-xs--8 col-start-xs--3 ace-manage-users-popups__margin-bottom">
                        <h3 className="ace-h3 ace-h3--center">
                            {translatePopup('popup_title.edit_user')}
                        </h3>
                    </div>
                    <UserForm
                        submitCreateUserForm={submitCreateUserForm}
                        endCreateUserFlow={endCreateUserFlow}
                        submitUpdateUserForm={submitUpdateUserForm}
                        endUpdateUserFlow={endUpdateUserFlow}
                        persistenceOperation={persistenceOperation}
                        persistenceState={persistenceState}
                        errors={errors}
                        temporaryUser={temporaryUser}
                    />
                </PopUp>
            )}

            {persistenceOperation === recordPersistenceOperations.UPDATE
            && persistenceState === recordPersistenceStates.SUCCEEDED
            && (
                <PopUp>
                    <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                        <h3 className="ace-h3 ace-h3--center ace-bottom-margin--md">
                            {translatePopup('popup_title.edit_user')}
                        </h3>
                        <p className="ace-copy-m ace-copy-m--grey text-center">
                            {`${translatePopup('popup_success_message.user')} 
                             ${temporaryUser.firstName} (${temporaryUser.email}) 
                              ${translatePopup('popup_success_message.successfully_added')}`}
                        </p>
                    </div>
                    <div className="col-xs--4 col-start-xs--5">
                        <ButtonPrimary
                            name="endCreate"
                            label={translatePopup('button_label.close')}
                            onClick={endUpdateUserFlow}
                        />
                    </div>
                </PopUp>
            )}

            {persistenceOperation === recordPersistenceOperations.DELETE
            && persistenceState !== recordPersistenceStates.SUCCEEDED
            && (
                <PopUp>
                    <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                        <h3 className="ace-h3 ace-h3--center ace-bottom-margin--md">
                            {translatePopup('popup_title.delete_user')}
                        </h3>
                        <p className="ace-copy-m ace-copy-m--grey text-center">
                            {translatePopup('popup_confirmation_message.do_you')}
                            {` ${temporaryUser.firstName} ${temporaryUser.lastName} (${temporaryUser.email}) `}
                            {translatePopup('popup_confirmation_message.delete')} ?
                        </p>
                    </div>
                    <div className="col-xs--4 col-start-xs--3">
                        <ButtonSecondary
                            name="cancelDelete"
                            label={translatePopup('button_label.decline')}
                            onClick={endDeleteUserFlow}
                            isDisabled={isFormDisabled}
                        />
                    </div>
                    <div className="col-xs--4">
                        <ButtonPrimary
                            name="confirmDelete"
                            label={translatePopup('button_label.delete')}
                            onClick={submitDeleteUserForm}
                            isDisabled={isFormDisabled}
                        />
                    </div>
                </PopUp>
            )}

            {persistenceOperation === recordPersistenceOperations.DELETE
            && persistenceState === recordPersistenceStates.SUCCEEDED
            && (
                <PopUp>
                    <div className="col-xs--8 col-start-xs--3 ace-bottom-margin--md">
                        <h3 className="ace-h3 ace-h3--center ace-bottom-margin--md">
                            {translatePopup('popup_title.delete_user')}
                        </h3>
                        <p className="ace-copy-m ace-copy-m--grey text-center">
                            {translatePopup('popup_confirmation_message.do_you')}
                            {` ${temporaryUser.firstName} ${temporaryUser.lastName} (${temporaryUser.email}) `}
                            {translatePopup('popup_success_message.delete')}.
                        </p>
                    </div>
                    <div className="col-xs--4 col-start-xs--5">
                        <ButtonPrimary
                            name="exitDelete"
                            label={translatePopup('button_label.close')}
                            onClick={endDeleteUserFlow}
                        />
                    </div>
                </PopUp>
            )}
        </Fragment>
    );
};

ManageUsersPopUps.propTypes = {
    persistenceOperation: PropTypes.string,
    persistenceState: PropTypes.string,
    errors: PropTypes.object,
    temporaryUser: PropTypes.object,
    submitCreateUserForm: PropTypes.func.isRequired,
    endCreateUserFlow: PropTypes.func.isRequired,
    submitUpdateUserForm: PropTypes.func.isRequired,
    endUpdateUserFlow: PropTypes.func.isRequired,
    submitDeleteUserForm: PropTypes.func.isRequired,
    endDeleteUserFlow: PropTypes.func.isRequired,
};

ManageUsersPopUps.defaultProps = {
    persistenceOperation: null,
    persistenceState: null,
    errors: {},
    temporaryUser: {},
};

const mapStateToProps = state => ({
    persistenceOperation: state.userManagement.persistenceOperation,
    persistenceState: state.userManagement.persistenceState,
    errors: getValidationErrors(state),
    temporaryUser: state.userManagement.temporaryUser,
});

const mapDispatchToProps = dispatch => ({
    submitCreateUserForm: payload => dispatch({type: actionTypes.SUBMIT_CREATE_USER_FORM, payload}),
    endCreateUserFlow: payload => dispatch({type: actionTypes.END_CREATE_USER_FLOW, payload}),
    submitUpdateUserForm: payload => dispatch({type: actionTypes.SUBMIT_UPDATE_USER_FORM, payload}),
    endUpdateUserFlow: payload => dispatch({type: actionTypes.END_UPDATE_USER_FLOW, payload}),
    submitDeleteUserForm: payload => dispatch({type: actionTypes.SUBMIT_DELETE_USER_FORM, payload}),
    endDeleteUserFlow: payload => dispatch({type: actionTypes.END_DELETE_USER_FLOW, payload}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManageUsersPopUps);
