import {all, fork} from 'redux-saga/effects';
import provideAccessTokenToServices from './sagas/provideAccessTokenToServices';

const applicationWatcher = function* applicationWatcher() {
    yield all([
        fork(provideAccessTokenToServices),
    ]);
};

export default applicationWatcher;
