import {put, take, fork, select} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as userSelectors from '../../user-management/userManagementSelectors';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';
import * as errorActionTypes from '../../application/errorActionTypes';


const exportInvoiceSubmissions = function* exportInvoiceSubmissions() {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.EXPORT_INVOICE_SUBMISSIONS);
        const userAccount = yield select(userSelectors.getUserAccount);

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.EXPORT_INVOICE_SUBMISSIONS_REQUEST,
            eafService.exportInvoiceSubmissions,
            {
                ...payload,
                contractPartnerIds: userAccount.partnerIds,
            },
        );

        const resultAction = yield take([
            invoiceSubmissionActionTypes.EXPORT_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.EXPORT_INVOICE_SUBMISSIONS_REQUEST_FAILED,
        ]);

        if (resultAction.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(resultAction.payload),
                    },
                },
            });
        }
    }
};

export default exportInvoiceSubmissions;
