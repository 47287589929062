import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import routePaths from '../../../routePaths';
import {TableBody, TableCell, TableRow} from '../../../ui-components/table';
import {HoverIcon, Ribbon} from '../../../ui-components';
import {getMainServiceNameForType} from '../../utils/mainServices';
import {convertNumberToCurrency} from '../../utils/conversion';
import {isFollowUpInvoiceSubmission} from '../../utils/subCases';
import './InvoiceSubmissionArchiveRowDetail.scss';
import {eafStatuses} from '../../../ella-dispo-entity-types';
import * as invoiceSubmissionActionTypes from '../../invoiceSubmissionActionTypes';
import {InvoiceSubmissionDataStates} from '../../constants/invoiceSubmissionDataStates';
import config from '../../../config';

// todo check PROD guards and adjust implementation so it follows LEA features - after LEA is available for PROD
const InvoiceSubmissionArchiveRowDetail = props => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateRowDetail = createTranslateShorthand('invoice_submission_archive_row_detail');
    const {downloadInvoiceAction, downloadStatus, history, invoiceSubmission, createFollowUpAssignment} = props;
    const {downloadBCReceiptPDF} = props;

    const downloadPDF = () => {
        const attachment = invoiceSubmission.attachments.find(attachment => !!attachment.isReceipt);
        if (config.DEPLOYMENT_ENV !== config.PROD_DEPLOYMENT_ENV && attachment) {
            downloadBCReceiptPDF({
                fileName: attachment.fileName,
                url: attachment.url,
            });
            return;
        }
        if (config.DEPLOYMENT_ENV === config.PROD_DEPLOYMENT_ENV || invoiceSubmission.invoiceLocation) {
            downloadInvoiceAction({
                fileId: invoiceSubmission.invoiceSubmissionId,
                path: invoiceSubmission.invoiceLocation,
            });
        }
    };

    const viewInvoiceSubmission = e => {
        e.preventDefault();
        history.push(resolveRoute(routePaths.INVOICE_SUBMISSION, {
            invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
        }));
    };

    const triggerFollowUpCreation = e => {
        e.preventDefault();
        const {invoiceSubmissionId} = invoiceSubmission;
        createFollowUpAssignment({invoiceSubmissionId});
    };
    const startTime = `${invoiceSubmission.assignment.startDate.format('DD.MM.YYYY HH:mm')} ${translate('global.time.hours')}`;
    const endTime = `${invoiceSubmission.assignment.endDate.format('DD.MM.YYYY HH:mm')} ${translate('global.time.hours')}`;
    const mainServiceAmount = invoiceSubmission.mainServices.reduce((acc, {subtotal}) => {
        return acc + Number(subtotal);
    }, 0);
    const isCaseFollowUpInvoiceSubmission = isFollowUpInvoiceSubmission(invoiceSubmission.invoiceSubmissionId);

    return (
        <TableBody className="ace-invoice-submission-archive-row-detail__table-body">
            <TableRow className="ace-invoice-submission-archive-row-detail__table-row">
                <TableCell className="col-sm--2 ace-invoice-submission-archive-row-detail__cell">
                    <span className="ace-copy-s"><b>{translateRowDetail('table_cell.period')}</b></span>
                </TableCell>
                <TableCell className="col-sm--1 ace-invoice-submission-archive-row-detail__cell">
                    <span className="ace-copy-s">{translateRowDetail('table_cell.start')}</span>
                </TableCell>
                <TableCell className="col-sm--3 ace-invoice-submission-archive-row-detail__cell">
                    <span className="ace-copy-s">{startTime}</span>
                </TableCell>
                <TableCell className="col-sm--1 ace-invoice-submission-archive-row-detail__cell">
                    <span className="ace-copy-s">{translateRowDetail('table_cell.end')}</span>
                </TableCell>
                <TableCell className="col-sm--4 ace-invoice-submission-archive-row-detail__cell">
                    <span className="ace-copy-s">{endTime}</span>
                </TableCell>

                <TableCell
                    className="col-sm--2 ace-invoice-submission-archive-row-detail__cell ace-invoice-submission-archive-row-detail__cell--light"
                >
                    <Ribbon color="light-grey" width="50vw" />
                    <span className="ace-copy-s"><b>{translateRowDetail('table_cell.delivered_services')}</b></span>
                </TableCell>
                <TableCell
                    className="col-sm--4 ace-invoice-submission-archive-row-detail__cell ace-invoice-submission-archive-row-detail__cell--light"
                >
                    <Ribbon color="light-grey" width="50px" />
                    <p className="ace-copy-s ace-invoice-submission-archive-row-detail__single-service">
                        {invoiceSubmission.mainServices.length ? invoiceSubmission.mainServices
                            .map(({type, principleMainService}) => {
                                return getMainServiceNameForType({
                                    type,
                                    principleMainService,
                                }, translate);
                            }).join(', ') : '-'}
                    </p>
                </TableCell>
                <TableCell
                    className="col-sm--5 ace-invoice-submission-archive-row-detail__cell ace-invoice-submission-archive-row-detail__cell--light"
                >
                    <Ribbon color="light-grey" width="50px" />
                    <span className="ace-copy-s">{convertNumberToCurrency(mainServiceAmount)} €</span>
                </TableCell>

                <TableCell className="col-sm--2 ace-invoice-submission-archive-row-detail__cell">
                    <span className="ace-copy-s"><b>{translateRowDetail('table_cell.additional_services')}</b></span>
                </TableCell>
                <TableCell className="col-sm--4 ace-invoice-submission-archive-row-detail__cell">
                    <p className="ace-copy-s">
                        {invoiceSubmission.additionalServices.length ? invoiceSubmission.additionalServices
                            .map(({type}) => {
                                return translate(`global.additional_services.${type.toLowerCase()}`);
                            }).join(', ') : '-'}
                    </p>
                </TableCell>
                <TableCell className="col-sm--5 ace-invoice-submission-archive-row-detail__cell">
                    <span className="ace-copy-s">
                        {invoiceSubmission.additionalServices ? (
                            convertNumberToCurrency(
                                invoiceSubmission.additionalServices.reduce((acc, {subtotal}) => {
                                    return acc + Number(subtotal);
                                }, 0),
                            )
                        ) : (
                            '0'
                        )} €
                    </span>
                </TableCell>

                <TableCell
                    className="col-sm--2 ace-invoice-submission-archive-row-detail__cell ace-invoice-submission-archive-row-detail__cell--light"
                >
                    <Ribbon color="light-grey" width="50vw" />
                    <span className="ace-copy-s"><b>{translateRowDetail('table_cell.comment')}</b></span>
                </TableCell>
                <TableCell
                    className="col-sm--9 ace-invoice-submission-archive-row-detail__cell ace-invoice-submission-archive-row-detail__cell--light"
                >
                    <Ribbon color="light-grey" width="50px" />
                    <p className="ace-copy-s">{invoiceSubmission.mainServicesComment || '-'}</p>
                </TableCell>

                <TableCell className="ace-invoice-submission-archive-row-detail__options-column">
                    {(invoiceSubmission.status === eafStatuses.BOOKED || invoiceSubmission.status === eafStatuses.PAID)
                        && !isCaseFollowUpInvoiceSubmission
                        && invoiceSubmission.assignment.isFollowUpInvoiceSubmissionAllowed && (
                            <HoverIcon
                                icon="plus"
                                hoverText={translateRowDetail('hover_text.follow_up')}
                                onClick={triggerFollowUpCreation}
                                isLink
                                href={`${routePaths.INVOICE_SUBMISSION}/${invoiceSubmission.assignment.assignmentId}/${invoiceSubmission.invoiceSubmissionId}?isFormOpen=true`}
                            />
                    )}
                    <HoverIcon
                        icon="eye"
                        hoverText={translateRowDetail('hover_text.view')}
                        onClick={viewInvoiceSubmission}
                        isLink
                        href={`${routePaths.INVOICE_SUBMISSION}/${invoiceSubmission.assignment.assignmentId}/${invoiceSubmission.invoiceSubmissionId}`}
                    />
                    {(invoiceSubmission.status === eafStatuses.PAID
                        || (invoiceSubmission.status === eafStatuses.BOOKED
                            && config.DEPLOYMENT_ENV === config.PROD_DEPLOYMENT_ENV)
                    ) && (invoiceSubmission.invoiceLocation
                            || (config.DEPLOYMENT_ENV !== config.PROD_DEPLOYMENT_ENV
                                && invoiceSubmission.attachments.find(attachment => !!attachment.isReceipt))) && (
                                <HoverIcon
                                    icon="download"
                                    isDisabled={downloadStatus === InvoiceSubmissionDataStates.DOWNLOADING}
                                    hoverText={translateRowDetail('hover_text.download_pdf')}
                                    onClick={downloadPDF}
                                />
                    )}
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

InvoiceSubmissionArchiveRowDetail.propTypes = {
    invoiceSubmission: PropTypes.object,
    downloadInvoiceAction: PropTypes.func.isRequired,
    createFollowUpAssignment: PropTypes.func.isRequired,
    downloadStatus: PropTypes.string.isRequired,
    downloadBCReceiptPDF: PropTypes.func.isRequired,
    history: PropTypes.object,
};

InvoiceSubmissionArchiveRowDetail.defaultProps = {
    history: null,
    invoiceSubmission: null,
};

const mapStateToProps = state => ({
    downloadStatus: state.invoiceSubmission.invoiceSubmissionDataState,
});

const mapDispatchToProps = dispatch => ({
    createFollowUpAssignment: payload => dispatch({
        type: invoiceSubmissionActionTypes.CREATE_FOLLOW_UP_INVOICE_SUBMISSION,
        payload,
    }),
    downloadInvoiceAction: payload => dispatch({
        type: invoiceSubmissionActionTypes.DOWNLOAD_INVOICE_SUBMISSION_PDF,
        payload,
    }),
    downloadBCReceiptPDF: payload => dispatch({
        type: invoiceSubmissionActionTypes.DOWNLOAD_BC_RECEIPT_PDF,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvoiceSubmissionArchiveRowDetail));
