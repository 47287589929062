import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useTranslate} from '@computerrock/formation-i18n';
import {Icon} from '../../ui-components';
import {TableRow, TableCell} from '../../ui-components/table';
import TableRowFile from './TableRowFile';

const directoryTypes = ['directory-list', 'directory-euro', 'directory-section', 'directory'];

const TableRowDirectory = props => {
    const {documentFile, directoryDocumentFiles, downloadingDocuments, submitDocumentDownloadForm} = props;
    const {selectedDocuments, selectDocumentForDownload, partnerIds} = props;
    const {isOpened, toggleDirectory} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateRow = createTranslateShorthand('table_row_directory');
    const directoryDocumentFilesCount = `${directoryDocumentFiles.length} ${directoryDocumentFiles.length === 1
        ? translateRow('files.singular') : translateRow('files.plural')}`;

    const directoryTypeIndex = [translateRow('file_type.payment_advice')]
        .findIndex(keyword => documentFile.name.toLowerCase().includes(keyword.toLowerCase()));
    const directoryType = directoryTypes[
        directoryTypeIndex > -1 ? directoryTypeIndex + 1 : 0
    ];
    const directoryName = partnerIds.length === 1
        ? documentFile.name
        : `${documentFile.filePath.substr(0, documentFile.filePath.length - 1)}`;

    return (
        <TableRow
            wrapperClassName="ace-document-download-view__directory-table-row"
            className="ace-c-table-row--md"
            onClick={toggleDirectory}
            dropdownComponent={directoryDocumentFiles.length > 0 && isOpened
                ? (
                    <Fragment>
                        {directoryDocumentFiles.map(contentDocumentFile => (
                            <TableRowFile
                                key={contentDocumentFile.id}
                                documentFile={contentDocumentFile}
                                downloadingDocuments={downloadingDocuments}
                                submitDocumentDownloadForm={submitDocumentDownloadForm}
                                selectedDocuments={selectedDocuments}
                                selectDocumentForDownload={selectDocumentForDownload}
                            />
                        ))}
                    </Fragment>
                )
                : null
            }
        >
            <TableCell className="col-start-sm--2 col-sm--1 ace-c-table-cell--center">
                <Icon
                    dimension="xxl"
                    iconName={isOpened ? `${directoryType}-opened` : `${directoryType}-closed`}
                />
            </TableCell>
            <TableCell className="col-sm--4">
                <span className="ace-copy-s">
                    {directoryName} | {directoryDocumentFilesCount}
                </span>
            </TableCell>
            <TableCell className="col-sm--2">
                <span className="ace-copy-s">
                    {documentFile.lastModifiedDate
                        ? documentFile.lastModifiedDate.format('DD.MM.YYYY') : ''}
                </span>
            </TableCell>
            <TableCell className="col-sm--2">
                <span className="ace-copy-s">
                    {documentFile.fileSizeWithUnit}
                </span>
            </TableCell>
            <TableCell className="col-sm--2 ace-c-table-cell--center">
                <span className={classnames('ace-document-download-view__directory-arrow', {
                    'icon-rotate': isOpened,
                })}
                >
                    <Icon dimension="md" iconName="expand-arrow" />
                </span>
            </TableCell>
        </TableRow>
    );
};

TableRowDirectory.propTypes = {
    documentFile: PropTypes.object.isRequired,
    directoryDocumentFiles: PropTypes.arrayOf(PropTypes.object),
    downloadingDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    isOpened: PropTypes.bool,
    toggleDirectory: PropTypes.func.isRequired,
    submitDocumentDownloadForm: PropTypes.func.isRequired,
    selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectDocumentForDownload: PropTypes.func.isRequired,
    partnerIds: PropTypes.array.isRequired,
};

TableRowDirectory.defaultProps = {
    directoryDocumentFiles: [],
    isOpened: false,
};

export default TableRowDirectory;
