import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './TableBody.scss';

const TableBody = props => {
    const tableBodyClass = classnames('ace-c-table-body', {
        'ace-c-table-body--has-rows': props.hasRows,
    });
    return (
        <div className={`${tableBodyClass} ${props.className}`}>
            {props.children}
        </div>
    );
};

TableBody.propTypes = {
    className: PropTypes.string,
    hasRows: PropTypes.bool,
};

TableBody.defaultProps = {
    className: '',
    hasRows: false,
};

export default TableBody;
