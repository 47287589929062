import {immerable} from 'immer';
import Entity from '../entity/Entity';

/**
 * Location entity type definition
 *
 * @typedef {Object} Location
 * @property {?double} longitude
 * @property {?double} latitude

 */

/**
 * Location entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const locationEntityDTOMapping = {
    lng: {
        dtoProperty: 'longitude',
        fromDTO: longitude => (longitude ? parseFloat(longitude) : null),
    },
    lat: {
        dtoProperty: 'latitude',
        fromDTO: latitude => (latitude ? parseFloat(latitude) : null),
    },
};

/**
 * Location entity
 *
 * @class
 * @extends Entity
 * @type {Location}
 */
export default class Location extends Entity {
    [immerable] = true;

    static entityDTOMapping = locationEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}

