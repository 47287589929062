/**
 * Damage events action types
 */

// open, submitted, dispatch open and dispatch assigned damage events
export const GET_DRIVER_ASSIGNMENTS_REQUEST_SENT = 'GET_DRIVER_ASSIGNMENTS_REQUEST_SENT';
export const GET_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED = 'GET_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED';
export const GET_DRIVER_ASSIGNMENTS_REQUEST_FAILED = 'GET_DRIVER_ASSIGNMENTS_REQUEST_FAILED';
export const GET_DRIVER_ASSIGNMENTS_REQUEST = 'GET_DRIVER_ASSIGNMENTS_REQUEST';

export const SET_DRIVER_ASSIGNMENTS_STATE = 'SET_DRIVER_ASSIGNMENTS_STATE';
export const CLEAR_DRIVER_ASSIGNMENT_DATA = 'CLEAR_DRIVER_ASSIGNMENT_DATA';

export const STORE_OPEN_DRIVER_ASSIGNMENTS = 'STORE_OPEN_DRIVER_ASSIGNMENTS';
export const STORE_ASSIGNED_DRIVER_ASSIGNMENTS = 'STORE_ASSIGNED_DRIVER_ASSIGNMENTS';

// searched damage events
export const START_DRIVER_ASSIGNMENTS_SEARCH_FLOW = 'START_DRIVER_ASSIGNMENTS_SEARCH_FLOW';
export const CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS = 'CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS';

export const SEARCH_DRIVER_ASSIGNMENTS_REQUEST_SENT = 'SEARCH_DRIVER_ASSIGNMENTS_REQUEST_SENT';
export const SEARCH_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED = 'SEARCH_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED';
export const SEARCH_DRIVER_ASSIGNMENTS_REQUEST_FAILED = 'SEARCH_DRIVER_ASSIGNMENTS_REQUEST_FAILED';
export const SEARCH_DRIVER_ASSIGNMENTS_REQUEST = 'SEARCH_DRIVER_ASSIGNMENTS_REQUEST';

export const STORE_DRIVER_ASSIGNMENT_SEARCH_RESULTS = 'STORE_DRIVER_ASSIGNMENT_SEARCH_RESULTS';
export const STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS = 'STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS';


// archived damage events
export const START_ARCHIVE_DRIVER_ASSIGNMENTS_FETCHING_FLOW = 'START_ARCHIVE_DRIVER_ASSIGNMENTS_FETCHING_FLOW';
export const SUBMIT_DOWNLOAD_DRIVER_ASSIGNMENTS_ARCHIVE_FORM = 'SUBMIT_DOWNLOAD_DRIVER_ASSIGNMENTS_ARCHIVE_FORM';

// THIS WILL BE CHANGED
export const FETCH_ARCHIVE_DATA_REQUEST_SENT = 'FETCH_ARCHIVE_DATA_REQUEST_SENT';
export const FETCH_ARCHIVE_DATA_REQUEST_SUCCEEDED = 'FETCH_ARCHIVE_DATA_REQUEST_SUCCEEDED';
export const FETCH_ARCHIVE_DATA_REQUEST_FAILED = 'FETCH_ARCHIVE_DATA_REQUEST_FAILED';

export const DOWNLOAD_DAMAGE_EVENTS_ARCHIVE_REQUEST_SENT = 'DOWNLOAD_DAMAGE_EVENTS_ARCHIVE_REQUEST_SENT';
export const DOWNLOAD_DAMAGE_EVENTS_ARCHIVE_REQUEST_SUCCEEDED = 'DOWNLOAD_DAMAGE_EVENTS_ARCHIVE_REQUEST_SUCCEEDED';
export const DOWNLOAD_DAMAGE_EVENTS_ARCHIVE_REQUEST_FAILED = 'DOWNLOAD_DAMAGE_EVENTS_ARCHIVE_REQUEST_FAILED';

export const STORE_ARCHIVE_FETCHING_DATA_STATUS = 'STORE_ARCHIVE_FETCHING_DATA_STATUS';
export const STORE_ARCHIVE_DATA = 'STORE_ARCHIVE_DATA';
export const STORE_ARCHIVED_DRIVER_ASSIGNMENTS = 'STORE_ARCHIVED_DRIVER_ASSIGNMENTS';

// get damage event
export const GET_DRIVER_ASSIGNMENT_REQUEST_SENT = 'GET_DRIVER_ASSIGNMENT_REQUEST_SENT';
export const GET_DRIVER_ASSIGNMENT_REQUEST_SUCCEEDED = 'GET_DRIVER_ASSIGNMENT_REQUEST_SUCCEEDED';
export const GET_DRIVER_ASSIGNMENT_REQUEST_FAILED = 'GET_DRIVER_ASSIGNMENT_REQUEST_FAILED';
export const GET_DRIVER_ASSIGNMENT_REQUEST = 'GET_DRIVER_ASSIGNMENT_REQUEST';

export const STORE_DRIVER_ASSIGNMENT = 'STORE_DRIVER_ASSIGNMENT';

export const STORE_UPDATE_STATUS = 'STORE_UPDATE_STATUS';

// change view state
export const CHANGE_DRIVER_ASSIGNMENT_VIEW_STATE = 'CHANGE_DRIVER_ASSIGNMENT_VIEW_STATE';
export const STORE_DRIVER_ASSIGNMENT_VIEW_STATE = 'STORE_DRIVER_ASSIGNMENT_VIEW_STATE';

// change damage event status

export const START_CHANGING_DRIVER_ASSIGNMENT_STATUS_FLOW = 'START_CHANGING_DRIVER_ASSIGNMENT_STATUS_FLOW';

export const CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_SENT = 'CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_SENT';
export const CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_SUCCEEDED = 'CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_SUCCEEDED';
export const CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_FAILED = 'CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST_FAILED';
export const CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST = 'CHANGE_DRIVER_ASSIGNMENT_STATUS_REQUEST';

export const TRIGGER_CHANGE_DECLINE_CASE_POP_UP_STATE = 'TRIGGER_CHANGE_DECLINE_CASE_POP_UP_STATE';
export const TRIGGER_CHANGE_SCHEDULE_CASE_POP_UP_STATE = 'TRIGGER_CHANGE_SCHEDULE_CASE_POP_UP_STATE';

export const GET_CONTRACT_PARTNER_INFO_REQUEST = 'GET_CONTRACT_PARTNER_INFO_REQUEST';
export const GET_CONTRACT_PARTNER_INFO_REQUEST_SENT = 'GET_CONTRACT_PARTNER_INFO_REQUEST_SENT';
export const GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED = 'GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED';
export const GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED = 'GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED';

export const DOWNLOAD_PROOF_OF_SERVICE = 'DOWNLOAD_PROOF_OF_SERVICE';
export const DOWNLOAD_PROOF_OF_SERVICE_REQUEST = 'DOWNLOAD_PROOF_OF_SERVICE_REQUEST';
export const DOWNLOAD_PROOF_OF_SERVICE_REQUEST_SENT = 'DOWNLOAD_PROOF_OF_SERVICE_REQUEST_SENT';
export const DOWNLOAD_PROOF_OF_SERVICE_REQUEST_SUCCEEDED = 'DOWNLOAD_PROOF_OF_SERVICE_REQUEST_SUCCEEDED';
export const DOWNLOAD_PROOF_OF_SERVICE_REQUEST_FAILED = 'DOWNLOAD_PROOF_OF_SERVICE_REQUEST_FAILED';
export const STORE_DOWNLOAD_STATUS = 'STORE_DOWNLOAD_STATUS';

// update assignment data flow
export const TOGGLE_DRIVER_ASSIGNMENT_EDIT_MODE = 'TOGGLE_DRIVER_ASSIGNMENT_EDIT_MODE';
export const SUBMIT_DRIVER_ASSIGNMENT_DATA_CHANGES = 'SUBMIT_DRIVER_ASSIGNMENT_DATA_CHANGES';

export const UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST = 'UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST';
export const UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_SENT = 'UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_SENT';
export const UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_SUCCEEDED = 'UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_SUCCEEDED';
export const UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_FAILED = 'UPDATE_DRIVER_ASSIGNMENT_DATA_REQUEST_FAILED';
