import React, {Fragment} from 'react';
import {withRouter} from '@computerrock/formation-router';
import moment from 'moment';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {v1 as uuidv1} from 'uuid';
import classnames from 'classnames';
import {Marker, Polygon} from '@react-google-maps/api';
import {subDays} from 'date-fns';
import {withTranslations} from '@computerrock/formation-i18n';
import DriverRow from '../user-management/view-elements/DriverRow';
import DriverAssignmentMap from '../google-map/view-elements/CustomGoogleMap';
import {assembleAddressString} from '../utils/address';
import {driverAssignmentTriggerTypes, CancelableStatuses, AssignedStatuses} from './constants/driverAssignmentMappings';
import {Icon, ButtonSecondary, ButtonPrimary, ToggleButton, PopUp, Input, IconOption, AceDatePicker} from '../ui-components';
import * as driverAssignmentActions from './driverAssignmentActions';
import {driversAvailability} from '../user-management/constants/driversAvailability';
import './DriverAssignmentActiveView.scss';
import {getGeolocationFromString} from '../google-map/googleMapFunctions';
import * as mapSettings from '../google-map/googleMapSettings';
import DriverMarker from './view-elements/DriverMarker';
import {DecliningReasons, CancelReasons} from './constants/rejectReasons';
import MapContainer from './view-elements/MapContainer';
import DriverList from './view-elements/DriverList';
import DriverAssignmentDataRow from './view-elements/rows/DriverAssignmentDataRow';
import DriverAssignmentDataRowCell from './view-elements/rows/DriverAssignmentDataRowCell';
import {dafStatuses} from '../ella-dispo-entity-types';
import {movableToEllaStatuses} from './constants/driverAssignmentStatuses';
import {WarningMessage} from '../ui-components/toast-message';
import SearchAutocomplete from '../google-map/view-elements/SearchAutocomplete';
import {sortDrivers} from './utils/driversSort';

const DATE_TIME_FORMAT = ['dd.MM.yyyy, HH:mm', 'dd.MM.yyyy, HH:mm'];

const addressTypes = {
    TOWING: 'TOWING',
    DAMAGE: 'DAMAGE',
};

class DriverAssignmentActiveView extends React.Component {
    static propTypes = {
        driverAssignment: PropTypes.object.isRequired,
        drivers: PropTypes.array.isRequired,
        changingDriverAssignmentStatus: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        isDeclinePopUpOpen: PropTypes.bool.isRequired,
        isSchedulePopUpOpen: PropTypes.bool.isRequired,
        triggerDeclineCasePopUpState: PropTypes.func.isRequired,
        triggerScheduleCasePopUpState: PropTypes.func.isRequired,
        isEditMode: PropTypes.bool,
        submitDriverAssignmentDataChanges: PropTypes.func.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        isEditMode: false,
        translate: null,
    };

    state = {
        isMapExtended: false,
        selectedDriverId: '',
        shouldReassignDriver: false,
        isEllaScreenActive: false,
        clickedDriverId: '',
        isInfoWindowOpen: false,
        declineReason: null,
        scheduledDateTime: null,
        declineReasonComment: '',
        declineReasonCommentError: false,
        assignmentInputValues: {
            licensePlate: this.props.driverAssignment.vehicle.licensePlate || '',
            personPhoneNumber: this.props.driverAssignment.callBackNumber ? `${this.props.driverAssignment.callBackNumber}` : this.props.driverAssignment.member.phoneNumber ? `${this.props.driverAssignment.member.phoneNumber}` : '',
            damageDestinationAddress: assembleAddressString({
                street: this.props.driverAssignment.damage.address.street,
                postalCode: this.props.driverAssignment.damage.address.postCode,
                city: this.props.driverAssignment.damage.address.city,
            }) || '',
            damageLocation: this.props.driverAssignment.damage.location || {latitude: '', longitude: ''},
            damageAddressStreet: this.props.driverAssignment.damage.address.street || '',
            damageAddressCity: this.props.driverAssignment.damage.address.city || '',
            damageAddressPostCode: this.props.driverAssignment.damage.address.postCode || '',
            damageAddressCountry: this.props.driverAssignment.damage.address.country || '',
            towingDestinationAddress: assembleAddressString({
                street: this.props.driverAssignment.towing.address.street,
                postalCode: this.props.driverAssignment.towing.address.postCode,
                city: this.props.driverAssignment.towing.address.city,
            }) || '',
            towingLocation: this.props.driverAssignment.towing.location || {latitude: '', longitude: ''},
            towingAddressStreet: this.props.driverAssignment.towing.address.street || '',
            towingAddressCity: this.props.driverAssignment.towing.address.city || '',
            towingAddressPostCode: this.props.driverAssignment.towing.address.postCode || '',
            towingAddressCountry: this.props.driverAssignment.towing.address.country || '',
            towingDestination: this.props.driverAssignment.towing.destinationName || '',
        },
        damageLocationError: '',
        towingLocationError: '',
    };

    componentDidMount() {
        this.setMapDamageLocation();
    }

    componentDidUpdate(prevProps) {
        const {isEditMode} = this.props;

        if (!isEditMode && prevProps.isEditMode !== isEditMode) {
            this.resetStateOnEditModeChange();
            this.setMapDamageLocation();
        }
    }

    setMapDamageLocation = () => {
        const {driverAssignment} = this.props;

        if (driverAssignment.damage.location.lat && driverAssignment.damage.location.lng) {
            this.setState({
                deLocation: {
                    lat: parseFloat(driverAssignment.damage.location.lat),
                    lng: parseFloat(driverAssignment.damage.location.lng),
                },
            });
            return;
        }
        const damageEventAddress = assembleAddressString({
            street: driverAssignment.damage.address.street,
            postalCode: driverAssignment.damage.address.postCode,
            city: driverAssignment.damage.address.city,
        });
        getGeolocationFromString(damageEventAddress)
            .then(geolocation => {
                const {location, address} = geolocation;
                this.setState({
                    deLocation: address ? location : mapSettings.DEFAULT_MAP_CENTER,
                });
            });
    };

    resetStateOnEditModeChange = () => {
        this.setState(prevState => ({
            ...prevState,
            assignmentInputValues: {
                licensePlate: this.props.driverAssignment.vehicle.licensePlate || '',
                personPhoneNumber: this.props.driverAssignment.callBackNumber ? `${this.props.driverAssignment.callBackNumber}` : this.props.driverAssignment.member.phoneNumber ? `${this.props.driverAssignment.member.phoneNumber}` : '',
                damageDestinationAddress: assembleAddressString({
                    street: this.props.driverAssignment.damage.address.street,
                    postalCode: this.props.driverAssignment.damage.address.postCode,
                    city: this.props.driverAssignment.damage.address.city,
                }) || '',
                damageLocation: this.props.driverAssignment.damage.location || {latitude: '', longitude: ''},
                damageAddressStreet: this.props.driverAssignment.damage.address.street || '',
                damageAddressCity: this.props.driverAssignment.damage.address.city || '',
                damageAddressPostCode: this.props.driverAssignment.damage.address.postCode || '',
                damageAddressCountry: this.props.driverAssignment.damage.address.country || '',
                towingDestinationAddress: assembleAddressString({
                    street: this.props.driverAssignment.towing.address.street,
                    postalCode: this.props.driverAssignment.towing.address.postCode,
                    city: this.props.driverAssignment.towing.address.city,
                }) || '',
                towingLocation: this.props.driverAssignment.towing.location || {latitude: '', longitude: ''},
                towingAddressStreet: this.props.driverAssignment.towing.address.street || '',
                towingAddressCity: this.props.driverAssignment.towing.address.city || '',
                towingAddressPostCode: this.props.driverAssignment.towing.address.postCode || '',
                towingAddressCountry: this.props.driverAssignment.towing.address.country || '',
                towingDestination: this.props.driverAssignment.towing.destinationName || '',
            },
            damageLocationError: '',
            towingLocationError: '',
        }));
    };

    changingOnEllaScreen = () => {
        this.setState(prevState => {
            return {
                isEllaScreenActive: !prevState.isEllaScreenActive,
            };
        });
    };

    getDataForUpdateStatus = (status, selectedId) => {
        const {translate} = this.props;
        let labels = {
            thirdButton: {
                label: '',
                type: '',
            },
            secondaryButton: {
                label: '',
                type: '',
            },
            primaryButton: {
                label: '',
                type: '',
            },
        };
        switch (true) {
            case (status === dafStatuses.VP_ASSIGNED || status === dafStatuses.VP_ACCEPTED) && this.props.isEditMode:
                labels = {
                    primaryButton: {
                        label: translate('driver_assignments_active_view.button_label.save'),
                        type: driverAssignmentTriggerTypes.SAVE_CHANGES,
                    },
                };
                break;

            case status === dafStatuses.VP_ASSIGNED && !selectedId:
                labels = {
                    thirdButton: {
                        label: translate('driver_assignments_active_view.button_label.schedule_case'),
                        type: driverAssignmentTriggerTypes.SCHEDULE_CASE,
                    },
                    secondaryButton: {
                        label: translate('driver_assignments_active_view.button_label.decline'),
                        type: driverAssignmentTriggerTypes.DECLINE_CASE,
                    },
                    primaryButton: {
                        label: translate('driver_assignments_active_view.button_label.accept_case'),
                        type: driverAssignmentTriggerTypes.ACCEPT_CASE,
                    },
                };
                break;

            case status === dafStatuses.VP_ASSIGNED && !!selectedId:
                labels = {
                    thirdButton: {
                        label: translate('driver_assignments_active_view.button_label.schedule_case'),
                        type: driverAssignmentTriggerTypes.SCHEDULE_CASE,
                    },
                    secondaryButton: {
                        label: translate('driver_assignments_active_view.button_label.decline'),
                        type: driverAssignmentTriggerTypes.DECLINE_CASE,
                    },
                    primaryButton: {
                        label: translate('driver_assignments_active_view.button_label.accept_assign_case'),
                        type: driverAssignmentTriggerTypes.ACCEPT_CASE,
                        shouldUpdateTwice: true,
                    },
                };
                break;

            case status === dafStatuses.VP_ACCEPTED:
                labels = {
                    secondaryButton: {
                        label: translate('driver_assignments_active_view.button_label.cancel_case'),
                        type: driverAssignmentTriggerTypes.DISPO_CANCEL,
                    },
                    primaryButton: {
                        label: translate('driver_assignments_active_view.button_label.assign'),
                        type: driverAssignmentTriggerTypes.ASSIGN_DRIVER,
                    },
                };
                break;

            case CancelableStatuses.includes(status):
                labels = {
                    secondaryButton: {
                        label: translate('driver_assignments_active_view.button_label.cancel_case'),
                        type: driverAssignmentTriggerTypes.DISPO_CANCEL,
                    },
                    primaryButton: {
                        label: translate('driver_assignments_active_view.button_label.new_assignment'),
                        type: driverAssignmentTriggerTypes.ASSIGN_DRIVER,
                    },
                };
                break;

            case status === dafStatuses.SERVICE_FINISHED:
                labels = {
                    primaryButton: {
                        label: translate('driver_assignments_active_view.button_label.back'),
                        type: driverAssignmentTriggerTypes.GO_BACK,
                    },
                };
                break;
        }
        return {
            ...labels,
            ellaButton: {
                label: translate('driver_assignments_active_view.toggle_button_label.ella_billing'),
                type: driverAssignmentTriggerTypes.MANUAL_BILLABLE,
            },
        };
    };

    onDriverSelect = (id, name) => {
        this.setState(prevState => {
            prevState.selectedDriverId = prevState.selectedDriverId === id ? '' : id;
            prevState.selectedDriverName = prevState.selectedDriverName === name ? '' : name;
            return prevState;
        });
    };

    showDriversList = () => {
        this.setState({
            shouldReassignDriver: true,
            selectedDriverId: '',
        });
    };

    handleOnRescheduleDriverAssignment = (status, driverId, button, driverName) => {
        this.changingDriverAssignmentStatus(status, driverId, button, driverName);
        this.props.triggerScheduleCasePopUpState({isOpen: false});
    };

    changingDriverAssignmentStatus = (status, driverId, button, driverName, isDeclineCommentMandatory, declineType) => {
        const {driverAssignment, history} = this.props;
        const {shouldReassignDriver, declineReasonComment, declineReason, scheduledDateTime} = this.state;
        const updateStatusData = declineType
            ? {type: declineType}
            : this.getDataForUpdateStatus(status, driverId)[button];

        if (declineType && isDeclineCommentMandatory && !declineReasonComment.trim()) {
            this.setState({declineReasonCommentError: true});
            return;
        }

        if (updateStatusData.type === driverAssignmentTriggerTypes.GO_BACK) {
            return history.goBack();
        }
        const declineFields = {};
        if (declineType) {
            declineType === driverAssignmentTriggerTypes.DECLINE_CASE
                ? declineFields.declineReason = declineReason.type
                : declineFields.emptyTripReason = declineReason.type;
            declineFields.comment = declineReasonComment;
        }

        const data = {
            deviceId: uuidv1(),
            driverId,
            driverName,
            location: {
                latitude: driverAssignment.damage.location.lat,
                longitude: driverAssignment.damage.location.lng,
            },
            eventTime: moment(new Date()).format(),
            type: shouldReassignDriver ? driverAssignmentTriggerTypes.ASSIGN_DRIVER : updateStatusData.type,
            ...(scheduledDateTime && {scheduledDateTime: moment(scheduledDateTime).format()}),
            ...declineFields,
        };
        const driverAssignmentId = this.props.driverAssignment.assignmentId;
        const shouldUpdateTwice = updateStatusData.shouldUpdateTwice;
        this.props.changingDriverAssignmentStatus({driverAssignmentId, data, shouldUpdateTwice});
    };

    onMapExtendClick = () => {
        this.setState(prevState => {
            prevState.isMapExtended ? document.body.classList.remove('no-scroll') : document.body.classList.add('no-scroll');
            return {
                isMapExtended: !prevState.isMapExtended,
            };
        });
    };

    setClickedDriverId = (id, value) => {
        this.setState({clickedDriverId: id, isInfoWindowOpen: value});
    };

    onDeclineReasonChange = reason => {
        this.setState({declineReason: reason});
    };

    onDeclineReasonCommentChange = e => {
        this.setState({declineReasonComment: e.value});
    };

    resetDeclineFields = () => {
        this.setState({
            declineReason: null,
            declineReasonComment: '',
            declineReasonCommentError: false,
        });
    };

    onDataChange = value => {
        this.setState({
            scheduledDateTime:
                value ? moment(value) : null,
        });
    };

    isLocationValid = async (addressType, inputValue) => {
        const {translate} = this.props;
        let damageLocationError = '';
        let towingLocationError = '';
        await getGeolocationFromString(inputValue)
            .then(geolocation => {
                if (!geolocation || !geolocation.address) {
                    damageLocationError = addressType === addressTypes.DAMAGE
                        ? translate('driver_assignments_active_view.error_message.location')
                        : this.state.damageLocationError;
                    towingLocationError = addressType === addressTypes.TOWING && inputValue
                        ? translate('driver_assignments_active_view.error_message.location')
                        : this.state.towingLocationError;
                }
                if (geolocation && geolocation.address) {
                    const {street, postCode} = geolocation.address;
                    if (!street || !postCode) {
                        damageLocationError = addressType === addressTypes.DAMAGE
                            ? translate('driver_assignments_active_view.error_message.location')
                            : this.state.damageLocationError;
                        towingLocationError = addressType === addressTypes.TOWING && inputValue
                            ? translate('driver_assignments_active_view.error_message.location')
                            : this.state.towingLocationError;
                    }
                }
            });

        this.setState(prevState => {
            return {
                ...prevState,
                damageLocationError: addressType === addressTypes.DAMAGE
                    ? damageLocationError
                    : prevState.damageLocationError,
                towingLocationError: addressType === addressTypes.TOWING
                    ? towingLocationError
                    : prevState.towingLocationError,
            };
        });
    };

    setDamageLocation = value => {
        return getGeolocationFromString(value)
            .then(geolocation => {
                let damageLocation = null;
                let damageAddressStreet = value;
                let damageAddressCity = null;
                let damageAddressPostCode = null;
                let damageAddressCountry = null;
                let damageDestinationAddress = value;

                if (geolocation && geolocation.address) {
                    const {
                        street, num, city, postCode, country,
                        formattedAddress,
                    } = geolocation.address;
                    const {lat, lng} = geolocation.location;
                    damageLocation = {longitude: lng, latitude: lat};
                    damageAddressStreet = `${street} ${num || ''}`;
                    damageAddressCity = city;
                    damageAddressPostCode = postCode;
                    damageAddressCountry = country;
                    damageDestinationAddress = formattedAddress;
                }

                this.setState(prevState => {
                    return {
                        assignmentInputValues: {
                            ...prevState.assignmentInputValues,
                            damageLocation,
                            damageAddressStreet,
                            damageAddressCity,
                            damageAddressPostCode,
                            damageAddressCountry,
                            damageDestinationAddress,
                        },
                    };
                });
            });
    };

    setTowingLocation = value => {
        return getGeolocationFromString(value)
            .then(geolocation => {
                let towingLocation = null;
                let towingAddressStreet = value;
                let towingAddressCity = null;
                let towingAddressPostCode = null;
                let towingAddressCountry = null;
                let towingDestinationAddress = value;

                if (geolocation && geolocation.address) {
                    const {
                        street, num, city, postCode, country,
                        formattedAddress,
                    } = geolocation.address;
                    const {lat, lng} = geolocation.location;
                    towingLocation = {longitude: lng, latitude: lat};
                    towingAddressStreet = `${street} ${num || ''}`;
                    towingAddressCity = city;
                    towingAddressPostCode = postCode;
                    towingAddressCountry = country;
                    towingDestinationAddress = formattedAddress;
                }

                this.setState(prevState => ({
                    ...prevState,
                    assignmentInputValues: {
                        ...prevState.assignmentInputValues,
                        towingLocation,
                        towingAddressStreet,
                        towingAddressCity,
                        towingAddressPostCode,
                        towingAddressCountry,
                        towingDestinationAddress,
                    },
                }));
            });
    };

    updateDriverAssignment = assignmentInputValues => {
        const {submitDriverAssignmentDataChanges, driverAssignment} = this.props;

        const data = {
            assignmentId: driverAssignment.assignmentId,
            data: {
                damage: {
                    address: {
                        street: assignmentInputValues.damageAddressStreet,
                        postCode: assignmentInputValues.damageAddressPostCode,
                        city: assignmentInputValues.damageAddressCity,
                        country: assignmentInputValues.damageAddressCountry,
                    },
                    location: assignmentInputValues.damageLocation,
                },
                towing: {
                    address: {
                        street: assignmentInputValues.towingAddressStreet,
                        postCode: assignmentInputValues.towingAddressPostCode,
                        city: assignmentInputValues.towingAddressCity,
                        country: assignmentInputValues.towingAddressCountry,
                    },
                    destinationName: assignmentInputValues.towingDestinationAddress,
                    location: assignmentInputValues.towingLocation,
                    ...(driverAssignment.towing.destinationType
                        && {destinationType: driverAssignment.towing.destinationType}
                    ),
                },
                vehicle: {
                    ...driverAssignment.vehicle,
                    licensePlate: assignmentInputValues.licensePlate,
                },
                member: {
                    ...driverAssignment.member,
                    ...(!driverAssignment.callBackNumber
                        && {phoneNumber: assignmentInputValues.personPhoneNumber}
                    ),
                },
                ...(driverAssignment.callBackNumber
                    && {callbackPhoneNumber: assignmentInputValues.personPhoneNumber}
                ),
            },
        };

        submitDriverAssignmentDataChanges(data);
    };

    onPopupScheduleDateTimeClose = () => {
        this.props.triggerScheduleCasePopUpState({isOpen: false});
        this.setState(prevState => ({
            ...prevState,
            scheduledDateTime: null,
        }));
    };

    render() {
        const {driverAssignment, drivers, isEditMode, translate} = this.props;
        const {
            selectedDriverId, isMapExtended, shouldReassignDriver, isEllaScreenActive, deLocation,
            declineReason, selectedDriverName, assignmentInputValues,
            damageLocationError, towingLocationError, scheduledDateTime,
        } = this.state;
        const isDriverUnassigned = driverAssignment.status === 'VP_ACCEPTED' || driverAssignment.status === 'VP_ASSIGNED';
        const driversSectionTitle = isDriverUnassigned
            ? translate('driver_assignments_active_view.driver_section_title.driver_unassigned')
            : translate('driver_assignments_active_view.driver_section_title.driver_assigned');
        const labels = driverAssignment
            && this.getDataForUpdateStatus(driverAssignment.status, selectedDriverId);
        const personData = driverAssignment.member.name ? `${driverAssignment.member.name}` : '---';
        const personPhoneNumber = driverAssignment.callBackNumber ? `${driverAssignment.callBackNumber}` : driverAssignment.member.phoneNumber ? `${driverAssignment.member.phoneNumber}` : '---';
        const vehicleInfo = driverAssignment.vehicle.specialFeatures
            ? driverAssignment.vehicle.specialFeatures.map(specialFeature => translate(`global.vehicle_type.${specialFeature.toLowerCase()}`)).join(', ') : '';
        const vehicleData = driverAssignment.vehicle.manufacturer && driverAssignment.vehicle.manufacturer
            ? `${driverAssignment.vehicle.manufacturer} ${driverAssignment.vehicle.model} (${vehicleInfo})` : '---';
        const dateString = driverAssignment.emergencyCallDateTime ? driverAssignment.emergencyCallDateTime.calendar() : '---';
        const damageAddressString = assembleAddressString({
            street: driverAssignment.damage.address.street,
            postalCode: driverAssignment.damage.address.postCode,
            city: driverAssignment.damage.address.city,
        });
        const vehicleWeight = translate(`global.vehicle_tonnages.${driverAssignment.vehicle.weight.toLowerCase()}`);
        const vehicleWidth = driverAssignment.vehicle.width || '---';
        const vehicleHeight = driverAssignment.vehicle.height || '---';
        const vehicleLength = driverAssignment.vehicle.length || '---';
        const childrenAgeLength = driverAssignment.damage.childrenAge.length;
        const childrenAge = childrenAgeLength ? driverAssignment.damage.childrenAge.map(age => {
            return `${age} J.`;
        }).join(', ') : '';
        const childrenAgeString = childrenAgeLength ? ` (Alter: ${childrenAge})` : '';
        const shouldChangeAssignButton = AssignedStatuses.includes(driverAssignment.status);
        const shouldShowToggleButton = movableToEllaStatuses.includes(driverAssignment.status);

        const driversSectionClass = classnames('col-xs--6', {
            'col-start-xs--7': isMapExtended,
        });
        const rowClass = classnames('ace-c-assigned-driver__row', {
            'ace-c-assigned-driver__row--active': selectedDriverId,
        });
        const isCaseFinished = driverAssignment.status === dafStatuses.SERVICE_FINISHED;
        const vpCoords = {
            lat: parseFloat(driverAssignment.contractPartner.location.lat),
            lng: parseFloat(driverAssignment.contractPartner.location.lng),
        };
        const assignedDriver = driverAssignment.driverId && drivers.length
            ? drivers.find(driver => driver.id === driverAssignment.driverId) : null;
        const driversLocations = drivers
            .filter(driver => {
                return driver?.location?.lat && driver?.location?.lng && driver.userActivityStatus === 'AVAILABLE';
            })
            .map(driver => {
                const {location} = driver;
                return {
                    id: driver.id,
                    status: driver.userActivityStatus,
                    firstName: driver.firstName,
                    lastName: driver.lastName,
                    location,
                };
            });

        const mapMarkers = [
            ...(vpCoords.lat && vpCoords.lng ? [vpCoords] : []),
            ...(deLocation && deLocation.lat && deLocation.lng ? [deLocation] : []),
            ...(driversLocations.length ? driversLocations.map(driver => driver.location) : []),
        ];
        const isDeclineCommentMandatory = declineReason
            && (declineReason.type === DecliningReasons.OTHER.type
                || declineReason.type === DecliningReasons.NO_CAPACITY.type
                || declineReason.type === CancelReasons.ORDER_CANCELED.type);

        const sortedDrivers = sortDrivers(drivers);

        const filterPassedTime = time => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        };

        return (
            <Fragment>
                {shouldShowToggleButton && (
                    <div className="ace-grid__row">
                        <div className="col-xs--12">
                            <div className="ace-edit__toggle-wrapper">
                                <div className="ace-edit__toggle-container">
                                    <ToggleButton
                                        onChange={this.changingOnEllaScreen}
                                        isChecked={isEllaScreenActive}
                                        label={translate('driver_assignments_active_view.toggle_button_label.ella_billing')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isEllaScreenActive ? (
                    <div className="ace-grid__row">
                        <div className="col-xs--6 col-start-xs--4">
                            <div className="ace-edit__message">
                                <p className="ace-copy-m ace-copy-m--center">{translate('driver_assignments_active_view.ella_screen.message')}</p>
                                <div className="ace-edit__message-exit-btn">
                                    <ButtonPrimary
                                        label={translate('driver_assignments_active_view.toggle_button_label.ella_billing')}
                                        name="ella"
                                        onClick={() => {
                                            this.changingDriverAssignmentStatus(driverAssignment.status, selectedDriverId, 'ellaButton');
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="ace-driver-assignment-active-view">
                        <div className="ace-grid__row ace-bottom-margin--sm">
                            <div className="col-xs--12">
                                <h4 className="ace-h4">{translate('driver_assignments_active_view.case_details.title')}</h4>
                            </div>
                        </div>
                        <div className="ace-grid__row ace-bottom-margin--md">
                            <div className="col-xs--6">
                                <DriverAssignmentDataRow
                                    iconName="in-service-status"
                                    iconColor="fill-grey"
                                    label={translate('driver_assignments_active_view.data_row_label.service_type')}
                                >
                                    <DriverAssignmentDataRowCell>{driverAssignment.serviceType
                                        ? translate(`global.daf_main_services.${driverAssignment.serviceType.toLowerCase()}`)
                                        : translate('global.daf_main_services.roadside_assistance')}
                                    </DriverAssignmentDataRowCell>
                                </DriverAssignmentDataRow>
                                <DriverAssignmentDataRow
                                    iconName="time-status"
                                    iconColor="fill-grey"
                                    label={translate('driver_assignments_active_view.data_row_label.commissioned')}
                                >
                                    <DriverAssignmentDataRowCell>{dateString}</DriverAssignmentDataRowCell>
                                </DriverAssignmentDataRow>
                                <DriverAssignmentDataRow
                                    iconName="member"
                                    iconColor="fill-grey"
                                    label={translate('driver_assignments_active_view.data_row_label.name')}
                                    classNames={isEditMode ? 'ace-bottom-margin--sm' : ''}
                                >
                                    <DriverAssignmentDataRowCell>{personData || '---'}</DriverAssignmentDataRowCell>
                                </DriverAssignmentDataRow>
                                <DriverAssignmentDataRow
                                    label={!isEditMode ? translate('driver_assignments_active_view.data_row_label.phone_number') : ''}
                                    isEditMode={isEditMode}
                                >
                                    <DriverAssignmentDataRowCell>
                                        {!isEditMode
                                            ? personPhoneNumber || '---'
                                            : (
                                                <Input
                                                    label={translate('driver_assignments_active_view.data_row_label.phone_number')}
                                                    value={assignmentInputValues.personPhoneNumber}
                                                    isLabelIntegrated={true}
                                                    className="ace-bottom-margin--sm ace-c-input--sm ace-c-input--squared"
                                                    onChange={value => this.setState(prevState => ({
                                                        ...prevState,
                                                        assignmentInputValues: {
                                                            ...prevState.assignmentInputValues,
                                                            personPhoneNumber: value.value,
                                                        },
                                                    }))}
                                                />
                                            )
                                        }
                                    </DriverAssignmentDataRowCell>
                                </DriverAssignmentDataRow>
                                <DriverAssignmentDataRow
                                    iconName="car-front"
                                    iconColor="fill-grey"
                                    label={translate('driver_assignments_active_view.data_row_label.vehicle_data')}
                                >
                                    <DriverAssignmentDataRowCell>
                                        {!isEditMode
                                            ? driverAssignment.vehicle.licensePlate || '---'
                                            : (
                                                <Input
                                                    label={translate('driver_assignments_active_view.input_label.licence_plate')}
                                                    value={assignmentInputValues.licensePlate}
                                                    isLabelIntegrated={true}
                                                    className="ace-bottom-margin--sm ace-c-input--sm ace-c-input--squared"
                                                    onChange={value => this.setState(prevState => ({
                                                        ...prevState,
                                                        assignmentInputValues: {
                                                            ...prevState.assignmentInputValues,
                                                            licensePlate: value.value,
                                                        },
                                                    }))}
                                                />
                                            )
                                        }
                                    </DriverAssignmentDataRowCell>
                                    <DriverAssignmentDataRowCell>{vehicleData || '---'}</DriverAssignmentDataRowCell>
                                    <DriverAssignmentDataRowCell>
                                        {translate('driver_assignments_active_view.data_row_cell.weight')}
                                        {driverAssignment.vehicle.weight ? vehicleWeight : '---'}
                                    </DriverAssignmentDataRowCell>
                                    {/* eslint-disable-next-line */}
                                    <DriverAssignmentDataRowCell>
                                        {`${translate('global.measure.length')}: ${vehicleLength} ${translate('global.metric_units.meter')} / 
                                        ${translate('global.measure.width')}: ${vehicleWidth} ${translate('global.metric_units.meter')} /
                                        ${translate('global.measure.height')}: ${vehicleHeight} ${translate('global.metric_units.meter')}`}
                                    </DriverAssignmentDataRowCell>
                                    <DriverAssignmentDataRowCell>
                                        <div className="ace-bottom-margin--sm">
                                            {translate('driver_assignments_active_view.data_row_cell.color')}
                                            {driverAssignment.vehicle.color
                                                ? translate(`global.vehicle_color.${driverAssignment.vehicle.color.toLowerCase()}`)
                                                : '---'}
                                        </div>
                                    </DriverAssignmentDataRowCell>
                                </DriverAssignmentDataRow>
                                <DriverAssignmentDataRow
                                    iconName={!isEditMode ? 'pin' : ''}
                                    label={!isEditMode ? translate('driver_assignments_active_view.data_row_label.address') : ''}
                                    isEditMode={isEditMode}
                                >
                                    <DriverAssignmentDataRowCell>
                                        {!isEditMode
                                            ? damageAddressString || '---'
                                            : (
                                                <div className="ace-bottom-margin--sm">
                                                    <SearchAutocomplete
                                                        label={translate('driver_assignments_active_view.autocomplete_input_label.address')}
                                                        name="damageDestinationAddress"
                                                        value={assignmentInputValues.damageDestinationAddress}
                                                        isLabelIntegrated={true}
                                                        className=" ace-c-input--sm ace-c-input--squared"
                                                        listOptionClassName="ace-c-list-option--font-md"
                                                        onChange={value => this.setState(prevState => ({
                                                            ...prevState,
                                                            assignmentInputValues: {
                                                                ...prevState.assignmentInputValues,
                                                                damageDestinationAddress: value.value,
                                                            },
                                                        }))}
                                                        setAutocompleteFields={this.setDamageLocation}
                                                        onOptionClick={value => this.setState(prevState => (
                                                            {
                                                                ...prevState,
                                                                assignmentInputValues: {
                                                                    ...prevState.assignmentInputValues,
                                                                    damageDestinationAddress: value,
                                                                },
                                                            }
                                                        ))}
                                                        onBlur={value => this.isLocationValid(
                                                            addressTypes.DAMAGE,
                                                            value,
                                                        )}
                                                        error={damageLocationError}
                                                    />
                                                </div>
                                            )
                                        }
                                    </DriverAssignmentDataRowCell>
                                    {isEditMode
                                        && (
                                            <DriverAssignmentDataRowCell>
                                                <div className="ace-bottom-margin--sm">
                                                    <SearchAutocomplete
                                                        label={translate('driver_assignments_active_view.autocomplete_input_label.towing_address')}
                                                        name="towingDestinationAddress"
                                                        value={assignmentInputValues.towingDestinationAddress}
                                                        isLabelIntegrated={true}
                                                        className=" ace-c-input--sm ace-c-input--squared"
                                                        listOptionClassName="ace-c-list-option--font-md"
                                                        onChange={value => this.setState(prevState => ({
                                                            ...prevState,
                                                            assignmentInputValues: {
                                                                ...prevState.assignmentInputValues,
                                                                towingDestinationAddress: value.value,
                                                            },
                                                        }))}
                                                        setAutocompleteFields={this.setTowingLocation}
                                                        onOptionClick={value => this.setState(prevState => ({
                                                            ...prevState,
                                                            assignmentInputValues: {
                                                                ...prevState.assignmentInputValues,
                                                                towingDestinationAddress: value,
                                                            },
                                                        }))}
                                                        onBlur={value => this.isLocationValid(
                                                            addressTypes.TOWING,
                                                            value,
                                                        )
                                                        }
                                                        error={towingLocationError}
                                                    />
                                                </div>
                                            </DriverAssignmentDataRowCell>
                                        )
                                    }
                                </DriverAssignmentDataRow>
                                <DriverAssignmentDataRow
                                    iconName="person-on-site"
                                    label={translate('driver_assignments_active_view.data_row_label.person_at_site')}
                                >
                                    <DriverAssignmentDataRowCell>
                                        {driverAssignment.damage.adultsOnSite || '---'}
                                        {translate('driver_assignments_active_view.data_row_cell.adults')}
                                        {!!driverAssignment.damage.childrenOnSite && ','}
                                    </DriverAssignmentDataRowCell>
                                    {!!driverAssignment.damage.childrenOnSite && (
                                        <DriverAssignmentDataRowCell>
                                            {driverAssignment.damage.childrenOnSite}
                                            {translate('driver_assignments_active_view.data_row_cell.children')}
                                            {childrenAgeString}
                                        </DriverAssignmentDataRowCell>
                                    )}
                                </DriverAssignmentDataRow>
                            </div>
                            <div className="col-xs--6">
                                <DriverAssignmentDataRow
                                    iconName="warning"
                                    label={translate('driver_assignments_active_view.data_row_label.description')}
                                >
                                    <DriverAssignmentDataRowCell>{driverAssignment.damage.description || '---'}</DriverAssignmentDataRowCell>
                                </DriverAssignmentDataRow>
                                <DriverAssignmentDataRow
                                    iconName="comment"
                                    label={translate('driver_assignments_active_view.data_row_label.comment')}
                                    classNames="ace-bottom-margin--sm"
                                >
                                    <DriverAssignmentDataRowCell>{driverAssignment.assignmentText || '---'}</DriverAssignmentDataRowCell>
                                </DriverAssignmentDataRow>
                                {driverAssignment.additionalText && (
                                    <WarningMessage
                                        classNames="ace-c-warning-message ace-c-warning-message--full-width"
                                    >
                                        {translate('driver_assignments_active_view.warning_message.notice')}
                                        {driverAssignment.additionalText}
                                    </WarningMessage>
                                )}
                            </div>
                        </div>
                        <div className="ace-grid__row ace-bottom-margin--md">
                            <div className="col-xs--12">
                                <h4 className="ace-h4">
                                    {translate('driver_assignments_active_view.driver_assignment.title')}
                                </h4>
                            </div>
                        </div>
                        <div className={isMapExtended ? 'ace-driver-assignment-map__overlay' : ''} />
                        <div className="ace-grid__row">
                            <MapContainer
                                isMapExtended={this.state.isMapExtended}
                                onMapExtendClick={this.onMapExtendClick}
                            >
                                <DriverAssignmentMap
                                    markers={mapMarkers}
                                    isInfoWindowOpen={this.state.isInfoWindowOpen}
                                >
                                    {vpCoords.lat && vpCoords.lng && (
                                        <Marker
                                            key="00"
                                            position={vpCoords}
                                            icon={{
                                                url: '/assets/icons/home-marker.svg',
                                                scaledSize: new window.google.maps.Size(75, 75),
                                            }}
                                            zIndex={1}
                                        />
                                    )}
                                    {deLocation && deLocation.lat && deLocation.lng && (
                                        <Marker
                                            key="01"
                                            position={deLocation}
                                            icon={{
                                                url: '/assets/icons/marker.svg',
                                                scaledSize: new window.google.maps.Size(50, 50),
                                            }}
                                            zIndex={3}
                                        />
                                    )}
                                    {driverAssignment.contractPartner.polygon && (
                                        <Polygon
                                            paths={driverAssignment.contractPartner.polygon}
                                            options={mapSettings.polygonOptions}
                                        />
                                    )}
                                    {driversLocations.length && driversLocations.map(driver => {
                                        const params = {setClickedDriverId: this.setClickedDriverId};
                                        // eslint-disable-next-line
                                        if (driver.id === this.state.clickedDriverId) params.clickedDriverId = this.state.clickedDriverId;
                                        return (
                                            <DriverMarker
                                                key={driver.id}
                                                driver={driver}
                                                isAddressShown
                                                {...params}
                                            />
                                        );
                                    })}
                                </DriverAssignmentMap>
                            </MapContainer>
                            <div className={driversSectionClass}>
                                <p className="ace-copy-s ace-bottom-margin--sm"><b>{driversSectionTitle}</b></p>
                                {isDriverUnassigned || shouldReassignDriver ? (
                                    <DriverList>
                                        {!!sortedDrivers.length && sortedDrivers.map(driver => {
                                            return (
                                                <DriverRow
                                                    key={driver.id}
                                                    driver={driver}
                                                    onRowClick={this.onDriverSelect}
                                                    selectedDriverId={selectedDriverId}
                                                />
                                            );
                                        })}
                                    </DriverList>
                                ) : (
                                    <div
                                        className={rowClass}
                                        onClick={() => {
                                            this.onDriverSelect(driverAssignment.driverId);
                                        }}
                                    >
                                        <span className="ace-c-assigned-driver__name">
                                            {assignedDriver ? `${assignedDriver.firstName} ${assignedDriver.lastName}` : ''}
                                        </span>
                                        <span
                                            className="ace-c-assigned-driver__status"
                                        >{
                                            assignedDriver && driversAvailability[assignedDriver.userActivityStatus]
                                                ? translate(`global.drivers_availability.${assignedDriver.userActivityStatus.toLowerCase()}`)
                                                : ''
                                        }
                                        </span>
                                        <span>
                                            <Icon
                                                dimension="md"
                                                iconName={assignedDriver ? driversAvailability[assignedDriver.userActivityStatus]?.icon : ''}
                                                color={selectedDriverId ? 'fill-red' : 'fill-grey'}
                                            />
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="ace-grid__row">
                            {!isEditMode
                                ? (
                                    <>
                                        {driverAssignment.status === dafStatuses.VP_ASSIGNED && (
                                        <div className="col-xs--4">
                                            <ButtonSecondary
                                                onClick={() => {
                                                    // eslint-disable-next-line
                                                        labels.thirdButton.type === driverAssignmentTriggerTypes.SCHEDULE_CASE &&
                                                        this.props.triggerScheduleCasePopUpState({isOpen: true});
                                                }}
                                                name="secondaryScheduleCase"
                                                label={labels.thirdButton.label}
                                            />
                                        </div>
                                        )}
                                        {!isCaseFinished && (
                                            <div
                                                className={`col-xs--4 ${driverAssignment.status === dafStatuses.VP_ASSIGNED ? '' : 'col-start-xs--3'}`}
                                            >
                                                <ButtonSecondary
                                                    onClick={() => {
                                                        // eslint-disable-next-line
                                                        labels.secondaryButton.type === driverAssignmentTriggerTypes.DISPO_CANCEL || labels.secondaryButton.type === driverAssignmentTriggerTypes.DECLINE_CASE
                                                            ? this.props.triggerDeclineCasePopUpState({isOpen: true})
                                                            : this.changingDriverAssignmentStatus(driverAssignment.status, selectedDriverId, 'secondaryButton');
                                                    }}
                                                    name="secondaryRejectCase"
                                                    label={labels.secondaryButton.label}
                                                />
                                            </div>
                                        )}
                                        <div className={`col-xs--4 ${isCaseFinished ? 'col-start-xs--5' : ''}`}>
                                            <ButtonPrimary
                                                name="primary"
                                                label={shouldReassignDriver
                                                    ? translate('driver_assignments_active_view.button_label.assign')
                                                    : labels.primaryButton.label
                                            }
                                                isDisabled={
                                                    (driverAssignment.status === dafStatuses.VP_ACCEPTED
                                                        && !selectedDriverId)
                                                    || (shouldReassignDriver && !selectedDriverId)}
                                                onClick={() => {
                                                    shouldChangeAssignButton && !shouldReassignDriver && !isCaseFinished
                                                        ? this.showDriversList()
                                                        : this.changingDriverAssignmentStatus(driverAssignment.status, selectedDriverId, 'primaryButton', selectedDriverName);
                                                }}
                                            />
                                        </div>
                                    </>
                                )
                                : (
                                    <div className="col-xs--4 col-start-xs--5">
                                        <ButtonPrimary
                                            onClick={() => this.updateDriverAssignment(assignmentInputValues)}
                                            name="primary"
                                            label={labels.primaryButton.label}
                                            isDisabled={
                                                !assignmentInputValues.damageDestinationAddress
                                                || !!damageLocationError
                                                || !!towingLocationError
                                            }
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )}
                {
                    this.props.isSchedulePopUpOpen && (
                        <PopUp>
                            <div className="col-xs--6 col-start-xs--4 ace-bottom-margin--md">
                                <h3 className="ace-h3 ace-h3--center ace-bottom-margin--md">
                                    {translate('driver_assignments_active_view.pop_up_title.case')}
                                    {` ${this.props.driverAssignment.assignmentId} `}
                                    {translate('driver_assignments_active_view.pop_up_title.terminate')}
                                </h3>
                                <div className="ace-copy-m ace-copy-m--grey text-center">
                                    <AceDatePicker
                                        id="scheduled-date-time"
                                        name="scheduledDateTime"
                                        dateFormat={DATE_TIME_FORMAT}
                                        popperPlacement="bottom-end"
                                        selected={scheduledDateTime ? scheduledDateTime.toDate() : new Date()}
                                        onChange={this.onDataChange}
                                        hasTime={true}
                                        label={translate('driver_assignments_active_view.date_picker_label.date_time')}
                                        minDate={subDays(new Date(), 0)}
                                        filterPassedTime={filterPassedTime}
                                    />
                                </div>
                            </div>
                            <div className="col-xs--3 col-start-xs--5">
                                <ButtonSecondary
                                    name="discard"
                                    label={translate('driver_assignments_active_view.button_label.discard')}
                                    onClick={() => this.onPopupScheduleDateTimeClose()}
                                />
                            </div>
                            <div className="col-xs--3">
                                <ButtonPrimary
                                    name="reschedule"
                                    label="Terminieren"
                                    onClick={() => this.handleOnRescheduleDriverAssignment(
                                        driverAssignment.status,
                                        selectedDriverId,
                                        'thirdButton',
                                        selectedDriverName,
                                    )}
                                />
                            </div>
                        </PopUp>
                    )
                }
                {
                    this.props.isDeclinePopUpOpen && (
                        <PopUp>
                            <div className="col-start-xs--3 col-xs--8 ace-bottom-margin--md">
                                <h3 className="ace-h3 ace-h3--center">
                                    {translate('driver_assignments_active_view.pop_up_title.select_reason')}
                                </h3>
                            </div>
                            <div className="col-start-xs--4 col-xs--6 ace-bottom-margin--md">
                                {driverAssignment.status === dafStatuses.VP_ASSIGNED
                                    ? Object.keys(DecliningReasons).map(reason => {
                                        return (
                                            <IconOption
                                                key={reason}
                                                dimension="xxl"
                                                isSelected={this.state.declineReason
                                                    // eslint-disable-next-line
                                                    && DecliningReasons[reason].type === this.state.declineReason.type}
                                                serviceImages={DecliningReasons[reason]}
                                                onChange={() => {
                                                    return this.onDeclineReasonChange(DecliningReasons[reason]);
                                                }}
                                                errorName=""
                                                handleToastMessage={this.handleToastMessages}
                                                name={translate(`global.declining_reasons.${reason.toLowerCase()}`)}
                                                iconName={DecliningReasons[reason].icon}
                                            />
                                        );
                                    })
                                    : Object.keys(CancelReasons).map(reason => {
                                        return (
                                            <IconOption
                                                key={reason}
                                                dimension="xxl"
                                                isSelected={this.state.declineReason
                                                    // eslint-disable-next-line
                                                    && CancelReasons[reason].type === this.state.declineReason.type}
                                                serviceImages={CancelReasons[reason]}
                                                onChange={() => {
                                                    return this.onDeclineReasonChange(CancelReasons[reason]);
                                                }}
                                                errorName=""
                                                handleToastMessage={this.handleToastMessages}
                                                name={translate(`global.cancel_reasons.${reason.toLowerCase()}`)}
                                                iconName={CancelReasons[reason].icon}
                                            />
                                        );
                                    })
                                }
                            </div>
                            <div className="col-start-xs--3 col-xs--8 ace-bottom-margin--md">
                                <Input
                                    name="declineReasonComment"
                                    type="text"
                                    value={this.state.declineReasonComment}
                                    onChange={this.onDeclineReasonCommentChange}
                                    error={this.state.declineReasonCommentError && !this.state.declineReasonComment
                                        ? translate('driver_assignments_active_view.error_message.decline_comment')
                                        : null}
                                    label={!declineReason
                                        ? translate('driver_assignments_active_view.data_row_label.decline_reasons_details')
                                        : isDeclineCommentMandatory
                                            ? translate('driver_assignments_active_view.data_row_label.decline_reasons_details_mandatory')
                                            : translate('driver_assignments_active_view.data_row_label.decline_reasons_details_optional')
                                }
                                />
                            </div>
                            <div className="col-xs--4 col-start-xs--3">
                                <ButtonPrimary
                                    label={translate('driver_assignments_active_view.button_label.decline_case')}
                                    isDisabled={!declineReason}
                                    name="declineCase"
                                    onClick={
                                        () => this.changingDriverAssignmentStatus(
                                            driverAssignment.status,
                                            driverAssignment.driverId,
                                            'secondaryButton',
                                            driverAssignment.driverName,
                                            isDeclineCommentMandatory,
                                            labels.secondaryButton.type,
                                        )
                                    }
                                />
                            </div>

                            <div className="col-xs--4">
                                <ButtonSecondary
                                    label={translate('driver_assignments_active_view.button_label.close')}
                                    name="abortDeclineCase"
                                    onClick={() => {
                                        this.props.triggerDeclineCasePopUpState({isOpen: false});
                                        this.resetDeclineFields();
                                    }}
                                />
                            </div>
                        </PopUp>
                    )
                }
            </Fragment>
        );
    }
}


const mapStateToProps = state => ({
    isDeclinePopUpOpen: state.driverAssignments.isDeclinePopUpOpen,
    isSchedulePopUpOpen: state.driverAssignments.isSchedulePopUpOpen,
});

const mapDispatchToProps = dispatch => ({
    changingDriverAssignmentStatus: payload => dispatch({
        type: driverAssignmentActions.START_CHANGING_DRIVER_ASSIGNMENT_STATUS_FLOW,
        payload,
    }),
    triggerDeclineCasePopUpState: payload => dispatch({
        type: driverAssignmentActions.TRIGGER_CHANGE_DECLINE_CASE_POP_UP_STATE,
        payload,
    }),
    triggerScheduleCasePopUpState: payload => dispatch({
        type: driverAssignmentActions.TRIGGER_CHANGE_SCHEDULE_CASE_POP_UP_STATE,
        payload,
    }),
    submitDriverAssignmentDataChanges: payload => dispatch({
        type: driverAssignmentActions.SUBMIT_DRIVER_ASSIGNMENT_DATA_CHANGES,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslations(DriverAssignmentActiveView)));
