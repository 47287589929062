import {OpenIDConnectClient} from '@computerrock/oidc-auth-client';
import {I18nService} from '@computerrock/formation-i18n';
import {ServiceManager} from '@computerrock/formation-core';
import {DatadogLoggerClient} from '@computerrock/datadog-logger-client';
import {MSClientDriverEllaUserManagementDEUM} from './services/ms-client-driver-ella-user-management-deum';
import {MSClientACEAssignmentFlowDAF} from './services/ms-client-ace-assignment-flow-daf';
import {MSClientACEDriverLocatorDL} from './services/ms-client-ace-driver-locator-dl';
import {MSClientACEPartnerManagementAPM} from './services/ms-client-ace-partner-management-apm';
import {MSClientACEMemberProfileAMP} from './services/ms-client-ace-member-profile-amp';
import {MSClientELLAAssignmentFlowEAF} from './services/ms-client-ella-assignment-flow-eaf';
import config from './config';
import defaultLocaleTranslations from './locale';

const firebaseConfig = {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
    // databaseURL: config.FIREBASE_DATABASE_URL,
    projectId: config.FIREBASE_PROJECT_ID,
    storageBucket: config.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
    appId: config.FIREBASE_APP_ID,
};

/**
 * App services
 */
const serviceManager = new ServiceManager();

// initialize Datadog logger
if (config.DATADOG_ENABLE) {
    const ffwLoggerService = serviceManager.loadService('ffwLoggerService');
    ffwLoggerService.setLoggerClient(new DatadogLoggerClient({
        applicationId: config.DATADOG_RUM_APPLICATION_ID,
        clientToken: config.DATADOG_CLIENT_TOKEN,
        service: config.DATADOG_SERVICE,
        options: {
            env: config.DEPLOYMENT_ENV,
            version: `v${config.APP_VERSION}`,
        },
    }));
}

serviceManager.registerService('keycloakAuthService', new OpenIDConnectClient({
    authServiceId: 'keycloak',
    serviceURL: config.KC_AUTH_URL,
    realm: config.KC_AUTH_REALM,
    clientId: config.KC_CLIENT_ID,
    cookieDomain: config.COOKIE_DOMAIN,
}));

serviceManager.registerService('userManagementService', new MSClientDriverEllaUserManagementDEUM({
    SERVICE_URL: config.DEUM_URL,
    firebaseConfig,
}));

serviceManager.registerService('driverAssignmentFlowService', new MSClientACEAssignmentFlowDAF({
    SERVICE_URL: config.DAF_SERVICE_URL,
}));

serviceManager.registerService('driverLocatorService', new MSClientACEDriverLocatorDL({
    SERVICE_URL: config.DL_SERVICE_URL,
}));

serviceManager.registerService('partnerManagementService', new MSClientACEPartnerManagementAPM({
    SERVICE_URL: config.APM_SERVICE_URL,
}));

serviceManager.registerService('memberProfileService', new MSClientACEMemberProfileAMP({
    SERVICE_URL: config.AMP_SERVICE_URL,
}));

serviceManager.registerService('ellaAssignmentFlowService', new MSClientELLAAssignmentFlowEAF({
    SERVICE_URL: config.EAF_SERVICE_URL,
}));

serviceManager.registerService('i18nService', I18nService({
    LOCALE: 'de-DE',
    DEFAULT_LOCALE_TRANSLATIONS: defaultLocaleTranslations,
    LOCALE_RESOURCES: [
        {
            locale: 'en-US',
            url: config.ELLA_LOCALE_TRANSLATION_EN_US_URL,
        },
        {
            locale: 'de-DE',
            url: config.ELLA_LOCALE_TRANSLATION_DE_DE_URL,
        },
    ],
}));

export default serviceManager;
