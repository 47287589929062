import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import routePaths from '../../../routePaths';
import {isFollowUpInvoiceSubmission} from '../../utils/subCases';
import {convertNumberToCurrency} from '../../utils/conversion';
import {Icon, CaseStatePill} from '../../../ui-components';
import {TableRow, TableCell} from '../../../ui-components/table';
import './OpenInvoiceSubmissionRow.scss';
import {DEFAULT_DATE_FORMAT, NULL_DATE_STRING} from '../../constants/dates';
import Tooltip from '../../../ui-components/tooltip/Tooltip';
import {eafStatusPillTypes} from '../../constants/invoiceSubmissionStatuses';
import {getServiceInfo} from '../../utils/mainServices';

const OpenInvoiceSubmissionRow = props => {
    const {translate} = useTranslate();
    const {dateField, invoiceSubmission} = props;
    const date = invoiceSubmission.assignment[dateField];
    const isCaseFollowUpInvoiceSubmission = isFollowUpInvoiceSubmission(invoiceSubmission.invoiceSubmissionId);
    const dateString = !date || date === NULL_DATE_STRING ? NULL_DATE_STRING : moment(date).format(DEFAULT_DATE_FORMAT);
    const {serviceIcon, serviceName} = getServiceInfo(invoiceSubmission.serviceType, invoiceSubmission.isEmptyTrip, invoiceSubmission.emptyTripReason, translate); // eslint-disable-line

    return (
        <TableRow
            className="ace-c-table-row--md"
            linkTo={resolveRoute(routePaths.INVOICE_SUBMISSION, {
                invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
            }, {invoiceSubmissionStatus: invoiceSubmission.status})}
        >
            <TableCell className="col-sm--2">
                <CaseStatePill type={eafStatusPillTypes[invoiceSubmission.status]}>
                    {translate(`global.eaf_status.${invoiceSubmission.status.toLowerCase()}`)}
                </CaseStatePill>
            </TableCell>
            <TableCell className="col-sm--1 ace-flex-cell">
                {serviceIcon && serviceName ? (
                    <Tooltip
                        position="top-left"
                        message={serviceName}
                    >
                        <Icon
                            className="ace-c-open-invoice-submission-row__tooltip-icon"
                            dimension="lg"
                            iconName={serviceIcon}
                            color="grey"
                        />
                    </Tooltip>
                ) : '---'}
            </TableCell>
            <TableCell className="col-sm--2">
                <span className="ace-c-open-invoice-submission-cell__small">{invoiceSubmission.invoiceSubmissionName}</span>
                {isCaseFollowUpInvoiceSubmission && (
                    <Icon dimension="md" iconName="circle-plus" className="ace-c-open-invoice-submission-row__plus-icon" />
                )}
            </TableCell>
            <TableCell className="col-sm--2">
                <span className="ace-copy-s">{invoiceSubmission.assignment.member.name || '---'}</span>
            </TableCell>
            <TableCell className="col-sm--2">
                <span className="ace-copy-s">{invoiceSubmission.assignment.vehicle.licensePlate || '---'}</span>
            </TableCell>
            <TableCell className="col-sm--1">
                <span className="ace-copy-s">{dateString}</span>
            </TableCell>
            <TableCell className="col-sm--2 ace-c-table-cell--right">
                <span className="ace-copy-s">{convertNumberToCurrency(invoiceSubmission.total)} €</span>
            </TableCell>
        </TableRow>
    );
};

OpenInvoiceSubmissionRow.propTypes = {
    invoiceSubmission: PropTypes.object,
    dateField: PropTypes.string.isRequired,
};

OpenInvoiceSubmissionRow.defaultProps = {
    invoiceSubmission: {},
};

export default OpenInvoiceSubmissionRow;
