import {validationMessagesTranslation, validationMessages} from '../utils/validationMessages';

export const getValidationErrors = state => {
    return state.errorsReducer.error ? state.errorsReducer.error.errors : {};
};

export const getServerErrors = state => {
    return state.errorsReducer.error && !Object.keys(state.errorsReducer.error.errors).length
    && state.errorsReducer.error.errorType !== validationMessagesTranslation[validationMessages.INVALID_GRANT]
        && state.errorsReducer.error.errorType !== validationMessagesTranslation[validationMessages.USER_DRIVER]
        ? {errorType: state.errorsReducer.error.errorType} : null;
};

export const getLogInError = state => {
    return state.errorsReducer.error && !Object.keys(state.errorsReducer.error.errors).length
        && (state.errorsReducer.error.errorType === validationMessagesTranslation[validationMessages.INVALID_GRANT]
        || state.errorsReducer.error.errorType === validationMessagesTranslation[validationMessages.USER_DRIVER])
        ? {errorType: state.errorsReducer.error.errorType} : null;
};
