/**
 * Document download action types
 */
export const SUBMIT_DOWNLOAD_SELECTED_DOCUMENTS_FORM = 'SUBMIT_DOWNLOAD_SELECTED_DOCUMENTS_FORM';
export const SUBMIT_DOCUMENT_DOWNLOAD_FORM = 'SUBMIT_DOCUMENT_DOWNLOAD_FORM';

export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_REQUEST_SENT = 'FETCH_DOCUMENTS_REQUEST_SENT';
export const FETCH_DOCUMENTS_REQUEST_SUCCEEDED = 'FETCH_DOCUMENTS_REQUEST_SUCCEEDED';
export const FETCH_DOCUMENTS_REQUEST_FAILED = 'FETCH_DOCUMENTS_REQUEST_FAILED';

export const DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST';
export const DOWNLOAD_DOCUMENT_REQUEST_SENT = 'DOWNLOAD_DOCUMENT_REQUEST_SENT';
export const DOWNLOAD_DOCUMENT_REQUEST_SUCCEEDED = 'DOWNLOAD_DOCUMENT_REQUEST_SUCCEEDED';
export const DOWNLOAD_DOCUMENT_REQUEST_FAILED = 'DOWNLOAD_DOCUMENT_REQUEST_FAILED';

export const DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST = 'DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST';
export const DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_SENT = 'DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_SENT';
export const DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_SUCCEEDED = 'DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_SUCCEEDED';
export const DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_FAILED = 'DOWNLOAD_MULTIPLE_DOCUMENTS_REQUEST_FAILED';

export const STORE_DOCUMENTS = 'STORE_DOCUMENTS';
export const SET_DOCUMENT_DOWNLOAD_STATUS = 'SET_DOCUMENT_DOWNLOAD_STATUS';
export const SET_SELECTED_DOCUMENTS_DOWNLOAD_STATUS = 'SET_MULTIPLE_DOCUMENT_DOWNLOAD_STATUS';
