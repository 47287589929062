import hashSum from 'hash-sum';
import {immerable} from 'immer';
import moment from 'moment';
import Entity from '../entity/Entity';

/**
 * Calculates file size closest unit
 *
 * @param {number} fileSize
 * @returns {string}
 */
const calculateFileSizeWithUnit = fileSize => {
    const fileSizeUnits = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const fileSizeUnitIndex = fileSize ? Math.floor(Math.log2(fileSize) / 10) : 0;

    return Math.floor(fileSize / Math.pow(1024, fileSizeUnitIndex)) + ' ' + fileSizeUnits[fileSizeUnitIndex];
};

/**
 * DocumentFile definition
 *
 * @typedef {Immutable.Record} DocumentFile
 * @property {?string} id
 * @property {?string} parentId
 * @property {?string} name
 * @property {?string} fileType
 * @property {?string} filePath
 * @property {?number} fileSize
 * @property {?string} fileSizeWithUnit
 * @property {?boolean} isDir
 * @property {?Object} createdDate
 * @property {?Object} lastModifiedDate
 */
const documentDownloadDTOMapping = {
    id: {
        dtoProperty: 'path',
        fromDTO: path => hashSum(path),
    },
    parentId: 'parentId',
    name: {
        dtoProperty: 'name',
        defaultValue: '',
    },
    fileType: {
        dtoProperty: 'type',
        defaultValue: null,
    },
    filePath: {
        dtoProperty: 'path',
        defaultValue: null,
    },
    fileSize: {
        dtoProperty: 'size',
        fromDTO: size => (parseInt(size, 10) || 0),
    },
    fileSizeWithUnit: {
        dtoProperty: 'size',
        fromDTO: size => (calculateFileSizeWithUnit(size) || 0),
    },
    isDir: {
        dtoProperty: 'dir',
        defaultValue: false,
    },
    createdDate: {
        dtoProperty: 'createdAt',
        fromDTO: createdAt => (createdAt ? moment(createdAt) : null),
    },
    lastModifiedDate: {
        dtoProperty: 'lastModifiedAt',
        fromDTO: (lastModifiedAt => (lastModifiedAt ? moment(lastModifiedAt) : null)),
    },
};

/**
 * DocumentFile record
 *
 * @type {DocumentFile}
 */
export default class DocumentFile extends Entity {
    [immerable] = true;

    static entityDTOMapping = documentDownloadDTOMapping;

    static extendEntityDTOMapping = entityDTOMapping => {
        return {
            ...documentDownloadDTOMapping,
            ...entityDTOMapping,
        };
    };

    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
