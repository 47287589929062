/**
 * @typedef {string} originator
 */

/**
 * Originator
 *
 * @enum {originator}
 */
export default {
    ELLA: 'ELLA',
    NAV: 'NAV',
    ALF: 'ALF',
};
