import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useTranslate} from '@computerrock/formation-i18n';
import {availabilityStatuses, driversAvailability} from '../constants/driversAvailability';
import {Icon} from '../../ui-components';
import './DriverRow.scss';

const DriverRow = ({driver, onRowClick, selectedDriverId, isDisabled}) => {
    const {translate} = useTranslate();
    const getUserInitials = (firstName, lastName) => {
        return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`.toUpperCase();
    };
    const rowClass = classnames('ace-c-driver-row', {
        'ace-c-driver-row--active': driver.id === selectedDriverId,
        'ace-c-driver-row--disabled': isDisabled,
    });
    const initialsClass = classnames('ace-c-driver-row__initials', {
        'ace-c-driver-row__initials--red': driver.userActivityStatus ? driversAvailability[driver.userActivityStatus].status === availabilityStatuses.BUSY : false,
        'ace-c-driver-row__initials--green': driver.userActivityStatus ? driversAvailability[driver.userActivityStatus].status === availabilityStatuses.AVAILABLE : false,
        'ace-c-driver-row__initials--grey': driver.userActivityStatus ? driversAvailability[driver.userActivityStatus].status === availabilityStatuses.UNAVAILABLE : false,
    });

    const driverFirstName = driver.lastName ? driver.firstName : driver.firstName.split(' ')[0] || '';
    const driverLastName = driver.lastName ? driver.lastName : driver.firstName.split(' ').map((string, index) => {
        if (index > 0) {
            return string;
        }
        return '';
    })?.join(' ');

    return (
        <div
            className={rowClass}
            onClick={() => {
                if (isDisabled) {
                    return;
                }
                onRowClick(driver.id, `${driver.firstName} ${driver.lastName}`);
            }}
        >
            <div className={initialsClass}>{getUserInitials(driver.firstName, driver.lastName)}</div>
            <div className="ace-c-driver-row__driver">
                <div className="ace-c-driver-row__driver-text">
                    <p className="ace-c-driver-row__driver-name">{`${driverLastName} ${driverFirstName}`}</p>
                    <p className="ace-c-driver-row__driver-status">
                        {driver.userActivityStatus
                            ? translate(`global.drivers_availability.${driver.userActivityStatus.toLowerCase()}`)
                            : ''}
                    </p>
                </div>
                <div className="ace-c-driver-row__driver-icon">
                    <Icon
                        iconName={driver.userActivityStatus ? driversAvailability[driver.userActivityStatus].icon : ''}
                        dimension="md"
                        color={driver.id === selectedDriverId ? 'fill-red' : 'fill-grey'}
                    />
                </div>
            </div>

        </div>
    );
};

DriverRow.propTypes = {
    driver: PropTypes.object.isRequired,
    onRowClick: PropTypes.func,
    selectedDriverId: PropTypes.string,
    isDisabled: PropTypes.bool,
};

DriverRow.defaultProps = {
    onRowClick: null,
    selectedDriverId: '',
    isDisabled: false,
};

export default DriverRow;
