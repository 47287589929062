import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import MemberForm from './MemberForm';
import {ButtonPrimary, ButtonSecondary} from '../../ui-components';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {invoiceSubmissionViews} from '../constants/invoiceSubmissionViews';

const getInitialState = data => {
    const isDriverEqualToMember = data.driverType === 'MEMBER';
    const isOwnerEqualToMember = data.ownerType === 'MEMBER';

    const memberData = {
        name: data.memberFullName,
        city: data.memberAddressCity,
        country: data.memberAddressCountry || 'DE',
        address: data.memberAddressStreet,
        postCode: data.memberAddressPostCode,
        phone: data.memberPhoneNumber,
        membershipNumber: data.membershipNumber,
        type: data.memberType,
    };
    const driverData = {
        name: isDriverEqualToMember ? data.memberFullName : data.driverName,
        city: isDriverEqualToMember ? data.memberAddressCity : data.driverAddressCity,
        country: isDriverEqualToMember ? data.memberAddressCountry : data.driverAddressCountry,
        address: isDriverEqualToMember ? data.memberAddressStreet : data.driverAddressStreet,
        postCode: isDriverEqualToMember ? data.memberAddressPostCode : data.driverAddressPostCode,
        type: data.driverType,
    };
    const ownerData = {
        name: isOwnerEqualToMember ? data.memberFullName : data.ownerName,
        city: isOwnerEqualToMember ? data.memberAddressCity : data.ownerAddressCity,
        country: isOwnerEqualToMember ? data.memberAddressCountry : data.ownerAddressCountry,
        address: isOwnerEqualToMember ? data.memberAddressStreet : data.ownerAddressStreet,
        postCode: isOwnerEqualToMember ? data.memberAddressPostCode : data.ownerAddressPostCode,
        type: data.ownerType,
    };
    return {
        memberData,
        driverData,
        ownerData,
    };
};

const MemberFormWrapper = props => {
    const {invoiceSubmission, closeMemberForm, saveMemberData} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateWrapper = createTranslateShorthand('member_form_wrapper');
    const data = getInitialState(invoiceSubmission);
    const [memberData, setMemberData] = useState({...data.memberData});
    const [driverData, setDriverData] = useState({...data.driverData});
    const [ownerData, setOwnerData] = useState({...data.ownerData});
    const [shouldDisplayDriverForm, setShouldDisplayDriverForm] = useState(invoiceSubmission.driverType !== 'MEMBER');
    const [shouldDisplayOwnerForm, setShouldDisplayOwnerForm] = useState(invoiceSubmission.ownerType !== 'MEMBER');

    const onDataSave = () => {
        const {invoiceSubmissionId, assignmentId, isForeignInsurance} = invoiceSubmission;

        saveMemberData({
            invoiceSubmissionId,
            assignmentId,
            data: {
                memberData,
                driverData,
                ownerData,
            },
            isDriverMember: !shouldDisplayDriverForm,
            isOwnerMember: !shouldDisplayOwnerForm,
            isNoValidation: isForeignInsurance,
        });
    };

    const onChangeDriverCheckBox = () => {
        if (!shouldDisplayDriverForm) {
            setDriverData({
                ...driverData,
                type: null,
            });
        }
        setShouldDisplayDriverForm(!shouldDisplayDriverForm);
    };

    const onChangeOwnerCheckBox = () => {
        if (!shouldDisplayOwnerForm) {
            setOwnerData({
                ...driverData,
                type: null,
            });
        }
        setShouldDisplayOwnerForm(!shouldDisplayOwnerForm);
    };

    const memberFormValidation = args => {
        if (args) return !args.filter(element => !element).length;
    };

    const validateAllFields = () => {
        let isValidate = true;
        if (invoiceSubmission.isManuallyCreatedCase) {
            isValidate = isValidate && memberFormValidation(
                [memberData.name, memberData.address, memberData.city, memberData.postCode, memberData.country],
            );
        }
        if (shouldDisplayDriverForm) {
            isValidate = isValidate && memberFormValidation(Object.values(driverData));
        }
        if (shouldDisplayOwnerForm) {
            isValidate = isValidate && memberFormValidation(Object.values(ownerData));
        }
        return isValidate;
    };

    const isActive = validateAllFields() || invoiceSubmission.isForeignInsurance;

    return (
        <div className="ace-forms-container">
            <div className="edit-page__form-container">
                <div className="edit-page__shadow-wrapper edit-page__shadow-wrapper--top">
                    <div className="edit-page__shadow" />
                </div>
                <div className="ace-grid__row edit-page-form-container__title">
                    <div className="col-xs--12">
                        <h4 className="ace-h4">{translateWrapper('section_title.member_data')}</h4>
                    </div>
                </div>
                <MemberForm
                    isMemberForm
                    isDamageEventCreatedManually={invoiceSubmission.isManuallyCreatedCase}
                    title={translateWrapper('member_form_title.member')}
                    isDisplayedDriverForm={shouldDisplayDriverForm}
                    isDisplayedOwnerForm={shouldDisplayOwnerForm}
                    inputData={memberData}
                    onChangeDriverCheckBox={onChangeDriverCheckBox}
                    onChangeOwnerCheckBox={onChangeOwnerCheckBox}
                    onDataChange={data => setMemberData({...memberData, ...data})}
                    isForeignInsurance={invoiceSubmission.isForeignInsurance}
                />
                {shouldDisplayDriverForm && (
                    <MemberForm
                        title={translateWrapper('member_form_title.driver')}
                        isDisplayedDriverForm={shouldDisplayDriverForm}
                        inputData={driverData}
                        onDataChange={data => setDriverData({...driverData, ...data})}
                    />
                )}
                {shouldDisplayOwnerForm && (
                    <MemberForm
                        title={translateWrapper('member_form_title.owner')}
                        isDisplayedDriverForm={shouldDisplayOwnerForm}
                        inputData={ownerData}
                        onDataChange={data => setOwnerData({...ownerData, ...data})}
                    />
                )}
                <div className="ace-grid__row">
                    <div className="col-xs--3 col-start-xs--4">
                        <ButtonSecondary
                            type="button"
                            label={translateWrapper('button_label.back')}
                            name="backButton"
                            onClick={() => closeMemberForm({
                                invoiceSubmissionViewState: invoiceSubmissionViews.SUMMARY,
                            })}
                        />
                    </div>
                    <div className="col-xs--3">
                        <ButtonPrimary
                            type="button"
                            label={translateWrapper('button_label.save')}
                            name="saveButton"
                            onClick={onDataSave}
                            isDisabled={!isActive}
                        />
                    </div>
                </div>
                <div className="edit-page__shadow-wrapper edit-page__shadow-wrapper--bottom">
                    <div className="edit-page__shadow" />
                </div>
            </div>
        </div>
    );
};

MemberFormWrapper.propTypes = {
    invoiceSubmission: PropTypes.object.isRequired,
    saveMemberData: PropTypes.func.isRequired,
    closeMemberForm: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    saveMemberData: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_MEMBER_DATA,
        payload,
    }),
    closeMemberForm: payload => dispatch({
        type: invoiceSubmissionActionTypes.CHANGE_INVOICE_SUBMISSION_VIEW_STATE,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(MemberFormWrapper);
