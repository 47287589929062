import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as messagesActionTypes from '../messagesActionTypes';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import getPartnersMessagesFlow from './getPartnersMessagesFlow';

const markMessagesAsRead = function* markMessagesAsRead(params) {
    const {unreadMessageIds} = params;
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    yield fork(fetchRequest, messagesActionTypes.MARK_MESSAGES_AS_READ_REQUEST,
        eafService.markMessagesAsRead, {messageIds: unreadMessageIds});

    const actionResult = yield take([
        messagesActionTypes.MARK_MESSAGES_AS_READ_REQUEST_SUCCEEDED,
        messagesActionTypes.MARK_MESSAGES_AS_READ_REQUEST_FAILED,
    ]);

    if (actionResult.error) {
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(actionResult.payload),
                },
            },
        });
        return;
    }
    yield fork(getPartnersMessagesFlow);
};

export default markMessagesAsRead;
