import {LOCATION_CHANGE} from '@computerrock/formation-router';
import {fork, delay, take, race} from 'redux-saga/effects';
import routePaths from '../../routePaths';
import config from '../../config';
import * as messagesActionTypes from '../messagesActionTypes';
import getPartnersMessagesFlow from './getPartnersMessagesFlow';

const refreshingPartnersMessages = function* refreshingPartnersMessages() {
    while (true) {
        yield take(messagesActionTypes.REFRESH_MESSAGES);
        const allowingRefresh = yield race({
            routeChange: take(LOCATION_CHANGE),
            time: delay(config.DEFAULT_MESSAGES_REFRESH_TIME),
        });
        if (allowingRefresh.routeChange
            && (allowingRefresh.routeChange.payload.location.pathname === routePaths.OPEN_INVOICE_SUBMISSIONS
                || allowingRefresh.routeChange.payload.location.pathname === routePaths.OIDC_AUTHORIZE)) {
            continue;
        }
        yield fork(getPartnersMessagesFlow);
    }
};
export default refreshingPartnersMessages;
