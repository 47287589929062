import {put, fork, take, select} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../../invoice-submission/invoiceSubmissionActionTypes';
import * as errorActions from '../../application/errorActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import {parseErrorMessages} from '../../utils/validationMessages';

const getPricingInfoFlow = function* getPricingInfoFlow({payload}) {
    yield put({
        type: invoiceSubmissionActionTypes.STORE_PRICING_INFO,
        payload: {pricingDTO: {flat: null, openCarMax: null, isPricingDataReady: false}},
    });

    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    const {contractPartnerId, serviceEndDateTime} = payload;
    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_PRICING_INFO_REQUEST,
        eafService.getPricingInfo,
        {contractPartnerId, serviceEndDateTime},
    );

    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_PRICING_INFO_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_PRICING_INFO_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const pricingDTO = resultAction.payload.response;
        yield put({
            type: invoiceSubmissionActionTypes.STORE_PRICING_INFO,
            payload: {pricingDTO: {...pricingDTO, isPricingDataReady: true}},
        });
        return;
    }
    yield put({
        type: errorActions.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export default getPricingInfoFlow;
