import React, {useCallback, useEffect, useRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './WarningMessage.scss';
import {useClickOutside} from '../general/useClickOutside';
import WarningMessage from './WarningMessage';

const ToastMessage = ({handleToastMessage, className, isEnabled, children}) => {
    const toastRef = useRef(null);

    useEffect(() => {
        if (isEnabled) {
            const toastMessageTimeout = setTimeout(() => {
                handleToastMessage();
            }, 5000);
            return () => {
                clearTimeout(toastMessageTimeout);
            };
        }
    }, [handleToastMessage, isEnabled]);


    const handleClickOutside = useCallback(() => {
        handleToastMessage();
    }, [handleToastMessage]);

    useClickOutside(toastRef, handleClickOutside);

    const classNames = classnames('ace-c-warning-message', className, {
        'ace-c-warning-message--show': isEnabled,
    });
    return (
        <WarningMessage classNames={classNames} toastRef={toastRef}>
            {children}
        </WarningMessage>
    );
};

ToastMessage.propTypes = {
    isEnabled: PropTypes.any,
    className: PropTypes.string,
    handleToastMessage: PropTypes.func,
};

ToastMessage.defaultProps = {
    isEnabled: false,
    className: '',
    handleToastMessage: null,
};

export default ToastMessage;
