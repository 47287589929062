import {fork, select, take, put} from 'redux-saga/effects';
import * as messagesActionTypes from '../messagesActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';


const sendMessageFlow = function* sendMessageFlow() {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(messagesActionTypes.SEND_MESSAGE);
        const {invoiceSubmissionId, text, author} = payload;
        yield put({type: errorActionTypes.DELETE_ERRORS});

        yield fork(fetchRequest, messagesActionTypes.SEND_MESSAGE_REQUEST,
            eafService.createInvoiceSubmissionMessage, {invoiceSubmissionId, text, author});

        const actionResult = yield take([
            messagesActionTypes.SEND_MESSAGE_REQUEST_SUCCEEDED,
            messagesActionTypes.SEND_MESSAGE_REQUEST_FAILED,
        ]);

        if (!actionResult.error) {
            yield fork(fetchRequest, messagesActionTypes.GET_INVOICE_SUBMISSION_MESSAGES_REQUEST,
                eafService.getInvoiceSubmissionMessages, {invoiceSubmissionId});

            const actionResult = yield take([
                messagesActionTypes.GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_SUCCEEDED,
                messagesActionTypes.GET_INVOICE_SUBMISSION_MESSAGES_REQUEST_FAILED,
            ]);

            if (!actionResult.error) {
                const messagesDTO = actionResult.payload.response.data;
                yield put({
                    type: messagesActionTypes.STORE_INVOICE_SUBMISSION_MESSAGES,
                    payload: {messagesDTO},
                });
                continue;
            }
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(actionResult.payload),
                    },
                },
            });
            continue;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(actionResult.payload),
                },
            },
        });
    }
};

export default sendMessageFlow;
