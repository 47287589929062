import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {Polygon, Marker} from '@react-google-maps/api';
import {withTranslations} from '@computerrock/formation-i18n';
import AppLayoutView from '../application/AppLayoutView';
import DriversMap from '../google-map/view-elements/CustomGoogleMap';
import DriverRow from '../user-management/view-elements/DriverRow';
import {createCurrentUsersSelector} from '../user-management/userManagementSelectors';
import './DriverStatusView.scss';
import * as mapSettings from '../google-map/googleMapSettings';
import DriverMarker from './view-elements/DriverMarker';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import MapContainer from './view-elements/MapContainer';
import DriverList from './view-elements/DriverList';
import {sortDrivers} from './utils/driversSort';


class DriverStatusView extends React.Component {
    static propTypes = {
        drivers: PropTypes.array,
        contractPartners: PropTypes.object,
        translate: PropTypes.func,
    };

    static defaultProps = {
        drivers: [],
        contractPartners: {},
        translate: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            isMapExtended: false,
            clickedDriverId: '',
        };
    }

    onMapExtendClick = () => {
        this.setState(prevState => {
            return {
                isMapExtended: !prevState.isMapExtended,
            };
        });
    };

    setClickedDriverId = id => {
        this.setState({clickedDriverId: id});
    };

    render() {
        const {drivers, contractPartners, translate} = this.props;

        const selectedVps = Object.values(contractPartners);
        const shouldRenderDriverView = !!Object.keys(contractPartners).length && drivers && drivers.length;
        const {isMapExtended} = this.state;
        const driversSectionClass = classnames('col-xs--6', {
            'col-start-xs--7': isMapExtended,
        });
        const driversLocations = !!drivers.length && drivers
            .filter(driver => {
                return driver?.location?.lat && driver?.location?.lng;
            })
            .map(driver => {
                const {location} = driver;
                return {
                    id: driver.id,
                    status: driver.userActivityStatus,
                    firstName: driver.firstName,
                    lastName: driver.lastName,
                    phoneNumber: driver.phoneNumber,
                    location,
                };
            });

        const vpLocations = !!Object.values(contractPartners).length && Object.values(contractPartners).filter(vp => {
            return vp?.location?.lat && vp?.location?.lng;
        }).map(vp => {
            const {location} = vp;
            return {
                id: vp.id,
                lat: vp.location.lat,
                lng: vp.location.lng,
                location,
            };
        });

        const mapMarkers = [
            ...(driversLocations.length ? driversLocations.map(driver => driver.location) : []),
            ...(vpLocations.length ? vpLocations : []),
        ];

        const sortedDrivers = sortDrivers(drivers);
        return (
            <AppLayoutView>
                {shouldRenderDriverView
                    ? (
                        <div className="ace-driver-status-view">
                            <div className="ace-grid__row ace-bottom-margin--md">
                                <div className="col-xs--12">
                                    <h4 className="ace-h4">
                                        {translate('driver_status_view.section_title.driver_overview')}
                                    </h4>
                                </div>
                            </div>
                            <div className={isMapExtended ? 'ace-driver-assignment-map__overlay' : ''} />
                            <div className="ace-grid__row">
                                <MapContainer
                                    isMapExtended={isMapExtended}
                                    onMapExtendClick={this.onMapExtendClick}
                                >
                                    <DriversMap markers={mapMarkers}>
                                        {window.google && !!vpLocations.length && vpLocations.map(vpLocation => (
                                            <Marker
                                                key={vpLocation.id}
                                                position={vpLocation.location}
                                                icon={{
                                                    url: '/assets/icons/home-marker.svg',
                                                    scaledSize: new window.google.maps.Size(72, 72),
                                                }}
                                                zIndex={1}
                                            />
                                        ))}
                                        {!!driversLocations.length && driversLocations.map(driver => {
                                            const props = {};
                                            if (driver.id === this.state.clickedDriverId) {
                                                props.clickedDriverId = this.state.clickedDriverId;
                                            }
                                            return (
                                                <DriverMarker
                                                    key={driver.id}
                                                    driver={driver}
                                                    setClickedDriverId={this.setClickedDriverId}
                                                    {...props}
                                                />
                                            );
                                        })}
                                        {!!selectedVps.length && selectedVps.map(selectedVp => (
                                            selectedVp?.polygon?.length && (
                                                <Polygon
                                                    key={selectedVp.id}
                                                    paths={selectedVp.polygon}
                                                    options={mapSettings.polygonOptions}
                                                />
                                            )))}
                                    </DriversMap>
                                </MapContainer>
                                <div className={driversSectionClass}>
                                    <p className="ace-copy-s ace-bottom-margin--sm">
                                        <b>{translate('driver_status_view.field_title.driver_status')}</b>
                                    </p>
                                    <DriverList>
                                        {!!sortedDrivers.length && sortedDrivers.map(driver => {
                                            return (
                                                <DriverRow
                                                    key={driver.id}
                                                    driver={driver}
                                                    onRowClick={this.setClickedDriverId}
                                                    selectedDriverId={this.state.clickedDriverId}
                                                />
                                            );
                                        })}
                                    </DriverList>
                                </div>
                            </div>
                        </div>
                    ) : <ScreenMessage messageParagraphs={[translate('global.screen_message.loading')]} isLoading />}
            </AppLayoutView>
        );
    }
}

const mapStateToProps = state => {
    const getDrivers = createCurrentUsersSelector();
    return {
        drivers: getDrivers(state),
        contractPartners: state.vpContactReducer.contractPartners,
    };
};

export default connect(mapStateToProps, null)(withTranslations(DriverStatusView));
