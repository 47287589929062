import moment from 'moment/moment';

export const isStartDateBeforeEndDate = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return true;
    }

    return moment(endDate).isAfter(moment(startDate), 'day');
};

export const isStartDateEqualEndDate = (startDate, endDate, startTime, endTime) => {
    const startDateString = getDateStringFromDate(startDate);
    const endDateString = getDateStringFromDate(endDate);
    const startTimeString = formatTimeFromTimeObject(startTime);
    const endTimeString = formatTimeFromTimeObject(endTime);

    if (!startDateString || !endDateString) return false;

    return `${startDateString} ${startTimeString}` === `${endDateString} ${endTimeString}`;
};

export const getDateStringFromDate = date => {
    if (!date) return '';
    const momentDate = moment(date);
    if (!momentDate.isValid()) return '';
    return momentDate.format('DD.MM.YYYY');
};

export const formatTimeFromTimeObject = timeObject => {
    const hours = timeObject?.hours
        ? timeObject.hours.length === 1
            ? `0${timeObject.hours}` : timeObject.hours
        : '00';
    const minutes = timeObject?.minutes
        ? timeObject.minutes.length === 1
            ? `0${timeObject.minutes}` : timeObject.minutes
        : '00';
    return `${hours}:${minutes}`;
};
