import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Parser} from 'html-to-react';
import {resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import './DriverAssignmentSearchResultsView.scss';
import routePaths from '../routePaths';
import {DEFAULT_DATE_FORMAT} from './constants/dates';
import {Icon, CaseStatePill, SearchTableBody, SearchRow, SearchDataCell} from '../ui-components';
import {TableCell} from '../ui-components/table';
import {highlightResults} from '../utils/searchResults';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import {dafMainServiceIcons} from './constants/driverAssignmentServices';
import {dafStatusPillTypes} from './constants/driverAssignmentStatuses';

const DriverAssignmentSearchResultsView = props => {
    const {searchResults, searchTerm, isSearchPending} = props;
    const rowClass = classnames('search-results-view__table-row', {
        'search-results-view__table-row--dispatch': true,
    });
    const HTMLParser = new Parser();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateView = createTranslateShorthand('driver_assignments_search_results');

    return (
        <Fragment>
            {!isSearchPending && (searchResults.length ? (
                <SearchTableBody>
                    {searchResults.map(driverAssignment => {
                        const memberNo = driverAssignment.member.membershipNumber;
                        const licencePlate = driverAssignment.vehicle.licensePlate
                            ? driverAssignment.vehicle.licensePlate.replace(/^(.*)-$/, '$1')
                            : '-';
                        const linkTo = resolveRoute(routePaths.DISPATCH_DRIVER_ASSIGNMENT, {
                            driverAssignmentId: driverAssignment.assignmentId,
                        });
                        const date = driverAssignment.emergencyCallDateTime
                            ? driverAssignment.emergencyCallDateTime.format(DEFAULT_DATE_FORMAT) : '---';

                        return (
                            <SearchRow
                                key={driverAssignment.assignmentId}
                                className={rowClass}
                                linkTo={linkTo}
                            >
                                <TableCell className="col-xs--2 search-results-view__table-cell">
                                    <CaseStatePill type={dafStatusPillTypes[driverAssignment.status]}>
                                        {translate(`global.daf_status_pill_text.${driverAssignment.status.toLowerCase()}`)}
                                    </CaseStatePill>
                                </TableCell>
                                <TableCell className="col-xs--4 search-results-view__table-cell">
                                    {driverAssignment.serviceType
                                        ? (
                                            <Fragment>
                                                <div className="search-results-view__service-img">
                                                    <Icon dimension="lg" iconName={dafMainServiceIcons[driverAssignment.serviceType]} color="grey" />
                                                </div>
                                                <p className="search-results-view__value">
                                                    {translate(`global.daf_main_services.${driverAssignment.serviceType.toLowerCase()}`) || '---'}
                                                </p>
                                            </Fragment>
                                        ) : <span className="ace-copy-m">---</span>}
                                </TableCell>
                                <TableCell className="col-xs--2 search-results-view__table-cell search-results-view__table-cell--space-between">
                                    <SearchDataCell label={translateView('search_data_cell_label.case_number')}>
                                        {
                                            HTMLParser
                                                .parse(highlightResults(searchTerm, driverAssignment.assignmentId))
                                            }
                                    </SearchDataCell>
                                    <SearchDataCell label={translateView('search_data_cell_label.license_plate')}>
                                        {licencePlate || '---'}
                                    </SearchDataCell>
                                    <SearchDataCell label={translateView('search_data_cell_label.postal_code')}>
                                        {driverAssignment.postalCode || '---'}
                                    </SearchDataCell>
                                </TableCell>
                                <TableCell className="col-xs--2 search-results-view__table-cell search-results-view__table-cell--space-between">
                                    <SearchDataCell label={translateView('search_data_cell_label.date')}>
                                        {date}
                                    </SearchDataCell>
                                    <SearchDataCell label={translateView('search_data_cell_label.member_number')}>
                                        {HTMLParser.parse(highlightResults(searchTerm, memberNo))}
                                    </SearchDataCell>
                                    <SearchDataCell label={translateView('search_data_cell_label.member')}>
                                        {driverAssignment.member.name || '---'}
                                    </SearchDataCell>
                                </TableCell>
                                <TableCell className="col-xs--2 search-results-view__table-cell search-results-view__table-cell--space-between">
                                    <SearchDataCell label={translateView('search_data_cell_label.payment_proposal_number')}>
                                        {HTMLParser
                                            .parse(highlightResults(searchTerm, driverAssignment.paymentProposalNo))
                                        }
                                    </SearchDataCell>
                                    <SearchDataCell label={translateView('search_data_cell_label.assigned')}>
                                        {date}
                                    </SearchDataCell>
                                    <SearchDataCell label={translateView('search_data_cell_label.driver')}>
                                        {HTMLParser
                                            .parse(highlightResults(searchTerm, driverAssignment.driverFirstName))}
                                    </SearchDataCell>
                                </TableCell>
                            </SearchRow>
                        );
                    })}
                </SearchTableBody>
            ) : (
                <ScreenMessage
                    messageParagraphs={[
                        translate('global.screen_message.no_order'),
                        translate('global.screen_message.check_input'),
                        translate('global.screen_message.note'),
                    ]}
                />
            ))}
            {isSearchPending
                && (<ScreenMessage messageParagraphs={[translate('global.screen_message.search')]} isLoading />)}
        </Fragment>
    );
};

DriverAssignmentSearchResultsView.propTypes = {
    searchResults: PropTypes.array,
    searchTerm: PropTypes.string,
    isSearchPending: PropTypes.bool,
};

DriverAssignmentSearchResultsView.defaultProps = {
    searchTerm: '',
    isSearchPending: false,
    searchResults: null,
};

export default DriverAssignmentSearchResultsView;
