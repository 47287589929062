import React from 'react';
import {useTranslate} from '@computerrock/formation-i18n';
import AppLayoutView from './AppLayoutView';
import './Maintenance.scss';

const Maintenance = () => {
    const {createTranslateShorthand} = useTranslate();
    const translateMaintenance = createTranslateShorthand('maintenance');

    return (
        <AppLayoutView>
            <div className="maintenance-wrapper">
                <div className="maintenance__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                        <g fill="none" fillRule="evenodd" stroke="#E2001A" strokeLinejoin="round" strokeWidth="4">
                            <path d="M33.112 34v24.052H90V69.93l-12.001-.001L78 70a8 8 0 0 1-7.75 7.996L70 78a8 8 0 0 1-8-8.072H32L32 70a8 8 0 0 1-7.75 7.996L24 78a8 8 0 0 1-8-8v-.072h-6v-24.09C10 38.332 17.372 34 22.61 34h10.502z" />
                            <path d="M48.306 38.13L77 20 48.306 38.13l-4.11 19.835z" />
                            <path strokeLinecap="round" d="M82 43.677C82 46.993 80.53 50 77.312 50c-3.219 0-5.486-2.012-5.301-6.323.184-4.312 5.3-5.4 5.3-5.4V20" />
                        </g>
                    </svg>
                </div>
                <h3 className="ace-h3 ace-h3--center">{translateMaintenance('section_title.maintenance')}<br />
                    {translateMaintenance('section_message.maintenance_on')}<br />
                    {translateMaintenance('section_message.try_later')}<br />
                    {translateMaintenance('section_message.signature')}
                </h3>
            </div>
        </AppLayoutView>
    );
};

export default Maintenance;
