import {immerable} from 'immer';
import moment from 'moment';
import Entity from '../entity/Entity';
import eafMessageOriginator from './eafMessageOriginator';


/**
 * Message entity type definition
 *
 * @typedef {Object} Message
 * @property {?string} invoiceSubmissionId
 * @property {?string} messageId
 * @property {?string} originator
 * @property {?boolean} isRead
 * @property {?string} createAt
 * @property {?string} author
 */

/**
 * Message entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const messageEntityDTOMapping = {
    invoiceSubmissionId: {
        dtoProperty: 'invoiceSubmissionId',
        defaultValue: '',
    },
    messageId: {
        dtoProperty: 'messageId',
        defaultValue: '',
    },
    text: {
        dtoProperty: 'text',
        defaultValue: '',
    },
    originator: {
        dtoProperty: 'originator',
        defaultValue: eafMessageOriginator.NAV,
    },
    isRead: {
        dtoProperty: 'isRead',
        defaultValue: false,
    },
    createdAt: {
        dtoProperty: 'createdAt',
        fromDTO: date => (date ? moment(date) : null),
    },
    author: {
        dtoProperty: 'author',
        defaultValue: '',
    },
    updatedAt: {
        dtoProperty: 'updatedAt',
        fromDTO: date => (date ? moment(date) : null),
    },
};

/**
 * Message entity
 *
 * @class
 * @extends Entity
 * @type {Message}
 */
export default class Message extends Entity {
    [immerable] = true;

    static entityDTOMapping = messageEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
