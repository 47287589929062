import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Checkbox, Input, Select} from '../../ui-components';
import './MemberForm.scss';
import SelectOption from '../../ui-components/select/SelectOption';
import {CountriesList} from '../constants/countries';
import {PersonnelTypes} from '../constants/personnelTypes';


const sortCountries = translate => {
    const listCountries = {};
    Object.keys(CountriesList).forEach(countryCode => (
        listCountries[countryCode] = translate(`global.countries.${countryCode.toLowerCase()}`)
    ));
    return Object.entries(listCountries)
        .sort((a, b) => a[1].localeCompare(b[1]))
        .reduce((_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
        }), {});
};

const MemberForm = props => {
    const {onDataChange, isMemberForm, isForeignInsurance, isDamageEventCreatedManually, inputData, title} = props;
    const {isDisplayedOwnerForm, isDisplayedDriverForm, onChangeOwnerCheckBox, onChangeDriverCheckBox} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('member_form');
    const sortedCountries = sortCountries(translate);

    const onInputChange = ({name, value}) => {
        onDataChange({
            [name]: value,
        });
    };

    const shouldDisableElement = (isMemberForm && !isForeignInsurance) && !isDamageEventCreatedManually;
    return (
        <div className="member-form__wrapper">
            <div className="ace-grid__row">
                <div className="col-xs--12">
                    <h6 className="ace-h6">{title}</h6>
                </div>
            </div>
            <div className="ace-grid__row member-form__row--first">
                {!isMemberForm && (
                    <div className="col-xs--4">
                        <Select
                            onChange={type => { onDataChange({type}); }}
                            name="type"
                            value={inputData.type}
                            label={translateForm('select_label.member_relation')}
                        >
                            {Object.keys(PersonnelTypes).map(type => {
                                return (
                                    <SelectOption
                                        key={`${title}-${type}`}
                                        optionValue={type}
                                        optionText={PersonnelTypes[type]}
                                    />
                                );
                            })}
                        </Select>
                    </div>
                )}
                <div className="col-xs--4">
                    <Input
                        label={translateForm('input_label.name')}
                        type="text"
                        name="name"
                        value={inputData.name || ''}
                        isDisabled={shouldDisableElement}
                        onChange={onInputChange}
                        additionalAttributes={{maxLength: '50'}}
                    />
                </div>
                {isMemberForm && (
                    <div className="col-xs--4">
                        <Input
                            label={translateForm('input_label.membership_number')}
                            type="text"
                            name="membershipNumber"
                            value={inputData.membershipNumber || ''}
                            isDisabled={!isForeignInsurance}
                            onChange={onInputChange}
                            additionalAttributes={{maxLength: '20'}}
                        />
                    </div>
                )}
            </div>
            <div className="ace-grid__row member-form__row">
                <div className="col-xs--4 member-form__street-number">
                    <Input
                        label={translateForm('input_label.street')}
                        type="text"
                        name="address"
                        value={inputData.address || ''}
                        isDisabled={shouldDisableElement}
                        onChange={onInputChange}
                        additionalAttributes={{maxLength: '50'}}
                    />
                </div>
                <div className="col-xs--2">
                    <Input
                        label={translateForm('input_label.postal_code')}
                        type="text"
                        name="postCode"
                        value={inputData.postCode || ''}
                        isDisabled={shouldDisableElement}
                        onChange={onInputChange}
                        additionalAttributes={{maxLength: '20'}}
                    />
                </div>
                <div className="col-xs--4">
                    <Input
                        label={translateForm('input_label.city')}
                        type="text"
                        name="city"
                        value={inputData.city || ''}
                        isDisabled={shouldDisableElement}
                        onChange={onInputChange}
                        additionalAttributes={{maxLength: '30'}}
                    />
                </div>
            </div>
            <div className="ace-grid__row member-form__row">
                <div className="col-xs--4">
                    <Select
                        label={translateForm('select_label.country')}
                        name="country"
                        isDisabled={shouldDisableElement}
                        value={inputData.country}
                        onChange={country => onDataChange({country})}
                    >
                        {Object.keys(sortedCountries).map(countryCode => {
                            return (
                                <SelectOption
                                    optionValue={countryCode}
                                    optionText={sortedCountries[countryCode]}
                                    key={countryCode}
                                />
                            );
                        })}
                    </Select>
                </div>
                {isMemberForm && (
                    <div className="col-xs--4">
                        <Input
                            label={translateForm('input_label.phone_number')}
                            type="text"
                            name="phone"
                            value={inputData.phone || ''}
                            isDisabled={shouldDisableElement}
                            onChange={onInputChange}
                            additionalAttributes={{maxLength: '20'}}
                        />
                    </div>
                )}
            </div>
            {isMemberForm && (
                <div className="ace-grid__row member-form__checkboxes">
                    <div className="col-xs--12">
                        <div className="member-form__checkbox">
                            <Checkbox
                                isChecked={isDisplayedDriverForm}
                                onChange={onChangeDriverCheckBox}
                            />
                            <label className="member-form__checkbox-label" htmlFor="end-date">
                                <span className="ace-copy-m">{translateForm('checkbox_label.driver_not_member')}</span>
                            </label>
                        </div>
                        <div className="member-form__checkbox">
                            <Checkbox
                                isChecked={isDisplayedOwnerForm}
                                onChange={onChangeOwnerCheckBox}
                            />
                            <label className="member-form__checkbox-label" htmlFor="end-date">
                                <span className="ace-copy-m">{translateForm('checkbox_label.owner_not_member')}</span>
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

MemberForm.propTypes = {
    isMemberForm: PropTypes.bool,
    inputData: PropTypes.object,
    isDisplayedDriverForm: PropTypes.bool,
    isDisplayedOwnerForm: PropTypes.bool,
    onChangeDriverCheckBox: PropTypes.func,
    onChangeOwnerCheckBox: PropTypes.func,
    title: PropTypes.string,
    onDataChange: PropTypes.func,
    isDamageEventCreatedManually: PropTypes.bool,
    isForeignInsurance: PropTypes.bool,
};

MemberForm.defaultProps = {
    isMemberForm: false,
    inputData: {},
    isDisplayedDriverForm: true,
    isDisplayedOwnerForm: true,
    onChangeDriverCheckBox: () => {},
    onChangeOwnerCheckBox: () => {},
    title: '',
    onDataChange: () => {},
    isDamageEventCreatedManually: false,
    isForeignInsurance: false,
};

export default MemberForm;
