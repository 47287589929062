import React from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import './TabLinks.scss';

const TabLink = ({linkTo, tabCount, children}) => {
    return (
        <NavLink
            exact
            className="ace-tab-link__title"
            activeClassName="ace-tab-link__title--active"
            to={linkTo}
        >
            {children}
            {!!tabCount && (
                <span className="ace-tab-link__result-counter">
                    {tabCount}
                </span>
            )}
        </NavLink>
    );
};

TabLink.propTypes = {
    linkTo: PropTypes.string.isRequired,
    tabCount: PropTypes.number,
};

TabLink.defaultProps = {
    tabCount: 0,
};

export default TabLink;
