import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './ToggleButton.scss';

const ToggleButton = props => {
    const {className, isDisabled, label, isChecked, onChange} = props;
    const toggleButtonClassName = classnames('ace-c-toggle', {
        'ace-c-toggle--disabled': isDisabled,
    },
    className);

    return (
        <div className={toggleButtonClassName}>
            <label className="ace-c-toggle__button">
                <input type="checkbox" onClick={() => onChange()} checked={isChecked} onChange={() => {}} />
                <span className="ace-c-toggle__slider" />
            </label>
            <span className="ace-c-toggle__label">{label}</span>
        </div>
    );
};

ToggleButton.propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
};

ToggleButton.defaultProps = {
    isChecked: false,
    className: '',
    isDisabled: false,
    label: '',
};

export default ToggleButton;
