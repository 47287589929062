import React from 'react';
import PropTypes from 'prop-types';
import './MemberDetailRow.scss';

const MemberDetailRow = ({children, label}) => {
    return (
        <div className="ace-c-member-detail-row">
            <h6 className="ace-c-member-detail-row__label">
                {label}
            </h6>
            <div>
                {children}
            </div>
        </div>
    );
};

MemberDetailRow.propTypes = {
    label: PropTypes.string.isRequired,
};

export default MemberDetailRow;
