import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SearchInput.scss';
import Icon from '../icon/Icon';

const KEY_ENTER = 'Enter';

class SearchInput extends React.Component {
    static propTypes = {
        placeholder: PropTypes.any,
        doSearching: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        hasSearchResults: PropTypes.bool,
        isSearchTermCleared: PropTypes.bool.isRequired,
        searchTerm: PropTypes.string,
        isDisabled: PropTypes.bool,
        isLocked: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string,
        error: PropTypes.string,
        additionalAttributes: PropTypes.object,
        isDispatchSearch: PropTypes.bool,
    };

    static defaultProps = {
        placeholder: '',
        isDisabled: false,
        isLocked: false,
        label: '',
        name: '',
        searchTerm: '',
        hasSearchResults: false,
        error: '',
        additionalAttributes: {},
        isDispatchSearch: false,
    };

    constructor(props) {
        super(props);
        const {
            hasSearchResults,
            additionalAttributes,
        } = props;
        this.state = {
            searchTerm: '',
            searchActive: hasSearchResults,
            isFocused: false,
            characterCount: parseInt(additionalAttributes.maxLength, 10),

            // isLabelOnTop: true,
        };
        this.keyReleased = true;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isSearchTermCleared && props.isSearchTermCleared !== state.isSearchTermCleared) {
            return {
                searchTerm: '',
                searchActive: false,
                isSearchTermCleared: props.isSearchTermCleared,
            };
        }
        return {
            isSearchTermCleared: props.isSearchTermCleared,
        };
    }

    onInputFocus = () => {
        const {isLocked, isDisabled} = this.props;
        this.setState({
            isFocused: !isDisabled && !isLocked,
        });
        this.input.focus();
    };

    onInputBlur = () => {
        this.setState(prevState => {
            return {
                isFocused: !!prevState.searchText,
            };
        });
    };

    doSearch = () => {
        const {searchTerm} = this.state;
        const {isDispatchSearch} = this.props;
        if (!searchTerm) {
            return;
        }
        this.setState(
            {
                searchActive: true,
            },
            () => {
                this.props.doSearching({
                    searchTerm,
                    isDispatchSearch,
                });
            },
        );
    };

    onExitSearch = () => {
        this.setState(
            {
                searchActive: false,
                searchTerm: '',
            },
            () => {
                this.props.clearSearchResults();
            },
        );
    };

    onInputKeyUp = () => {
        this.keyReleased = true;
    };

    onInputKeyDown = event => {
        const {key} = event;
        if (key !== KEY_ENTER || !this.keyReleased) {
            return;
        }
        event.preventDefault();
        // ensure we send only one search request per keyDown
        this.keyReleased = false;
        this.doSearch();
    };

    onInputChange = event => {
        const {additionalAttributes} = this.props;
        const {
            target: {value},
        } = event;
        this.setState({
            searchTerm: value,
            searchActive: false,
            characterCount: parseInt(additionalAttributes.maxLength, 10) - value.length,
        }, () => {
            if (!value) {
                this.props.clearSearchResults();
            }
        });
    };

    render() {
        const {isDisabled, isLocked, placeholder, error, name, label, additionalAttributes = {}} = this.props;
        const {searchActive, searchTerm, isFocused, characterCount} = this.state;

        const iconWrapperClassName = classNames('ace-c-search-input__icon-wrapper', {
            'ace-c-search-input__icon-wrapper--disabled': isDisabled,
            'ace-c-search-input__icon-wrapper--locked': isLocked,
        });
        const searchInputClassName = classNames('ace-c-search-input', {
            'ace-c-search-input--disabled': isDisabled,
            'ace-c-search-input--error': error,
            'ace-c-search-input--focused': isFocused,
            'ace-c-search-input--locked': isLocked,
        });
        const errorLabelClassName = classNames('ace-c-search-input__error-label', {
            'ace-c-search-input__error-label--disabled': isDisabled,
        });
        const characterCountString = isFocused && searchTerm ? `${characterCount}/${additionalAttributes.maxLength} ` : '';

        return (
            <div className="ace-c-search-input__wrapper">
                <div className={iconWrapperClassName} onClick={!searchActive ? this.doSearch : this.onExitSearch}>
                    {!searchActive
                        ? (<Icon dimension="xl" iconName="magnifying-glass" />)
                        : (<Icon dimension="xl" iconName="close" color="white" />)
                    }
                </div>
                <input
                    className={searchInputClassName}
                    ref={input => {
                        this.input = input;
                    }}
                    name={name}
                    type="text"
                    value={searchTerm === '' && searchActive ? this.onExitSearch : searchTerm}
                    placeholder={placeholder}
                    onChange={this.onInputChange}
                    disabled={isDisabled}
                    onKeyDown={this.onInputKeyDown}
                    onKeyUp={this.onInputKeyUp}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    {...additionalAttributes}
                />
                <div className="ace-c-search-input__label">
                    {`${characterCountString}${label}`}
                </div>
                {error && (
                    <div className={`${errorLabelClassName}`}>{error}</div>
                )}
            </div>
        );
    }
}

export default SearchInput;
