import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './IconOption.scss';
import Icon from '../icon/Icon';
import ToastMessage from '../toast-message/ToastMessage';

class IconOption extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        errorName: PropTypes.string.isRequired,
        iconName: PropTypes.string.isRequired,
        isSelected: PropTypes.bool,
        onChange: PropTypes.func,
        isDisabled: PropTypes.bool,
        isStrikeIcons: PropTypes.bool,
        errors: PropTypes.any,
        toastMessage: PropTypes.string,
        toastMessageClassName: PropTypes.string,
        handleToastMessage: PropTypes.func,
        dimension: PropTypes.string,
    };

    static defaultProps = {
        isSelected: false,
        onChange: () => {},
        handleToastMessage: () => {},
        isDisabled: false,
        isStrikeIcons: false,
        errors: {},
        toastMessage: '',
        toastMessageClassName: '',
        dimension: '',
    };

    onSelectService = () => {
        return this.props.onChange();
    };

    render() {
        const {name, iconName, isSelected, isDisabled, isStrikeIcons, errors, errorName, dimension} = this.props;
        const {toastMessage, toastMessageClassName, handleToastMessage} = this.props;

        const isClickableIcon = isSelected ? 'white' : 'red';
        const fill = isDisabled && !isSelected ? '' : isClickableIcon;
        const classNameImage = isStrikeIcons
            ? classNames('ace-c-icon-option__icon-strike', {
                'ace-c-icon-option__icon-strike--selected': isSelected,
                'ace-c-icon-option__icon-strike--disabled': isDisabled,
                'ace-c-icon-option__icon-strike--selected-disabled': isSelected && isDisabled,
                'ace-c-icon-option__icon-strike--errors': errors[errorName],
            })
            : classNames('ace-c-icon-option__icon', {
                'ace-c-icon-option__icon--selected': isSelected,
                'ace-c-icon-option__icon--disabled': isDisabled,
                'ace-c-icon-option__icon--selected-disabled': isSelected && isDisabled,
                'ace-c-icon-option__icon--errors': errors[errorName],
            });
        return (
            <div className="ace-c-icon-option">
                <div className={classNameImage} onClick={this.onSelectService}>
                    {isStrikeIcons || dimension === 'xxl'
                        ? <Icon dimension="xxl" iconName={iconName} color={fill} />
                        : <Icon dimension="lg" iconName={iconName} color={fill} />
                    }
                </div>
                <div className="ace-c-icon-option__text-wrapper ace-copy-m">
                    {name}
                    <ToastMessage
                        className={toastMessageClassName}
                        isEnabled={toastMessage}
                        handleToastMessage={handleToastMessage}
                    >
                        { toastMessage }
                    </ToastMessage>
                </div>
            </div>
        );
    }
}
export default IconOption;
