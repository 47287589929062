import {immerable} from 'immer';
import Entity from '../entity/Entity';

/**
 * InvoiceSubmissionMainService entity type definition
 *
 * @typedef {Object} InvoiceSubmissionMainService
 * @property {?string} type
 * @property {?number} subtotal
 * @property {?boolean} isPrincipalService
 */

/**
 * InvoiceSubmissionMainService entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const InvoiceSubmissionMainServiceEntityDTOMapping = {
    type: {
        dtoProperty: 'type',
        defaultValue: '',
    },
    subtotal: {
        dtoProperty: 'subtotal',
        defaultValue: '',
    },
    isPrincipalService: {
        dtoProperty: 'isPrincipalService',
        defaultValue: false,
    },
};

/**
 * InvoiceSubmissionMainService entity
 *
 * @class
 * @extends Entity
 * @type {InvoiceSubmissionMainService}
 */
export default class InvoiceSubmissionMainService extends Entity {
    [immerable] = true;

    static entityDTOMapping = InvoiceSubmissionMainServiceEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
