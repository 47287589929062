export const availabilityStatuses = {
    BUSY: 'BUSY',
    UNAVAILABLE: 'UNAVAILABLE',
    AVAILABLE: 'AVAILABLE',
};

export const driversAvailability = {
    AVAILABLE: {
        type: 'AVAILABLE',
        status: availabilityStatuses.AVAILABLE,
        icon: 'check-circle',
        order: 1,
    },
    BUSY_APPROACHING: {
        type: 'BUSY_APPROACHING',
        status: availabilityStatuses.BUSY,
        icon: 'busy-approaching',
        order: 6,
    },
    BUSY_IN_SERVICE: {
        type: 'BUSY_IN_SERVICE',
        status: availabilityStatuses.BUSY,
        icon: 'in-service-status',
        order: 4,
    },
    BUSY_TOWING: {
        type: 'BUSY_TOWING',
        status: availabilityStatuses.BUSY,
        icon: 'busy-towing',
        order: 3,
    },
    BUSY_RECOVERY: {
        type: 'BUSY_RECOVERY',
        status: availabilityStatuses.BUSY,
        icon: 'busy-recovery',
        order: 5,
    },
    BUSY_SERVICE_FINISHED: {
        type: 'BUSY_SERVICE_FINISHED',
        status: availabilityStatuses.BUSY,
        icon: 'home',
        order: 2,
    },
    UNAVAILABLE: {
        type: 'UNAVAILABLE',
        status: availabilityStatuses.UNAVAILABLE,
        icon: 'unavailable',
        order: 7,
    },
};
