export const createInvoiceSubmissionPreCheckRadioOptions = {
    yes: {
        text: 'global.answer.yes',
        type: true,
    },
    no: {
        text: 'global.answer.no',
        type: false,
    },
};

export const createInvoiceSubmissionPreCheckRadioOptionsLast = {
    yes: {
        text: 'global.answer.yes_or_do_not_know',
        type: true,
    },
    no: {
        text: 'global.answer.no',
        type: false,
    },
};

export const createInvoiceSubmissionPreCheckSteps = [
    {
        step: 0,
        questionText: 'create_invoice_submission_view.question_text.step_1',
        correctOption: false,
        suggestionText: 'create_invoice_submission_view.suggestion_text.step_1',
    },
    {
        step: 1,
        questionText: 'create_invoice_submission_view.question_text.step_2',
        correctOption: true,
        suggestionText: 'create_invoice_submission_view.suggestion_text.step_2',
    },
    {
        step: 2,
        questionText: 'create_invoice_submission_view.question_text.step_3',
        correctOption: false,
        suggestionText: 'create_invoice_submission_view.suggestion_text.step_3',
    },
    {
        step: 3,
        questionText: 'create_invoice_submission_view.question_text.step_4',
        correctOption: false,
        suggestionText: 'create_invoice_submission_view.suggestion_text.step_4',
    },
];

export const createInvoiceSubmissionStatuses = {
    IDLE: 'IDLE',
    STARTED: 'STARTED',
    MEMBERSHIP_CHECK_PENDING: 'MEMBERSHIP_CHECK_PENDING',
    INVOICE_SUBMISSION_PENDING: 'INVOICE_SUBMISSION_PENDING',
};
