import qs from 'qs';
import handleResponse from './handleResponse';
import resourceEndpoints from './resourceEndpoints';

/**
 * ECS Driver locator (DL) microservice client
 *
 * @constructor
 */
export default class MSClientACEDriverLocatorDL {
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            // ensure SERVICE_URL has trailing slash
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/') : '',
        };

        // add SERVICE_URL to each resource endpoint
        this.resourceEndpoints = Object.keys(resourceEndpoints)
            .reduce((parsedResourceEndpoints, endpointKey) => {
                parsedResourceEndpoints[endpointKey] = this.serviceParameters.SERVICE_URL
                    + resourceEndpoints[endpointKey];
                return parsedResourceEndpoints;
            }, {});

        return {
            setAccessToken: this.setAccessToken,
            getDriversLocations: this.getDriversLocations,
        };
    }

    accessToken = null;

    getDriversLocations = params => {
        const {partnerIds} = params;
        const query = {
            partnerIds,
        };

        const queryString = qs.stringify(query, {
            arrayFormat: 'comma',
        });

        const urlPath = this.resourceEndpoints['LOCATIONS'];
        return fetch(
            `${urlPath}?${queryString}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`,
                },
                method: 'GET',
            },
        )
            .then(handleResponse);
    };

    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };
}

