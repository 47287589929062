import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import * as actionTypes from './userManagementActionTypes';
import {TableHeader, TablePanel, TableBody, TableHeaderCell, TableRow, TableCell} from '../ui-components/table';
import './ManageUsersView.scss';
import AppLayout from '../application/AppLayoutView';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import {ButtonPrimary} from '../ui-components';
import ManageUsersPopUps from './view-elements/ManageUsersPopUps';
import * as userSelectors from './userManagementSelectors';


const ManageUsersView = props => {
    const {users, startCreateUserFlow, startUpdateUserFlow, startDeleteUserFlow} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateView = createTranslateShorthand('manage_users_view');
    return (
        <AppLayout>
            <Fragment>
                <div className="ace-manage-users-view__header">
                    <h3 className="ace-h3">
                        {translateView('section_title.user_management')}
                    </h3>
                </div>
                <TablePanel>
                    <TableHeader className="ace-bottom-margin--sm">
                        <TableHeaderCell
                            title={translateView('table_header.name')}
                            className="col-sm--3 col-md--4"
                        />
                        <TableHeaderCell
                            title={translateView('table_header.email')}
                            className="col-sm--4"
                        />
                        <TableHeaderCell
                            title={translateView('table_header.role')}
                            className="col-sm--2"
                        />
                    </TableHeader>
                    {users && users.length > 0 ? (
                        <TableBody className="ace-manage-users-view__table-body">
                            {users
                                .sort((userA, userB) => {
                                    const userAName = userA.firstName || '';
                                    const userBName = userB.firstName || '';
                                    return userAName.localeCompare(userBName);
                                })
                                .map(user => {
                                    const onUserUpdateButtonClick = () => startUpdateUserFlow({userId: user.id});
                                    const onUserDeleteButtonClick = () => startDeleteUserFlow({userId: user.id});
                                    return (
                                        <TableRow linkTo={null} key={user.id} className="ace-manage-users-view__table-row">
                                            <TableCell className="col-sm--3 col-md--4">
                                                <span className="ace-copy-s">{`${user.firstName} ${user.lastName}`}</span>
                                            </TableCell>
                                            <TableCell className="col-sm--4">
                                                <span className="ace-copy-s">{user.email}</span>
                                            </TableCell>
                                            <TableCell className="col-sm--2">
                                                <span className="ace-copy-s">
                                                    {user.userRoles.map(role => {
                                                        return translate(`global.user_roles.${role.toLowerCase()}`);
                                                    }).join(', ')}
                                                </span>
                                            </TableCell>
                                            <TableCell className="col-sm--1">
                                                <p className="ace-link-m" onClick={onUserDeleteButtonClick}>
                                                    {translateView('table_cell.extinguish')}
                                                </p>
                                            </TableCell>
                                            <TableCell className="col-sm--2 col-md--1 ace-c-manage-users-view__link">
                                                <p className="ace-link-m" onClick={onUserUpdateButtonClick}>
                                                    {translateView('table_cell.edit')}
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    ) : (
                        <ScreenMessage
                            messageParagraphs={[translate('global.screen_message.loading')]}
                            isLoading
                        />
                    )}
                </TablePanel>
                <div className="ace-grid__row ace-manage-users-view__button-wrapper">
                    <div className="col-start-sm--5 col-sm--4">
                        <ButtonPrimary
                            name="btnNewUser"
                            label={translateView('button_label.create_new_user')}
                            onClick={startCreateUserFlow}
                            icon="plus--white"
                        />
                    </div>
                </div>
                <ManageUsersPopUps />
            </Fragment>
        </AppLayout>
    );
};

ManageUsersView.propTypes = {
    users: PropTypes.array.isRequired,
    startCreateUserFlow: PropTypes.func.isRequired,
    startUpdateUserFlow: PropTypes.func.isRequired,
    startDeleteUserFlow: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const getUsers = userSelectors.createCurrentUsersSelector();
    return {
        users: getUsers(state),
    };
};

const mapDispatchToProps = dispatch => ({
    startCreateUserFlow: payload => dispatch({type: actionTypes.START_CREATE_USER_FLOW, payload}),
    startUpdateUserFlow: payload => dispatch({type: actionTypes.START_UPDATE_USER_FLOW, payload}),
    startDeleteUserFlow: payload => dispatch({type: actionTypes.START_DELETE_USER_FLOW, payload}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManageUsersView);
