import moment from 'moment/moment';

const DURATION_NOT_AVAILABLE = '-';
const DATES_NOT_VALID = 'n.v.';

const validateStartAndEndDateTime = dateTimeInfo => {
    const {
        startDate,
        startTime: {
            hours: startHours,
            minutes: startMinutes,
        },
        endDate,
        endTime: {
            hours: endHours,
            minutes: endMinutes,
        },
    } = dateTimeInfo;

    // validate date fields
    return startDate && endDate && startHours && startMinutes && endHours && endMinutes;
};

const getValueWithSuffixOrEmptyString = (value, suffix) => {
    if (!value) {
        return '';
    }
    return `${value} ${suffix}`;
};

export const calculateDuration = dateTimeInfo => {
    const {startDate, startTime, endDate, endTime} = dateTimeInfo;
    if (!validateStartAndEndDateTime(dateTimeInfo)) {
        return DURATION_NOT_AVAILABLE;
    }
    const sd = new Date(startDate);
    const ed = new Date(endDate);
    sd.setHours(startTime.hours);
    sd.setMinutes(startTime.minutes);
    ed.setHours(endTime.hours);
    ed.setMinutes(endTime.minutes);
    const diff = moment(ed).diff(moment(sd)); // millisecond difference between datetimes
    if (diff >= 0) {
        const duration = moment.duration(diff);
        const years = duration.years(diff);
        const months = duration.months(diff);
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        const fr = getValueWithSuffixOrEmptyString;
        return `${fr(years, 'J.')} ${fr(months, 'M.')} ${fr(days, 'T.')} ${fr(hours, 'Std.')} ${fr(minutes, 'Min.')}`
            .trim().replace(/\s+/g, ' ');
    }

    return DATES_NOT_VALID;
};
