import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import withDriverAssignmentSearch from './view-elements/withDriverAssignmentSearch';
import AppLayout from '../application/AppLayoutView';
import DriverAssignmentsPanel from './DriverAssignmentsPanel';
import {SearchInput} from '../ui-components';
import * as driverAssignmentsSelectors from './driverAssignmentSelectors';
import {getDispatchRowDefinition} from './utils/table';
import DriverAssignmentSearchResultsView from './DriverAssignmentSearchResultsView';
import './DriverAssignmentsView.scss';


const rowDefinition = getDispatchRowDefinition(false);

const AssignedDriverAssignmentsView = props => {
    const {numberOfAssignedCases, driverAssignments, isLoading} = props;
    const {driverAssignmentSearchResults, isSearchPending, isSearchTermCleared, searchTerm} = props;
    const {searchDriverAssignments, clearSearchResults, isSearchViewActive} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateView = createTranslateShorthand('driver_assignments_assigned_assignment_view');

    return (
        <AppLayout>
            <div className="ace-driver-assignments-view">
                <div className="ace-grid__row ace-driver-assignments-view__header">
                    <div className="col-start-xs--3 col-xs--8 col-start-md--4 col-md--6">
                        <SearchInput
                            hasSearchResults={isSearchViewActive
                                && driverAssignmentSearchResults.length === 0}
                            placeholder={translateView('search_input_placeholder.search_term')}
                            label={translateView('search_input_label.search_term')}
                            isSearchTermCleared={isSearchTermCleared}
                            doSearching={searchDriverAssignments}
                            clearSearchResults={clearSearchResults}
                            additionalAttributes={{maxLength: '50'}}
                            isDispatchSearch
                        />
                    </div>
                </div>
                {!isSearchViewActive ? (
                    <DriverAssignmentsPanel
                        rowDefinition={rowDefinition}
                        driverAssignments={driverAssignments}
                        emptyResultMessage={[translateView('empty_results.message')]}
                        numberOfAssignedCases={numberOfAssignedCases}
                        isLoading={isLoading}
                    />
                ) : (
                    <DriverAssignmentSearchResultsView
                        searchResults={driverAssignmentSearchResults}
                        isSearchPending={isSearchPending}
                        searchTerm={searchTerm}
                    />
                )}
            </div>
        </AppLayout>
    );
};

AssignedDriverAssignmentsView.propTypes = {
    numberOfAssignedCases: PropTypes.number,
    driverAssignments: PropTypes.array,
    driverAssignmentSearchResults: PropTypes.array,
    isSearchPending: PropTypes.bool,
    isSearchTermCleared: PropTypes.bool,
    searchDriverAssignments: PropTypes.func.isRequired,
    clearSearchResults: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    isSearchViewActive: PropTypes.bool.isRequired,
};

AssignedDriverAssignmentsView.defaultProps = {
    numberOfAssignedCases: 0,
    driverAssignments: null,
    driverAssignmentSearchResults: null,
    isSearchPending: false,
    isSearchTermCleared: false,
    searchTerm: '',
};

const mapStateToProps = state => {
    const getDriverAssignments = driverAssignmentsSelectors.createAssignedDriverAssignmentsSelector();
    return {
        driverAssignments: getDriverAssignments(state),
        numberOfAssignedCases: state.driverAssignments.totalCount,
        isLoading: state.driverAssignments.isLoading,
    };
};

export default withDriverAssignmentSearch(connect(mapStateToProps)(AssignedDriverAssignmentsView));
