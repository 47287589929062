import {all, fork} from 'redux-saga/effects';
import submitSupportFormFlow from './sagas/submitSupportFormFlow';

const supportWatcher = function* supportWatcher() {
    yield all([
        fork(submitSupportFormFlow),
    ]);
};

export default supportWatcher;
