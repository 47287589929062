import React from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Icon} from '../../ui-components';
import './Pagination.scss';

const Pagination = ({onPreviousClick, onNextClick, currentPage, totalPages, goToPage, links}) => {
    const {createTranslateShorthand} = useTranslate();
    const translatePagination = createTranslateShorthand('pagination');

    return (
        <div className="pagination ace-bottom-margin--md">
            <a href={links.firstPage} className="pagination__text pagination__text--link" onClick={e => goToPage(e, 1)}>
                {translatePagination('link_text.first_page')}
            </a>
            <a
                href={links.prevPage}
                className="pagination__arrow pagination__arrow--left"
                onClick={onPreviousClick}
            >
                <Icon dimension="xs" iconName="expand-arrow" />
            </a>
            <span className="pagination__text">{translatePagination('text.page')} </span>
            <span className="pagination__text">{currentPage} </span>
            <span className="pagination__text">{translatePagination('text.from')} </span>
            <span className="pagination__text">{totalPages}</span>
            <a
                href={links.nextPage}
                className="pagination__arrow pagination__arrow--right"
                onClick={onNextClick}
            >
                <Icon dimension="xs" iconName="expand-arrow" />
            </a>
            <a
                href={links.lastPage}
                className="pagination__text pagination__text--link"
                onClick={e => goToPage(e, totalPages)}
            >  {translatePagination('link_text.last_page')}
            </a>
        </div>
    );
};

Pagination.propTypes = {
    onPreviousClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    goToPage: PropTypes.func.isRequired,
    links: PropTypes.object.isRequired,
};

Pagination.defaultProps = {
    currentPage: 1,
    totalPages: 1,
};

export default Pagination;
