import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './CaseStatePill.scss';

const CaseStatePill = ({type, className, children}) => {
    const statusClass = classnames('ace-c-case-state-pill', {
        'ace-c-case-state-pill--red': type === 'red',
        'ace-c-case-state-pill--trans-red': type === 'trans-red',
        'ace-c-case-state-pill--grey': type === 'grey',
        'ace-c-case-state-pill--trans-grey': type === 'trans-grey',
        'ace-c-case-state-pill--font-14': children === 'Bestätigung wartend' || children === 'Leist. abgeschlossen',
    });

    return (
        <div className={`${statusClass} ${className}`}>
            {children}
        </div>
    );
};

CaseStatePill.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['red', 'trans-red', 'grey', 'trans-grey']),
};

CaseStatePill.defaultProps = {
    className: '',
    type: 'red',
};

export default CaseStatePill;
