import qs from 'qs';
import moment from 'moment';

export const NULL_DATE_STRING = '-';

export const SortDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
};

// default is ASC
export const sortTypes = {
    byNumber: (a, b) => {
        return a - b;
    },
    byOrderAndDate: (a, b, secondA, secondB) => {
        if (a === b) {
            return sortTypes.byDate(secondA, secondB);
        }
        return a - b;
    },
    byString: (a, b) => {
        if (!a) {
            return 1;
        }
        if (!b) {
            return -1;
        }
        return a.localeCompare(b);
    },
    byDate: (a, b) => {
        if (a === NULL_DATE_STRING) {
            return -1;
        }
        if (b === NULL_DATE_STRING) {
            return 1;
        }
        const dateA = moment(a);
        const dateB = moment(b);
        if (dateA.isAfter(dateB)) {
            return 1;
        }
        if (dateA.isBefore(dateB)) {
            return -1;
        }
        return 0;
    },
};

export const goToNewSortingDashboard = ({history, location, sortDirection, sortColumnId}) => {
    history.push({
        pathname: location.pathname,
        search: qs.stringify({sortColumnId, sortDirection}),
    });
};

export const reverseSort = sortFunc => {
    return (a, b) => {
        const sortResult = sortFunc(a, b);
        if (sortResult === 0) {
            return sortResult;
        }
        return sortResult * -1;
    };
};

export const getNestedValue = (object, keys) => keys.split('.').reduce((o, k) => o?.[k], object);

export const getSortedTableData = ({tableData, rowDefinition, sortColumnId, sortDirection, secondSortingKey}) => {
    return tableData.sort((first, second) => {
        const definition = rowDefinition[sortColumnId];
        const [sortKey] = Object.keys(definition);
        const reverseFunc = sortDirection === SortDirection.ASC
            ? a => { return a; } : reverseSort;
        const sortFunc = reverseFunc(definition[sortKey]);
        if (sortKey === 'statusSortOrder' && secondSortingKey) {
            // eslint-disable-next-line
            return sortFunc(getNestedValue(first, sortKey), getNestedValue(second, sortKey), getNestedValue(first, secondSortingKey), getNestedValue(second, secondSortingKey));
        }
        return sortFunc(getNestedValue(first, sortKey), getNestedValue(second, sortKey));
    });
};

export const getDispatchRowDefinition = areOpenRows => {
    return [
        {statusSortOrder: sortTypes.byNumber},
        {serviceTypeText: sortTypes.byString},
        {name: sortTypes.byString},
        {damageCity: sortTypes.byString},
        areOpenRows ? {emergencyCallDateTime: sortTypes.byDate} : {driverFirstName: sortTypes.byString},
        {statusSortOrder: sortTypes.byOrderAndDate},
    ];
};
