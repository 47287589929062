import {immerable} from 'immer';
import Entity from '../entity/Entity';
import {Address} from '../address';

/**
 * PickUp entity type definition
 *
 * @typedef {Object} PickUp
 * @property {?Address} address
 * @property {?string} destinationName
 * @property {?string} destinationType
 * @property {?number} distance
 */

/**
 * PickUp entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const pickUpEntityDTOMapping = {
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
    },
    destinationName: {
        dtoProperty: 'destinationName',
        defaultValue: '',
    },
    destinationType: {
        dtoProperty: 'destinationType',
        defaultValue: '',
    },
    distance: {
        dtoProperty: 'distance',
        defaultValue: '',
    },
};

/**
 * PickUp entity
 *
 * @class
 * @extends Entity
 * @type {PickUp}
 */
export default class PickUp extends Entity {
    [immerable] = true;

    static entityDTOMapping = pickUpEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
