import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withTranslations} from '@computerrock/formation-i18n';
import recordPersistenceStates from '../../utils/recordPersistenceStates';
import recordPersistenceOperations from '../../utils/recordPersistenceOperations';
import {ButtonPrimary, ButtonSecondary, Input, CheckboxSelect} from '../../ui-components';
import {UserRoles} from '../../ella-dispo-entity-types';

class UserForm extends Component {
    static propTypes = {
        submitCreateUserForm: PropTypes.func.isRequired,
        endCreateUserFlow: PropTypes.func.isRequired,
        submitUpdateUserForm: PropTypes.func.isRequired,
        endUpdateUserFlow: PropTypes.func.isRequired,
        persistenceOperation: PropTypes.string.isRequired,
        persistenceState: PropTypes.string.isRequired,
        errors: PropTypes.object,
        temporaryUser: PropTypes.object,
        translate: PropTypes.func,
    };

    static defaultProps = {
        temporaryUser: null,
        errors: {},
        translate: null,
    };

    constructor(props) {
        super(props);

        const {temporaryUser} = props;
        this.state = {
            ...(temporaryUser ? {
                email: temporaryUser.email || '',
                emailConfirm: temporaryUser.email || '',
                password: '',
                passwordConfirm: '',
                firstName: temporaryUser.firstName || '',
                userRoles: temporaryUser.userRoles,
                phoneNumber: temporaryUser.phoneNumber || '',
            } : {
                email: '',
                emailConfirm: '',
                firstName: '',
                userRoles: [],
                phoneNumber: '',
            }),
            prevTemporaryUser: temporaryUser, // eslint-disable-line react/no-unused-state
            isFormTouched: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.temporaryUser !== state.prevTemporaryUser) {
            const {temporaryUser} = props;
            return {
                ...(temporaryUser ? {
                    email: temporaryUser.email || '',
                    emailConfirm: temporaryUser.email || '',
                    password: '',
                    passwordConfirm: '',
                    firstName: temporaryUser.firstName || '',
                    userRoles: temporaryUser.userRoles,
                    phoneNumber: temporaryUser.phoneNumber || '',
                } : {
                    email: '',
                    emailConfirm: '',
                    password: '',
                    passwordConfirm: '',
                    firstName: '',
                    userRoles: [],
                    phoneNumber: '',
                }),
                prevTemporaryUser: temporaryUser,
            };
        }

        return null;
    }

    onCancelButtonClick = () => {
        const {endCreateUserFlow, endUpdateUserFlow, persistenceOperation} = this.props;

        if (persistenceOperation === recordPersistenceOperations.CREATE) endCreateUserFlow();
        if (persistenceOperation === recordPersistenceOperations.UPDATE) endUpdateUserFlow();
    };

    onSubmitButtonClick = () => {
        const {submitCreateUserForm, submitUpdateUserForm, persistenceOperation} = this.props;
        const {email, password, firstName, userRoles, phoneNumber} = this.state;
        const payload = {
            email,
            firstName,
            userRoles,
            phoneNumber: phoneNumber || null,
        };

        if (password) payload.password = password;
        if (persistenceOperation === recordPersistenceOperations.CREATE) submitCreateUserForm(payload);
        if (persistenceOperation === recordPersistenceOperations.UPDATE) submitUpdateUserForm(payload);
    };

    onInputChange = ({name, value}) => {
        if (name === 'phoneNumber' && value) {
            const phoneNoRegex = /^([+]?)[0-9]*$/;
            if (!phoneNoRegex.test(value)) return;
        }
        this.setState({
            [name]: value,
            isFormTouched: true,
        });
    };

    onUserRolesChange = (name, type) => {
        this.setState({
            [name]: type,
            isFormTouched: true,
        });
    };

    render() {
        const {persistenceOperation, persistenceState, errors: serverErrors, translate} = this.props;
        const {email, emailConfirm, password, passwordConfirm, firstName, userRoles, phoneNumber} = this.state;
        const {isFormTouched} = this.state;
        const isSubmitDisabled = !email || !emailConfirm || !(emailConfirm === email)
            || (persistenceOperation === recordPersistenceOperations.CREATE
                ? !password || !passwordConfirm || !(passwordConfirm === password) : false)
            || (persistenceOperation === recordPersistenceOperations.UPDATE && !isFormTouched)
            || !(Array.isArray(userRoles) && userRoles.length > 0);
        const isFormDisabled = persistenceState === recordPersistenceStates.PENDING;
        const userRolesOptions = Object.keys(UserRoles).map(key => {
            if (key === UserRoles.ANONYMOUS || key === UserRoles.NAVISION) return;
            return {
                text: translate(`global.user_roles.${key.toLowerCase()}`),
                type: UserRoles[key],
            };
        }).reduce((allRoles, role) => {
            if (!role) return {...allRoles};
            return {
                ...allRoles,
                [UserRoles[role.type]]: role,
            };
        });

        // Parse local errors
        const errors = {
            ...serverErrors,
        };

        if (!!email && !!emailConfirm && !(emailConfirm === email)) {
            errors['emailConfirm'] = translate('global.validation_messages.email_confirm');
        }

        if (!!password && !!passwordConfirm && !(passwordConfirm === password)) {
            errors['passwordConfirm'] = translate('global.validation_messages.password_confirm');
        }

        return (
            <Fragment>
                <div className="col-xs--4 col-start-xs--3">
                    <Input
                        type="text"
                        name="firstName"
                        label={translate('user_form.input_label.name')}
                        value={firstName}
                        onChange={this.onInputChange}
                        error={errors['firstName'] && translate(`global.validation_messages.${errors['firstName']}`)}
                        isDisabled={isFormDisabled}
                        additionalAttributes={{maxLength: '50'}}
                    />
                </div>
                <div className="col-xs--4 ace-bottom-margin--md">
                    <p className="ace-manage-users-view__checkbox-label">
                        {translate('user_form.field_title.role')}
                    </p>
                    <CheckboxSelect
                        onChange={this.onUserRolesChange}
                        options={userRolesOptions}
                        checkboxName="userRoles"
                        values={userRoles}
                        isDisabled={isFormDisabled}
                    />
                </div>
                <div className="col-xs--4 col-start-xs--3 ace-bottom-margin--lg">
                    <Input
                        type="text"
                        name="email"
                        label={translate('user_form.input_label.email')}
                        value={email}
                        onChange={this.onInputChange}
                        error={errors['email'] && translate(`global.validation_messages.${errors['email']}`)}
                        isDisabled={isFormDisabled}
                        additionalAttributes={{maxLength: '100'}}
                    />
                </div>
                <div className="col-xs--4">
                    <Input
                        type="text"
                        name="emailConfirm"
                        label={translate('user_form.input_label.email_repeat')}
                        value={emailConfirm}
                        onChange={this.onInputChange}
                        error={errors['emailConfirm']}
                        isDisabled={isFormDisabled}
                        additionalAttributes={{maxLength: '100'}}
                    />
                </div>
                <div className="col-xs--4 col-start-xs--3 ace-bottom-margin--lg">
                    <Input
                        type="password"
                        name="password"
                        label={translate('user_form.input_label.password')}
                        value={password}
                        onChange={this.onInputChange}
                        error={errors['password'] && translate(`global.validation_messages.${errors['password']}`)}
                        isDisabled={isFormDisabled}
                        additionalAttributes={{maxLength: '100'}}
                    />
                </div>
                <div className="col-xs--4">
                    <Input
                        type="password"
                        name="passwordConfirm"
                        label={translate('user_form.input_label.password_repeat')}
                        value={passwordConfirm}
                        onChange={this.onInputChange}
                        error={errors['passwordConfirm']}
                        isDisabled={isFormDisabled}
                        additionalAttributes={{maxLength: '100'}}
                    />
                </div>
                <div className="col-xs--4 col-start-xs--5 ace-bottom-margin--lg">
                    <Input
                        type="text"
                        name="phoneNumber"
                        label={translate('user_form.input_label.phone_number')}
                        value={phoneNumber}
                        onChange={this.onInputChange}
                        error={errors['phoneNumber'] && translate(`global.validation_messages.${errors['phoneNumber']}`)}
                        isDisabled={isFormDisabled}
                        additionalAttributes={{maxLength: '100'}}
                    />
                </div>
                <div className="col-xs--4 col-start-xs--3 ace-bottom-margin--lg">
                    <ButtonSecondary
                        name="endCreate"
                        label={translate('user_form.button_label.decline')}
                        onClick={this.onCancelButtonClick}
                        isDisabled={isFormDisabled}
                    />
                </div>
                <div className="col-xs--4">
                    <ButtonPrimary
                        name="submitCreate"
                        label={persistenceOperation === recordPersistenceOperations.CREATE
                            ? translate('user_form.button_label.add_user') : translate('user_form.button_label.edit_user')}
                        onClick={this.onSubmitButtonClick}
                        isDisabled={isSubmitDisabled || isFormDisabled}
                    />
                </div>
            </Fragment>
        );
    }
}

export default withTranslations(UserForm);
