import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {assembleAddressStringForDestination} from './utils/address';
import {isPdfFileExtension} from '../utils/files';
import {ButtonPrimary} from '../ui-components';
import pdfIcon from '../assets/images/pdf-icon.png';
import './DriverAssignmentInactiveView.scss';
import {dafStatuses, journeyContinuationTexts} from '../ella-dispo-entity-types';
import * as driverAssignmentActionTypes from './driverAssignmentActions';


const DriverAssignmentInactiveView = ({driverAssignment, isDownloadActive, downloadProofOfService}) => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateView = createTranslateShorthand('driver_assignments_inactive_view');
    const {address: memberAddress, membershipNumber, name: memberName, phoneNumber} = driverAssignment.member || {};
    const {address: driverAddress, name: driverName, type: driverType} = driverAssignment.vehicle.driver || {};
    const {address: ownerAddress, name: ownerName, type: ownerType} = driverAssignment.vehicle.owner || {};
    const {address: towingAddress} = driverAssignment.towing || {};
    const {address: damageAddress} = driverAssignment.damage || {};

    const memberAddressString = assembleAddressStringForDestination({
        street: memberAddress ? memberAddress.street : '',
        postalCode: memberAddress ? memberAddress.postCode : '',
        city: memberAddress ? memberAddress.city : '',
    });
    const driverAddressString = assembleAddressStringForDestination({
        street: driverAddress ? driverAddress.street : '',
        postalCode: driverAddress ? driverAddress.postCode : '',
        city: driverAddress ? driverAddress.city : '',
    });
    const ownerAddressString = assembleAddressStringForDestination({
        street: ownerAddress ? ownerAddress.street : '',
        postalCode: ownerAddress ? ownerAddress.postCode : '',
        city: ownerAddress ? ownerAddress.city : '',
    });
    const damageAddressString = assembleAddressStringForDestination({
        street: damageAddress ? damageAddress.street : '',
        postalCode: damageAddress ? damageAddress.postCode : '',
        city: damageAddress ? damageAddress.city : '',
    });
    const towingAddressString = assembleAddressStringForDestination({
        street: towingAddress ? towingAddress.street : '',
        postalCode: towingAddress ? towingAddress.postCode : '',
        city: towingAddress ? towingAddress.city : '',
    });

    const startTime = driverAssignment.startDate ? `${driverAssignment.startDate.format('HH:mm')} ${translate('global.time.hours')}` : '---';
    const endTime = driverAssignment.endDate ? `${driverAssignment.endDate.format('HH:mm')} ${translate('global.time.hours')}` : '---';
    return (
        <div className="ace-driver-assignment-inactive-view">
            {driverAssignment.status === dafStatuses.BILLABLE && (
                <div className="ace-grid__row">
                    <div className="col-xs--4 col-start-xs--9 col-lg--3 col-start-lg--10 ace-driver-assignment-inactive-view__download-section">
                        <ButtonPrimary
                            label={translateView('button_label.pdf_download')}
                            name="pdfDownload"
                            isDisabled={isDownloadActive}
                            onClick={() => {
                                downloadProofOfService({driverAssignmentId: driverAssignment.assignmentId});
                            }}
                            icon="download--white"
                        />
                    </div>
                </div>
            )}
            <div className="ace-driver-assignment-inactive-view__section">
                <div className="ace-grid__row">
                    <h4 className="ace-h4 col-xs--6 col-start-xs--4 ace-bottom-margin--xmd">
                        {translateView('title_section.member_data')}
                    </h4>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.member_data')}</b>
                    </p>
                    <div className="col-xs--4">
                        <p className="ace-driver-assignment-inactive-view__text">{membershipNumber || '---'}</p>
                        <p className="ace-driver-assignment-inactive-view__text">{memberName || '---'}</p>
                        <p className="ace-driver-assignment-inactive-view__text">
                            {memberAddressString
                                ? `${memberAddressString} ${translate('global.country_code.germany')}`
                                : '---'
                            }
                        </p>
                        <p className="ace-driver-assignment-inactive-view__text">{phoneNumber || '---'}</p>
                    </div>
                </div>
                {driverType !== 'MEMBER' && (
                    <div className="ace-grid__row">
                        <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                            <b>{translateView('field_title.driver')}</b>
                        </p>
                        <div className="col-xs--4">
                            <p className="ace-driver-assignment-inactive-view__text">{driverName || '---'}</p>
                            <p className="ace-driver-assignment-inactive-view__text">{driverAddressString || '---'}</p>
                        </div>
                    </div>
                )}
                {ownerType !== 'MEMBER' && (
                    <div className="ace-grid__row">
                        <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                            <b> {translateView('field_title.vehicle_owner')}</b>
                        </p>
                        <div className="col-xs--4">
                            <p className="ace-driver-assignment-inactive-view__text">{ownerName || '---'}</p>
                            <p className="ace-driver-assignment-inactive-view__text">{ownerAddressString || '---'}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className="ace-driver-assignment-inactive-view__section">
                <div className="ace-grid__row">
                    <h4 className="ace-h4 col-xs--6 col-start-xs--4 ace-bottom-margin--xmd">
                        {translateView('title_section.vehicle_data')}
                    </h4>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.vehicle_manufacturer')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{driverAssignment.vehicle.manufacturer || '---'}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b>  {translateView('field_title.vehicle_model')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{driverAssignment.vehicle.model || '---'}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.vehicle_weight')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">
                        {translate(`global.vehicle_tonnages.${driverAssignment.vehicle.weight.toLowerCase()}`)}
                    </div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b>  {translateView('field_title.vehicle_license_plate')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{driverAssignment.vehicle.licensePlate}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.vehicle_registration')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">
                        {driverAssignment.vehicle.registrationDateTime
                            ? moment(driverAssignment.vehicle.registrationDateTime).seconds(0).format('YYYY') : '---'}
                    </div>
                </div>
            </div>
            <div className="ace-driver-assignment-inactive-view__section">
                <div className="ace-grid__row">
                    <h4 className="ace-h4 col-xs--6 col-start-xs--4 ace-bottom-margin--xmd">
                        {translateView('title_section.performance_data')}
                    </h4>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.date_of_use')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{driverAssignment.emergencyCallDateTime.format('DD.MM.YYYY')}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.period_of_use')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{`${startTime} - ${endTime}`}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.location')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{damageAddressString || '---'}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b>{translateView('field_title.further_drive')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">
                        {driverAssignment.journeyContinuation ? journeyContinuationTexts[driverAssignment.journeyContinuation] : '-'}
                    </div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.accident')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">
                        {driverAssignment.isAccident
                            ? translate('global.answer.yes')
                            : translate('global.answer.no')
                        }
                    </div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.service')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">
                        {driverAssignment.mainServices.map(mainService => {
                            return translate(`global.daf_main_services.${mainService.toLowerCase()}`);
                        }).filter(mainServiceName => {
                            return !!mainServiceName;
                        }).join(', ') || '---'}
                    </div>
                </div>
            </div>
            <div className="ace-driver-assignment-inactive-view__section">
                <div className="ace-grid__row">
                    <h4 className="ace-h4 col-xs--6 col-start-xs--4 ace-bottom-margin--xmd">
                        {translateView('title_section.towing_location')}
                    </h4>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.destination_name')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{driverAssignment.towing.destinationName || '---'}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.address')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">{towingAddressString || '---'}</div>
                </div>
                <div className="ace-grid__row">
                    <p className="col-xs--2 col-start-xs--4 ace-driver-assignment-inactive-view__text">
                        <b> {translateView('field_title.drive_member')}</b>
                    </p>
                    <div className="col-xs--4 ace-driver-assignment-inactive-view__text">
                        {driverAssignment.driveMember
                            ? translate('global.answer.yes')
                            : translate('global.answer.no')
                        }
                    </div>
                </div>
                {!!driverAssignment.attachments && !!driverAssignment.attachments.length && (
                    <Fragment>
                        <div className="ace-grid__row ace-driver-assignment-inactive-view__attachments-title">
                            <h4 className="ace-h6 col-xs--6 col-start-xs--4 ace-bottom-margin--sm">
                                {translateView('title_section.attachments')}
                            </h4>
                        </div>
                        <div className="ace-grid__row">
                            <div className="col-xs--6 col-start-xs--4 ace-driver-assignment-inactive-view__attachments">
                                {driverAssignment.attachments.map(({fileName, url, id, isSignature}) => {
                                    const isPdf = isPdfFileExtension(url);
                                    const fileUrl = isPdf ? pdfIcon : url;
                                    return (
                                        !isSignature && (
                                        <div key={id} className="ace-driver-assignment-inactive-view__attachment-wrapper">
                                            <a href={fileUrl} download={fileName} target="_blank" rel="noopener noreferrer" className="ace-driver-assignment-inactive-view__attachment-icon-wrapper">
                                                <img
                                                    src={fileUrl}
                                                    className="ace-driver-assignment-inactive-view__attachment-icon"
                                                    alt={fileName}
                                                />
                                            </a>
                                            <p className="ace-driver-assignment-inactive-view__attachment-icon-text">
                                                {fileName}
                                            </p>
                                        </div>
                                        ));
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

DriverAssignmentInactiveView.propTypes = {
    driverAssignment: PropTypes.object,
    isDownloadActive: PropTypes.bool,
    downloadProofOfService: PropTypes.func.isRequired,
};

DriverAssignmentInactiveView.defaultProps = {
    driverAssignment: null,
    isDownloadActive: false,
};

const mapStateToProps = state => {
    return {
        isDownloadActive: state.driverAssignments.isDownloadActive,
    };
};

const mapDispatchToProps = dispatch => ({
    downloadProofOfService: payload => dispatch({
        type: driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverAssignmentInactiveView);
