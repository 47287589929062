import React, {useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import './VersionPopUp.scss';
import {useClickOutside} from '../general/useClickOutside';

const VersionPopUp = props => {
    const {children, className, isClosableOnClickOutside, closeFunction} = props;
    const containerRef = useRef(null);

    useEffect(() => {
        document.body.classList.add('no-scroll');

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const handleClickOutside = useCallback(() => {
        if (isClosableOnClickOutside) {
            closeFunction();
        }
    }, [closeFunction, isClosableOnClickOutside]);

    useClickOutside(containerRef, handleClickOutside);


    // componentDidMount() {
    //     document.body.classList.add('no-scroll');
    //     if (this.props.isClosableOnClickOutside) {
    //         document.addEventListener('click', this.handleClickOutside, true);
    //     }
    // }
    //
    // componentWillUnmount() {
    //     document.body.classList.remove('no-scroll');
    //     if (this.props.isClosableOnClickOutside) {
    //         document.removeEventListener('click', this.handleClickOutside, true);
    //     }
    // }

    // handleClickOutside = e => {
    //     if (!this.containerRef.current.contains(e.target)) {
    //         this.props.closeFunction();
    //     }
    // };

    return (
        <div className="ace-c-pop-up">
            <div className={`ace-c-pop-up__scroll-container ${className}`} ref={containerRef}>
                {children}
            </div>
        </div>

    );
};

VersionPopUp.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isClosableOnClickOutside: PropTypes.bool,
    closeFunction: PropTypes.func,
};

VersionPopUp.defaultProps = {
    className: '',
    isClosableOnClickOutside: false,
    closeFunction: null,
};

export default VersionPopUp;
