import React from 'react';
import './DriverAssignmentDataRowCell.scss';

const DriverAssignmentDataRowCell = ({children}) => {
    return (
        <div className="ace-c-driver-assignment-data-row-cell">
            {children}
        </div>
    );
};

export default DriverAssignmentDataRowCell;
