import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withTranslations} from '@computerrock/formation-i18n';
import {Input, Select} from '../../ui-components';
import {DestinationOptions, pickUpDestinationOptions} from '../constants/destinations';
import {NumberValidator} from '../../utils/validation';
import {assembleAddressString} from '../../utils/address';
import SelectOption from '../../ui-components/select/SelectOption';
import SearchAutocomplete from '../../google-map/view-elements/SearchAutocomplete';
import {getGeolocationFromString} from '../../google-map/googleMapFunctions';

class PickUpSection extends React.Component {
    static propTypes = {
        invoiceSubmission: PropTypes.object.isRequired,
        onDataChange: PropTypes.func.isRequired,
        errors: PropTypes.object,
        forwardRef: PropTypes.any.isRequired,
        isDisabled: PropTypes.bool,
        translate: PropTypes.func,
    };

    static defaultProps = {
        errors: {},
        isDisabled: false,
        translate: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.invoiceSubmission.pickUpDestinationName !== this.props.invoiceSubmission.pickUpDestinationName
            && prevProps.invoiceSubmission.pickUpDestinationAddress
            !== this.props.invoiceSubmission.pickUpDestinationAddress) {
            this.setDestinationLocation(this.props.invoiceSubmission.pickUpDestinationAddress);
        }
    }

    onInputChange = ({name, value}) => {
        if (name === 'pickUpDistance' && !NumberValidator.validate(value)) {
            return;
        }
        this.props.onDataChange({
            [name]: value,
        });
    };

    onSelectTypeChange = destinationType => {
        const {invoiceSubmission} = this.props;

        if (!destinationType) {
            return;
        }

        let destinationState = {
            pickUpDestinationType: destinationType,
            pickUpDestinationName: invoiceSubmission.pickUpDestinationName,
            pickUpDestinationAddress: invoiceSubmission.pickUpDestinationAddress,
        };
        if (destinationType === DestinationOptions.PLACE_OF_RESIDENCE) {
            const placeOfResidence = assembleAddressString({
                street: invoiceSubmission.memberAddressStreet,
                postalCode: invoiceSubmission.memberAddressPostCode,
                city: invoiceSubmission.memberAddressCity,
                country: invoiceSubmission.memberAddressCountry,
            });
            destinationState = {
                ...destinationState,
                pickUpDestinationName: invoiceSubmission.memberFullName || invoiceSubmission.pickUpDestinationName,
                pickUpDestinationAddress: placeOfResidence || invoiceSubmission.pickUpDestinationAddress,
            };
        }
        this.props.onDataChange({
            ...destinationState,
        });
    };

    onAutocompleteInputChange = data => {
        this.onInputChange(data);
    };

    onAutocompleteClick = value => {
        this.props.onDataChange({
            pickUpDestinationAddress: value,
        });
    };

    setDestinationLocation = value => {
        const {invoiceSubmission} = this.props;
        return getGeolocationFromString(invoiceSubmission.pickUpDestinationAddress)
            .then(geolocation => {
                let pickUpAddressStreet = value;
                let pickUpAddressCity = null;
                let pickUpAddressPostCode = null;
                let pickUpAddressCountry = null;
                let pickUpDestinationAddress = value;

                if (geolocation && geolocation.address) {
                    const {street, num, city, postCode, country, formattedAddress} = geolocation.address;
                    pickUpAddressStreet = `${street} ${num !== undefined ? num : ''}`;
                    pickUpAddressCity = city;
                    pickUpAddressPostCode = postCode;
                    pickUpAddressCountry = country;
                    pickUpDestinationAddress = formattedAddress;
                }
                this.props.onDataChange({
                    pickUpAddressStreet,
                    pickUpAddressCity,
                    pickUpAddressPostCode,
                    pickUpAddressCountry,
                    pickUpDestinationAddress,
                });
            });
    };

    render() {
        const {invoiceSubmission, errors, forwardRef, isDisabled, translate} = this.props;
        const pickupDestinationAddressError = errors[Object.keys(errors).find(key => key.includes('assignment.pickup.address')
            || key.includes('assignment.pickup.location')
            || key.includes('assignment.pickup.isInsideVpPolygon'))];
        return (
            <Fragment>
                <div className="ace-grid__row ace-bottom-margin--md" ref={forwardRef}>
                    <div className="col-xs--12">
                        <p className="ace-copy-m">{translate('pickup_section.section_title.pickup_destination')}</p>
                    </div>
                </div>
                <div className="ace-bottom-margin--md">
                    <div className="ace-grid__row">
                        <div className="col-xs--4 ace-bottom-margin--md">
                            <Select
                                onChange={this.onSelectTypeChange}
                                name="pickUpDestinationType"
                                value={invoiceSubmission.pickUpDestinationType}
                                error={errors['assignment.pickup.destinationType']
                                    && translate(`global.validation_messages.${errors['assignment.pickup.destinationType']}`)}
                                isDisabled={isDisabled}
                                placeholder={translate('pickup_section.select_placeholder.destination')}
                            >
                                {pickUpDestinationOptions.map(option => {
                                    return (
                                        <SelectOption
                                            key={option}
                                            optionValue={option}
                                            optionText={translate(`global.destination_options.${option.toLowerCase()}`)}
                                        />
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-xs--6">
                            <Input
                                name="pickUpDestinationName"
                                label={translate('pickup_section.input_label.name')}
                                value={invoiceSubmission.pickUpDestinationName || ''}
                                onChange={this.onInputChange}
                                error={errors['assignment.pickup.destinationName']
                                    && translate(`global.validation_messages.${errors['assignment.pickup.destinationName']}`)}
                                additionalAttributes={{maxLength: '50'}}
                                isDisabled={isDisabled}
                            />
                        </div>
                        <div className="col-xs--10 ace-bottom-margin--md">
                            <SearchAutocomplete
                                name="pickUpDestinationAddress"
                                label={translate('pickup_section.autocomplete_label.address')}
                                onChange={this.onAutocompleteInputChange}
                                setAutocompleteFields={this.setDestinationLocation}
                                onOptionClick={this.onAutocompleteClick}
                                value={invoiceSubmission.pickUpDestinationAddress || ''}
                                isDisabled={isDisabled}
                                additionalAttributes={{maxLength: '100'}}
                                error={pickupDestinationAddressError && translate(`global.validation_messages.${pickupDestinationAddressError}`)}
                            />
                        </div>
                        <div className="col-xs--4">
                            <Input
                                type="text"
                                name="pickUpDistance"
                                label={translate('pickup_section.input_label.pickup_distance')}
                                onChange={this.onInputChange}
                                value={invoiceSubmission.pickUpDistance || ''}
                                error={errors['assignment.pickup.distance']
                                    && translate(`global.validation_messages.${errors['assignment.pickup.distance']}`)}
                                additionalAttributes={{maxLength: '4'}}
                                isDisabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslations(PickUpSection);
