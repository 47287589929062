import React from 'react';
import PropTypes from 'prop-types';
import {Link} from '@computerrock/formation-router';
import classnames from 'classnames';
import './TableRow.scss';

const TableRow = props => {
    const toggleShadowClass = classnames('ace-grid', {
        'ace-c-table-row--shadow': props.dropdownComponent,
    });

    return (
        props.linkTo
            ? (
                <Link to={props.linkTo ? props.linkTo : null} className="ace-grid">
                    <div className={`ace-grid__row ${props.className}`}>
                        {props.children}
                    </div>
                    {props.dropdownComponent}
                </Link>
            )
            : (
                <div className={`${toggleShadowClass} ${props.wrapperClassName}`}>
                    <div onClick={props.onClick ? props.onClick : null} className="ace-grid__row-wrapper">
                        <div className={`ace-grid__row ${props.className}`}>
                            {props.children}
                        </div>
                    </div>
                    {props.dropdownComponent}
                </div>
            )
    );
};

TableRow.propTypes = {
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    linkTo: PropTypes.object,
    dropdownComponent: PropTypes.object,
    onClick: PropTypes.func,
};

TableRow.defaultProps = {
    wrapperClassName: '',
    className: '',
    linkTo: null,
    dropdownComponent: null,
    onClick: () => {},
};

export default TableRow;
