import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withTranslations} from '@computerrock/formation-i18n';
import {DEFAULT_DATE_FORMAT, NULL_DATE_STRING} from '../../constants/dates';
import {Icon} from '../../../ui-components';
import {TableRow, TableCell} from '../../../ui-components/table';
import DispatchArchiveDetailRow from './DriverAssignmentsArchiveRowDetail';


class DriverAssignmentArchiveRow extends React.Component {
    static propTypes = {
        driverAssignment: PropTypes.object.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        translate: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
        };
    }

    onRowClick = () => {
        this.setState(prevState => ({
            showDetails: !prevState.showDetails,
        }));
    };

    render() {
        const {showDetails} = this.state;
        const {driverAssignment, translate} = this.props;

        const toggleIconClass = classnames('ace-driver-assignment-archive-row__icon', {
            'ace-driver-assignment-archive-row__icon--rotate': showDetails,
        });

        const dateString = driverAssignment.startDate
            ? driverAssignment.startDate.format(DEFAULT_DATE_FORMAT)
            : NULL_DATE_STRING;
        const statusIcon = driverAssignment.status === 'DECLINED' ? 'close-status' : 'history';
        const statusText = driverAssignment.status === 'DECLINED' ? 'Abgelehnt' : 'Fall beendet';
        return (
            <TableRow
                className="ace-c-table-row--md"
                onClick={this.onRowClick}
                dropdownComponent={showDetails ? <DispatchArchiveDetailRow {...this.props} /> : null}
            >
                <TableCell className="col-sm--3 ace-c-table-row__status-column">
                    <div className="ace-c-table__icon-status-wrapper">
                        <Icon dimension="md" iconName={statusIcon} color="fill-white" />
                    </div>
                    <div className="ace-c-table__status-text">{statusText}</div>
                </TableCell>
                <TableCell className="col-sm--3">
                    <span className="ace-copy-s">
                        {driverAssignment.mainServices.length > 0
                            ? driverAssignment.mainServices.length > 1
                                ? translate(`global.daf_main_services.multiple_services`)
                                : translate(`global.daf_main_services.${driverAssignment.mainServices[0].toLowerCase()}`)
                            : '---'}
                    </span>
                </TableCell>
                <TableCell className="col-sm--2">
                    <span className="ace-copy-s">{driverAssignment.assignmentId}</span>
                </TableCell>
                <TableCell className="col-sm--2">
                    <span className="ace-copy-s">{dateString}</span>
                </TableCell>
                <TableCell className="col-sm--2 ace-c-driver-assignment-row__last-column">
                    <span className="ace-copy-s">{driverAssignment.driverName}</span>
                    <span className={toggleIconClass}>
                        <Icon dimension="md" iconName="expand-arrow" />
                    </span>
                </TableCell>
            </TableRow>
        );
    }
}

export default withTranslations(DriverAssignmentArchiveRow);
