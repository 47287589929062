import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import {connect} from 'react-redux';
import {withTranslations} from '@computerrock/formation-i18n';
import {convertNumberToCurrency} from '../../utils/conversion';
import {DEFAULT_DATE_FORMAT, NULL_DATE_STRING} from '../../constants/dates';
import {Icon, CaseStatePill} from '../../../ui-components';
import {TableRow, TableCell} from '../../../ui-components/table';
import AccountingArchiveRowDetail from './InvoiceSubmissionArchiveRowDetail';
import './InvoiceSubmissionArchiveRow.scss';
import {isFollowUpInvoiceSubmission} from '../../utils/subCases';
import {eafStatusPillTypes} from '../../constants/invoiceSubmissionStatuses';
import {getServiceInfo} from '../../utils/mainServices';
import * as invoiceActionTypes from '../../invoiceSubmissionActionTypes';
import {eafStatuses} from '../../../ella-dispo-entity-types';

class InvoiceSubmissionArchiveRow extends React.Component {
    static propTypes = {
        invoiceSubmission: PropTypes.object,
        translate: PropTypes.func,
        getInvoiceSubmissionDetails: PropTypes.func.isRequired,
    };

    static defaultProps = {
        invoiceSubmission: null,
        translate: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
        };
    }

    onRowClick = () => {
        const {showDetails} = this.state;
        const {getInvoiceSubmissionDetails, invoiceSubmission} = this.props;
        if (invoiceSubmission.status === eafStatuses.PAID && !showDetails) {
            getInvoiceSubmissionDetails({invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId});
        }
        this.setState(prevState => ({
            showDetails: !prevState.showDetails,
        }));
    };

    render() {
        const {showDetails} = this.state;
        const {invoiceSubmission, translate} = this.props;
        const isCaseFollowUpInvoiceSubmission = isFollowUpInvoiceSubmission(invoiceSubmission.invoiceSubmissionId);

        const toggleIconClass = classnames('ace-invoice-submission-archive-row__icon', {
            'ace-invoice-submission-archive-row__icon--rotate': showDetails,
        });

        const dateString = invoiceSubmission.assignment.startDate
            ? moment(invoiceSubmission.assignment.startDate).format(DEFAULT_DATE_FORMAT)
            : NULL_DATE_STRING;

        const {
            serviceIcon,
            serviceName,
        } = getServiceInfo(invoiceSubmission.serviceType, invoiceSubmission.isEmptyTrip, invoiceSubmission.emptyTripReason, translate); // eslint-disable-line


        return (
            <TableRow
                className="ace-c-table-row--md"
                onClick={this.onRowClick}
                dropdownComponent={showDetails ? <AccountingArchiveRowDetail {...this.props} /> : null}
            >
                <TableCell className="col-sm--2">
                    <CaseStatePill type={eafStatusPillTypes[invoiceSubmission.status]}>
                        {translate(`global.eaf_status.${invoiceSubmission.status.toLowerCase()}`)}
                    </CaseStatePill>
                </TableCell>
                <TableCell className="col-sm--3">
                    {serviceIcon && (
                        <span className="ace-invoice-submission-archive-row__icon">
                            <Icon dimension="lg" iconName={serviceIcon} color="grey" />
                        </span>
                    )}
                    <span className="ace-copy-s">{serviceName || '---'}</span>
                </TableCell>
                <TableCell className="col-sm--3">
                    <span className="ace-copy-s">{invoiceSubmission.invoiceSubmissionName}</span>
                    {isCaseFollowUpInvoiceSubmission && (
                        <Icon dimension="md" iconName="circle-plus" className="ace-c-open-row__plus-icon" />
                    )}
                </TableCell>
                <TableCell className="col-sm--2">
                    <span className="ace-copy-s">{dateString}</span>
                </TableCell>
                <TableCell className="col-sm--2 ace-c-table-cell--right">
                    <span className="ace-copy-s">{convertNumberToCurrency(invoiceSubmission.total)} €</span>
                    <span className={toggleIconClass}>
                        <Icon dimension="md" iconName="expand-arrow" />
                    </span>
                </TableCell>
            </TableRow>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getInvoiceSubmissionDetails: payload => dispatch({
        type: invoiceActionTypes.GET_INVOICE_SUBMISSION_DETAILS,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(withTranslations(InvoiceSubmissionArchiveRow));
