import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as invoiceSubmissionSelectors from '../invoiceSubmissionSelectors';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';


const withInvoiceSubmissionSearch = DataConsumer => {
    const DataProvider = props => {
        const {
            invoiceSubmissionSearchResults,
            isSearchPending,
            isSearchTermCleared,
            searchInvoiceSubmissions,
            clearSearchResults,
            searchTerm,
            isSearchViewActive,
        } = props;

        return (
            <DataConsumer
                {...props}
                invoiceSubmissionSearchResults={invoiceSubmissionSearchResults}
                isSearchPending={isSearchPending}
                isSearchTermCleared={isSearchTermCleared}
                searchInvoiceSubmissions={searchInvoiceSubmissions}
                clearSearchResults={clearSearchResults}
                searchTerm={searchTerm}
                isSearchViewActive={isSearchViewActive}
            />
        );
    };

    DataProvider.propTypes = {
        invoiceSubmissionSearchResults: PropTypes.array.isRequired,
        isSearchPending: PropTypes.bool.isRequired,
        isSearchTermCleared: PropTypes.bool.isRequired,
        searchInvoiceSubmissions: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchTerm: PropTypes.string.isRequired,
        isSearchViewActive: PropTypes.bool.isRequired,
    };

    const mapStateToProps = state => {
        const getInvoiceSubmissionSearchedResults = invoiceSubmissionSelectors.createSearchedInvoiceSubmissions();
        return {
            invoiceSubmissionSearchResults: getInvoiceSubmissionSearchedResults(state),
            isSearchPending: state.invoiceSubmission.isSearchPending,
            isSearchTermCleared: state.invoiceSubmission.isSearchTermCleared,
            searchTerm: state.invoiceSubmission.searchTerm,
            isSearchViewActive: state.invoiceSubmission.isSearchViewActive,
        };
    };
    const mapDispatchToProps = dispatch => ({
        searchInvoiceSubmissions:
            payload => dispatch({type: invoiceSubmissionActionTypes.SEARCH_INVOICE_SUBMISSION, payload}),
        clearSearchResults: () => dispatch({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS}), // eslint-disable-line
    });

    return connect(mapStateToProps, mapDispatchToProps)(DataProvider);
};

export default withInvoiceSubmissionSearch;
