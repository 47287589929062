import React, {useState, Children} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import './Select.scss';
import Dropdown from '../dropdown/Dropdown';
import List from '../list/List';
import DropdownWithTrigger from '../dropdown/DropdownWithTrigger';

const Select = props => {
    const {isDisabled, isLocked, className, children, name} = props;
    const {placeholder, label, error} = props;
    const [focused, setFocused] = useState(false);
    const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(false);

    let valueContent = null;
    const enhancedChildren = [];
    Children.forEach(children, child => {
        if (!child || typeof child === 'string') return child;
        if (child.props.optionValue === props.value) {
            valueContent = child.props?.optionText;
        }
        enhancedChildren.push(React.cloneElement(child, {
            onClick: value => {
                props.onChange(value);
                setShouldCloseOnSelect(true);
            },
        }));
    });

    const onFocus = isFocused => {
        setFocused(isFocused);
        setShouldCloseOnSelect(false);
    };

    const inputClassName = classNames('ace-c-select__field', {
        'ace-c-select__field--disabled': isDisabled,
        'ace-c-select__field--locked': isLocked,
        'ace-c-select__field--error': error && !isDisabled,
        'ace-c-select__field--focused': focused && !isDisabled && !error,
    });

    const inputWrapperClassName = classNames('ace-c-select', {
        'ace-c-select--disabled': isDisabled,
        'ace-c-select--locked': isLocked,
        'ace-c-select--error': error && !isDisabled,
    });
    const labelClassName = classNames('ace-c-select__label', {
        'ace-c-select__label--disabled': isDisabled,
        'ace-c-select__label--locked': isLocked,
        'ace-c-select__label--error': error && !isDisabled,
    });
    const placeholderClassName = classNames('ace-c-select__placeholder', {
        'ace-c-select__placeholder--disabled': isDisabled,
        'ace-c-select__placeholder--locked': isLocked,
        'ace-c-select__placeholder--focused': focused,
        'ace-c-select__placeholder--error': error && !isDisabled,
        'ace-c-select__placeholder--selected': !!valueContent,
    });
    const iconClassName = classNames('ace-c-select__icon', {
        'ace-c-select__icon--focused': focused && !isDisabled && !isLocked,
    });

    const errorLabelClassName = 'ace-c-select__error-label';
    const fill = isDisabled || isLocked ? '' : 'red';


    return (
        <label className={`${inputWrapperClassName} ${className}`}>
            {
            label && !isLocked && !error
                ? <div className={`${labelClassName}`}>{label}</div>
                : null
        }
            <DropdownWithTrigger className="ace-c-select__input-wrapper" handleStateChange={onFocus} isClosableOnSelect={shouldCloseOnSelect}>
                <div
                    className={inputClassName}
                >
                    <span className={placeholderClassName}>{valueContent || placeholder}</span>
                    <div className={iconClassName}>
                        <Icon dimension="lg" iconName="expand-arrow" color={fill} />
                    </div>
                </div>
                <Dropdown
                    position="select-dropdown"
                    className={name === 'country' ? 'ace-c-dropdown--scrollable' : ''}
                >
                    <List>
                        {
                    enhancedChildren
                    }
                    </List>
                </Dropdown>
            </DropdownWithTrigger>
            {error && (
                <div className={`${errorLabelClassName}`}>{error}</div>
            )}
        </label>
    );
};

Select.propTypes = {
    value: PropTypes.any,
    // name: PropTypes.string.isRequired,
    // isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLocked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.any,
    label: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string,
    // children: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
};

Select.defaultProps = {
    value: null,
    // isRequired: false,
    isDisabled: false,
    isLocked: false,
    className: '',
    placeholder: '',
    label: '',
    error: '',
    name: '',
    // children: [],
    onChange: () => {},
};


export default Select;

