import React from 'react';
import PropTypes from 'prop-types';
import './SummaryDetailRow.scss';

const SummaryDetailRow = ({children, label}) => {
    return (
        <div className="ace-c-summary-detail-row">
            <div className="ace-c-summary-detail-row__label">
                <p className="ace-c-summary-detail-row__label__text">
                    {label}
                </p>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

SummaryDetailRow.propTypes = {
    label: PropTypes.string.isRequired,
};

export default SummaryDetailRow;
