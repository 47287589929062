import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary} from '../ui-components';
import keycloakAuth from '../keycloakAuth';
import AppLayoutView from './AppLayoutView';
import './AccessDeniedScreen.scss';

const AccessDeniedScreen = props => {
    const {signOutUser} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('access_denied_screen');

    const handleOnClick = () => {
        signOutUser();
    };

    return (
        <AppLayoutView>
            <div className="access-denied-screen-wrapper">
                <div className="ace-grid__row">
                    <div className="col-xs--8 col-start-xs--3">
                        <h3 className="ace-h3 ace-h3--center ace-top-margin--md ace-bottom-margin--md">
                            {translateScreen('section_title.status')}
                        </h3>
                        <p className="ace-copy-s ace-bottom-margin--sm text-center">
                            {translateScreen('access_denied_message.forbidden')}
                        </p>
                    </div>
                    <div className="col-xs--4 col-start-xs--5">
                        <ButtonPrimary
                            onClick={handleOnClick}
                            label={translateScreen('button_label.login')}
                            name="login"
                        />
                    </div>
                </div>
            </div>
        </AppLayoutView>
    );
};

AccessDeniedScreen.propTypes = {
    signOutUser: PropTypes.func,
};
AccessDeniedScreen.defaultProps = {
    signOutUser: null,
};
const mapDispatchToProps = dispatch => {
    return {
        signOutUser: () => dispatch(keycloakAuth.signOutUserAction),
    };
};
export default connect(null, mapDispatchToProps)(AccessDeniedScreen);
