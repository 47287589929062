import {put, take, fork, select} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';
import * as userSelectors from '../../user-management/userManagementSelectors';
import config from '../../config';

const downloadMultipleInvoices = function* downloadMultipleInvoices() {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_INVOICES);
        const {size, search} = payload;
        const userAccount = yield select(userSelectors.getUserAccount);

        yield put({
            type: invoiceSubmissionActionTypes.SET_DOWNLOAD_NOTIFICATION_MODAL_STATE,
            payload: {isDownloadNotificationModalOpen: true},
        });

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.DOWNLOADING},
        });

        const queryObject = Object.fromEntries(new URLSearchParams(search));
        const {page, statuses, mainServices, fromServiceStartDateTime} = queryObject;
        const {toServiceEndDateTime, sortBy, direction} = queryObject;
        const date = {
            ...(fromServiceStartDateTime ? {fromServiceStartDateTime} : {}),
            ...(toServiceEndDateTime ? {toServiceEndDateTime} : {}),
        };

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST,
            eafService.getInvoiceSubmissions,
            {
                contractPartnerIds: userAccount.partnerIds,
                ...(size ? {size} : {}),
                ...(statuses ? {statuses} : {}),
                ...(page && {page}),
                ...(mainServices && {mainServices}),
                ...(sortBy ? {sortBy} : {sortBy: 'SERVICE_START_DATE'}),
                ...(direction ? {direction} : {direction: 'DESC'}),
                ...(date || {}),
            },
        );

        const resultAction = yield take([
            invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_FAILED,
        ]);

        if (!resultAction.error) {
            const invoiceSubmissionsDTO = resultAction.payload.response.data;
            const paths = invoiceSubmissionsDTO.map(invoiceSubmissionDTO => invoiceSubmissionDTO.invoiceLocation)
                .filter(path => path);
            const invoiceSubmissionIds = invoiceSubmissionsDTO.map(invoiceSubmission => (
                invoiceSubmission.invoiceSubmissionId
            ));
            let downloadBCReceiptsActionResult = null;
            let downloadInvoicesActionResult = null;

            if (paths.length > 0) {
                yield fork(
                    fetchRequest,
                    invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_INVOICES_REQUEST,
                    eafService.downloadMultipleFiles,
                    {paths},
                );

                downloadInvoicesActionResult = yield take([
                    invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_INVOICES_REQUEST_SUCCEEDED,
                    invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_INVOICES_REQUEST_FAILED,
                ]);
            }

            // todo remove PROD guard once LEA is available for PROD
            if (config.DEPLOYMENT_ENV !== config.PROD_DEPLOYMENT_ENV) {
                yield fork(
                    fetchRequest,
                    invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST,
                    eafService.downloadMultipleReceipts,
                    {invoiceSubmissionIds},
                );

                downloadBCReceiptsActionResult = yield take([
                    invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_SUCCEEDED,
                    invoiceSubmissionActionTypes.DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_FAILED,
                ]);
            }

            yield put({
                type: invoiceSubmissionActionTypes.SET_DOWNLOAD_NOTIFICATION_MODAL_STATE,
                payload: {isDownloadNotificationModalOpen: false},
            });

            yield put({
                type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
                payload: {state: InvoiceSubmissionDataStates.IDLE},
            });

            if (downloadInvoicesActionResult?.error || downloadBCReceiptsActionResult?.error) {
                yield put({
                    type: errorActionTypes.STORE_ERRORS,
                    payload: {
                        error: {
                            ...parseErrorMessages(resultAction.payload),
                        },
                    },
                });
                continue;
            }
            continue;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export default downloadMultipleInvoices;
