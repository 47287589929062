import {createSelector} from 'reselect';
import {CountriesList} from './constants/countries';
import {decodeMainServices} from './utils/mainServices';
import {accident} from './constants/accident';
import {trailer} from './constants/trailer';

export const getInvoiceSubmissions = state => state.invoiceSubmission.invoiceSubmissions;
export const getOpenInvoiceSubmissionIds = state => state.invoiceSubmission.openInvoiceSubmissionIds;
export const getSubmittedInvoiceSubmissionIds = state => state.invoiceSubmission.submittedInvoiceSubmissionIds;
export const getArchiveInvoiceSubmissionIds = state => state.invoiceSubmission.archiveInvoiceSubmissionIds;
export const getSearchedInvoiceSubmissionIds = state => state.invoiceSubmission.searchedInvoiceSubmissionIds;

export const createOpenInvoiceSubmissions = () => createSelector(
    [getInvoiceSubmissions, getOpenInvoiceSubmissionIds],
    (invoiceSubmissions, openInvoiceSubmissionIds) => {
        if (!openInvoiceSubmissionIds.length) return [];
        const openInvoiceSubmissions = [];
        openInvoiceSubmissionIds.forEach(invoiceSubmissionId => {
            const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];
            if (invoiceSubmission) openInvoiceSubmissions.push(invoiceSubmission);
        });
        return openInvoiceSubmissions;
    },
);

export const createSubmittedInvoiceSubmissions = () => createSelector(
    [getInvoiceSubmissions, getSubmittedInvoiceSubmissionIds],
    (invoiceSubmissions, submittedInvoiceSubmissionIds) => {
        if (!submittedInvoiceSubmissionIds.length) return [];
        const submittedInvoiceSubmissions = [];
        submittedInvoiceSubmissionIds.forEach(invoiceSubmissionId => {
            const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];
            if (invoiceSubmission) submittedInvoiceSubmissions.push(invoiceSubmission);
        });
        return submittedInvoiceSubmissions;
    },
);

export const createArchiveInvoiceSubmissions = () => createSelector(
    [getInvoiceSubmissions, getArchiveInvoiceSubmissionIds],
    (invoiceSubmissions, archiveInvoiceSubmissionIds) => {
        if (!archiveInvoiceSubmissionIds.length) return [];
        const archiveInvoiceSubmissions = [];
        archiveInvoiceSubmissionIds.forEach(invoiceSubmissionId => {
            const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];
            if (invoiceSubmission) archiveInvoiceSubmissions.push(invoiceSubmission);
        });
        return archiveInvoiceSubmissions;
    },
);

export const createSearchedInvoiceSubmissions = () => createSelector(
    [getInvoiceSubmissions, getSearchedInvoiceSubmissionIds],
    (invoiceSubmissions, searchedInvoiceSubmissionIds) => {
        if (!searchedInvoiceSubmissionIds.length) return [];
        const searchedInvoiceSubmissions = [];
        searchedInvoiceSubmissionIds.forEach(invoiceSubmissionId => {
            const invoiceSubmission = invoiceSubmissions[invoiceSubmissionId];
            if (invoiceSubmission) searchedInvoiceSubmissions.push(invoiceSubmission);
        });
        return searchedInvoiceSubmissions;
    },
);

export const createInvoiceSubmissionSelector = () => createSelector(
    [
        getInvoiceSubmissions,
        (state, props) => {
            const {match} = props;
            return `${match?.params['invoiceSubmissionId']}`;
        },
    ],
    (invoiceSubmissions, invoiceSubmissionId) => {
        if (!invoiceSubmissionId) return null;

        return invoiceSubmissionObjectGenerator(invoiceSubmissions[invoiceSubmissionId]);
    },
);

export const getIsSearchViewActive = state => state.invoiceSubmission.isSearchViewActive;

export const createAddressCountryField = countryCode => {
    if (!countryCode) return null;
    return Object.keys(CountriesList).includes(countryCode) ? countryCode : null;
};

const invoiceSubmissionObjectGenerator = invoiceSubmission => {
    if (!invoiceSubmission) {
        return null;
    }

    return {
        status: invoiceSubmission.status,
        externalDocumentNo: invoiceSubmission.externalDocumentNo,
        assignmentId: invoiceSubmission.assignment.assignmentId,
        invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
        attachments: invoiceSubmission.attachments,
        mainServices: invoiceSubmission.mainServices,
        mainServicesComment: invoiceSubmission.mainServicesComment,
        additionalServices: invoiceSubmission.additionalServices,
        additionalServicesComment: invoiceSubmission.additionalServicesComment,
        name: invoiceSubmission.invoiceSubmissionName,
        startDate: invoiceSubmission.assignment.startDate,
        startTime: invoiceSubmission.assignment.startTime,
        endDate: invoiceSubmission.assignment.endDate,
        endTime: invoiceSubmission.assignment.endTime,
        billingType: invoiceSubmission.assignment.billingType,
        hasTrailer: invoiceSubmission.assignment.vehicle.hasTrailer === true ? trailer.HAS_TRAILER : trailer.NO_TRAILER,
        isAccident: invoiceSubmission.assignment.isAccident === true ? accident.IS_ACCIDENT : accident.NO_ACCIDENT,
        journeyContinuation: invoiceSubmission.assignment.journeyContinuation,
        damageLocation: {
            lat: invoiceSubmission.assignment.damage.location.lat,
            lng: invoiceSubmission.assignment.damage.location.lng,
        },
        vpPolygon: invoiceSubmission.assignment.contractPartnerInfo.polygon,
        damageAddressStreet: invoiceSubmission.assignment.damage.address.street,
        damageCity: invoiceSubmission.assignment.damage.address.city,
        damagePostCode: invoiceSubmission.assignment.damage.address.postCode,
        damageCountry: invoiceSubmission.assignment.damage.address.country,
        towingDestinationType: invoiceSubmission.assignment.towing.destinationType,
        towingDestinationName: invoiceSubmission.assignment.towing.destinationName,
        pickUpDestinationType: invoiceSubmission.assignment.pickup.destinationType,
        pickUpDestinationName: invoiceSubmission.assignment.pickup.destinationName,
        startOfRecovery: invoiceSubmission.assignment.startOfRecovery,
        recoveryStart: invoiceSubmission.assignment.recoveryStart,
        endOfRecovery: invoiceSubmission.assignment.endOfRecovery,
        recoveryEnd: invoiceSubmission.assignment.recoveryEnd,
        vpLatitude: invoiceSubmission.assignment.contractPartnerInfo.location.lat,
        vpLongitude: invoiceSubmission.assignment.contractPartnerInfo.location.lng,
        decodedMainServices: decodeMainServices(invoiceSubmission.mainServices),
        towingLocation: {
            lat: invoiceSubmission.assignment.towing.location.lat,
            lng: invoiceSubmission.assignment.towing.location.lng,
        },
        towingAddressStreet: invoiceSubmission.assignment.towing.address.street,
        towingAddressCity: invoiceSubmission.assignment.towing.address.city,
        towingAddressPostCode: invoiceSubmission.assignment.towing.address.postCode,
        towingAddressCountry: invoiceSubmission.assignment.towing.address.country,
        pickUpAddressStreet: invoiceSubmission.assignment.pickup.address.street,
        pickUpAddressCity: invoiceSubmission.assignment.pickup.address.city,
        pickUpAddressPostCode: invoiceSubmission.assignment.pickup.address.postCode,
        pickUpAddressCountry: invoiceSubmission.assignment.pickup.address.country,
        vpAddressStreet: invoiceSubmission.assignment.contractPartnerInfo.address.street,
        vpAddressCity: invoiceSubmission.assignment.contractPartnerInfo.address.city,
        vpAddressPostCode: invoiceSubmission.assignment.contractPartnerInfo.address.postCode,
        vpAddressCountry: invoiceSubmission.assignment.contractPartnerInfo.address.country,
        vpName: invoiceSubmission.assignment.contractPartnerInfo.name,
        memberAddressStreet: invoiceSubmission.assignment.member.address.street,
        memberAddressCity: invoiceSubmission.assignment.member.address.city,
        memberAddressPostCode: invoiceSubmission.assignment.member.address.postCode,
        memberAddressCountry: createAddressCountryField(invoiceSubmission.assignment.member.address.country),
        memberFullName: invoiceSubmission.assignment.member.name,
        memberType: invoiceSubmission.assignment.member.type,
        emptyTripReason: invoiceSubmission.assignment.emptyTripReason,
        isTowingLocationInsideVpPolygon: invoiceSubmission.assignment.towing.isInsideVpPolygon,
        pickUpDistance: invoiceSubmission.assignment.pickup.distance,
        membershipNumber: invoiceSubmission.assignment.member.membershipNumber,
        driverType: invoiceSubmission.assignment.vehicle.driver.type,
        driverName: invoiceSubmission.assignment.vehicle.driver.name,
        driverAddressStreet: invoiceSubmission.assignment.vehicle.driver.address.street,
        driverAddressPostCode: invoiceSubmission.assignment.vehicle.driver.address.postCode,
        driverAddressCity: invoiceSubmission.assignment.vehicle.driver.address.city,
        driverAddressCountry: createAddressCountryField(invoiceSubmission.assignment.vehicle.driver.address.country),
        ownerType: invoiceSubmission.assignment.vehicle.owner.type,
        ownerName: invoiceSubmission.assignment.vehicle.owner.name,
        ownerAddressStreet: invoiceSubmission.assignment.vehicle.owner.address.street,
        ownerAddressPostCode: invoiceSubmission.assignment.vehicle.owner.address.postCode,
        ownerAddressCity: invoiceSubmission.assignment.vehicle.owner.address.city,
        ownerAddressCountry: createAddressCountryField(invoiceSubmission.assignment.vehicle.owner.address.country),
        vehicleManufacturer: invoiceSubmission.assignment.vehicle.manufacturer,
        vehicleModel: invoiceSubmission.assignment.vehicle.model,
        odometer: invoiceSubmission.assignment.vehicle.odometer,
        registrationDateTime: invoiceSubmission.assignment.vehicle.registrationDateTime,
        licensePlate: invoiceSubmission.assignment.vehicle.licensePlate,
        vehicleWeight: invoiceSubmission.assignment.vehicle.weight,
        vehicleType: invoiceSubmission.assignment.vehicle.type,
        memberPhoneNumber: invoiceSubmission.assignment.member.phoneNumber,
        isForeignInsurance: invoiceSubmission.isForeignInsurance,
        isManuallyCreatedCase: invoiceSubmission.assignment.isMoaCase,
        isFirstOpening: invoiceSubmission.isFirstOpening,
        isFollowUpInvoiceSubmissionAllowed: invoiceSubmission.assignment.isFollowUpInvoiceSubmissionAllowed,
        total: invoiceSubmission.total,
        invoiceLocation: invoiceSubmission.invoiceLocation,
        fallbackSystemId: invoiceSubmission.assignment.fallbackSystemId,
        inflationSubsidyAllowed: invoiceSubmission.inflationSubsidyAllowed,
    };
};
