import {immerable} from 'immer';
import moment from 'moment';
import Entity from '../entity/Entity';
import Damage from './Damage';
import {Member} from '../member';
import {ContractPartner} from '../contract-partner';
import {Vehicle} from '../vehicle';
import Towing from './Towing';
import {Attachment} from '../attachment';
import dafStatuses from './dafStatuses';
import {JourneyContinuation} from '../journey-continuation';

/**
 * DriverAssignment entity type definition
 *
 * @typedef {Object} DriverAssignment
 * @property {?string} assignmentId
 * @property {?string} driverId
 * @property {?string} status
 * @property {?Damage} damage
 * @property {?string} callBackNumber
 * @property {?boolean} isAccident
 * @property {?string} journeyContinuation
 * @property {?Array} mainServices
 * @property {?string} mainServicesComment
 * @property {?Member} member
 * @property {?Vehicle} vehicle
 * @property {?Towing} towing
 * @property {?ContractPartner} contractPartner
 * @property {?string} emergencyCallDateTime
 * @property {?string} serviceStartDateTime
 * @property {?string} serviceEndDateTime
 * @property {?string} startOfRecovery
 * @property {?string} endOfRecovery
 * @property {?string} additionalText
 * @property {?string} assignmentText
 * @property {?string} startDate
 * @property {?string} endDate
 * @property {?string} serviceType
 * @property {?string} serviceText
 * @property {?string} driverFirstName
 * @property {?string} driverLastName
 * @property {?Array} attachments
 * @property {?string} ongoingServiceType
 * @property {?string} originalMainService
 * @property {?string} scheduledDateTime
 */

/**
 * DriverAssignment entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */

export const dafStatusSortOrder = {
    [dafStatuses.VP_ASSIGNED]: 1,
    [dafStatuses.VP_ACCEPTED]: 2,
    [dafStatuses.DRIVER_ASSIGNED]: 3,
    [dafStatuses.DRIVER_ACCEPTED]: 4,
    [dafStatuses.APPROACHING]: 5,
    [dafStatuses.IN_SERVICE]: 6,
    [dafStatuses.TOWING_SUB_SERVICE]: 7,
    [dafStatuses.SERVICE_FINISHED]: 8,
    [dafStatuses.DECLINED]: 9,
    [dafStatuses.BILLABLE]: 10,
};

const driverAssignmentEntityDTOMapping = {
    assignmentId: {
        dtoProperty: 'assignmentId',
        fromDTO: id => encodeURIComponent(id),
    },
    damage: {
        dtoProperty: 'damage',
        defaultValue: new Damage(),
        entity: Damage,
    },

    callBackNumber: {
        dtoProperty: 'callbackPhoneNumber',
        defaultValue: '',
    },

    member: {
        dtoProperty: 'member',
        defaultValue: new Member(),
        entity: Member,
    },
    contractPartner: {
        dtoProperty: 'contractPartner',
        defaultValue: new ContractPartner(),
        entity: ContractPartner,
    },
    vehicle: {
        dtoProperty: 'vehicle',
        defaultValue: new Vehicle(),
        entity: Vehicle,
    },
    towing: {
        dtoProperty: 'towing',
        defaultValue: new Towing(),
        entity: Towing,
    },
    mainServices: {
        dtoProperty: 'mainServices',
        defaultValue: [],
    },
    status: {
        dtoProperty: 'status',
        defaultValue: '',
    },
    additionalText: {
        dtoProperty: 'additionalText',
        defaultValue: '',
    },
    driverId: {
        dtoProperty: 'driverId',
        defaultValue: '',
    },
    driverName: {
        dtoProperty: 'driverName',
        defaultValue: '',
    },
    isAccident: {
        dtoProperty: 'isAccident',
        defaultValue: false,
    },
    journeyContinuation: {
        dtoProperty: 'journeyContinuation',
        defaultValue: JourneyContinuation.YES,
    },
    mainServicesComment: {
        dtoProperty: 'mainServicesComment',
        defaultValue: '',
    },
    assignmentText: {
        dtoProperty: 'assignmentText',
        defaultValue: '',
    },
    driverFirstName: {
        dtoProperty: 'driverFirstName',
        defaultValue: '',
    },
    driverLastName: {
        dtoProperty: 'driverLastName',
        defaultValue: '',
    },
    ongoingServiceType: {
        dtoProperty: 'ongoingServiceType',
        defaultValue: '',
    },
    driveMember: {
        dtoProperty: 'driveMember',
        defaultValue: false,
    },
    attachments: {
        dtoProperty: 'attachments',
        fromDTO: attachments => (attachments ? attachments.map(attachment => {
            return new Attachment().fromDTO(attachment);
        }) : []),
    },
    emergencyCallDateTime: {
        dtoProperty: 'emergencyCallDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    serviceStartDateTime: {
        dtoProperty: 'serviceStartDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    serviceEndDateTime: {
        dtoProperty: 'serviceEndDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },
    originalMainService: {
        dtoProperty: 'originalMainService',
        defaultValue: '',
    },
    scheduledDateTime: {
        dtoProperty: 'scheduledDateTime',
        fromDTO: date => (date ? moment(date) : null),
    },

};

/**
 * DriverAssignment entity
 *
 * @class
 * @extends Entity
 * @type {DriverAssignment}
 */
export default class DriverAssignment extends Entity {
    [immerable] = true;

    static entityDTOMapping = driverAssignmentEntityDTOMapping;

    static extendEntityDTOMapping = entityDTOMapping => {
        return {
            ...driverAssignmentEntityDTOMapping,
            ...entityDTOMapping,
        };
    };

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }

    get serviceType() {
        return this.originalMainService;
    }

    get statusSortOrder() {
        return this.status ? (this.status === dafStatuses.IN_SERVICE
        && this.ongoingServiceType === 'TOWING' ? 7 : dafStatusSortOrder[this.status]) : 99;
    }

    get startDate() {
        return this.serviceStartDateTime || this.emergencyCallDateTime;
    }

    get endDate() {
        return this.serviceEndDateTime || this.startDate;
    }
}
