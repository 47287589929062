import hashSum from 'hash-sum';
import {produce} from 'immer';
import * as documentDownloadActionTypes from './documentDownloadActionTypes';
import {DocumentFile} from '../ella-dispo-entity-types/document-download';

const initialState = {
    documentFiles: null,
    downloadingDocuments: [],
    isMultipleDocumentDownloadInProgress: false,
    errors: {},
};

/**
 * Recursively maps all child document files into flat array of DocumentFile records
 *
 * All but first level directories from nested structure returning from API will be flatten
 *
 * @param {Array<Object>} documentFileDTOs
 * @returns {Array<Object>}
 */
const flattenDirectoryContents = (documentFileDTOs = []) => {
    const documentFiles = documentFileDTOs.flatMap(childDocumentFileDTO => {
        if (childDocumentFileDTO.dir) {
            return flattenDirectoryContents(childDocumentFileDTO.children);
        }
        return childDocumentFileDTO;
    });
    return documentFiles;
};

const documentDownloadReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case documentDownloadActionTypes.STORE_DOCUMENTS: {
            const {documentFileDTOs} = payload;
            const documentFiles = documentFileDTOs ? {} : null;
            if (documentFileDTOs) {
                const partnerDocumentFiles = documentFileDTOs.reduce((acc, partnerFile) => {
                    return [
                        ...acc,
                        ...partnerFile.children,
                    ];
                }, []);
                partnerDocumentFiles.forEach(documentFileDTO => {
                    const dirContents = documentFileDTO.dir
                        ? flattenDirectoryContents(documentFileDTO.children) : [];
                    const contentsSize = dirContents.length > 0
                        ? dirContents.reduce((dirContentSize, contentDocumentFile) => {
                            dirContentSize += contentDocumentFile.fileSize;
                            return dirContentSize;
                        }, 0)
                        : 0;

                    const documentFile = new DocumentFile({})
                        .fromDTO({
                            ...documentFileDTO,
                            size: contentsSize || documentFileDTO.size,
                            id: hashSum(documentFileDTO.path || (documentFileDTO.name + documentFileDTO.createdAt)),
                        });

                    documentFiles[documentFile.id] = documentFile;

                    if (dirContents.length > 0) {
                        dirContents.forEach(childDocumentFileDTO => {
                            const childDocumentFile = new DocumentFile({})
                                .fromDTO({
                                    ...childDocumentFileDTO,
                                    parentId: documentFile.id,
                                });
                            documentFiles[childDocumentFile.id] = childDocumentFile;
                        });
                    }
                });
            }

            draft.documentFiles = documentFiles;
            break;
        }

        case documentDownloadActionTypes.SET_DOCUMENT_DOWNLOAD_STATUS: {
            const {documentFileId, isDownloading} = payload;

            const downloadingFiles = [...draft.downloadingDocuments];
            draft.downloadingDocuments = isDownloading
                ? downloadingFiles.concat([documentFileId])
                : downloadingFiles
                    .filter(downloadingDocumentFileId => downloadingDocumentFileId !== documentFileId);
            break;
        }

        case documentDownloadActionTypes.SET_SELECTED_DOCUMENTS_DOWNLOAD_STATUS: {
            const {isMultipleDocumentDownloadInProgress} = payload;

            draft.isMultipleDocumentDownloadInProgress = isMultipleDocumentDownloadInProgress;
            break;
        }

        default:
        // no-op
    }
}, initialState);

export default documentDownloadReducer;
