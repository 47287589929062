import {fork, put, select, take} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const getInvoiceSubmissionDetails = function* getInvoiceSubmissionDetails() {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_DETAILS);
        const {invoiceSubmissionId} = payload;

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_DETAILS_REQUEST,
            eafService.getInvoiceSubmission,
            {invoiceSubmissionId},
        );
        const resultAction = yield take([
            invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_DETAILS_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_DETAILS_REQUEST_FAILED,
        ]);

        if (!resultAction.error) {
            const {response: invoiceSubmission} = resultAction.payload;

            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION,
                payload: {invoiceSubmissionDTO: invoiceSubmission},
            });
        }
    }
};

export default getInvoiceSubmissionDetails;
