/**
 * @typedef {string} vehicleTonnages
 */

/**
 * Vehicle tonnages
 *
 * @enum {vehicleTonnages}
 */
export default {
    WEIGHT_VALUE_1: 'WEIGHT_VALUE_1',
    WEIGHT_VALUE_3: 'WEIGHT_VALUE_3',
    WEIGHT_VALUE_5: 'WEIGHT_VALUE_5',
};
