import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SelectOption = ({className, optionValue, optionText, onClick}) => {
    const selectOptionClassName = classNames('ace-c-select__option', className);

    return (
        <div
            className={selectOptionClassName}
            id={optionValue}
            onClick={() => onClick(optionValue)}
        >
            {optionText}
        </div>
    );
};

SelectOption.propTypes = {
    className: PropTypes.string,
    optionValue: PropTypes.string,
    optionText: PropTypes.string,
    onClick: PropTypes.func,
};

SelectOption.defaultProps = {
    className: '',
    optionText: '',
    optionValue: '',
    onClick: null,
};

export default SelectOption;
