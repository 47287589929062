/**
 * @typedef {string} mainService
 */

/**
 * Main service
 *
 * @enum {mainService}
 */

export default {
    TOWING: 'TOWING',
    ROADSIDE_ASSISTANCE: 'ROADSIDE_ASSISTANCE',
    JUMP_START: 'JUMP_START',
    OPENING: 'OPENING',
    RECOVERY: 'RECOVERY',
    MULTIPLE_SERVICES: 'MULTIPLE_SERVICES',
};
