import React from 'react';
import PropTypes from 'prop-types';

import './TableCell.scss';

const TableCell = props => {
    return (
        <div className={`ace-c-table-cell ${props.className}`}>
            {props.children}
        </div>
    );
};

TableCell.propTypes = {
    className: PropTypes.string,
};

TableCell.defaultProps = {
    className: '',
};

export default TableCell;
