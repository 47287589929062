import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Marker, Polygon} from '@react-google-maps/api';
import {withTranslations} from '@computerrock/formation-i18n';
import Tooltip from '../../ui-components/tooltip/Tooltip';
import InfoIcon from '../../ui-components/assets/icons/info-red.svg';
import CustomGoogleMap from '../../google-map/view-elements/CustomGoogleMap';
import * as mapSettings from '../../google-map/googleMapSettings';
import {ButtonPrimary, ButtonSecondary, Icon, Input} from '../../ui-components';
import {getGeolocationFromString, setIsLocationInsidePolygon} from '../../google-map/googleMapFunctions';
import {isMainServiceSelected} from '../utils/mainServices';
import {partnerSupportsFlatRate} from '../utils/pricing';
import {eafMainServices} from '../../ella-dispo-entity-types';

const DEFAULT_GEO_LOCATION = '-';

class MapSection extends React.Component {
    static propTypes = {
        invoiceSubmission: PropTypes.object.isRequired,
        errors: PropTypes.object,
        isDisabled: PropTypes.bool,
        pricingInfo: PropTypes.number,
        onDataChange: PropTypes.func.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        errors: {},
        isDisabled: false,
        pricingInfo: null,
        translate: null,
    };

    state = {
        isMapExtended: false,
        addressFieldError: '',
    };

    componentDidMount() {
        document.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.onKeyUp);
    }


    onKeyUp = event => {
        const {key} = event;
        const {isAddressInputFocused} = this.state;
        if (key !== 'Enter' || !isAddressInputFocused) {
            return;
        }
        event.preventDefault();
        this.setAddressAndLocationFromString();
    };

    onMapExtendClick = () => {
        if (this.state.isMapExtended) {
            return this.onMapButtonClick(true);
        }
        this.setState({
            isMapExtended: true,
            tempState: this.props.invoiceSubmission,
        });
    };

    onMapButtonClick = isCancel => {
        if (isCancel) {
            this.props.onDataChange({...this.state.tempState});
        }
        this.setState({
            isMapExtended: false,
            tempState: null,
        });
    };

    onAddressInputFocus = () => {
        this.setState({
            isAddressInputFocused: true,
        });
    };

    onAddressInputBlur = () => {
        this.setState({
            isAddressInputFocused: false,
        });
    };

    onAddressInputChange = ({value}) => {
        this.onLocationChangeError('');
        this.props.onDataChange({
            invoiceSubmissionAddressString: value,
            damageAddressStreet: value,
            damageCity: '',
            damagePostCode: '',
            damageCountry: '',
        });
    };

    setAddressAndLocationFromString = () => {
        const {invoiceSubmission} = this.props;
        getGeolocationFromString(invoiceSubmission.invoiceSubmissionAddressString)
            .then(geolocation => {
                if (geolocation) {
                    const {location, address} = geolocation;
                    if (address) {
                        return this.onLocationChange({location, address});
                    }
                    return this.onLocationChangeError(mapSettings.ADDRESS_VALIDATION_ERROR);
                }
                this.onLocationChangeError(mapSettings.ADDRESS_VALIDATION_ERROR);
            });
    };

    onLocationChange = ({location, address}) => {
        const streetAddress = address.num ? `${address.street} ${address.num}` : address.street;
        const partnerSupportsFlatRateFlag = partnerSupportsFlatRate(this.props.pricingInfo);
        this.props.onDataChange({
            invoiceSubmissionAddressString: address.formattedAddress || '',
            damageCity: address.city || '',
            damageAddressStreet: streetAddress || '',
            damagePostCode: address.postCode || '',
            damageCountry: address.country || '',
            damageLocation: location,
            isInvoiceSubmissionInsideVpPolygon: setIsLocationInsidePolygon({
                lat: location.lat,
                lng: location.lng,
                partnerSupportsFlatRateFlag,
                polygon: this.props.invoiceSubmission.vpPolygon,
            }),
        });
    };

    onLocationChangeError = error => {
        this.setState({
            addressFieldError: error,
        });
    };

    onMarkerDragEnd = data => {
        const {latLng} = data;
        const partnerSupportsFlatRateFlag = partnerSupportsFlatRate(this.props.pricingInfo);
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.props.onDataChange({
            damageLocation: {
                lng,
                lat,
            },
            isInvoiceSubmissionInsideVpPolygon: setIsLocationInsidePolygon({
                lat,
                lng,
                partnerSupportsFlatRateFlag,
                polygon: this.props.invoiceSubmission.vpPolygon,
            }),
        });
    };

    trimValuesForGeoLocation = geoLatLng => {
        if (geoLatLng) {
            return geoLatLng.toFixed(7);
        }
        return DEFAULT_GEO_LOCATION;
    };

    render() {
        const {invoiceSubmission, errors, pricingInfo, isDisabled, translate} = this.props;
        const {isMapExtended, addressFieldError} = this.state;
        const vpCoords = {
            lat: invoiceSubmission.vpLatitude,
            lng: invoiceSubmission.vpLongitude,
        };
        const isTowingSelected = isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.TOWING);
        const mapMarkers = [
            ...(invoiceSubmission.vpPolygon && !!pricingInfo ? invoiceSubmission.vpPolygon : []),
            ...(invoiceSubmission.damageLocation
            && invoiceSubmission.damageLocation.lat
            && invoiceSubmission.damageLocation.lng ? [invoiceSubmission.damageLocation] : []),
            ...(vpCoords.lat && vpCoords.lng ? [vpCoords] : []),
            ...(isTowingSelected && invoiceSubmission.towingLocation
            && invoiceSubmission.towingLocation.lat
            && invoiceSubmission.towingLocation.lng ? [invoiceSubmission.towingLocation] : []),
        ];
        const disableInputElementClass = {'disable-element': isDisabled};
        const extendIcon = isMapExtended
            ? <Icon dimension="lg" iconName="close" color="red" />
            : <Icon dimension="lg" iconName="map-extended" />;

        const mapContainerClass = classnames(
            classnames('ace-form-map__container', {
                'ace-form-map__container--full-screen': isMapExtended,
            }),
            disableInputElementClass,
        );
        const mapAddressFieldWrapperClass = classnames('ace-form-map__address-wrapper', {
            'ace-form-map__address-wrapper--full-screen': isMapExtended,
        });
        const mapButtonWrapperClass = classnames('ace-form-map__button-wrapper', {
            'ace-form-map__button-wrapper--full-screen': isMapExtended,
        });
        const addressError = errors['assignment.damage.address.street']
            || errors['assignment.damage.address.postCode']
            || errors['assignment.damage.address.city']
            || errors['assignment.damage.address.country']
            || errors['assignment.damage.location.longitude']
            || errors['assignment.damage.location.latitude']
            || addressFieldError;
        return (
            <>
                <div className="ace-grid__row ace-bottom-margin--sm">
                    <div className="col-xs--12">
                        <div className="overview-price__internal-invoice-text-wrapper">
                            <p className="ace-copy-m">{translate('map_section.field_title.location')}</p>
                            <Tooltip
                                message={translate('map_section.tooltip_message.location')}
                                position="left"
                            >
                                <img
                                    className="overview-price__internal-invoice-icon"
                                    src={InfoIcon}
                                    alt=""
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={isMapExtended ? 'ace-form-map__overlay' : ''}>
                    <div className={`ace-grid__row ${isMapExtended ? 'ace-form-map__wrapper' : 'ace-bottom-margin--md'}`}>
                        <div className={isMapExtended ? 'col-xs--12' : 'col-xs--6'}>
                            <div className={mapContainerClass}>
                                <div
                                    className="ace-form-map__extend-btn"
                                    onClick={this.onMapExtendClick}
                                >
                                    {extendIcon}
                                </div>
                                <CustomGoogleMap
                                    markers={mapMarkers}
                                >
                                    {invoiceSubmission.damageLocation.lng && invoiceSubmission.damageLocation.lat && (
                                        <Marker
                                            key="00"
                                            position={invoiceSubmission.damageLocation}
                                            icon={{
                                                url: '/assets/icons/marker.svg',
                                                scaledSize: new window.google.maps.Size(50, 50),
                                            }}
                                            draggable={true}
                                            onDragEnd={this.onMarkerDragEnd}
                                            zIndex={3}
                                        />
                                    )}
                                    {vpCoords.lng && vpCoords.lat && (
                                        <Marker
                                            key="01"
                                            position={vpCoords}
                                            icon={{
                                                url: '/assets/icons/home-marker.svg',
                                                scaledSize: new window.google.maps.Size(75, 75),
                                            }}
                                            zIndex={1}
                                        />
                                    )}
                                    {isTowingSelected
                                        && invoiceSubmission.towingLocation
                                        && invoiceSubmission.towingLocation.lat
                                        && invoiceSubmission.towingLocation.lng
                                        && (
                                            <Marker
                                                key="02"
                                                position={invoiceSubmission.towingLocation}
                                                icon={{
                                                    url: '/assets/icons/towing-marker.svg',
                                                    scaledSize: new window.google.maps.Size(72, 72),
                                                }}
                                                zIndex={2}
                                            />
                                        )}
                                    {invoiceSubmission.vpPolygon && !!pricingInfo && (
                                        <Polygon
                                            paths={invoiceSubmission.vpPolygon}
                                            options={mapSettings.polygonOptions}
                                        />
                                    )}
                                </CustomGoogleMap>
                            </div>
                        </div>
                        <div className={`col-xs--4 ${mapAddressFieldWrapperClass}`}>
                            <div className="ace-bottom-margin--sm">
                                <Input
                                    name="address"
                                    type="text"
                                    label={translate('map_section.input_label.address')}
                                    value={invoiceSubmission.invoiceSubmissionAddressString}
                                    onChange={this.onAddressInputChange}
                                    onFocus={this.onAddressInputFocus}
                                    onBlur={this.onAddressInputBlur}
                                    isDisabled={isDisabled}
                                    error={addressError && translate(`global.validation_messages.${addressError}`)}
                                    additionalAttributes={{maxLength: '100'}}
                                />
                            </div>
                            <div className="ace-bottom-margin--sm">
                                <p className="ace-copy-m">
                                    {`${translate('map_section.field_title.geocode')}: ${this.trimValuesForGeoLocation(invoiceSubmission.damageLocation?.lat)}, 
                                ${this.trimValuesForGeoLocation(invoiceSubmission.damageLocation?.lng)}`}
                                </p>
                            </div>
                            <ButtonSecondary
                                name="btnValidate"
                                label={translate('map_section.button_label.address_check')}
                                onClick={this.setAddressAndLocationFromString}
                                icon="location"
                                isDisabled={isDisabled}
                            />
                        </div>
                        <div className={`${mapButtonWrapperClass} col-xs--11 col-start-xs--2 ace-form-map__geolocation-wrapper ace-bottom-margin--md`}>
                            <p className="ace-copy-m">
                                {`${translate('map_section.field_title.geocode')}: ${this.trimValuesForGeoLocation(invoiceSubmission.damageLocation.lat)}, 
                                ${this.trimValuesForGeoLocation(invoiceSubmission.damageLocation.lng)}`}
                            </p>
                        </div>
                        <div className={`${mapButtonWrapperClass} col-xs--6 col-start-xs--2 ace-bottom-margin--md`}>
                            <Input
                                name="addressExtended"
                                type="text"
                                value={invoiceSubmission.invoiceSubmissionAddressString}
                                label={translate('map_section.input_label.address')}
                                onChange={this.onAddressInputChange}
                                onFocus={this.onAddressInputFocus}
                                onBlur={this.onAddressInputBlur}
                                isDisabled={isDisabled}
                                error={addressError && translate(`global.validation_messages.${addressError}`)}
                                additionalAttributes={{maxLength: '100'}}
                            />
                        </div>
                        <div className={`${mapButtonWrapperClass} col-xs--4`}>
                            <ButtonSecondary
                                name="btnValidateExtended"
                                label={translate('map_section.button_label.address_check')}
                                onClick={this.setAddressAndLocationFromString}
                                icon="location"
                                isDisabled={isDisabled}
                            />
                        </div>
                        <div className={`${mapButtonWrapperClass} col-xs--3 col-start-xs--4 ace-bottom-margin--md`}>
                            <ButtonSecondary
                                type="button"
                                label={translate('map_section.button_label.quit')}
                                name="btnMapBack"
                                onClick={() => { this.onMapButtonClick(true); }}
                                isDisabled={isDisabled}
                            />
                        </div>
                        <div className={`${mapButtonWrapperClass} col-xs--3 ace-bottom-margin--md`}>
                            <ButtonPrimary
                                type="button"
                                label={translate('map_section.button_label.save')}
                                name="btnMapSave"
                                onClick={() => this.onMapButtonClick(false)}
                                isDisabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslations(MapSection);
