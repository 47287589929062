import {produce} from 'immer';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import {ContractPartner} from '../ella-dispo-entity-types';

const initialState = {
    contractPartners: {},
};

const contractPartnerReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case contractPartnerActionTypes.STORE_CONTRACT_PARTNERS: {
            const {contractPartnersDTO} = payload;
            if (!contractPartnersDTO.length) {
                draft.contractPartners = {};
                break;
            }
            contractPartnersDTO.forEach(contractPartnerDTO => {
                draft.contractPartners[contractPartnerDTO.id] = new ContractPartner().fromDTO(contractPartnerDTO); // eslint-disable-line
            });
            break;
        }
    }
}, initialState);

export default contractPartnerReducer;
