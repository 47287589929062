import React, {Children} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorMessage from '../error-message/ErrorMessage';
import './RadioButtons.scss';

const RadioButtons = ({error, children, className, onChange}) => {
    const enhancedChildren = [];
    Children.forEach(children, child => {
        if (!child || typeof child === 'string') return child;

        enhancedChildren.push(React.cloneElement(child, {
            onClick: value => {
                onChange(value);
            },
        }));
    });
    const radioButtonsClassName = classNames('ace-radio-buttons__container', {
        'ace-radio-buttons__container--error': error,
    });
    return (
        <div className={className}>
            <div className={`ace-grid__row ${radioButtonsClassName}`}>
                {enhancedChildren}
            </div>
            <div className="ace-grid__row">
                <div className="col-xs--12">
                    <ErrorMessage error={error} />
                </div>
            </div>
        </div>
    );
};

RadioButtons.propTypes = {
    error: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

RadioButtons.defaultProps = {
    error: '',
    className: '',
};

export default RadioButtons;
