import {dafStatuses} from '../../ella-dispo-entity-types';

export const driverAssignmentTriggerTypes = {
    ACCEPT_CASE: 'ACCEPT_CASE',
    DECLINE_CASE: 'DECLINE_CASE',
    SCHEDULE_CASE: 'SCHEDULE_CASE',
    ASSIGN_DRIVER: 'ASSIGN_DRIVER',
    DRIVER_ACCEPT: 'DRIVER_ACCEPT',
    MANUAL_BILLABLE: 'MANUAL_BILLABLE',
    DISPO_CANCEL: 'DISPATCHER_CANCEL',
    GO_BACK: 'GO_BACK',
    SAVE_CHANGES: 'SAVE_CHANGES',
};

export const dispatchStatusMap = {
    ELLA: {
        text: 'Fall beendet',
        type: [dafStatuses.BILLABLE],
    },
    DECLINED: {
        text: 'Abgelehnt',
        type: [dafStatuses.DECLINED],
    },
};

export const defaultQueryParams = {
    size: 100,
};

export const driverAssignmentsParams = {
    SEARCH_DISPATCH: {
        query: {
            status: [
                dafStatuses.VP_ASSIGNED,
                dafStatuses.VP_ACCEPTED,
                dafStatuses.DRIVER_ASSIGNED,
                dafStatuses.DRIVER_ACCEPTED,
                dafStatuses.APPROACHING,
                dafStatuses.IN_SERVICE,
                dafStatuses.SERVICE_FINISHED,
                dafStatuses.DECLINED,
                dafStatuses.BILLABLE,
            ],
        },
    },
    DISPATCH_OPEN: {
        query: {
            ...defaultQueryParams,
            status: [
                dafStatuses.VP_ASSIGNED,
                dafStatuses.VP_ACCEPTED,
                dafStatuses.DRIVER_ASSIGNED,
                dafStatuses.DRIVER_ACCEPTED,
                dafStatuses.APPROACHING,
                dafStatuses.IN_SERVICE,
                dafStatuses.SERVICE_FINISHED,
            ],
        },
    },
    DISPATCH_ASSIGNED: {
        query: {
            ...defaultQueryParams,
            status: [
                dafStatuses.DRIVER_ASSIGNED,
                dafStatuses.DRIVER_ACCEPTED,
                dafStatuses.APPROACHING,
                dafStatuses.IN_SERVICE,
                dafStatuses.SERVICE_FINISHED,
            ],
        },
    },
    DISPATCH_ARCHIVED: {
        query: {
            ...defaultQueryParams,
            status: [
                dafStatuses.BILLABLE,
                dafStatuses.DECLINED,
            ],
        },
    },
};

export const AssignedStatuses = ['DRIVER_ASSIGNED', 'DRIVER_ACCEPTED', 'APPROACHING', 'IN_SERVICE', 'SERVICE_FINISHED'];
export const CancelableStatuses = ['DRIVER_ASSIGNED', 'DRIVER_ACCEPTED', 'APPROACHING', 'IN_SERVICE'];
