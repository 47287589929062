import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {NavLink} from 'react-router-dom';
import {matchPath, Link, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import keycloakAuth from '../../keycloakAuth';
import {UserRoles} from '../../ella-dispo-entity-types';
import {Ribbon, Icon} from '../../ui-components';
import routePaths from '../../routePaths';
import PartnerMessagesView from '../../messages/PartnerMessagesView';
import AceLogo from '../../assets/images/ACE_Logo.svg';
import config from '../../config';
import './Header.scss';
import DropdownWithTrigger from '../../ui-components/dropdown/DropdownWithTrigger';
import Dropdown from '../../ui-components/dropdown/Dropdown';

const Header = props => {
    const {createTranslateShorthand} = useTranslate();
    const translateHeader = createTranslateShorthand('header');
    const changeShowMessageStyle = showMessageBox => {
        if (showMessageBox) {
            document.body.style.overflow = 'hidden';
            return;
        }
        document.body.style.overflow = 'visible';
    };

    const getLogoClickRedirectionPath = (isUserSignIn, isEllaAppActive, isDispatchAppActive) => {
        let path;
        switch (true) {
            case !isUserSignIn:
                path = routePaths.OIDC_AUTHORIZE;
                break;
            case isUserSignIn && isEllaAppActive:
                path = routePaths.OPEN_INVOICE_SUBMISSIONS;
                break;
            case isUserSignIn && isDispatchAppActive:
                path = routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS;
                break;
            case isUserSignIn && !isEllaAppActive && !isDispatchAppActive:
                path = routePaths.OPEN_INVOICE_SUBMISSIONS;
        }
        return path;
    };

    const {isSessionValid, userAccount, location} = props;
    const {messageCount, messages} = props;
    const isEllaAppActive = !!matchPath(location.pathname, {
        path: routePaths.ELLA,
    });
    const isDispatchAppActive = !!matchPath(location.pathname, {
        path: routePaths.DISPATCH,
    });
    const logoTitleText = isEllaAppActive
        ? translateHeader('section_name.ella').toUpperCase()
        : (isDispatchAppActive ? translateHeader('section_name.dispo').toUpperCase() : '');
    const shouldShowAppLinks = userAccount && typeof userAccount === 'object' && Object.keys(userAccount).length
    && userAccount.userRoles.includes(UserRoles.ACCOUNTANT)
        && userAccount.userRoles.includes(UserRoles.DISPATCHER);
    const logoClass = classnames('ace-lower-header__logo-shape', {
        'ace-lower-header__logo-shape--has-title': isDispatchAppActive || isEllaAppActive,
    });
    const redirectionUrl = encodeURIComponent(`${config.REDIRECTION_URL}${location.pathname}`);
    const myProfileLink = `${config.KC_AUTH_URL}/realms/${config.KC_AUTH_REALM}/account?redirect_uri=${redirectionUrl}&client_id=${config.KC_CLIENT_ID}`;
    const passwordLink = `${config.KC_AUTH_URL}/realms/${config.KC_AUTH_REALM}/account/password?redirect_uri=${redirectionUrl}&client_id=${config.KC_CLIENT_ID}`;
    return (
        <div className="ace-header__wrapper">
            <div className="ace-upper-header">
                <div className="ace-grid__row">
                    <div className="ace-upper-header__navigation col-xs--12">
                        {isSessionValid && shouldShowAppLinks && !isDispatchAppActive && (
                            <NavLink
                                className="ace-upper-header__navigation-link"
                                to={routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS}
                            >
                                <Icon dimension="xs" iconName="assignment" />
                                <div className="ace-upper-header__navigation-title">{translateHeader('section_name.dispo')}</div>
                            </NavLink>
                        )}
                        {isSessionValid && shouldShowAppLinks && !isEllaAppActive && (
                            <NavLink
                                className="ace-upper-header__navigation-link"
                                to={routePaths.OPEN_INVOICE_SUBMISSIONS}
                            >
                                <Icon dimension="xs" iconName="book" />
                                <div className="ace-upper-header__navigation-title">{translateHeader('section_name.ella')}</div>
                            </NavLink>
                        )}
                        {isSessionValid && isEllaAppActive && (
                            <NavLink
                                className="ace-upper-header__navigation-link"
                                to={routePaths.SUPPORT}
                            >
                                <Icon dimension="smd" iconName="support" />
                                <div className="ace-upper-header__navigation-title">{translateHeader('section_name.support')}</div>
                            </NavLink>
                        )}
                        {isSessionValid && (
                            <a
                                className="ace-upper-header__navigation-link"
                                href="https://sites.google.com/computerrock.com/ella-handbuch/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <Icon dimension="sm" iconName="help" />
                                <div className="ace-upper-header__navigation-title">{translateHeader('link_text.help')}</div>
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className="ace-grid__row">
                <div className="col-xs--12">
                    <div className="ace-lower-header">
                        <Link
                            className={logoClass}
                            to={() => getLogoClickRedirectionPath(
                                isSessionValid, isEllaAppActive, isDispatchAppActive,
                            )}
                        >
                            <Ribbon color="red" width="50vw" />
                            <p className="ace-lower-header__logo-text">{logoTitleText}</p>
                            <img className="ace-lower-header__logo" src={AceLogo} alt="" />
                        </Link>
                        {isSessionValid && (
                            <Fragment>
                                <div className="ace-lower-header__navigation-top">
                                    {isEllaAppActive && (
                                        <Fragment>
                                            <NavLink
                                                className="ace-lower-header__navigation-link"
                                                activeClassName="ace-lower-header__navigation-link--active"
                                                to={routePaths.OPEN_INVOICE_SUBMISSIONS}
                                                isActive={(match, location) => {
                                                    if (match && match.isExact) return true;

                                                    return !!matchPath(location.pathname, {
                                                        path: [
                                                            routePaths.INVOICE_SUBMISSION,
                                                            routePaths.SUBMITTED_INVOICE_SUBMISSIONS,
                                                        ],
                                                    });
                                                }}
                                            >
                                                <div className="ace-lower-header__navigation-title">{translateHeader('section_name.start_page')}</div>
                                            </NavLink>
                                            <NavLink
                                                className="ace-lower-header__navigation-link"
                                                activeClassName="ace-lower-header__navigation-link--active"
                                                to={routePaths.ARCHIVE}
                                            >
                                                <div className="ace-lower-header__navigation-title">{translateHeader('section_name.archive')}</div>
                                            </NavLink>
                                            {!!Object.keys(userAccount).length
                                                && userAccount.userRoles.includes(UserRoles.ADMINISTRATOR) && (
                                                <NavLink
                                                    className="ace-lower-header__navigation-link"
                                                    activeClassName="ace-lower-header__navigation-link--active"
                                                    to={routePaths.DOCUMENTS}
                                                >
                                                    <div className="ace-lower-header__navigation-title">{translateHeader('section_name.documents')}</div>
                                                </NavLink>
                                            )}
                                        </Fragment>
                                    )}
                                    {isDispatchAppActive && (
                                        <Fragment>
                                            <NavLink
                                                className="ace-lower-header__navigation-link"
                                                activeClassName="ace-lower-header__navigation-link--active"
                                                to={routePaths.DISPATCH_OPEN_DRIVER_ASSIGNMENTS}
                                                isActive={(match, location) => {
                                                    if (match && match.isExact) return true;

                                                    return !!matchPath(location.pathname, {
                                                        path: [
                                                            routePaths.DISPATCH_DRIVER_ASSIGNMENT,
                                                            routePaths.DISPATCH_ASSIGNED_DRIVER_ASSIGNMENTS,
                                                        ],
                                                    });
                                                }}
                                            >
                                                <div className="ace-lower-header__navigation-title">{translateHeader('section_name.start_page')}</div>
                                            </NavLink>
                                            <NavLink
                                                className="ace-lower-header__navigation-link"
                                                activeClassName="ace-lower-header__navigation-link--active"
                                                to={routePaths.DISPATCH_ARCHIVE}
                                            >
                                                <div className="ace-lower-header__navigation-title">{translateHeader('section_name.archive')}</div>
                                            </NavLink>
                                            <NavLink
                                                className="ace-lower-header__navigation-link"
                                                activeClassName="ace-lower-header__navigation-link--active"
                                                to={routePaths.DRIVER}
                                            >
                                                <div className="ace-lower-header__navigation-title">{translateHeader('section_name.driver')}</div>
                                            </NavLink>
                                        </Fragment>
                                    )}
                                </div>
                                {(isEllaAppActive || isDispatchAppActive) && (
                                    <div className="ace-lower-header__user-options-wrapper">
                                        {isEllaAppActive && (
                                            <DropdownWithTrigger className="ace-lower-header__letter-icon-wrapper" handleStateChange={changeShowMessageStyle}>
                                                <div>
                                                    <Icon dimension="lg" iconName="letter" />
                                                    {messageCount > 0 && (
                                                    <div className="ace-lower-header__letter-icon-bubble">
                                                        <p className="ace-lower-header__message-count">{messageCount}</p>
                                                    </div>
                                                    )}
                                                </div>
                                                <Dropdown>
                                                    <PartnerMessagesView
                                                        messages={messages}
                                                    />
                                                </Dropdown>
                                            </DropdownWithTrigger>
                                        )}
                                        <DropdownWithTrigger className="ace-lower-header__user-icon-wrapper">
                                            <Icon dimension="lg" iconName="user" />
                                            {Object.keys(userAccount).length && (
                                                <Dropdown position="bottom-right">
                                                    <div className="ace-lower-header__greeting">
                                                        {translateHeader('dropdown_title.hello')} {userAccount.firstName}
                                                    </div>
                                                    <div
                                                        className="ace-lower-header__options-link"
                                                        onClick={() => {
                                                            window.location.href = myProfileLink;
                                                        }}
                                                    >
                                                        {translateHeader('dropdown_link_text.my_profile')}
                                                    </div>
                                                    <div
                                                        className="ace-lower-header__options-link"
                                                        onClick={() => {
                                                            window.location.href = passwordLink;
                                                        }}
                                                    >
                                                        {translateHeader('dropdown_link_text.change_password')}
                                                    </div>
                                                    {userAccount.userRoles.includes(UserRoles.ADMINISTRATOR) && (
                                                        <Link
                                                            className="ace-lower-header__options-link"
                                                            to={isEllaAppActive
                                                                ? routePaths.MANAGE_USERS
                                                                : routePaths.DISPATCH_MANAGE_USERS}
                                                        >
                                                            {translateHeader('dropdown_link_text.manage_users')}
                                                        </Link>
                                                    )}
                                                    <div
                                                        className="ace-lower-header__options-link"
                                                        onClick={props.submitUserSignOutForm}
                                                    >
                                                        {translateHeader('dropdown_link_text.logout')}
                                                    </div>
                                                </Dropdown>
                                            )}
                                        </DropdownWithTrigger>
                                    </div>
                                )}
                            </Fragment>
                        )}
                        <div className="ace-lower-header__bottom-shadow">
                            <div className="ace-lower-header__bottom-shadow--before" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    submitUserSignOutForm: PropTypes.func.isRequired,
    messageCount: PropTypes.number,
    isSessionValid: PropTypes.bool,
    userAccount: PropTypes.object,
    messages: PropTypes.array,
    location: PropTypes.object.isRequired,
};

Header.defaultProps = {
    isSessionValid: false,
    userAccount: null,
    messageCount: null,
    messages: null,
};

const mapStateToProps = state => ({
    isSessionValid: state.keycloakAuth.isSessionValid,
    userAccount: state.userManagement.user,
    messageCount: state.messagesReducers.totalUnreadCount,
    messages: state.messagesReducers.partnerMessages,
});

const mapDispatchToProps = dispatch => ({
    submitUserSignOutForm: () => dispatch(keycloakAuth.signOutUserAction),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Header));
