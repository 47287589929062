import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../checkbox/Checkbox';
import './CheckboxSelect.scss';

class CheckboxSelect extends React.Component {
    static propTypes = {
        options: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        checkboxName: PropTypes.string,
        isSingleValueFilter: PropTypes.bool,
        isDisabled: PropTypes.bool,
        values: PropTypes.array,
    };

    static defaultProps = {
        checkboxName: '',
        isSingleValueFilter: false,
        isDisabled: false,
        values: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedTypes: props.values,
        };
    }

    getValuesForArray = obj => {
        return Object.values(obj).map(curr => {
            return curr;
        });
    };

    clickOption = selectedType => {
        const {checkboxName, isSingleValueFilter, isDisabled} = this.props;
        if (isDisabled) {
            return;
        }
        this.setState(prevState => {
            const prevSelectedTypes = prevState.selectedTypes;
            const selectedTypes = isSingleValueFilter ? selectedType : (prevSelectedTypes.includes(selectedType)
                ? prevSelectedTypes.filter(type => type !== selectedType)
                : [...prevSelectedTypes, selectedType]);
            return {
                selectedTypes,
            };
        }, () => {
            this.props.onChange(checkboxName, this.state.selectedTypes);
        });
    };

    render() {
        const {selectedTypes} = this.state;
        const {options, isDisabled} = this.props;
        const wrapperClass = classnames('ace-c-checkbox-select__wrapper', {
            'ace-c-checkbox-select__wrapper--disabled': isDisabled,
        });
        const selectOptionClass = classnames('ace-c-checkbox-select__option', {
            'ace-c-checkbox-select__option--disabled': isDisabled,
        });

        return (
            <div className={wrapperClass}>
                {
                    this.getValuesForArray(options).map((option, index) => {
                        const type = option.type;
                        return (
                            <label
                                key={index}
                                id={type}
                                className={selectOptionClass}
                                onClick={() => { this.clickOption(type); }}
                                aria-selected={selectedTypes[type]}
                            >
                                <Checkbox
                                    isIconSmall
                                    isChecked={selectedTypes.includes(type)}
                                    isDisabled={isDisabled}
                                />
                                <span className="ace-c-checkbox-select__option-text">{option.text}</span>
                            </label>
                        );
                    })
                }
            </div>
        );
    }
}

export default CheckboxSelect;
