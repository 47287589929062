import {immerable} from 'immer';
import Entity from '../entity/Entity';

/**
 * InvoiceSubmissionAdditionalService entity type definition
 *
 * @typedef {Object} InvoiceSubmissionAdditionalService
 * @property {?string} type
 * @property {?number} amount
 * @property {?number} subtotal
 * @property {?boolean} autoAdded
 */

/**
 * InvoiceSubmissionAdditionalService entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const InvoiceSubmissionAdditionalServiceEntityDTOMapping = {
    type: {
        dtoProperty: 'type',
        defaultValue: '',
    },
    amount: {
        dtoProperty: 'amount',
        defaultValue: '',
    },
    subtotal: {
        dtoProperty: 'subtotal',
        defaultValue: '',
    },
    autoAdded: {
        dtoProperty: 'autoAdded',
        defaultValue: false,
    },
};

/**
 * InvoiceSubmissionAdditionalService entity
 *
 * @class
 * @extends Entity
 * @type {InvoiceSubmissionAdditionalService}
 */
export default class InvoiceSubmissionAdditionalService extends Entity {
    [immerable] = true;

    static entityDTOMapping = InvoiceSubmissionAdditionalServiceEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
