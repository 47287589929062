import {eafMainServices, JourneyContinuation} from '../../ella-dispo-entity-types';


export const eafMainServiceIcons = {
    [eafMainServices.ROADSIDE_ASSISTANCE]: 'pannenhilfe',
    [eafMainServices.JUMP_START]: 'starthilfe',
    [eafMainServices.OPENING]: 'fahrzeugoffnung',
    [eafMainServices.TOWING]: 'abschleppen',
    [eafMainServices.RECOVERY]: 'bergung',
    [eafMainServices.PICKUP]: 'pickup',
    [eafMainServices.MULTIPLE_SERVICES]: 'mehrere',
};

export const journeyContinuationMainServices = {
    [JourneyContinuation.YES]: [
        eafMainServices.ROADSIDE_ASSISTANCE,
        eafMainServices.OPENING,
        eafMainServices.JUMP_START,
        eafMainServices.RECOVERY,
    ],
    [JourneyContinuation.NO]: [
        eafMainServices.TOWING,
        eafMainServices.PICKUP,
        eafMainServices.ROADSIDE_ASSISTANCE,
        eafMainServices.OPENING,
        eafMainServices.JUMP_START,
        eafMainServices.RECOVERY,
    ],
    [JourneyContinuation.EMPTY_TRIP]: [
        eafMainServices.EMPTY_TRIP,
    ],
};

export const eafArchiveMainServices = [
    eafMainServices.ROADSIDE_ASSISTANCE,
    eafMainServices.JUMP_START,
    eafMainServices.OPENING,
    eafMainServices.TOWING,
    eafMainServices.RECOVERY,
    eafMainServices.PICKUP,
    eafMainServices.EMPTY_TRIP,
];
