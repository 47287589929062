import React from 'react';
import './TablePanel.scss';


const TablePanel = props => {
    return (
        <div className="ace-c-table-panel">
            {props.children}
        </div>
    );
};

export default TablePanel;
