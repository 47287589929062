import {take, put, select, fork} from 'redux-saga/effects';
import moment from 'moment';
import {invoiceSubmissionParams} from './constants/queryParams';
import * as userSelectors from '../user-management/userManagementSelectors';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import fetchRequest from '../application/sagas/fetchRequest';
import * as errorActionTypes from '../application/errorActionTypes';
import {parseErrorMessages} from '../utils/validationMessages';
import * as invoiceSubmissionSelectors from './invoiceSubmissionSelectors';
import {formAnchors} from './constants/formAnchors';
import {invoiceSubmissionViews} from './constants/invoiceSubmissionViews';
import {eafViewFromStatus} from './constants/invoiceSubmissionStatuses';
import getPricingInfoFlow from '../pricing/sagas/getPricingInfoFlow';
import {InvoiceSubmissionDataStates} from './constants/invoiceSubmissionDataStates';
import getMainServicesForSubCaseFlow from './sagas/getMainServicesForSubCaseFlow';
import {eafStatuses} from '../ella-dispo-entity-types';
import invoiceSubmissionFiles from '../invoiceSubmissionFiles';
import getInvoiceSubmissionMessages from '../messages/sagas/getInvoiceSubmissionMessages';

export const loadOpenInvoiceSubmissions = function* loadOpenInvoiceSubmissions() {
    const query = invoiceSubmissionParams.OPEN;
    const userAccount = yield select(userSelectors.getUserAccount);
    const isSearchViewActive = yield select(invoiceSubmissionSelectors.getIsSearchViewActive);
    if (isSearchViewActive) {
        yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS});
    }
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
        payload: {state: InvoiceSubmissionDataStates.LOADING},
    });
    yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_DATA});
    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST,
        eafService.getInvoiceSubmissions,
        {...query, contractPartnerIds: userAccount.partnerIds},
    );
    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_FAILED,
    ]);
    if (!resultAction.error) {
        const {data, totalCount} = resultAction.payload.response;
        yield put({
            type: invoiceSubmissionActionTypes.STORE_OPEN_INVOICE_SUBMISSIONS,
            payload: {invoiceSubmissionsDTO: data, totalCount},
        });
        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });
        return;
    }
    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
        payload: {state: InvoiceSubmissionDataStates.IDLE},
    });
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export const loadSubmittedInvoiceSubmissions = function* loadSubmittedInvoiceSubmissions() {
    const query = invoiceSubmissionParams.SUBMITTED;
    const userAccount = yield select(userSelectors.getUserAccount);
    const isSearchViewActive = yield select(invoiceSubmissionSelectors.getIsSearchViewActive);
    if (isSearchViewActive) {
        yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS});
    }
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
        payload: {state: InvoiceSubmissionDataStates.LOADING},
    });
    yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_DATA});
    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST,
        eafService.getInvoiceSubmissions,
        {...query, contractPartnerIds: userAccount.partnerIds},
    );
    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_FAILED,
    ]);
    if (!resultAction.error) {
        const {data} = resultAction.payload.response;
        yield put({
            type: invoiceSubmissionActionTypes.STORE_SUBMITTED_INVOICE_SUBMISSIONS,
            payload: {invoiceSubmissionsDTO: data},
        });
        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });
        return;
    }
    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
        payload: {state: InvoiceSubmissionDataStates.IDLE},
    });
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};

export const loadArchiveInvoiceSubmissions = function* loadArchiveInvoiceSubmissions(params) {
    const isSearchViewActive = yield select(invoiceSubmissionSelectors.getIsSearchViewActive);
    if (isSearchViewActive) {
        yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS});
    }
    const {search} = params.payload.location;
    const {size} = params;
    const query = invoiceSubmissionParams.ARCHIVE;
    const userAccount = yield select(userSelectors.getUserAccount);
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    const queryObject = Object.fromEntries(new URLSearchParams(search));
    const {page, statuses, mainServices, fromServiceStartDateTime} = queryObject;
    const {toServiceEndDateTime, sortBy, direction} = queryObject;
    const date = {
        ...(fromServiceStartDateTime ? {fromServiceStartDateTime} : {}),
        ...(toServiceEndDateTime ? {toServiceEndDateTime} : {}),
    };


    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
        payload: {state: InvoiceSubmissionDataStates.LOADING},
    });
    yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_DATA});

    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST,
        eafService.getInvoiceSubmissions,
        {
            ...query,
            contractPartnerIds: userAccount.partnerIds,
            ...(size ? {size} : {}),
            ...(statuses ? {statuses} : {}),
            ...(page && {page}),
            ...(mainServices && {mainServices}),
            ...(sortBy ? {sortBy} : {sortBy: 'SERVICE_START_DATE'}),
            ...(direction ? {direction} : {direction: 'DESC'}),
            ...(date || {}),
        },
    );

    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {data, totalCount, totalInvoicedCount} = resultAction.payload.response;

        yield put({
            type: invoiceSubmissionActionTypes.STORE_ARCHIVE_INVOICE_SUBMISSIONS,
            payload: {
                invoiceSubmissionsDTO: data,
                totalCount: totalCount,
                totalInvoicesCount: totalInvoicedCount,
            },
        });

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });
    } else {
        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
    }
};

export const loadInvoiceSubmission = function* loadInvoiceSubmission({payload}) {
    const {match, location} = payload;
    const {invoiceSubmissionId} = match.params;
    const {invoiceSubmissionStatus} = location;
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    const shouldSetInEditing = invoiceSubmissionStatus === eafStatuses.BILLABLE;

    yield put({
        type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE,
        payload: {invoiceSubmissionViewState: null, formAnchor: null},
    });

    yield put({
        type: invoiceSubmissionFiles.actionTypes.STORE_FILES,
        payload: {files: []},
    });

    yield put({type: errorActionTypes.DELETE_ERRORS});

    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
        payload: {state: InvoiceSubmissionDataStates.LOADING},
    });
    yield put({
        type: invoiceSubmissionActionTypes.STORE_FOLLOW_UP_INVOICE_SUBMISSION_MAIN_SERVICES,
        payload: {mainServices: null},
    });
    yield put({type: invoiceSubmissionActionTypes.CLEAR_INVOICE_SUBMISSION_DATA});
    yield fork(
        fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST,
        eafService.getInvoiceSubmission,
        {invoiceSubmissionId, shouldSetInEditing},
    );

    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_FAILED,
    ]);

    if (!resultAction.error) {
        const {response: invoiceSubmission} = resultAction.payload;
        const apmService = serviceManager.loadService('partnerManagementService');

        yield fork(getPricingInfoFlow, {
            payload: {
                contractPartnerId: invoiceSubmission.assignment?.contractPartnerId,
                serviceEndDateTime: invoiceSubmission.assignment?.serviceEndDateTime || moment().format(),
            },
        });

        yield fork(getInvoiceSubmissionMessages, {invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId});

        if (invoiceSubmission.invoiceSubmissionNumber !== 'A1') {
            yield fork(getMainServicesForSubCaseFlow, {
                payload: {
                    invoiceSubmissionId: invoiceSubmission.assignment.assignmentId + 'A1',
                },
            });
        }

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST,
            apmService.getContractPartner,
            {vpId: invoiceSubmission.assignment.contractPartnerId},
        );

        const resultContractPartnerAction = yield take([
            invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED,
        ]);

        if (!resultContractPartnerAction.error) {
            const {location} = payload;
            const {isFormOpen} = location;
            const shouldOpenForm = location.query.isFormOpen;
            const invoiceSubmissionViewState = isFormOpen || shouldOpenForm
                ? invoiceSubmissionViews.FORM : eafViewFromStatus[invoiceSubmission.status];
            const formAnchor = isFormOpen || shouldOpenForm ? formAnchors.ADDITIONAL_SERVICE_FORM : '';
            const {contractPartnerDTO} = resultContractPartnerAction.payload.response;
            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE,
                payload: {invoiceSubmissionViewState, formAnchor},
            });
            yield put({
                type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION,
                payload: {
                    invoiceSubmissionDTO: {
                        ...invoiceSubmission,
                        isFirstOpening: shouldSetInEditing,
                        assignment: {
                            ...invoiceSubmission.assignment,
                            contractPartnerInfo: contractPartnerDTO,
                        },
                    },
                },
            });

            yield put({
                type: invoiceSubmissionFiles.actionTypes.STORE_FILES,
                payload: {files: invoiceSubmission.attachments},
            });

            yield put({
                type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
                payload: {state: InvoiceSubmissionDataStates.IDLE},
            });
            return;
        }
        yield put({
            type: errorActionTypes.STORE_ERRORS,
            payload: {
                error: {
                    ...parseErrorMessages(resultAction.payload),
                },
            },
        });
        return;
    }
    yield put({
        type: errorActionTypes.STORE_ERRORS,
        payload: {
            error: {
                ...parseErrorMessages(resultAction.payload),
            },
        },
    });
};
