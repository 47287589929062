import {produce} from 'immer';
import {File} from '../../ella-dispo-entity-types';

const initialState = {
    files: {},
};


const createFilesReducer = context => {
    const {actionTypes} = context;

    return produce((draft, action) => {
        switch (action.type) {
            case actionTypes.STORE_FILES: {
                const filesDTOs = action.payload.files || [];
                const files = {};

                filesDTOs.forEach(fileDTO => {
                    const file = new File().fromDTO(fileDTO);
                    if (file.id) {
                        files[file.id] = file;
                    }
                });
                draft.files = files;
                break;
            }
            case actionTypes.STORE_FILE: {
                const fileDTO = action.payload.file;
                const file = new File().fromDTO(fileDTO);
                draft.files[file.id] = file;
                break;
            }
            case actionTypes.DELETE_FILE: {
                const {id} = action.payload;
                delete draft.files[id];
                break;
            }
        }
    }, initialState);
};

export default createFilesReducer;
