import {put, take, fork, select} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import * as driverAssignmentActionTypes from '../../driver-assignments/driverAssignmentActions';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import fetchRequest from '../../application/sagas/fetchRequest';

const downloadProofOfServiceFlow = function* () {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.START_DOWNLOAD_PROOF_OF_SERVICE_FLOW);
        const {invoiceSubmissionId} = payload;

        yield fork(fetchRequest, driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE_REQUEST,
            eafService.getProofOfServicePdf, {invoiceSubmissionId});

        const actionResult = yield take([
            driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE_REQUEST_SUCCEEDED,
            driverAssignmentActionTypes.DOWNLOAD_PROOF_OF_SERVICE_REQUEST_FAILED,
        ]);


        if (actionResult.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(actionResult.payload),
                    },
                },
            });
        }
    }
};

export default downloadProofOfServiceFlow;
