import * as pricingActions from './pricingActions';

const initialState = {
    pricingInfo: {},
};

const pricingReducer = (state = initialState, action) => {
    switch (action.type) {
        case pricingActions.STORE_PRICING_INFO: {
            const {pricingInfo} = action.payload;
            state = {
                ...state,
                pricingInfo,
            };
            break;
        }
    }
    return state;
};

export default pricingReducer;
