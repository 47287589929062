import {fork} from 'redux-saga/effects';
import keycloakAuth from './keycloakAuth';
import userManagementWatcher from './user-management/userManagementWatcher';
import supportWatcher from './support/supportWatcher';
import applicationWatcher from './application/applicationWatcher';
import driverAssignmentWatcher from './driver-assignments/driverAssignmentWatchers';
import invoiceSubmissionWatcher from './invoice-submission/invoiceSubmissionWatcher';
import supportFiles from './supportFiles';
import invoiceSubmissionFiles from './invoiceSubmissionFiles';
import messagesWatcher from './messages/messagesWatcher';
import documentDownloadWatcher from './document-download/documentDownloadWatcher';

// application sagas
export default [
    fork(applicationWatcher),
    fork(keycloakAuth.watcher),
    fork(supportFiles.watcher),
    fork(invoiceSubmissionFiles.watcher),
    fork(userManagementWatcher),
    fork(supportWatcher),
    fork(driverAssignmentWatcher),
    fork(invoiceSubmissionWatcher),
    fork(messagesWatcher),
    fork(documentDownloadWatcher),
];
