import React, {Children} from 'react';
import PropTypes from 'prop-types';
import '../../checkbox-select/CheckboxSelect.scss';


const FilterSelect = ({onChange, filterName, children}) => {
    const enhancedChildren = [];


    Children.forEach(children, child => {
        if (!child || typeof child === 'string') return child;

        enhancedChildren.push(React.cloneElement(child, {
            onClick: value => onChange(filterName, value),
        }));
    });

    return (
        <div className="ace-c-filter-select__wrapper">
            {enhancedChildren}
        </div>
    );
};

FilterSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    filterName: PropTypes.string.isRequired,
};

export default FilterSelect;
