const insertDriversByOrder = (sortedDrivers, driver) => {
    const driversFilteredByOrder = sortedDrivers.filter(sortedDriver => sortedDriver.order === driver.order);
    const lastIndexInOrder = sortedDrivers.indexOf(driversFilteredByOrder[driversFilteredByOrder.length - 1]);
    sortedDrivers.splice(lastIndexInOrder + 1, 0, driver);
};

export const sortDrivers = drivers => {
    const driversWithFirstNameOnly = drivers.filter(driver => (
        !driver.lastName && !driver.firstName.split(' ')[1]
        && isNaN(Number(driver.firstName))
    )).sort((driverA, driverB) => {
        if (driverA.order === driverB.order) {
            return driverA.firstName.toLowerCase() === driverB.firstName.toLowerCase() ? 0
                : driverA.firstName.toLowerCase() > driverB.firstName.toLowerCase() ? 1 : -1;
        }
        return driverA.order > driverB.order ? 1 : -1;
    });
    const driversWithNumbers = drivers.filter(driver => (
        (driver.lastName && (!isNaN(Number(driver.lastName))))
        || (driver.firstName && !isNaN(Number(driver.firstName)))
        || !isNaN(Number(driver.firstName.split(' ')[1]))
    )).sort((driverA, driverB) => {
        if (driverA.order === driverB.order) {
            const nameA = Number(driverA.lastName) || Number(driverA.firstName.split(' ')[1]) || Number(driverA.firstName);
            const nameB = Number(driverB.lastName) || Number(driverB.firstName.split(' ')[1]) || Number(driverB.firstName);
            return nameA - nameB;
        }
        return driverA.order > driverB.order ? 1 : -1;
    });
    const driversWithNoName = drivers.filter(driver => !driver.lastName && !driver.firstName);

    const sortedDrivers = drivers.filter(driver => (
        ((driver.lastName && isNaN(Number(driver.lastName)))
            || driver.firstName.split(' ')[1])
        && isNaN(Number(driver.firstName.split(' ')[1]))
        && isNaN(Number(driver.firstName))
    )).map(driver => {
        const newDriver = {...driver, order: driver.order};
        if (!newDriver.lastName) {
            newDriver.lastName = newDriver.firstName.split(' ')[1]
                ? newDriver.firstName.split(' ').map((string, index) => {
                    if (index > 0) {
                        return string;
                    }
                    return '';
                })?.join(' ').trim() : '';
            newDriver.firstName = newDriver.firstName.split(' ')[0];
        }
        return newDriver;
    })
        .sort((driverA, driverB) => {
            if (driverA.order === driverB.order) {
                return driverA.lastName.toLowerCase() === driverB.lastName.toLowerCase() ? 0
                    : driverA.lastName.toLowerCase() > driverB.lastName.toLowerCase() ? 1 : -1;
            }
            return driverA.order > driverB.order ? 1 : -1;
        });

    driversWithFirstNameOnly.forEach(driverWithFirstNameOnly => {
        insertDriversByOrder(sortedDrivers, driverWithFirstNameOnly);
    });
    driversWithNumbers.forEach(driverWithNumbers => {
        insertDriversByOrder(sortedDrivers, driverWithNumbers);
    });
    driversWithNoName.forEach(driverWithNoName => {
        insertDriversByOrder(sortedDrivers, driverWithNoName);
    });
    return sortedDrivers;
};
