import moment from 'moment';


const NULL_DATE_STRING = '-';

export const sortTypes = {
    byNumber: (a, b) => {
        return a - b;
    },
    byOrderAndDate: (a, b, secondA, secondB) => {
        if (a === b) {
            return sortTypes.byDate(secondA, secondB);
        }
        return a - b;
    },
    byString: (a, b) => {
        if (!a) {
            return 1;
        }
        if (!b) {
            return -1;
        }
        return a.localeCompare(b);
    },
    byDate: (a, b) => {
        if (a === NULL_DATE_STRING) {
            return -1;
        }
        if (b === NULL_DATE_STRING) {
            return 1;
        }
        const dateA = moment(a);
        const dateB = moment(b);
        if (dateA.isAfter(dateB)) {
            return 1;
        }
        if (dateA.isBefore(dateB)) {
            return -1;
        }
        return 0;
    },
};

export const getDispatchRowDefinition = areOpenRows => {
    return [
        {statusSortOrder: sortTypes.byNumber},
        {serviceTypeText: sortTypes.byString},
        {name: sortTypes.byString},
        {damageCity: sortTypes.byString},
        areOpenRows ? {emergencyCallDateTime: sortTypes.byDate} : {driverFirstName: sortTypes.byString},
        {statusSortOrder: sortTypes.byOrderAndDate},
    ];
};
