import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslate} from '@computerrock/formation-i18n';
import {Icon, Checkbox, HoverIcon} from '../../ui-components';
import {TableRow, TableCell} from '../../ui-components/table';

const TableRowFile = props => {
    const {documentFile, downloadingDocuments, submitDocumentDownloadForm} = props;
    const {selectedDocuments, selectDocumentForDownload} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateRow = createTranslateShorthand('table_row_file');
    const documentFileType = ['pdf', 'html', 'zip'].includes(documentFile.fileType)
        ? `file-${documentFile.fileType}` : 'file-dat';
    const isDownloadDisabled = downloadingDocuments.includes(documentFile.id);
    const shouldShowBothIcons = documentFile.fileType && ['pdf', 'html'].includes(documentFile.fileType.toLowerCase());

    const onDocumentDownloadClick = openInBrowser => e => {
        e.stopPropagation();
        submitDocumentDownloadForm({
            documentFile,
            openInBrowser,
        });
    };

    const onClickSelectDocumentForDownload = () => selectDocumentForDownload(documentFile.id);

    return (
        <TableRow
            wrapperClassName="ace-document-download-view__file-table-row-wrapper"
            className="ace-c-table-row--md ace-document-download-view__file-table-row"
            onClick={onClickSelectDocumentForDownload}
        >
            <TableCell className="col-sm--1">
                <Checkbox
                    className="ace-c-checkbox--secondary"
                    isChecked={selectedDocuments.includes(documentFile.id)}
                    isIconSmall
                />
            </TableCell>
            <TableCell className="col-sm--1 ace-c-table-cell--center">
                <Icon dimension="xxl" iconName={documentFileType} />
            </TableCell>
            <TableCell className="col-sm--4">
                <span className="ace-copy-s ace-wrap-word">
                    {documentFile.name}
                </span>
            </TableCell>
            <TableCell className="col-sm--2">
                <span className="ace-copy-s">
                    {documentFile.createdDate
                        ? documentFile.createdDate.format('DD.MM.YYYY') : ''}
                </span>
            </TableCell>
            <TableCell className="col-sm--2">
                <span className="ace-copy-s">
                    {documentFile.fileSizeWithUnit}
                </span>
            </TableCell>
            <TableCell className={classNames('col-sm--2', {
                'ace-c-table-cell--center': !shouldShowBothIcons,
                'ace-c-table-cell--space-around': shouldShowBothIcons,
            })}
            >
                <HoverIcon
                    icon="download"
                    hoverText={translateRow('hover_text.download')}
                    onClick={isDownloadDisabled ? null : onDocumentDownloadClick(false)}
                    isDisabled={isDownloadDisabled}
                />
                {shouldShowBothIcons && (
                    <HoverIcon
                        icon="eye"
                        hoverText={translateRow('hover_text.view')}
                        onClick={isDownloadDisabled ? null : onDocumentDownloadClick(true)}
                        isDisabled={isDownloadDisabled}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

TableRowFile.propTypes = {
    documentFile: PropTypes.object.isRequired,
    downloadingDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    submitDocumentDownloadForm: PropTypes.func.isRequired,
    selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectDocumentForDownload: PropTypes.func.isRequired,
};

export default TableRowFile;
