
export const DecliningReasons = {
    NO_CAPACITY: {
        type: 'NO_CAPACITY',
        icon: 'reason-no-capacity',
    },
    TOO_MUCH_TRAFFIC: {
        type: 'TOO_MUCH_TRAFFIC',
        icon: 'reason-traffic',
    },
    DAMAGE_LOCATION_TOO_FAR: {
        type: 'DAMAGE_LOCATION_TOO_FAR',
        icon: 'reason-location-too-far',
    },
    OTHER: {
        type: 'OTHER',
        icon: 'reason-other',
    },
};

export const CancelReasons = {
    ORDER_CANCELED: {
        type: 'ORDER_CANCELED',
        icon: 'reason-canceled',
    },
    NO_LOAD_CUSTOMER_NO_LONGER_ON_SITE: {
        type: 'NO_LOAD_CUSTOMER_NO_LONGER_ON_SITE',
        icon: 'reason-no-customer',
    },
    INCORRECT_LOCATION: {
        type: 'INCORRECT_LOCATION',
        icon: 'reason-incorrect-location',
    },
    NO_DAMAGE_DETECTED_VEHICLE_RAN_AGAIN: {
        type: 'NO_DAMAGE_DETECTED_VEHICLE_RAN_AGAIN',
        icon: 'reason-no-damage-detected',
    },
    FURTHER_ASSISTANCE_REFUSED: {
        type: 'FURTHER_ASSISTANCE_REFUSED',
        icon: 'reason-assistance-refused',
    },
};
