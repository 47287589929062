import * as supportFormActionsTypes from './supportFormActionsTypes';

const initialState = {
    isSupportModalOpen: false,
};

const supportFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case supportFormActionsTypes.SET_SUPPORT_MODAL_OPEN_STATUS:
            return {...state, isSupportModalOpen: action.payload.isSupportModalOpen};
        default:
            return state;
    }
};

export default supportFormReducer;
