import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {EmptyTripReasons} from '../constants/emptyTrip';
import ServiceOption from '../../application/view-elements/ServiceOption';
import {ErrorMessage} from '../../ui-components';

const EmptyTripReasonSection = props => {
    const {invoiceSubmission, onEmptyTripReasonChange, errors, isDisabled} = props;
    const {translate} = useTranslate();
    const mainServiceWrapperClass = classnames('ace-error__wrapper', {
        'ace-error__wrapper--errors': errors['assignment.emptyTripReason'],
    });
    return (
        <div className="ace-main-service__wrapper">
            <div className="ace-bottom-margin--sm">
                <span className="ace-copy-m">{translate('empty_trip_reason_section.section_title.why_empty_trip_occur')}</span>
            </div>
            <div className={mainServiceWrapperClass}>
                {Object.keys(EmptyTripReasons).map(type => EmptyTripReasons[type]).map(reason => {
                    const isSelected = reason.type === invoiceSubmission.emptyTripReason;
                    return (
                        <ServiceOption
                            key={reason.id}
                            isSelected={isSelected}
                            isDisabled={isDisabled}
                            isStrikeIcons={true}
                            serviceImages={reason.icon}
                            errorName="emptyTripReason"
                            onChange={() => onEmptyTripReasonChange(reason.type)}
                            name={translate(`empty_trip_reason_section.reason_title.${reason.type.toLowerCase()}`)}
                        />
                    );
                })}
            </div>
            {errors['assignment.emptyTripReason'] && (
                <div className="col-xs--12">
                    <ErrorMessage
                        error={translate(`global.validation_messages.${errors['assignment.emptyTripReason']}`)}
                    />
                </div>
            )}
        </div>
    );
};

EmptyTripReasonSection.propTypes = {
    invoiceSubmission: PropTypes.object.isRequired,
    onEmptyTripReasonChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isDisabled: PropTypes.bool,
};

EmptyTripReasonSection.defaultProps = {
    errors: {},
    isDisabled: false,
};

export default EmptyTripReasonSection;
