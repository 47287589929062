import {put, take, fork, select} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as errorActionTypes from '../../application/errorActionTypes';
import {parseErrorMessages} from '../../utils/validationMessages';
import {InvoiceSubmissionDataStates} from '../constants/invoiceSubmissionDataStates';

const downloadInvoicePdf = function* downloadInvoicePdf() {
    const {serviceManager} = yield select(state => state.application);
    const eafService = serviceManager.loadService('ellaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.DOWNLOAD_INVOICE_SUBMISSION_PDF);
        const {path, fileId} = payload;

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.DOWNLOADING},
        });

        yield fork(
            fetchRequest,
            invoiceSubmissionActionTypes.DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST,
            eafService.downloadSingleFile,
            {path, fileName: fileId},
        );

        const actionResult = yield take([
            invoiceSubmissionActionTypes.DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_FAILED,
        ]);

        yield put({
            type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_REQUEST_STATE,
            payload: {state: InvoiceSubmissionDataStates.IDLE},
        });

        if (actionResult.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        ...parseErrorMessages(actionResult.payload),
                    },
                },
            });
        }
    }
};

export default downloadInvoicePdf;
