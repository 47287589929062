import {take, call, select, put} from 'redux-saga/effects';
import * as userManagementActionTypes from '../userManagementActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as errorActionTypes from '../../application/errorActionTypes';

const unregisterPushNotifications = function* registerPushNotifications() {
    const {serviceManager} = yield select(state => state.application);
    const deumService = serviceManager.loadService('userManagementService');
    while (true) {
        yield take(userManagementActionTypes.SIGN_OUT_USER);
        yield call(fetchRequest, userManagementActionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST,
            deumService.unregisterPushNotifications);

        const unsubscribeActionResult = yield take([
            userManagementActionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_SUCCEEDED,
            userManagementActionTypes.UNREGISTER_PUSH_NOTIFICATIONS_REQUEST_FAILED,
        ]);

        if (unsubscribeActionResult.error) {
            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        errorDetail: unsubscribeActionResult.payload,
                    },
                },
            });
        }
    }
};

export default unregisterPushNotifications;
