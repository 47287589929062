import {all, fork} from 'redux-saga/effects';
import refreshingPartnersMessages from './sagas/refreshingPartnersMessages';
import sendMessageFlow from './sagas/sendMessageFlow';

const messagesWatcher = function* messagesWatcher() {
    yield all([
        fork(sendMessageFlow),
        fork(refreshingPartnersMessages),
    ]);
};

export default messagesWatcher;
