import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Parser} from 'html-to-react';
import {resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import routePaths from '../routePaths';
import {DEFAULT_DATE_FORMAT} from './constants/dates';
import {Icon, CaseStatePill, SearchRow, SearchDataCell, SearchTableBody} from '../ui-components';
import {TableCell} from '../ui-components/table';
import {highlightResults} from '../utils/searchResults';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import {eafStatusPillTypes} from './constants/invoiceSubmissionStatuses';
import {convertNumberToCurrency} from './utils/conversion';
import {getServiceInfo} from './utils/mainServices';

const SearchedInvoiceSubmissionsView = props => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateView = createTranslateShorthand('searched_invoice_submissions_view');
    const {searchResults, searchTerm, isSearchPending} = props;
    const HTMLParser = new Parser();

    return (
        <Fragment>
            {!isSearchPending && (searchResults.length ? (
                <SearchTableBody>
                    {searchResults.map((invoiceSubmission, index) => {
                        const memberNo = invoiceSubmission.assignment.member.membershipNumber;
                        const licencePlate = invoiceSubmission.assignment.vehicle.licensePlate
                            ? invoiceSubmission.assignment.vehicle.licensePlate.replace(/^(.*)-$/, '$1')
                            : '-';
                        const linkTo = resolveRoute(routePaths.INVOICE_SUBMISSION, {
                            invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
                        }, {invoiceSubmissionStatus: invoiceSubmission.status});
                        const {
                            serviceIcon,
                            serviceName,
                        } = getServiceInfo(invoiceSubmission.serviceType, invoiceSubmission.isEmptyTrip, invoiceSubmission.emptyTripReason, translate); // eslint-disable-line
                        return (
                            <SearchRow
                                key={index}
                                linkTo={linkTo}
                            >
                                <TableCell className="col-xs--2 search-row__table-cell">
                                    <CaseStatePill type={eafStatusPillTypes[invoiceSubmission.status]}>
                                        {translate(`global.eaf_status.${invoiceSubmission.status.toLowerCase()}`)}
                                    </CaseStatePill>
                                </TableCell>
                                <TableCell className="col-xs--4 search-row__table-cell">
                                    {serviceIcon
                                        ? (
                                            <Fragment>
                                                <div className="search-row__service-img">
                                                    <Icon dimension="lg" iconName={serviceIcon} color="grey" />
                                                </div>
                                                <p className="search-results-view__value">
                                                    {serviceName}
                                                </p>
                                            </Fragment>
                                        ) : <span className="ace-copy-m">---</span>}
                                </TableCell>
                                <TableCell
                                    className="col-xs--2 search-row__table-cell search-row__table-cell--space-between"
                                >
                                    <SearchDataCell
                                        label={translateView('search_data_cell_label.case_number')}
                                    >
                                        {/* eslint-disable-next-line */}
                                        {HTMLParser.parse(highlightResults(searchTerm, invoiceSubmission.searchedSubmissionName, true))}
                                    </SearchDataCell>
                                    <SearchDataCell
                                        label={translateView('search_data_cell_label.license_plate')}
                                    >{HTMLParser.parse(highlightResults(searchTerm, licencePlate))}
                                    </SearchDataCell>
                                </TableCell>
                                <TableCell
                                    className="col-xs--2 search-row__table-cell search-row__table-cell--space-between"
                                >
                                    <SearchDataCell label={translateView('search_data_cell_label.date')}>
                                        {invoiceSubmission.assignment.emergencyCallDateTime ? invoiceSubmission.assignment.emergencyCallDateTime.format(DEFAULT_DATE_FORMAT) : '---'}
                                    </SearchDataCell>
                                    <SearchDataCell label={translateView('search_data_cell_label.member_number')}>
                                        {HTMLParser.parse(highlightResults(searchTerm, memberNo))}
                                    </SearchDataCell>
                                </TableCell>
                                <TableCell
                                    className="col-xs--2 search-row__table-cell search-row__table-cell--space-between"
                                >
                                    <SearchDataCell label={translateView('search_data_cell_label.total')}>
                                        {`${convertNumberToCurrency(invoiceSubmission.total) || '-'} €`}
                                    </SearchDataCell>
                                    <SearchDataCell
                                        label={translateView('search_data_cell_label.payment_proposal_number')}
                                    >
                                        {/* eslint-disable-next-line */}
                                        {HTMLParser.parse(highlightResults(searchTerm, invoiceSubmission.paymentProposalNumber))}
                                    </SearchDataCell>
                                </TableCell>
                            </SearchRow>
                        );
                    })}
                </SearchTableBody>
            ) : (
                <ScreenMessage
                    messageParagraphs={[
                        translate('global.screen_message.no_order'),
                        translate('global.screen_message.check_input'),
                        translate('global.screen_message.note'),
                    ]}
                />
            ))}
            {isSearchPending
                && (<ScreenMessage messageParagraphs={[translate('global.screen_message.search')]} isLoading />)}
        </Fragment>
    );
};

SearchedInvoiceSubmissionsView.propTypes = {
    searchResults: PropTypes.array,
    searchTerm: PropTypes.string,
    isSearchPending: PropTypes.bool,
};

SearchedInvoiceSubmissionsView.defaultProps = {
    searchTerm: '',
    isSearchPending: false,
    searchResults: null,
};

export default SearchedInvoiceSubmissionsView;
