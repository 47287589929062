import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as driverAssignmentSelectors from '../driverAssignmentSelectors';
import * as driverAssignmentsActions from '../driverAssignmentActions';


const withDriverAssignmentSearch = DataConsumer => {
    const DataProvider = props => {
        const {
            driverAssignmentSearchResults,
            isSearchPending,
            isSearchTermCleared,
            searchDriverAssignments,
            clearSearchResults,
            searchTerm,
            isSearchViewActive,
        } = props;

        return (
            <DataConsumer
                {...props}
                driverAssignmentSearchResults={driverAssignmentSearchResults}
                isSearchPending={isSearchPending}
                isSearchTermCleared={isSearchTermCleared}
                searchDriverAssignments={searchDriverAssignments}
                clearSearchResults={clearSearchResults}
                searchTerm={searchTerm}
                isSearchViewActive={isSearchViewActive}
            />
        );
    };

    DataProvider.propTypes = {
        driverAssignmentSearchResults: PropTypes.array.isRequired,
        isSearchPending: PropTypes.bool.isRequired,
        isSearchTermCleared: PropTypes.bool.isRequired,
        searchDriverAssignments: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchTerm: PropTypes.string.isRequired,
        isSearchViewActive: PropTypes.bool.isRequired,
    };

    const mapStateToProps = state => {
        const getDriverAssignmentsSearchedResults = driverAssignmentSelectors.createSearchedDriverAssignmentsSelector();
        return {
            driverAssignmentSearchResults: getDriverAssignmentsSearchedResults(state),
            isSearchPending: state.driverAssignments.isSearchPending,
            isSearchTermCleared: state.driverAssignments.isSearchTermCleared,
            searchTerm: state.driverAssignments.searchTerm,
            isSearchViewActive: state.driverAssignments.isSearchViewActive,
        };
    };
    const mapDispatchToProps = dispatch => ({
        searchDriverAssignments:
                payload => dispatch({type: driverAssignmentsActions.START_DRIVER_ASSIGNMENTS_SEARCH_FLOW, payload}),
        clearSearchResults: () => dispatch({type: driverAssignmentsActions.CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS}),
    });

    return connect(mapStateToProps, mapDispatchToProps)(DataProvider);
};

export default withDriverAssignmentSearch;
