import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {resolveRoute, Link} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import routePaths from '../routePaths';
import './PartnerMessagesView.scss';

const PartnerMessagesView = ({messages}) => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateView = createTranslateShorthand('partner_message_view');

    return (
        <Fragment>
            <div className="ace-c-partner-messages-view">
                <div className="ace-c-partner-messages-view__box ace-c-partner-messages-view__box--header">
                    <span className="ace-c-partner-messages-view__label">
                        {translateView('section_title.latest_messages')}
                    </span>

                    <Link to={routePaths.SUPPORT} className="ace-c-partner-messages-view__mail-link">
                        {translateView('link_text.contact_ace')}
                    </Link>
                </div>
                <div className="ace-c-partner-messages-view__messages-wrapper">
                    {messages && !!messages.length && messages.map(message => {
                        const date = message.createdAt ? `${message.createdAt.format('DD.MM.YYYY, HH:mm')} Uhr` : '-';
                        const textClassName = classNames('ace-c-partner-messages-view__message', {
                            'ace-c-partner-messages-view__message--unread': !message.isRead,
                        });
                        const messageContent = message.text.replaceAll(/<[^>]*>/g, ' ').trim();
                        return (
                            <Link
                                key={message.messageId}
                                className="ace-c-partner-messages-view__box ace-c-partner-messages-view__box--message"
                                to={resolveRoute(routePaths.INVOICE_SUBMISSION,
                                    {invoiceSubmissionId: message.invoiceSubmissionId})}
                            >
                                <div className="ace-c-partner-messages-view__message-wrapper">
                                    <p className={textClassName}>
                                        {message.text.length > 120 ? `${messageContent.substring(0, 120)}...` : messageContent}
                                    </p>
                                    <div className="ace-c-partner-messages-view__footer">
                                        <p className="ace-c-partner-messages-view__font-small--link">
                                            {translateView('field_title.case_number')} {message.invoiceSubmissionId}
                                        </p>
                                        <span className="ace-c-partner-messages-view__label">
                                            {date}
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                    {!messages && (
                    <div className="ace-c-partner-messages-view__loading-box">
                        <h4 className="ace-h4 loading-dots">
                            {translate('global.screen_message.loading')}
                        </h4>
                    </div>
                    )}
                    {messages && messages.length === 0 && (
                    <div className="ace-c-partner-messages-view__loading-box">
                        <h4 className="ace-h4">
                            {translateView('section_title.no_messages')}
                        </h4>
                    </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

PartnerMessagesView.propTypes = {
    messages: PropTypes.array,
};

PartnerMessagesView.defaultProps = {
    messages: null,
};

export default PartnerMessagesView;
