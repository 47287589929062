import React from 'react';
import PropTypes from 'prop-types';

const List = ({children, className}) => {
    return (
        <div className={className}>
            {children}
        </div>
    );
};

List.propTypes = {
    className: PropTypes.string,
};

List.defaultProps = {
    className: '',
};

export default List;
