import {put} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';


const clearInvoiceSubmissionSearchResults = function* () {
    yield put({
        type: invoiceSubmissionActionTypes.SET_INVOICE_SUBMISSION_SEARCH_STATE,
        payload: {isSearchResultsCleared: true},
    });
};
export default clearInvoiceSubmissionSearchResults;
