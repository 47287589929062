import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import {resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import routePaths from '../../../routePaths';
import {TableRow, TableCell} from '../../../ui-components/table';
import {Icon} from '../../../ui-components';
import './DriverAssignmentRow.scss';
import {dafStatuses} from '../../../ella-dispo-entity-types';
import {dafStatusIconColors, dafStatusIcons} from '../../constants/driverAssignmentStatuses';


const DriverAssignmentRow = ({driverAssignment, areOpenCases}) => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateRow = createTranslateShorthand('driver_assignment_row');
    const iconStatusClassName = classnames('ace-c-driver-assignment-row__icon-wrapper', {
        'ace-c-driver-assignment-row__icon-wrapper--red': driverAssignment.status === dafStatuses.VP_ASSIGNED,
        'ace-c-driver-assignment-row__icon-wrapper--grey': driverAssignment.status === dafStatuses.SERVICE_FINISHED,
    });
    const isTowingSubService = driverAssignment.status === dafStatuses.IN_SERVICE
        && driverAssignment.onGoingServiceType === 'TOWING';
    const dateString = driverAssignment.emergencyCallDateTime ? driverAssignment.emergencyCallDateTime.calendar() : '---';
    const color = isTowingSubService ? '' : dafStatusIconColors[driverAssignment.status] || '';

    const addressString = driverAssignment.damage.address.postCode && driverAssignment.damage.address.city
        ? `${driverAssignment.damage.address.postCode} ${driverAssignment.damage.address.city}` : '---';

    const scheduleCaseTime = `${translateRow('table_cell.shipment')}, 
    ${moment(driverAssignment.scheduledDateTime).format('HH:mm')} 
    ${translate('global.time.hours')} - 
    ${moment(driverAssignment.scheduledDateTime).format('DD.MM.yyyy')}`;

    return (
        <TableRow
            className="ace-c-table-row--md"
            linkTo={resolveRoute(routePaths.DISPATCH_DRIVER_ASSIGNMENT, {
                driverAssignmentId: driverAssignment.assignmentId,
            })}
        >
            <TableCell className="col-xs--3">
                <div className={iconStatusClassName}>
                    <Icon
                        dimension="md"
                        iconName={isTowingSubService ? 'towing-status' : dafStatusIcons[driverAssignment.status]}
                        color={color}
                    />
                </div>
                <span
                    className="ace-copy-s"
                >{isTowingSubService ? translateRow('table_cell.shipment')
                    : driverAssignment.scheduledDateTime !== null
                        ? scheduleCaseTime
                        : translate(`global.daf_status_text.${driverAssignment.status.toLowerCase()}`)}
                </span>
            </TableCell>
            <TableCell className="col-xs--3">
                <span
                    className="ace-copy-s"
                >{driverAssignment.serviceType
                    ? translate(`global.daf_main_services.${driverAssignment.serviceType.toLowerCase()}`)
                    : translate('global.daf_main_services.roadside_assistance')}
                </span>
            </TableCell>
            <TableCell className="col-xs--2">
                <span className="ace-copy-s">{driverAssignment.assignmentId}</span>
            </TableCell>
            <TableCell className="col-xs--2">
                <span className="ace-copy-s">{addressString}</span>
            </TableCell>
            <TableCell className="col-xs--2 ace-c-driver-assignment-row__last-column">
                <span className="ace-copy-s">
                    {areOpenCases
                        ? dateString : driverAssignment.driverName
                    }
                </span>
                <Icon dimension="md" iconName="arrow-forward" className="ace-c-driver-assignment-row__arrow-icon" />
            </TableCell>
        </TableRow>
    );
};

DriverAssignmentRow.propTypes = {
    driverAssignment: PropTypes.object,
    areOpenCases: PropTypes.bool,
};

DriverAssignmentRow.defaultProps = {
    driverAssignment: null,
    areOpenCases: false,
};

export default DriverAssignmentRow;
