import React from 'react';
import {useTranslate} from '@computerrock/formation-i18n';

const PageNotFoundView = () => {
    const {translate} = useTranslate();
    return (
        <div>{translate('page_not_found.section_title.not_found')}</div>
    );
};

export default PageNotFoundView;
