import React from 'react';
import './DriverList.scss';

const DriverList = ({children}) => {
    return (
        <div className="ace-driver-assignment-active-view__drivers-list">
            {children}
        </div>
    );
};

export default DriverList;
