import {select, put, race, fork, take} from 'redux-saga/effects';
import * as driverAssignmentActionTypes from '../driverAssignmentActions';
import * as errorActionTypes from '../../application/errorActionTypes';
import * as userProfileSelectors from '../../user-management/userManagementSelectors';
import {driverAssignmentsParams} from '../constants/driverAssignmentMappings';
import fetchRequest from '../../application/sagas/fetchRequest';


const searchDriverAssignmentsFlow = function* () {
    const {serviceManager} = yield select(state => state.application);
    const dafService = serviceManager.loadService('driverAssignmentFlowService');
    while (true) {
        const {payload} = yield take(driverAssignmentActionTypes.START_DRIVER_ASSIGNMENTS_SEARCH_FLOW);
        const userAccount = yield select(userProfileSelectors.getUserAccount);
        const {searchTerm} = payload;
        yield put({
            type: driverAssignmentActionTypes.STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS,
            payload: {
                isSearchStarted: true,
                searchTerm,
            },
        });
        const query = driverAssignmentsParams.SEARCH_DISPATCH.query;
        yield fork(fetchRequest, driverAssignmentActionTypes.SEARCH_DRIVER_ASSIGNMENTS_REQUEST,
            dafService.getAssignments, {
                ...query,
                partnerIds: userAccount.partnerIds,
                searchPhrase: searchTerm,
            });
        const resultAction = yield race({
            success: take(driverAssignmentActionTypes.SEARCH_DRIVER_ASSIGNMENTS_REQUEST_SUCCEEDED),
            error: take(driverAssignmentActionTypes.SEARCH_DRIVER_ASSIGNMENTS_REQUEST_FAILED),
            clear: take(driverAssignmentActionTypes.CLEAR_DRIVER_ASSIGNMENTS_SEARCH_RESULTS),
        });

        if (resultAction.success) {
            const driverAssignments = resultAction.success.payload.response.results.data;
            yield put({
                type: driverAssignmentActionTypes.STORE_DRIVER_ASSIGNMENT_SEARCH_RESULTS,
                payload: {driverAssignments},
            });
            yield put({
                type: driverAssignmentActionTypes.SET_DRIVER_ASSIGNMENTS_STATE,
                payload: {isLoading: false},
            });
            yield put({
                type: driverAssignmentActionTypes.STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS,
                payload: {isSearchingEnded: true},
            });
            continue;
        }

        if (resultAction.clear) {
            yield put({
                type: driverAssignmentActionTypes.STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS,
                payload: {isSearchResultsCleared: true, isSearchingEnded: true},
            });
            continue;
        }

        if (resultAction.error) {
            yield put({
                type: driverAssignmentActionTypes.STORE_DRIVER_ASSIGNMENT_SEARCH_STATUS,
                payload: {isSearchingEnded: true},
            });

            yield put({
                type: errorActionTypes.STORE_ERRORS,
                payload: {
                    error: {
                        errorDetail: resultAction.payload,
                    },
                },
            });
        }
    }
};

export default searchDriverAssignmentsFlow;
