import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../checkbox/Checkbox';
import '../../checkbox-select/CheckboxSelect.scss';


const FilterOption = ({value, onClick, isChecked, children}) => {
    return (
        <label
            className="ace-c-checkbox-select__option"
            onClick={() => onClick(value)}
        >
            <Checkbox isIconSmall isChecked={isChecked} />
            <span className="ace-c-checkbox-select__option-text">{children}</span>
        </label>
    );
};

FilterOption.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    isChecked: PropTypes.bool.isRequired,
};

FilterOption.defaultProps = {
    value: '',
    onClick: () => {},
};

export default FilterOption;
