import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceSubmissionSelectors from './invoiceSubmissionSelectors';
import AppLayoutView from '../application/AppLayoutView';
import {SearchInput} from '../ui-components';
import AccountingPanel from './InvoiceSubmissionsPanel';
import {getRowDefinition} from './utils/table';
import './InvoiceSubmissionsView.scss';
import withInvoiceSubmissionSearch from './view-elements/withInvoiceSubmissionSearch';
import SearchedInvoiceSubmissionsView from './SearchedInvoiceSubmissionsView';
import CreateAccountingView from './CreateInvoiceSubmissionView';

const dateField = 'assignment.serviceStartDateTime';
const rowDefinition = getRowDefinition({dateField});

const OpenInvoiceSubmissionsView = props => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translateView = createTranslateShorthand('open_invoice_submissions_view');
    const {resultCount, invoiceSubmissionSearchResults, isSearchPending, isSearchTermCleared} = props;
    const {openInvoiceSubmissions, invoiceSubmissionDataState, searchTerm} = props;
    const {searchInvoiceSubmissions, clearSearchResults, isSearchViewActive} = props;
    return (
        <AppLayoutView>
            <div className="ace-invoice-submissions-view">
                <div className="ace-grid__row ace-invoice-submissions-view__header">
                    <div className="col-start-xs--3 col-xs--8 col-start-xl--3 col-xl--5">
                        <SearchInput
                            hasSearchResults={isSearchViewActive && invoiceSubmissionSearchResults.length === 0}
                            placeholder={translateView('search_input_placeholder.search_term')}
                            label={translateView('search_input_label.search_term')}
                            isSearchTermCleared={isSearchTermCleared}
                            doSearching={searchInvoiceSubmissions}
                            clearSearchResults={clearSearchResults}
                            additionalAttributes={{maxLength: '50'}}
                        />
                    </div>
                </div>

                {!isSearchViewActive ? (
                    <>
                        <div className="ace-grid__row ace-c-manual-case-wrapper">
                            <CreateAccountingView />
                        </div>
                        <AccountingPanel
                            resultCount={resultCount}
                            invoiceSubmissions={openInvoiceSubmissions}
                            dateField="serviceStartDateTime"
                            rowDefinition={rowDefinition}
                            emptyResultMessage={[translate('global.screen_message.empty_result')]}
                            loadingMessage={[translate('global.screen_message.loading')]}
                            invoiceSubmissionDataState={invoiceSubmissionDataState}
                        />
                    </>
                ) : (
                    <SearchedInvoiceSubmissionsView
                        isSearchPending={isSearchPending}
                        searchResults={invoiceSubmissionSearchResults}
                        searchTerm={searchTerm}
                    />
                )}
            </div>
        </AppLayoutView>
    );
};

OpenInvoiceSubmissionsView.propTypes = {
    openInvoiceSubmissions: PropTypes.array.isRequired,
    resultCount: PropTypes.number.isRequired,
    invoiceSubmissionSearchResults: PropTypes.array.isRequired,
    isSearchPending: PropTypes.bool.isRequired,
    isSearchTermCleared: PropTypes.bool.isRequired,
    searchInvoiceSubmissions: PropTypes.func.isRequired,
    clearSearchResults: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    invoiceSubmissionDataState: PropTypes.string.isRequired,
    isSearchViewActive: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    const getOpenInvoiceSubmissions = invoiceSubmissionSelectors.createOpenInvoiceSubmissions();
    return {
        openInvoiceSubmissions: getOpenInvoiceSubmissions(state),
        invoiceSubmissionDataState: state.invoiceSubmission.invoiceSubmissionDataState,
        resultCount: state.invoiceSubmission.totalCount,
    };
};

export default withInvoiceSubmissionSearch(connect(mapStateToProps, null)(OpenInvoiceSubmissionsView));
