import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Checkbox.scss';
import Icon from '../icon/Icon';

const Checkbox = ({onChange, isChecked, className, isDisabled, isIconSmall}) => {
    const onCheckboxChange = () => {
        onChange(!isChecked);
    };

    const boxClassName = classnames('ace-c-checkbox', {
        'ace-c-checkbox--checked': isChecked,
        'ace-c-checkbox--disabled': isDisabled,
        'ace-c-checkbox--icon-small': isIconSmall,
    },
    className);

    const fill = isDisabled ? '' : 'red';
    const dimension = isIconSmall ? 'xxs' : 'sm';

    return (
        <div className={boxClassName} onClick={onCheckboxChange}>
            {isChecked && (
                <Icon dimension={dimension} iconName="checkmark" color={fill} />
            )}
        </div>
    );
};

Checkbox.propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isIconSmall: PropTypes.bool,
};

Checkbox.defaultProps = {
    onChange: () => {},
    isChecked: false,
    className: '',
    isDisabled: false,
    isIconSmall: false,
};

export default Checkbox;
