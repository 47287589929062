import React from 'react';
import PropTypes from 'prop-types';

const buttonHOC = WrappedStyledComponent => {
    const ButtonHOC = props => {
        const className = `ace-hoc-button ${props.className} ${props.isDisabled ? 'ace-hoc-button--is-disabled' : ''}`;
        const newClassName = props.className ? `${props.className}__hoc-inner` : '';

        const changedProps = {
            ...props,
            className: `ace-hoc-button__inner ${newClassName}`,
        };

        return (
            <div className={className}>
                <label htmlFor={props.name} className="ace-hoc-button__label">
                    <input
                        id={props.name}
                        disabled={props.isDisabled}
                        name={props.name}
                        value={props.value ? props.value : props.name}
                        type={props.type === 'submit' ? 'submit' : 'button'}
                        className="ace-hoc-button__input"
                        onClick={props.onClick}
                    />
                    <WrappedStyledComponent {...changedProps} />
                </label>
            </div>
        );
    };

    ButtonHOC.propTypes = {
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        type: PropTypes.oneOf(['submit', 'button']),
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
        onClick: PropTypes.func.isRequired,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        isInGrid: PropTypes.bool,
    };

    ButtonHOC.defaultProps = {
        className: '',
        type: 'button',
        isDisabled: false,
        value: null,
        isInGrid: false,
    };

    return ButtonHOC;
};

export default buttonHOC;
