import {produce} from 'immer';
import {User} from '../ella-dispo-entity-types';
import * as actionTypes from './userManagementActionTypes';
import recordPersistenceOperations from '../utils/recordPersistenceOperations';
import recordPersistenceStates from '../utils/recordPersistenceStates';


const initialState = {
    user: {},
    users: {},
    currentUsersIds: [],
    persistenceOperation: recordPersistenceOperations.NO_OPERATION,
    persistenceState: recordPersistenceStates.IDLE,
    temporaryUser: {},
};

const userReducer = produce((draft, action) => {
    const {payload, type} = action;
    switch (type) {
        case actionTypes.STORE_USER_ACCOUNT: {
            const {userAccountDTO} = payload;
            draft.user = new User().fromDTO(userAccountDTO);
            break;
        }
        case actionTypes.CLEAR_USERS_DATA: {
            draft.currentUsersIds = [];
            break;
        }
        case actionTypes.STORE_USERS: {
            const {userDTOs} = payload;
            if (!userDTOs.length) {
                draft.users = {};
                draft.currentUsersIds = [];
                break;
            }
            userDTOs.forEach(userDTO => {
                draft.currentUsersIds.push(userDTO.userId);
                const newUser = new User().fromDTO(userDTO);
                draft.users[newUser.id] = newUser;
            });
            break;
        }

        case actionTypes.STORE_USER: {
            const {userDTO} = payload;
            const newUser = new User().fromDTO(userDTO);
            draft.currentUsersIds.push(userDTO.userId);
            draft.users[newUser.id] = newUser;
            break;
        }

        case actionTypes.UPDATE_USER: {
            const {userDTO} = payload;
            const newUser = new User().fromDTO(userDTO);
            draft.users[newUser.id] = newUser;
            break;
        }

        case actionTypes.DELETE_USER: {
            const {userId} = payload;
            delete draft.users[userId];
            draft.currentUsersIds.splice(draft.currentUsersIds.findIndex(id => id === userId), 1);
            break;
        }

        case actionTypes.SET_USER_PERSISTENCE_STATE: {
            const {persistenceOperation, persistenceState, userId} = action.payload;
            if (persistenceState) {
                draft.persistenceState = persistenceState;
            }
            if (persistenceOperation) {
                draft.persistenceOperation = persistenceOperation;
            }
            if (userId) {
                const user = draft.users[userId];
                draft.temporaryUser = user || {};
            }
            break;
        }
        default:
        // no-op
    }
}, initialState);

export default userReducer;
