/**
 * Record persistence operations
 */
export default {
    NO_OPERATION: 'NO_OPERATION',
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    LIST: 'LIST',
};
