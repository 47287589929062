import {take, put} from 'redux-saga/effects';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';

const changingViewState = function* () {
    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.CHANGE_INVOICE_SUBMISSION_VIEW_STATE);
        yield put({type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_VIEW_STATE, payload});
    }
};

export default changingViewState;
