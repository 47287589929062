
import resourceEndpoints from './resourceEndpoints';
import handleResponse from './handleResponse';
/**
 * ECS DriverApp Ella User Management (DEUM) microservice client
 *
 * @constructor
 */
export default class MSClientACEMemberProfileAMP {
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            // ensure SERVICE_URL has trailing slash
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/') : '',
        };

        // add SERVICE_URL to each resource endpoint
        this.resourceEndpoints = Object.keys(resourceEndpoints)
            .reduce((parsedResourceEndpoints, endpointKey) => {
                parsedResourceEndpoints[endpointKey] = this.serviceParameters.SERVICE_URL
                    + resourceEndpoints[endpointKey];
                return parsedResourceEndpoints;
            }, {});

        return {
            checkIfMemberExists: this.checkIfMemberExists,
            setAccessToken: this.setAccessToken,
        };
    }

    accessToken = null;

    checkIfMemberExists = params => {
        const {memberId} = params;
        const urlPath = `${this.resourceEndpoints['MEMBERS']}/${memberId}`;
        return fetch(
            urlPath,
            {
                headers: {
                    'Authorization': `Bearer ${this.accessToken}`,
                },
                method: 'HEAD',
            },
        ).then(handleResponse);
    };


    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };
}
