import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import {withRouter} from '@computerrock/formation-router';
import {withTranslations} from '@computerrock/formation-i18n';
import {SortDirection, goToNewSortingDashboard, getSortedTableData} from '../utils/table';
import {FilterDropdown, FilterOrder} from '../ui-components/filters';
import {TableHeader, TablePanel, TableBody, TableHeaderCell} from '../ui-components/table';
import ScreenMessage from '../application/view-elements/ScreenMessage';
import './InvoiceSubmissionsPanel.scss';
import OpenInvoiceSubmissionRow from './view-elements/rows/OpenInvoiceSubmissionRow';
import {TabLinks, TabLink} from '../ui-components';
import routePaths from '../routePaths';
import {InvoiceSubmissionDataStates as InvoiceSubmissionDataState} from './constants/invoiceSubmissionDataStates';
import {getFromLocalStorage, saveToLocalStorage} from './utils/localStorage';

const SORTING_STORAGE_KEY = 'sorting';

class InvoiceSubmissionsPanel extends React.Component {
    static propTypes = {
        resultCount: PropTypes.number,
        invoiceSubmissions: PropTypes.array,
        dateField: PropTypes.string.isRequired,
        rowDefinition: PropTypes.array.isRequired,
        emptyResultMessage: PropTypes.array.isRequired,
        loadingMessage: PropTypes.array.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        invoiceSubmissionDataState: PropTypes.string.isRequired,
        translate: PropTypes.func,
    };

    static defaultProps = {
        resultCount: 0,
        invoiceSubmissions: [],
        translate: null,
    };

    constructor(props) {
        super(props);
        const queryObject = qs.parse(props.location.search, {ignoreQueryPrefix: true});
        const {sortColumnId, sortDirection} = getFromLocalStorage(SORTING_STORAGE_KEY);
        const columnId = queryObject.sortColumnId || sortColumnId;
        const direction = queryObject.sortDirection || sortDirection;
        this.state = {
            sortColumnId: +columnId || 5,
            sortDirection: direction || SortDirection.DESC,
        };
    }

    onSortChange = (columnId, sortDirection) => {
        this.setState({
            sortColumnId: columnId,
            sortDirection: sortDirection,
        }, () => {
            const {history, location} = this.props;
            const {sortColumnId, sortDirection} = this.state;
            saveToLocalStorage(SORTING_STORAGE_KEY, this.state);
            goToNewSortingDashboard({history, location, sortDirection, sortColumnId});
        });
    };

    render() {
        const {resultCount, rowDefinition, dateField, emptyResultMessage, loadingMessage} = this.props;
        const {invoiceSubmissionDataState, invoiceSubmissions, translate} = this.props;
        const {sortColumnId, sortDirection} = this.state;
        return (
            <Fragment>
                <TabLinks>
                    <TabLink linkTo={routePaths.OPEN_INVOICE_SUBMISSIONS} tabCount={resultCount}>
                        {translate('invoice_submissions_panel.tab_link.open')}
                    </TabLink>
                    <TabLink linkTo={routePaths.SUBMITTED_INVOICE_SUBMISSIONS}>
                        {translate('invoice_submissions_panel.tab_link.submitted')}
                    </TabLink>
                </TabLinks>
                <TablePanel>
                    <TableHeader className="ace-c-invoice-submission-panel__table-header">
                        <TableHeaderCell
                            title={translate('invoice_submissions_panel.table_header.status')}
                            className="col-sm--2"
                        />
                        <TableHeaderCell
                            title={translate('invoice_submissions_panel.table_header.service')}
                            className="col-sm--1"
                        />
                        <TableHeaderCell className="col-sm--2">
                            <FilterDropdown
                                label={translate('invoice_submissions_panel.filter_dropdown_label.case_number')}
                                isActive={sortColumnId === 2}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={2}
                                    sortDirection={sortColumnId === 2 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-sm--2">
                            <FilterDropdown
                                label={translate('invoice_submissions_panel.filter_dropdown_label.member_name')}
                                isActive={sortColumnId === 3}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={3}
                                    sortDirection={sortColumnId === 3 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-sm--2">
                            <FilterDropdown
                                label={translate('invoice_submissions_panel.filter_dropdown_label.licence_plate')}
                                isActive={sortColumnId === 4}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={4}
                                    sortDirection={sortColumnId === 4 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-sm--1">
                            <FilterDropdown
                                label={translate('invoice_submissions_panel.filter_dropdown_label.date')}
                                isActive={sortColumnId === 5}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={5}
                                    sortDirection={sortColumnId === 5 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                        <TableHeaderCell className="col-sm--2 ace-c-table-cell--right">
                            <FilterDropdown
                                label={translate('invoice_submissions_panel.filter_dropdown_label.amount')}
                                isDropdownRight
                                isActive={sortColumnId === 6}
                            >
                                <FilterOrder
                                    onSortChange={this.onSortChange}
                                    name={6}
                                    sortDirection={sortColumnId === 6 ? sortDirection : ''}
                                />
                            </FilterDropdown>
                        </TableHeaderCell>
                    </TableHeader>
                    {invoiceSubmissionDataState !== InvoiceSubmissionDataState.LOADING ? (
                        <TableBody hasRows={!!invoiceSubmissions.length}>
                            {invoiceSubmissions.length ? getSortedTableData({
                                tableData: invoiceSubmissions,
                                rowDefinition,
                                sortColumnId,
                                sortDirection,
                            }).map(invoiceSubmission => {
                                return (
                                    <OpenInvoiceSubmissionRow
                                        key={`${invoiceSubmission.invoiceSubmissionId}-${invoiceSubmission.assignment.assignmentName}`}
                                        invoiceSubmission={invoiceSubmission}
                                        dateField={dateField}
                                    />
                                );
                            })
                                : (
                                    <ScreenMessage
                                        messageParagraphs={emptyResultMessage}
                                    />
                                )}
                        </TableBody>
                    ) : (<ScreenMessage messageParagraphs={loadingMessage} isLoading />)}
                </TablePanel>
            </Fragment>
        );
    }
}

export default withRouter(withTranslations(InvoiceSubmissionsPanel));
