import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Tooltip.scss';


const Tooltip = ({children, message, className, position}) => {
    const componentClassName = classnames('ace-c-tooltip', className);
    return (
        <div className={componentClassName}>
            <div className="ace-c-tooltip__icon">
                {children}
            </div>
            <p className={classnames('ace-c-tooltip__message', {
                'ace-c-tooltip__message--left': position === 'left',
                'ace-c-tooltip__message--right': position === 'right',
                'ace-c-tooltip__message--top-left': position === 'top-left',
                'ace-c-tooltip__message--top': position === 'top',
            })}
            >
                {message}
            </p>
        </div>
    );
};

Tooltip.propTypes = {
    message: PropTypes.string.isRequired,
    position: PropTypes.string,
    className: PropTypes.string,
};

Tooltip.defaultProps = {
    position: 'right',
    className: '',
};

export default Tooltip;
