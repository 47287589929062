import {immerable} from 'immer';
import Entity from '../entity/Entity';
import {Location} from '../location';
import {driversAvailability} from '../../user-management/constants/driversAvailability';
import UserRoles from './UserRoles';


/**
 * User entity type definition
 *
 * @typedef {Object} User
 * @property {?string} id
 * @property {?string} firstName
 * @property {?string} lastName
 * @property {?string} username
 * @property {?string} email
 * @property {?array} userRoles
 * @property {?string} userActivityStatus
 * @property {?array} partnerIds
 * @property {?string} phoneNumber
 * @property {?string} latitude
 * @property {?string} longitude
 * @property {?number} order
 */

/**
 * User entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const userEntityDTOMapping = {
    id: 'userId',
    firstName: {
        dtoProperty: 'firstName',
        defaultValue: '',
    },
    lastName: {
        dtoProperty: 'lastName',
        defaultValue: '',
    },
    username: {
        dtoProperty: 'username',
        defaultValue: '',
    },
    email: {
        dtoProperty: 'email',
        defaultValue: '',
    },
    userRoles: {
        dtoProperty: 'userRoles',
        fromDTO: roles => (roles ? roles.map(role => {
            return getUserRole(role);
        }) : []),
    },
    partnerIds: {
        dtoProperty: 'vpIds',
        fromDTO: ids => (ids ? ids.map(id => {
            return id.replace(/^.*\/(\d+)$/, '$1');
        }) : []),
    },
    phoneNumber: {
        dtoProperty: 'phoneNumber',
        defaultValue: '',
    },
    userActivityStatus: {
        dtoProperty: 'userActivityStatus',
        defaultValue: '',
    },
    location: {
        dtoProperty: 'location',
        defaultValue: new Location(),
        entity: Location,
    },
};

/**
 * User entity
 *
 * @class
 * @extends Entity
 * @type {User}
 */
export default class User extends Entity {
    [immerable] = true;

    static entityDTOMapping = userEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }

    get order() {
        return this.userActivityStatus ? driversAvailability[this.userActivityStatus].order : 99;
    }
}

export const getUserRole = roleString => {
    const userRole = UserRoles[roleString];
    if (!userRole) {
        return UserRoles.ANONYMOUS;
    }
    return userRole;
};
