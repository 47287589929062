export const ExportFileTypes = {
    CSV: {
        type: 'CSV',
        text: 'CSV',
        ext: '.csv',
    },
    XLS: {
        type: 'XLS',
        text: 'XLS',
        ext: '.xls',
    },
    XLSX: {
        type: 'XLSX',
        text: 'XLSX',
        ext: '.xlsx',
    },
};
