import React from 'react';
import PropTypes from 'prop-types';
import './ErrorMessage.scss';

const ErrorMessage = ({error}) => (
    <div className="ace-c-error-message">{error}</div>
);

ErrorMessage.propTypes = {
    error: PropTypes.string,
};

ErrorMessage.defaultProps = {
    error: '',
};

export default ErrorMessage;
