import React, {Fragment} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {withTranslations} from '@computerrock/formation-i18n';
import {isMainServiceSelected} from '../utils/mainServices';
import {ErrorMessage, IconOption} from '../../ui-components';
import MainServiceProviderHOC from './MainServiceProviderHOC';
import {eafMainServiceIcons} from '../constants/invoiceSubmissionMainServices';
import {JourneyContinuation, eafMainServices} from '../../ella-dispo-entity-types';


class MainServicesSection extends React.Component {
    static propTypes = {
        invoiceSubmission: PropTypes.object.isRequired,
        availableMainServices: PropTypes.array.isRequired,
        onMainServicesChange: PropTypes.func.isRequired,
        errors: PropTypes.object,
        isDisabled: PropTypes.bool,
        translate: PropTypes.func,
    };

    static defaultProps = {
        errors: {},
        isDisabled: false,
        translate: null,
    };

    state = {
        showRecoveryToastMessage: false,
        showPickupToastMessage: false,
    };

    onChangeMainService = serviceData => {
        const invoiceSubmission = {...this.props.invoiceSubmission};
        const isJourneyContinuationNotPossible = invoiceSubmission.journeyContinuation === JourneyContinuation.NO;
        const selected = {...invoiceSubmission.decodedMainServices};
        if (selected[serviceData]) {
            delete selected[serviceData];
        } else {
            selected[serviceData] = serviceData;
        }
        const isTowing = isMainServiceSelected(selected, eafMainServices.TOWING);

        const isPickup = isMainServiceSelected(selected, eafMainServices.PICKUP);
        const isPickupToastMessageShown = serviceData === eafMainServices.PICKUP
            && invoiceSubmission.billingType === 'TIME' && isPickup;
        const clearServices = isJourneyContinuationNotPossible && (!isPickup && !isTowing);
        const isRecovery = isMainServiceSelected(selected, eafMainServices.RECOVERY);
        const isRecoveryToastMessageShown = serviceData === eafMainServices.RECOVERY
            && invoiceSubmission.billingType === 'FIXED_PRICE' && isRecovery;

        if (serviceData === eafMainServices.ROADSIDE_ASSISTANCE) {
            delete selected[eafMainServices.OPENING];
            delete selected[eafMainServices.JUMP_START];
        }
        if (serviceData === eafMainServices.JUMP_START || serviceData === eafMainServices.OPENING) {
            delete selected[eafMainServices.ROADSIDE_ASSISTANCE];
        }

        this.setState({
            showRecoveryToastMessage: isRecoveryToastMessageShown,
            showPickupToastMessage: isPickupToastMessageShown,
        }, () => {
            this.props.onMainServicesChange({
                decodedMainServices: (clearServices ? {} : selected),
            });
        });
    };

    handleToastMessages = () => {
        this.setState({
            showRecoveryToastMessage: false,
            showPickupToastMessage: false,
        });
    };

    render() {
        const {invoiceSubmission, availableMainServices, errors, isDisabled, translate} = this.props;
        const {showRecoveryToastMessage, showPickupToastMessage} = this.state;
        const isTowingSelected = isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.TOWING);
        const isPickUpSelected = isMainServiceSelected(invoiceSubmission.decodedMainServices, eafMainServices.PICKUP);
        const isJourneyContinuationPossible = invoiceSubmission.journeyContinuation === JourneyContinuation.YES;
        const pickupToastMessage = showPickupToastMessage
            ? translate('main_services_section.toast_message.pickup')
            : '';
        const recoveryToastMessage = showRecoveryToastMessage
            ? translate('main_services_section.toast_message.recovery')
            : '';
        const mainServiceWrapperClass = classnames('ace-error__wrapper', {
            'ace-error__wrapper--errors': errors.mainServices,
        });

        return (
            <Fragment>
                <div className="ace-main-service__wrapper">
                    <div className="ace-bottom-margin--sm">
                        <span className="ace-copy-m">{translate('main_services_section.section_title.rendered_services')}</span>
                    </div>
                    <div className={mainServiceWrapperClass}>
                        {availableMainServices.map(serviceData => {
                            const isSelected = !!invoiceSubmission.decodedMainServices[serviceData];
                            const isRecoveryService = serviceData === eafMainServices.RECOVERY;
                            const isPickUpService = serviceData === eafMainServices.PICKUP;
                            const isJumpStartService = serviceData === eafMainServices.JUMP_START;
                            const isVehicleOpeningService = serviceData === eafMainServices.OPENING;
                            const isRoadsideAssistanceService = serviceData === eafMainServices.ROADSIDE_ASSISTANCE;
                            const shouldDisableService = !isJourneyContinuationPossible
                                && (!isPickUpSelected && !isTowingSelected);
                            const isRecoveryDisabled = isRecoveryService && shouldDisableService;
                            const isRoadsideAssistanceDisabled = isRoadsideAssistanceService
                                && shouldDisableService;
                            const isJumpStartDisabled = shouldDisableService && isJumpStartService;
                            const isVehicleOpeningDisabled = shouldDisableService
                                && isVehicleOpeningService;
                            const toastMessage = isRecoveryService
                                ? recoveryToastMessage : isPickUpService ? pickupToastMessage : '';
                            return (
                                <IconOption
                                    key={serviceData}
                                    isSelected={isSelected}
                                    iconName={eafMainServiceIcons[serviceData]}
                                    name={translate(`global.eaf_main_service.${serviceData.toLowerCase()}`)}
                                    onChange={() => this.onChangeMainService(serviceData)}
                                    errorName="selectedServices"
                                    // eslint-disable-next-line
                                    isStrikeIcons={invoiceSubmission.journeyContinuation === JourneyContinuation.EMPTY_TRIP}
                                    isDisabled={
                                        isRecoveryDisabled
                                        || isRoadsideAssistanceDisabled
                                        || isJumpStartDisabled
                                        || isVehicleOpeningDisabled
                                        || isDisabled
                                    }
                                    toastMessageClassName="ace-main-service__toast-message"
                                    toastMessage={toastMessage}
                                    handleToastMessage={this.handleToastMessages}
                                    {...serviceData}
                                />
                            );
                        })}
                    </div>
                    {errors.mainServices && (
                        <div className="col-xs--12">
                            <ErrorMessage error={translate(`global.validation_messages.${errors.mainServices}`)} />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default MainServiceProviderHOC(withTranslations(MainServicesSection));
