import React from 'react';
import PropTypes from 'prop-types';
import {TableBody} from '../table';
import './SearchTableBody.scss';

const SearchTableBody = ({className, children}) => {
    return (
        <TableBody className={`search-table-body ${className}`}>
            {children}
        </TableBody>
    );
};

SearchTableBody.propTypes = {
    className: PropTypes.string,
};

SearchTableBody.defaultProps = {
    className: '',
};

export default SearchTableBody;
