import {produce} from 'immer';
import * as messagesActionTypes from './messagesActionTypes';
import {Message} from '../ella-dispo-entity-types/message';
import {sortingMessagesByDate} from './utils/sortMessages';

const initialState = {
    invoiceSubmissionMessages: [],
    partnerMessages: [],
};

const messagesReducer = produce((draft, action) => {
    const {type, payload} = action;
    switch (type) {
        case messagesActionTypes.STORE_INVOICE_SUBMISSION_MESSAGES: {
            const {messagesDTO} = payload;
            if (!messagesDTO.length) {
                draft.invoiceSubmissionMessages = [];
                break;
            }
            draft.invoiceSubmissionMessages = messagesDTO.map(messageDTO => {
                return new Message().fromDTO(messageDTO);
            });
            break;
        }
        case messagesActionTypes.STORE_PARTNER_MESSAGES: {
            const {messages, totalUnread} = payload;
            if (!messages.length) {
                draft.partnerMessages = [];
                break;
            }
            const sortedMessages = sortingMessagesByDate(messages);
            draft.partnerMessages = sortedMessages.map(messageDTO => {
                return new Message().fromDTO(messageDTO);
            });
            draft.totalUnreadCount = totalUnread;
            break;
        }
    }
}, initialState);

export default messagesReducer;
