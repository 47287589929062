import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withTranslations} from '@computerrock/formation-i18n';
import {Input, Select} from '../../ui-components';
import {DestinationOptions, towingDestinationOptions} from '../constants/destinations';
import {getGeolocationFromString, setIsLocationInsidePolygon} from '../../google-map/googleMapFunctions';
import SelectOption from '../../ui-components/select/SelectOption';
import {assembleAddressString} from '../../utils/address';
import SearchAutocomplete from '../../google-map/view-elements/SearchAutocomplete';
import {partnerSupportsFlatRate} from '../utils/pricing';


class TowingSection extends React.Component {
    static propTypes = {
        invoiceSubmission: PropTypes.object.isRequired,
        pricingInfo: PropTypes.number,
        onDataChange: PropTypes.func.isRequired,
        errors: PropTypes.object,
        forwardRef: PropTypes.any.isRequired,
        isDisabled: PropTypes.bool,
        translate: PropTypes.func,
    };

    static defaultProps = {
        errors: {},
        isDisabled: false,
        pricingInfo: null,
        translate: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            geocoderErrorMessage: '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.invoiceSubmission.towingDestinationName !== this.props.invoiceSubmission.towingDestinationName
            && prevProps.invoiceSubmission.towingDestinationAddress
            !== this.props.invoiceSubmission.towingDestinationAddress) {
            this.setDestinationLocation(this.props.invoiceSubmission.towingDestinationAddress);
        }
    }

    onSelectTypeChange = destinationType => {
        const {invoiceSubmission} = this.props;

        if (!destinationType) {
            return;
        }

        let destinationState = {
            towingDestinationType: destinationType,
            towingDestinationName: invoiceSubmission.towingDestinationName,
            towingDestinationAddress: invoiceSubmission.towingDestinationAddress,
        };

        if (destinationType === DestinationOptions.VP) {
            destinationState = {
                ...destinationState,
                towingDestinationName: invoiceSubmission.vpName || invoiceSubmission.towingDestinationName,
                towingDestinationAddress: assembleAddressString({
                    street: invoiceSubmission.vpAddressStreet,
                    postalCode: invoiceSubmission.vpAddressPostCode,
                    city: invoiceSubmission.vpAddressCity,
                    country: invoiceSubmission.vpAddressCountry,
                })
                    || invoiceSubmission.towingDestinationAddress,
            };
        }
        if (destinationType === DestinationOptions.PLACE_OF_RESIDENCE) {
            const placeOfResidence = assembleAddressString({
                street: invoiceSubmission.memberAddressStreet,
                postalCode: invoiceSubmission.memberAddressPostCode,
                city: invoiceSubmission.memberAddressCity,
                country: invoiceSubmission.memberAddressCountry,
            });
            destinationState = {
                ...destinationState,
                towingDestinationName: invoiceSubmission.memberFullName || invoiceSubmission.towingDestinationName,
                towingDestinationAddress: placeOfResidence || invoiceSubmission.towingDestinationAddress,
            };
        }
        this.props.onDataChange({
            ...destinationState,
        });
    };

    onInputChange = ({name, value}) => {
        this.props.onDataChange({
            [name]: value,
        });
    };


    onAutocompleteInputChange = data => {
        this.onInputChange(data);
    };

    onAutocompleteClick = value => {
        this.props.onDataChange({
            towingDestinationAddress: value,
        });
    };

    setDestinationLocation = value => {
        const {invoiceSubmission, translate} = this.props;
        const partnerSupportsFlatRateFlag = partnerSupportsFlatRate(this.props.pricingInfo);

        if (invoiceSubmission.towingDestinationType === DestinationOptions.VP
        && invoiceSubmission.vpLatitude && invoiceSubmission.vpLongitude) {
            const towingAddressStreet = invoiceSubmission.vpAddressStreet;
            const towingAddressCity = invoiceSubmission.vpAddressCity;
            const towingAddressPostCode = invoiceSubmission.vpAddressPostCode;
            const towingAddressCountry = invoiceSubmission.vpAddressCountry;
            const towingDestinationAddress = invoiceSubmission.towingDestinationAddress;
            const towingLocation = {
                lat: invoiceSubmission.vpLatitude,
                lng: invoiceSubmission.vpLongitude,
            };
            const isTowingLocationInsideVpPolygon = setIsLocationInsidePolygon({
                lat: invoiceSubmission.vpLatitude,
                lng: invoiceSubmission.vpLongitude,
                partnerSupportsFlatRateFlag,
                polygon: invoiceSubmission.vpPolygon,
            });
            this.props.onDataChange({
                towingLocation,
                isTowingLocationInsideVpPolygon,
                towingAddressStreet,
                towingAddressCity,
                towingAddressPostCode,
                towingAddressCountry,
                towingDestinationAddress,
            });
            this.setState({
                geocoderErrorMessage: '',
            });
            return;
        }

        return getGeolocationFromString(value)
            .then(geolocation => {
                let towingLocation = null;
                let isTowingLocationInsideVpPolygon = null;
                let towingAddressStreet = value;
                let towingAddressCity = null;
                let towingAddressPostCode = null;
                let towingAddressCountry = null;
                let towingDestinationAddress = value;
                if (geolocation && geolocation.address) {
                    const {street, num, city, postCode, country,
                        formattedAddress} = geolocation.address;
                    const {lat, lng} = geolocation.location;
                    towingLocation = {lat, lng};
                    isTowingLocationInsideVpPolygon = setIsLocationInsidePolygon({
                        lat,
                        lng,
                        partnerSupportsFlatRateFlag,
                        polygon: invoiceSubmission.vpPolygon,
                    });
                    towingAddressStreet = `${street} ${num || ''}`;
                    towingAddressCity = city;
                    towingAddressPostCode = postCode;
                    towingAddressCountry = country;
                    towingDestinationAddress = formattedAddress;
                }

                this.setState({
                    geocoderErrorMessage: !towingLocation && towingAddressStreet
                        ? translate('towing_section.error_message.no_geocode')
                        : '',
                });

                this.props.onDataChange({
                    towingLocation,
                    isTowingLocationInsideVpPolygon,
                    towingAddressStreet,
                    towingAddressCity,
                    towingAddressPostCode,
                    towingAddressCountry,
                    towingDestinationAddress,
                });
            });
    };

    render() {
        const {errors, invoiceSubmission, isDisabled, translate} = this.props;
        const {geocoderErrorMessage} = this.state;
        const towingDestinationAddressError = errors[Object.keys(errors).find(key => key.includes('assignment.towing.address')
            || key.includes('assignment.towing.location')
            || key.includes('assignment.towing.isInsideVpPolygon'))];
        return (
            <Fragment>
                <div className="ace-grid__row ace-bottom-margin--md" ref={this.props.forwardRef}>
                    <div className="col-xs--12">
                        <p className="ace-copy-m">{translate('towing_section.section_title.pickup_destination')}</p>
                    </div>
                </div>
                <div className="ace-bottom-margin--md">
                    <div className="ace-grid__row">
                        <div className="col-xs--4 ace-bottom-margin--md">
                            <Select
                                onChange={this.onSelectTypeChange}
                                name="towingDestinationType"
                                value={invoiceSubmission.towingDestinationType}
                                error={errors['assignment.towing.destinationType']
                                    && translate(`global.validation_messages.${errors['assignment.towing.destinationType']}`)}
                                isDisabled={isDisabled}
                                placeholder={translate('towing_section.select_placeholder.destination')}
                            >
                                {towingDestinationOptions.map(option => {
                                    return (
                                        <SelectOption
                                            key={option}
                                            optionValue={option}
                                            optionText={translate(`global.destination_options.${option.toLowerCase()}`)}
                                        />
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-xs--6">
                            <Input
                                name="towingDestinationName"
                                label={translate('towing_section.input_label.name')}
                                value={invoiceSubmission.towingDestinationName || ''}
                                onChange={this.onInputChange}
                                error={errors['assignment.towing.destinationName']
                                    && translate(`global.validation_messages.${errors['assignment.towing.destinationName']}`)}
                                additionalAttributes={{maxLength: '50'}}
                                isDisabled={isDisabled}
                            />
                        </div>
                        <div className="col-xs--10">
                            <SearchAutocomplete
                                name="towingDestinationAddress"
                                label={translate('towing_section.autocomplete_label.address')}
                                onChange={this.onAutocompleteInputChange}
                                setAutocompleteFields={this.setDestinationLocation}
                                onOptionClick={this.onAutocompleteClick}
                                value={invoiceSubmission.towingDestinationAddress || ''}
                                isDisabled={isDisabled}
                                additionalAttributes={{maxLength: '100'}}
                                error={towingDestinationAddressError && translate(`global.validation_messages.${towingDestinationAddressError}`)}
                            />
                            <div className="ace-c-input__error">{geocoderErrorMessage}</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslations(TowingSection);
