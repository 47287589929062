import qs from 'qs';
import {v5 as uuidv5} from 'uuid';
// eslint-disable-next-line
import firebase from 'firebase/compat/app';
// eslint-disable-next-line
import 'firebase/compat/messaging';
import fingerprint from '@fingerprintjs/fingerprintjs';
import handleResponse from './handleResponse';
import resourceEndpoints from './resourceEndpoints';
import ServerResponseError from './ServerResponseError';

/**
 * ECS DriverApp Ella User Management (DEUM) microservice client
 *
 * @constructor
 */

const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

export default class MSClientDriverEllaUserManagementDEUM {
    constructor(serviceParameters = {}) {
        this.serviceParameters = {
            ...serviceParameters,
            // ensure SERVICE_URL has trailing slash
            SERVICE_URL: typeof serviceParameters.SERVICE_URL === 'string'
                ? serviceParameters.SERVICE_URL.replace(/\/?$/, '/') : '',
        };

        // add SERVICE_URL to each resource endpoint
        this.resourceEndpoints = Object.keys(resourceEndpoints)
            .reduce((parsedResourceEndpoints, endpointKey) => {
                parsedResourceEndpoints[endpointKey] = this.serviceParameters.SERVICE_URL
                    + resourceEndpoints[endpointKey];
                return parsedResourceEndpoints;
            }, {});

        return {
            getUserAccount: this.getUserAccount,
            setAccessToken: this.setAccessToken,
            fetchUsers: this.fetchUsers,
            fetchUser: this.fetchUser,
            createUser: this.createUser,
            deleteUser: this.deleteUser,
            updateUser: this.updateUser,
            registerPushNotifications: this.initPushNotification,
            unregisterPushNotifications: this.unsubscribeFromServer,
        };
    }

    accessToken = null;

    getUserAccount = () => {
        const urlPath = this.resourceEndpoints['CURRENT_USER'];
        return fetch(
            `${urlPath}`,
            {headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            method: 'GET'},
        )
            .then(handleResponse);
    };

    fetchUsers = params => {
        const {partnerIds, roleFilter} = params;

        const query = {
            partnerIds,
            ...(roleFilter && {roleFilter}),
        };
        const queryString = qs.stringify(query, {
            arrayFormat: 'comma',
        });

        const urlPath = this.resourceEndpoints['USERS'];
        return fetch(
            `${urlPath}?${queryString}`,
            {headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            method: 'GET'},
        )
            .then(handleResponse);
    };

    fetchUser = params => {
        const {userId} = params;
        const urlPath = `${this.resourceEndpoints['USERS']}/${userId}`;
        return fetch(
            `${urlPath}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`,
                },
                method: 'GET',
            },
        ).then(handleResponse);
    };

    createUser = data => {
        const {email, password, firstName, lastName, userRoles, phoneNumber} = data;
        return fetch(`${this.resourceEndpoints['USERS']}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            method: 'POST',
            body: JSON.stringify({email, password, firstName, lastName, userRoles, phoneNumber}),
        })
            .then(handleUserResponse);
    };

    deleteUser = params => {
        const apiUrl = `${this.resourceEndpoints['USERS']}/${params.userId}`;
        return fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            method: 'DELETE',
        })
            .then(handleNoResponse);
    };

    updateUser = params => {
        const {email, password, firstName, lastName, userRoles, phoneNumber, userId} = params;
        const apiUrl = `${this.resourceEndpoints['USERS']}/${userId}`;
        return fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`,
            },
            method: 'PUT',
            body: JSON.stringify({email, password, firstName, lastName, userRoles, phoneNumber}),
        })
            .then(handleUserResponse);
    };


    initPushNotification = () => {
        if ('serviceWorker' in navigator) {
            const app = firebase.initializeApp(this.serviceParameters.firebaseConfig);

            const messaging = app.messaging();
            messaging.requestPermission()
                .then(() => {
                    return messaging.getToken();
                })
                .then(token => {
                    return this.subscribeOnServer(token);
                })
            .catch(err => console.log(err)); // eslint-disable-line
        }
    };

    getDeviceId = async () => {
        const fpAgent = await fingerprint.load();
        const result = await fpAgent.get();
        return uuidv5(result.visitorId, MY_NAMESPACE);
    };

    subscribeOnServer = token => {
        return this.getDeviceId()
            .then(deviceId => {
                return this.registerDevice({token, deviceId});
            });
    };

    unsubscribeFromServer = () => {
        return this.getDeviceId()
            .then(deviceId => {
                return this.unregisterDevice({deviceId});
            });
    };

    registerDevice = params => {
        const urlPath = `${this.resourceEndpoints['NOTIFICATIONS']}`;
        const {token, deviceId} = params;
        return fetch(
            `${urlPath}`,
            {
                method: 'PUT',
                body: JSON.stringify({token, deviceId}),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`,
                },
            },
        );
    };

    unregisterDevice = params => {
        const urlPath = `${this.resourceEndpoints['NOTIFICATIONS']}`;
        const {deviceId} = params;
        return fetch(
            `${urlPath}/${deviceId}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.accessToken}`,
                },
            },
        );
    };


    setAccessToken = accessToken => {
        this.accessToken = accessToken;
    };
}

const handleNoResponse = response => {
    if (response.ok) {
        return Promise.resolve('');
    }

    return Promise.reject(new Error('error')).then(err => ServerResponseError(err, response));
};

const handleUserResponse = response => {
    if (response.ok) {
        return Promise.resolve('');
    }

    return response.json().then(error => {
        throw error;
    });
};
